import { useAuth } from "components/Auth";
import { useCallback, useEffect, useState } from "react";
import { MyAccountPresenter, UPDATE_TYPE } from "./MyAccountPresenter";
import { Account } from "api/mappers/accountMapper";
import imageCompression from "browser-image-compression";
import { useMutation, useQueryClient } from "react-query";
import { deleteAccount, putAccountUpdate } from "api/accountAPI";
import { notify } from "components/atoms/notification/Notification";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { changeLevelFromABB } from "utils/functions";
import { getAccountInfo } from "utils/AccountUtil";
import { setAccountSetting } from "redux/reducers/account/accountSlice";
import { getUserDetail } from "api/userAPI";
import { useNavigate } from "react-router-dom";
import { getCurrentMilliSecond } from "utils/timeUtil";
import { CustomError } from "api/interfaces/commonInterface.interface";
import useApiError from "hook/useApiError";

export default function MyAccountContainer(): JSX.Element {
  const navigate = useNavigate();
  const auth = useAuth();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { handleError } = useApiError();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  // 토글 상태
  const [isToggled, setIsToggled] = useState(true);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const [isImageError, setIsImageError] = useState<boolean>(false);

  const [isSwitchModal, setIsSwitchModal] = useState<boolean>(false);
  const onChangeSwitch = () => {
    setIsSwitchModal(false);
  };

  // 토글 onchange 함수
  const onChange = () => {
    setIsToggled(!isToggled);
  };

  // console.log(selectedAccount);

  const [editAccountInfo, setEditAccountInfo] = useState<Account>({
    accountId: selectedAccount.accountId,
    name: selectedAccount.accountName,
    level: selectedAccount.accountLevel,
    parentLevel: selectedAccount.parentLevel
      ? selectedAccount.parentLevel
      : "DW",
    accountNumber: selectedAccount.accountNumber,
    accountAdditionalNumber: selectedAccount.accountAdditionalNumber,
    timezone: selectedAccount.timezone,
    dateFormat: selectedAccount.dateFormat,
    timeFormat: selectedAccount.timeFormat,
    use2faAuth: selectedAccount.use2faAuth,
    hasAccounts: 0,
    hasRecorders: 0,
    hasUsers: 0,
    companyName: selectedAccount.companyName,
    companyLocation: selectedAccount.companyLocation,
    companyLogo: selectedAccount.companyLogo ? selectedAccount.companyLogo : "",
    description: selectedAccount.description,
    levelFullName: changeLevelFromABB(selectedAccount.accountLevel),
    parentAccount: [],
  });

  const [logoImageFile, setLogoImageFile] = useState<File>();
  const [logoImageUrl, setLogoImageUrl] = useState<string>(
    editAccountInfo.companyLogo
  );

  const onChangeAccountInfo = useCallback(
    (type: UPDATE_TYPE, value: number | string | boolean) => {
      if (type === UPDATE_TYPE.NAME) {
        setEditAccountInfo((info) => {
          return { ...info, name: value as string };
        });
      } else if (type === UPDATE_TYPE.DATE_FORMAT) {
        setEditAccountInfo((info) => {
          return { ...info, dateFormat: value as string };
        });
      } else if (type === UPDATE_TYPE.TIME_FORMAT) {
        setEditAccountInfo((info) => {
          return { ...info, timeFormat: value as string };
        });
      } else if (type === UPDATE_TYPE.TIMEZONE) {
        setEditAccountInfo((info) => {
          return { ...info, timezone: value as string };
        });
      } else if (type === UPDATE_TYPE.TOW_FACTOR_AUTH) {
        setEditAccountInfo((info) => {
          return { ...info, use2faAuth: value as boolean };
        });
      } else if (type === UPDATE_TYPE.ADDITIONAL_ACCOUNT_NUMBER) {
        setEditAccountInfo((info) => {
          return { ...info, accountAdditionalNumber: value as string };
        });
      } else if (type === UPDATE_TYPE.DESC) {
        setEditAccountInfo((info) => {
          return { ...info, description: value as string };
        });
      } else if (type === UPDATE_TYPE.COMPANY_NAME) {
        setEditAccountInfo((info) => {
          return { ...info, companyName: value as string };
        });
      }
      if (type === UPDATE_TYPE.COMPANY_LOCATION) {
        setEditAccountInfo((info) => {
          return { ...info, companyLocation: value as string };
        });
      }
    },
    []
  );
  useEffect(() => {
    setEditAccountInfo({
      ...editAccountInfo,
      accountId: selectedAccount.accountId,
      name: selectedAccount.accountName,
      level: selectedAccount.accountLevel,
      parentLevel: selectedAccount.parentLevel
        ? selectedAccount.parentLevel
        : "DW",
      accountNumber: selectedAccount.accountNumber,
      timezone: selectedAccount.timezone,
      dateFormat: selectedAccount.dateFormat,
      timeFormat: selectedAccount.timeFormat,
      use2faAuth: selectedAccount.use2faAuth,
      companyLogo: selectedAccount.companyLogo
        ? selectedAccount.companyLogo + "?v=" + getCurrentMilliSecond()
        : "",
      description: selectedAccount.description,
      levelFullName: changeLevelFromABB(selectedAccount.accountLevel),
    });
  }, [selectedAccount]);

  const mutationGetUserDetail = useMutation(getUserDetail, {
    onSuccess: (res: any) => {
      if (res) {
        if(res.accounts !== undefined) {
          setIsSwitchModal(true);
        }else{
          auth.logout();
        }
      } else {
        auth.logout();
      }
    },
    onError: () => {
      auth.logout();
    },
  });

  const onClickDeleteConfirm = useCallback(() => {
    setIsDeleteConfirm(!isDeleteConfirm);
  }, []);

  const mutationDeleteAccount = useMutation(deleteAccount, {
    onSuccess: (res: any) => {
      if (res.error !== 0) {
        notify(
          "error",
          intl.formatMessage({
            id: "validateMsg.account.delete.fail",
            defaultMessage: "Failed to delete organization.",
          })
        );
        return;
      } else {
        notify(
          "success",
          intl.formatMessage({
            id: "validateMsg.account.delete.success",
            defaultMessage: "Account deleted successfully.",
          })
        );
        // queryClient.invalidateQueries("account");
        if(auth.user.totalAccount === 1){
          auth.logout();
        }else{
          mutationGetUserDetail.mutate();          
        }
        //mutationGetUserDetail.mutate();
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "validateMsg.account.delete.fail",
          defaultMessage: "Failed to delete organization.",
        })
      );
    },
  });

  const deleteAccountCb = useCallback(
    (accountId: string) => {
      mutationDeleteAccount.mutate(accountId);
    },
    [mutationDeleteAccount]
  );

  const onClickDeactivate = useCallback((result: boolean) => {
    setIsDeleteConfirm(false);
    if (result) {
      deleteAccountCb(editAccountInfo.accountId);
    }
  }, []);

  const logoHandleChange = async (e: any) => {
    let file = e.target.files[0]; // 입력받은 file객체

    // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)onClickDeleteConfirm
    const options = {
      maxSizeMB: 2,
      // maxWidthOrHeight: 60,
    };
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg"
    ) {
      // imgRef.current.value = "";
      return;
    }
    try {
      setIsImageLoading(true);
      const compressedFile = await imageCompression(file, options);
      setLogoImageFile(compressedFile);
      // resize된 이미지의 url을 받아 fileUrl에 저장
      const promise = imageCompression.getDataUrlFromFile(compressedFile);
      promise.then((result) => {
        setLogoImageUrl(result);
      });
    } catch (error) {
      console.log(error);
      setIsImageError(true);
      notify(
        "error",
        intl.formatMessage({
          id: "label.account.notify.logo.upload.fail",
          defaultMessage: "Failed to upload company logo.",
        })
      );
    } finally {
      setIsImageLoading(false);
    }
  };
  const mutationUpdateAccount = useMutation(putAccountUpdate, {
    onSuccess: (res: any) => {
      if (res.error === 0) {
        notify(
          "success",
          intl.formatMessage({
            id: "validateMsg.account.modify.success",
            defaultMessage: "Account updated successfully.",
          })
        );
        queryClient.invalidateQueries("account");

        getAccountInfo(selectedAccount.accountId).then((res) => {
          console.log(res, "res");
          //Company Logo가 없는 경우 Pass해야함.
          if (res.companyLogo) {
            res.companyLogo = res.companyLogo + "?v=" + getCurrentMilliSecond();
          }
          dispatch(setAccountSetting(res));
        });
      } else {
        notify(
          "error",
          intl.formatMessage({
            id: "validateMsg.account.modify.fail",
            defaultMessage: "Failed to update organization.",
          })
        );
      }
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "validateMsg.account.modify.fail",
          defaultMessage: "Failed to update organization.",
        })
      );
    },
  });

  const onClickUpdate = useCallback(
    (accountInfo: Account, file: File) => {
      const formData = new FormData();
      if (file !== undefined) {
        formData.append("file", file);
      }
      const json = JSON.stringify(accountInfo);
      const blob = new Blob([json], { type: "application/json" });
      formData.append("accountOptionDto", blob);

      mutationUpdateAccount.mutate({
        form: formData, // Account -> UpdateRequest
        accountId: selectedAccount.accountId,
      });
    },
    [mutationUpdateAccount, editAccountInfo, selectedAccount.accountId]
  );

  return (
    // <Spin
    //   spinning={true}
    //   // spinning={mutationDeleteAccount.isLoading}
    //   size="large"
    //   indicator={
    //     <div className="progress-indicator progress-indicator--responsive" />
    //   }
    // >
    <MyAccountPresenter
      isToggled={isToggled}
      onChange={onChange}
      onClickDelete={onClickDeactivate}
      accountInfo={editAccountInfo}
      onClickSave={() => onClickUpdate(editAccountInfo, logoImageFile as File)}
      onChangeAccountInfo={onChangeAccountInfo}
      isDeleteConfirm={isDeleteConfirm}
      user={auth.user?.email as string}
      userInfo={auth.user}
      onClickDeleteConfirm={onClickDeleteConfirm}
      logoHandleChange={logoHandleChange}
      logoImage={logoImageUrl}
      isImageLoading={isImageLoading}
      isImageError={isImageError}
      isSwitchModal={isSwitchModal}
      onChangeSwitch={onChangeSwitch}
    />
    // </Spin>
  );
}
