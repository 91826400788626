import { AxiosResponse } from "axios";
import { AxiosInstance } from "./axiosInstance";
import {
  CRUDUserResponse,
  CUDGroupResponse,
  GetTokenResponse,
  GetUserAllList,
  GetUserDetailResponse,
  GetUserProfileResponse,
  GetUserResponse,
  resetInfo,
  ResponseUserInfo,
  updateProfileIamgeResponse,
  UserDetail,
  UserInfo,
} from "./interfaces/userInterface.interface";
import qs from "qs";
import {
  CreateRoleRequest,
  GetRoleListResposne,
  GetRoleRequest,
  UpdateRoleRequest,
  CreateGroupRequest,
  GetGroupListResposne,
  GetGroupRequest,
  UpdateGroupRequest,
  CreateUserRequest,
  GetUserListResposne,
  GetUserRequest,
  UpdateUserRequest,
} from "api/interfaces/userInterface.interface";
import {
  CustomError,
  HTTP_RESULT,
} from "./interfaces/commonInterface.interface";
import {
  CheckPasswordResponse,
  PostLoginRequest,
} from "./interfaces/authInterface.interface";
const USER_API_PATH = "/user";
const USER_GROUP_PATH = "/ugroup";
const ROLE_PATH = "/role";

export const getUser = async (userId: string): Promise<GetUserResponse> => {
  try {
    const { data }: AxiosResponse<GetUserResponse> = await AxiosInstance.get(
      `${USER_API_PATH}?user_id=${userId}`
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getUserProfile = async ({
  accountId,
  userId,
}: {
  accountId: string;
  userId: string;
}): Promise<GetUserDetailResponse> => {
  try {
    const { data }: AxiosResponse<GetUserDetailResponse> =
      await AxiosInstance.get(
        `${USER_API_PATH}/get/profile/${accountId}/${userId}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postChangeProfile = async ({
  userId,
  type,
  payload,
}: {
  userId: string;
  type: string;
  payload: any;
}): Promise<GetUserProfileResponse> => {
  try {
    const { data }: AxiosResponse<GetUserProfileResponse> =
      await AxiosInstance.put(
        `${USER_API_PATH}/change/${type}/${userId}`,
        payload
      );
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getTokenInfo = async (
  token: string
): Promise<GetTokenResponse> => {
  try {
    const res: AxiosResponse<GetTokenResponse> = await AxiosInstance.get(
      `${USER_API_PATH}/token?token=${token}`
    );
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const checkPassword = async ({
  payload,
}: {
  payload: PostLoginRequest;
}): Promise<CheckPasswordResponse> => {
  try {
    const { data }: AxiosResponse<CheckPasswordResponse> =
      await AxiosInstance.post(`${USER_API_PATH}/checkPassword`, payload);
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getUserDetail = async (): Promise<UserInfo> => {
  try {
    const res: AxiosResponse<ResponseUserInfo> = await AxiosInstance.get(
      `${USER_API_PATH}/get/detail`
    );
    // console.log(res.data.result, " getUserDetail");
    return res.data.result;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getUserDetailAll = async (): Promise<UserInfo> => {
  try {
    const res: AxiosResponse<ResponseUserInfo> = await AxiosInstance.get(
      `${USER_API_PATH}/get/detail`
    );
    console.log(res.data.result, " getUserDetailAll");
    return res.data.result;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getUserDetailAccount = async (
  accountId: string
): Promise<UserInfo> => {
  try {
    const res: AxiosResponse<ResponseUserInfo> = await AxiosInstance.get(
      `${USER_API_PATH}/get/detail/${accountId}`
    );
    return res.data.result;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getSwitchAccount = async (
  accountId: string
): Promise<UserInfo> => {
  try {
    const res: AxiosResponse<ResponseUserInfo> = await AxiosInstance.get(
      `${USER_API_PATH}/switchAccount/${accountId}`
    );
    return res.data.result;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getUserList = async ({
  payload,
  accountId,
}: {
  payload: GetUserRequest;
  accountId: string;
}): Promise<GetUserListResposne> => {
  try {
    const res: AxiosResponse<GetUserListResposne> = await AxiosInstance.get(
      `${USER_API_PATH}/list/${accountId}?${qs.stringify(payload)}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getUserTotalList = async ({
  payload,
  accountId,
}: {
  payload: GetUserRequest;
  accountId: string;
}): Promise<GetUserListResposne> => {
  try {
    const res: AxiosResponse<GetUserListResposne> = await AxiosInstance.get(
      `${USER_API_PATH}/totalList/${accountId}?${qs.stringify(payload)}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getUserAllList = async ({
  accountId,
}: {
  accountId: string;
}): Promise<GetUserAllList> => {
  try {
    const res: AxiosResponse<GetUserAllList> = await AxiosInstance.get(
      `${USER_API_PATH}/list/all/active`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const createUser = async ({
  payload,
  accountId,
}: {
  payload: CreateUserRequest;
  accountId: string;
}): Promise<CRUDUserResponse> => {
  try {
    const { data }: AxiosResponse<CRUDUserResponse> = await AxiosInstance.post(
      `${USER_API_PATH}/create/${accountId}`,
      payload
    );
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const updateUser = async ({
  payload,
  accountId,
  userId,
}: {
  payload: CreateUserRequest;
  accountId: string;
  userId: string;
}): Promise<CRUDUserResponse> => {
  try {
    const { data }: AxiosResponse<CRUDUserResponse> = await AxiosInstance.put(
      `${USER_API_PATH}/update/${accountId}/${userId}`,
      payload
    );
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};


export const deletableUser = async ({
  accountId,
  userId
}: {
  accountId: string,
  userId: string
}): Promise<GetUserListResposne> => {
  try {
    const res: AxiosResponse<GetUserListResposne> = await AxiosInstance.get(
      `${USER_API_PATH}/deletable/${accountId}/${userId}`
    );
    return res.data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const deleteUser = async ({
  accountId,
  userId,
}: {
  accountId: string;
  userId: string;
}): Promise<CRUDUserResponse> => {
  try {
    const { data }: AxiosResponse<CRUDUserResponse> =
      await AxiosInstance.delete(
        `${USER_API_PATH}/deactivate/${accountId}/${userId}`
      );
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const deactiveProfile = async ({
  userId,
}: {
  userId: string;
}): Promise<CRUDUserResponse> => {
  try {
    const { data }: AxiosResponse<CRUDUserResponse> =
      await AxiosInstance.delete(`${USER_API_PATH}/delete/${userId}`);
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const postUpdateProfile = async ({
  accountId,
  userId,
  payload,
}: {
  accountId: string;
  userId: string;
  payload: UserDetail;
}): Promise<CRUDUserResponse> => {
  try {
    const { data }: AxiosResponse<CRUDUserResponse> = await AxiosInstance.put(
      `${USER_API_PATH}/update/profile/${accountId}/${userId}`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getGroupList = async ({
  payload,
  accountId,
}: {
  payload: GetGroupRequest;
  accountId: string;
}): Promise<GetGroupListResposne> => {
  try {
    const res: AxiosResponse<GetGroupListResposne> = await AxiosInstance.get(
      `${USER_GROUP_PATH}/list/${accountId}?${qs.stringify(payload)}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getGroupListForEdit = async ({
  payload,
  accountId,
}: {
  payload: GetGroupRequest;
  accountId: string;
}): Promise<GetGroupListResposne> => {
  try {
    const res: AxiosResponse<GetGroupListResposne> = await AxiosInstance.get(
      `${USER_GROUP_PATH}/list/editUser/${accountId}?${qs.stringify(payload)}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const createGroup = async ({
  payload,
  accountId,
}: {
  payload: CreateGroupRequest;
  accountId: string;
}): Promise<CUDGroupResponse> => {
  try {
    const { data }: AxiosResponse<CUDGroupResponse> = await AxiosInstance.post(
      `${USER_GROUP_PATH}/create/${accountId}`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};
export const updateGroup = async ({
  payload,
  accountId,
  groupId,
}: {
  payload: CreateGroupRequest;
  accountId: string;
  groupId: string;
}): Promise<CUDGroupResponse> => {
  try {
    const { data }: AxiosResponse<CUDGroupResponse> = await AxiosInstance.put(
      `${USER_GROUP_PATH}/update/${accountId}/${groupId}`,
      payload
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const deleteGroup = async ({
  accountId,
  groupId,
}: {
  accountId: string;
  groupId: string;
}): Promise<CUDGroupResponse> => {
  try {
    const { data }: AxiosResponse<CUDGroupResponse> =
      await AxiosInstance.delete(
        `${USER_GROUP_PATH}/delete/${accountId}/${groupId}`
      );
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getRoleList = async ({
  payload,
  accountId,
}: {
  payload: GetRoleRequest;
  accountId: string;
}): Promise<GetRoleListResposne> => {
  try {
    const res: AxiosResponse<GetRoleListResposne> = await AxiosInstance.get(
      `${ROLE_PATH}/list/${accountId}?${qs.stringify(payload)}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const createRole = async ({
  payload,
  accountId,
}: {
  payload: CreateRoleRequest;
  accountId: string;
}): Promise<void> => {
  try {
    await AxiosInstance.post(`${ROLE_PATH}/create/${accountId}`, payload);
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// update profile image
export const updateProfileIamge = async ({
  accountId,
  userId,
  payload,
}: {
  accountId: string;
  userId: string;
  payload: any;
}): Promise<updateProfileIamgeResponse> => {
  // console.log(accountId, userId, payload);
  try {
    const { data }: AxiosResponse<updateProfileIamgeResponse> =
      await AxiosInstance.put(
        `${USER_API_PATH}/update/profileImage/${accountId}/${userId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

// update profile image
export const updateTheme = async ({
  accountId,
  userId,
  payload,
}: {
  accountId: string;
  userId: string;
  payload: { theme: string };
}): Promise<updateProfileIamgeResponse> => {
  // console.log(accountId, userId, payload);
  try {
    const { data }: AxiosResponse<updateProfileIamgeResponse> =
      await AxiosInstance.put(
        `${USER_API_PATH}/update/theme/${accountId}/${userId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const deleteRole = async ({
  accountId,
  emailId,
}: {
  accountId: string;
  emailId: string;
}): Promise<void> => {
  try {
    await AxiosInstance.delete(`${ROLE_PATH}/delete/${accountId}/${emailId}`);
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const deleteProfileImage = async ({
  accountId,
  userId,
}: {
  accountId: string;
  userId: string;
}): Promise<void> => {
  try {
    await AxiosInstance.delete(
      `${USER_API_PATH}/delete/profileImage/${accountId}/${userId}`
    );
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};
