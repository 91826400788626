import { StringLiteral } from "typescript";
import { CommonResponse } from "./commonInterface.interface";

export enum HTTP_RESULT {
  OK = "OK",
  NOK = "NOK",
}

export interface PostCheckEmailRequest {
  email: string;
}

export interface PostForgotEmailRequest {
  phoneNumber: string;
}

export interface resendEmailRequest {
  userId: string;
}

export interface GetTokenResponse{
  error : number;
  result : string;
}
export interface PostCheckEmailResponse {
  error_code: number;
  result: {
    isExist :boolean;
    isActivate : boolean;
  };
}
export interface CloudTokenResponse {
  error: number;
  result: {
    token :string;
    provider:string;
    host:string;
    port:string;
    username:string;
  };
}
export interface NVRTokenResponse {
  error: number;
  result: string;
}

export interface NvrLinkTokenResponse extends CommonResponse{
  result : {
    ip: string,
    mac: string,
    p2p: number,
    auth: string,
    camMax: number
  }
}

export interface PostForgotEmailResponse {
  error_code: number;
  result: PostForgotEmailRequest;
}

export interface CheckPasswordResponse {
  result: HTTP_RESULT;
  error_code: number;
  data: PostLoginRequest;
}

export interface PostLoginRequest {
  email: string;
  password: string;
  device?: string;
  twoFactorType?: string;
  trustDevice? : boolean;
}

export interface PostLoginResponse {
  error: number;
  result: {
    accessToken: string;
    refreshToken :string;
    mfaToken:string;
    requiredTwoFactor: boolean;
    twoFactorNumber: string;
    accountId : string;
    phoneNumber: string;
  }
}

export interface CheckExistResponse {
  error: number;
  result: {
   isExist : boolean; 
  }
}

export enum SEND_TYPE {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export interface PostRegisterRequest {
  email: string;
  name: string;
  phone: string;
  password: string;
  parentAccount : string;
  sendCode: SEND_TYPE;
}

export interface PostRegisterResponse {
  error: number;
  errorString: string;
  result: PostRegisterResponse;
}

export interface PostPasswordResetRequest {
  email: string;
  password: string;
}

export interface PostPasswordResetResponse {
  error: number;
  result: {
    email: string;
  }
}

export interface PostPhoneResendCordRequest {
  phoneNumber: string;
}

export interface PostEmailResendCordRequest {
  email: string;
}

export interface PostResendCordRequest {
  twoFactorType: string;
  email: string;
}

export interface PostResendCordResponse {
  error: number;
  result: {
    verifiedNumber: string;
  }
}

export interface ResponseNewToken extends CommonResponse {
  result : {
    accessToken : string;
    refreshToken :string;
  }
}