import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import TextArea from "components/atoms/input/TextArea";
import BigButton from "components/atoms/buttons/BigButton";
import StepperComponent from "components/blocks/stepper/Stepper";
import Selects from "components/atoms/select/Select";
import ModalSelects from "components/atoms/select/modal/ModalSelect";
import * as option from "utils/options";
import DW_CLOUD from "assets/images/DW_Cloud.png";

import SmallPopup from "components/templates/modal/small/SmallPopupTemplate";
import { Text } from "components/atoms/text/Text";
import MapUpload from "components/atoms/input/upload/map/MapUpload";
import DateSelect from "components/atoms/select/DateSelect";
import AddressInput from "components/atoms/input/address/AddressInput";
import AddressMap from "components/atoms/input/address/AddressMap";
import type { Option } from "utils/options";
import * as mediaQuery from "components/MediaQuery";

const recorderGroupOptions: Option[] = [
  { value: "ceritos1", label: "ceritos1" },
];

interface RecorderRuleTypes {
  name: string;
  title: string;
  subTitle: string;
}

interface Props {
  type: string;
  ruleType: string;
  onChangeRuleType: (e: any) => void;
  onModal: (type?: string) => void;
  recorderRuleTypes: Array<RecorderRuleTypes>;
  onChangePage: (name: string) => void;
  page: number;
  isLoaded: boolean;
  selected: google.maps.LatLngLiteral;
  onChangeLocationSelect: (address: google.maps.LatLngLiteral) => void;
}
export function AddListPresenter(props: Props): JSX.Element {
  const steps = [
    {
      label: "Connection",
    },
    {
      label: "Other Information",
    },
  ];

  const directs = [
    <s.AddWrapper>
      <ModalInput
        label="Host"
        essential
        isValidated
        validatedWord="Host name is essential"
      >
        <Input placeholder="Enter host IP or Pathfinder ID." isValidated />
      </ModalInput>

      <ModalInput label="Port" essential>
        <Input placeholder="Enter port number." />
      </ModalInput>

      <ModalInput label="Recorder Login" essential>
        <Input placeholder="Recorder user name" />
        <Input placeholder="Recorder password." />
      </ModalInput>
      <s.BottomButtonWrapper>
        <BigButton
          buttonType="cancel"
          label="Next"
          onClickBtn={() => props.onChangePage("next")}
        />
      </s.BottomButtonWrapper>
    </s.AddWrapper>,
    <s.CloudContainer>
      <s.Row>
        <ModalInput label="Name" essential half>
          <Input placeholder="Enter recorder name." />
        </ModalInput>

        <ModalInput label="Location" essential half>
          {/* <Input placeholder="Enter this recorders address." /> */}
          <AddressInput
            isLoaded={props.isLoaded}
            onChangeLocationSelect={props.onChangeLocationSelect}
          />
        </ModalInput>
      </s.Row>

      <s.Map>
        <AddressMap isLoaded={props.isLoaded} selected={props.selected} />
      </s.Map>

      <s.Row>
        <ModalInput label="Web Port" essential half>
          <Input placeholder="Enter web port." />
        </ModalInput>

        <ModalInput label="Installation Date" half>
          <DateSelect placeholder="Enter installation date" />
        </ModalInput>
      </s.Row>

      <s.Row>
        <ModalInput label="Custom Thumbnail" half>
          <MapUpload
            onUpload={() => console.log("upload")}
            onDelete={() => console.log("delete")}
          />
        </ModalInput>
        <ModalInput label="Recorder Group" half>
          {/* 사용자별 options by API */}
          <ModalSelects options={recorderGroupOptions} />
        </ModalInput>
      </s.Row>
      <ModalInput label="Description">
        <TextArea placeholder="Enter description here." />
      </ModalInput>
      <s.BottomButtonWrapper>
        <BigButton
          buttonType="cancel"
          label="Previous"
          onClickBtn={() => props.onChangePage("previous")}
        />
        <BigButton label="Save" onClickBtn={() => props.onChangePage("save")} />
      </s.BottomButtonWrapper>
    </s.CloudContainer>,
  ];

  const clouds = [
    <s.AddWrapper>
      <s.DwCloudContainer>
        <img src={DW_CLOUD} alt="DW Cloud image" />
        Log in to DW Spectrum Web Portal
      </s.DwCloudContainer>
      <s.InputBorder>
        <ModalInput label="Email" essential>
          <Input
            placeholder="Enter host IP or Pathfinder ID."
            value="sarmstrong@digital-watchdog.com"
          />
        </ModalInput>

        <ModalInput label="Password" essential>
          <Input
            placeholder="Enter port number. "
            value="000000"
            type="password"
          />
        </ModalInput>
      </s.InputBorder>

      <s.description>
        Users can add DW Spectrum Servers to the myDW management platform. After
        providing myDW with the DW Spectrum System Owner’s DW Cloud credentials,
        myDW will automatically add the servers that are merged within the DW
        Spectrum System and will register them to myDW as a group. If additional
        servers are merged into the DW Cloud connected DW Spectrum System, myDW
        will automatically detect and register the additional server.
      </s.description>
      <s.ButtonWrapper>
        <BigButton
          buttonType="cancel"
          label="Next"
          onClickBtn={() => props.onChangePage("next")}
        />
      </s.ButtonWrapper>
    </s.AddWrapper>,
    <s.CloudContainer>
      <s.description>
        Cerritos Office Parking Lot is part of a cloud server with multiple
        recorders, you can edit each of these recorders' info from here.
      </s.description>
      <s.CloudWrapper>
        <s.CloudLeftWrapper>
          <ModalInput label="Recorder List">
            <Input placeholder="Search" />
          </ModalInput>

          <mediaQuery.Mobile>
            {/* option : select */}
            <ModalSelects options={recorderGroupOptions} />
          </mediaQuery.Mobile>
        </s.CloudLeftWrapper>
        <s.CloudRightWrapper>
          <mediaQuery.Default>
            <s.Row>
              <ModalInput label="Location" essential flex={3}>
                <AddressInput
                  isLoaded={props.isLoaded}
                  onChangeLocationSelect={props.onChangeLocationSelect}
                />
              </ModalInput>
              <ModalInput label="Installation Date" flex={1}>
                <DateSelect right placeholder="Enter date" />
              </ModalInput>
            </s.Row>
            <s.Map>
              <AddressMap isLoaded={props.isLoaded} selected={props.selected} />
            </s.Map>
          </mediaQuery.Default>

          <mediaQuery.Mobile>
            <s.Row>
              <ModalInput label="Location" essential flex={3}>
                <AddressInput
                  isLoaded={props.isLoaded}
                  onChangeLocationSelect={props.onChangeLocationSelect}
                />

                <s.Map>
                  <AddressMap
                    isLoaded={props.isLoaded}
                    selected={props.selected}
                  />
                </s.Map>
              </ModalInput>
              <ModalInput label="Installation Date" flex={1}>
                <DateSelect right placeholder="Enter date" />
              </ModalInput>
            </s.Row>
          </mediaQuery.Mobile>

          <s.Row>
            <ModalInput label="Custom Thumbnail" flex={3}>
              <Input placeholder="" />
            </ModalInput>
            <ModalInput label="Recorder Group" flex={1}>
              {/* 사용자별 options by API */}
              <ModalSelects options={recorderGroupOptions} />
            </ModalInput>
          </s.Row>
          <ModalInput label="Description">
            <TextArea placeholder="Enter description here." />
          </ModalInput>
          <BigButton
            buttonType="cancel"
            label="Apply Changes"
            onClickBtn={() => props.onChangePage("save")}
          />
        </s.CloudRightWrapper>
      </s.CloudWrapper>
      <s.ButtonWrapper>
        <BigButton
          buttonType="cancel"
          label="Previous"
          onClickBtn={() => props.onChangePage("previous")}
        />
        <BigButton label="Save" onClickBtn={() => props.onChangePage("save")} />
      </s.ButtonWrapper>
    </s.CloudContainer>,
  ];

  return props.page >= 0 ? (
    <BigPopupTemplate
      title="Add Recorder"
      subTitle={
        props.ruleType === "directs" ? "Direct Connect" : "Cloud Connect"
      }
      onModal={props.onModal}
    >
      {props.ruleType === "directs" && (
        <>
          {props.type === "add" && (
            <StepperComponent
              activeStep={props.page}
              steps={steps}
              onClick={props.onChangePage}
            />
          )}

          {props.type === "add" ? directs[props.page] : directs[1]}
        </>
      )}

      {props.ruleType === "clouds" && (
        <s.CloudContainer>
          {props.type === "add" && (
            <StepperComponent
              activeStep={props.page}
              steps={steps}
              onClick={props.onChangePage}
            />
          )}

          {props.type === "add" ? clouds[props.page] : clouds[1]}
        </s.CloudContainer>
      )}
    </BigPopupTemplate>
  ) : (
    <SmallPopup
      title="Add Recorder"
      subTitle="Select a type of rule."
      onModal={props.onModal}
      onClick={() => props.onChangePage("next")}
    >
      <s.TypeContainer>
        {props.recorderRuleTypes.map((type, index) => (
          <s.TypeItem
            key={index}
            onClick={() => props.onChangeRuleType(type.name)}
          >
            <input
              type="radio"
              name={type.name}
              onChange={() => props.onChangeRuleType(type.name)}
              checked={props.ruleType === type.name}
            />
            <s.TypeContents>
              <Text fontSize={18} bold>
                {type.title}
              </Text>
              <Text fontSize={14} color="#80879C">
                {type.subTitle}
              </Text>
            </s.TypeContents>
          </s.TypeItem>
        ))}
      </s.TypeContainer>
    </SmallPopup>
  );
}
