import { useState } from "react";
import * as s from "styles/ModalCommonStyled";
import StepperComponent, { StepType } from "components/blocks/stepper/Stepper";
import * as DATA from "pages/alert/temp/data";
import BigButton from "components/atoms/buttons/BigButton";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import { Media, TableColumn } from "react-data-table-component";
import TextArea from "components/atoms/input/TextArea";
import { Text, ValidateWord } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import * as mediaQuery from "components/MediaQuery";
import { UPDATE_TYPE } from "./AddCvvContainer";
import { SearchCameraWithImage } from "components/element/SearchCameraWithImage";
import { SearchRecorder } from "components/element/SearchRecorder";
import {
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import {
  CVVReport,
  CameraStep,
  ReportDataRow,
} from "api/interfaces/reportInterface.interface";
import AddNotification, { NOTIFICATION_TYPE } from "../common/AddNotification";
import DeleteConfirmModal from "components/templates/modal/small/confirm/delete";
import Toggle from "components/atoms/toggle/Toggle";

type Props = {
  steps: Array<StepType>;
  page: number;
  accountId: string;
  hostName: string;
  onChangePage: (name: string, index?: number) => void;
  onChangeReportInfo: (
    type: UPDATE_TYPE,
    value:
      | string
      | number
      | boolean
      | string[]
      | boolean[]
      | number[]
      | CameraStep[]
  ) => void;
  onChangeNotification: (
    type: NOTIFICATION_TYPE,
    value: string | number | boolean | string[] | boolean[]
  ) => void;
  onClickCreate: () => void;
  data: CVVReport;
  recorderCount: number;
  cameraCount: number;
  isEdit: boolean | undefined;
  isConfirm: boolean;
  onDelete: (value: boolean) => void;
  onConfirmDelete: () => void;
  setIsDeleteConfirm?: (confirm: boolean) => void;
  onChangeEnable: (value: boolean) => void;
  onChangeCamera?: (camera: CameraStep[]) => void;
  onChangeRecorderCount: (recorderCount: number) => void;
  onChangeCameraCount: (cameraCount: number) => void;
  reportRuleValidate: Validate;
  reportRuleValidateMsg: ValidateMsg;
  selectRecorderCount: number;
  reportRow: ReportDataRow | undefined;
};

export default function AddCvvPresenter(props: Props): JSX.Element {
  const [currentMenu, setCurrentMenu] = useState(0);
  const [interval, setInterval] = useState(true);

  const onChangeMenu = (index: number) => {
    setCurrentMenu(index);
  };
  const addUserColumns: TableColumn<DATA.AddUserData>[] = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      hide: Media.SM,
    },
  ];
  const addUserGroupColumns: TableColumn<DATA.AddUserGroupData>[] = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Users",
      selector: (row) => row.users,
      center: true,
      hide: Media.SM,
    },
    {
      name: "Email",
      cell: (row) => <input type="checkbox" checked={row.emailCheck} />,
    },
    {
      name: "Push",
      cell: (row) => <input type="checkbox" checked={row.pushCheck} />,
    },
  ];

  // step 1 - recipients sub menu
  const recipientsMenus = [
    {
      label: "Add Users",
      value: true,
      placeholder: "Search users or add new ones via email.",
      columns: addUserColumns,
      data: DATA.addUserData,
    },
    {
      label: "Add User Groups",
      value: false,
      placeholder: "Search Groups",
      columns: addUserGroupColumns,
      data: DATA.addUserGroupsData,
    },
  ];

  const pages = (
    // recorders
    <>
      <div style={{ display: props.page === 0 ? "block" : "none" }}>
        <s.CheckInput>
          <input
            type="checkbox"
            onChange={(e) =>
              props.onChangeReportInfo(
                UPDATE_TYPE.AUTO_APPLY,
                e.currentTarget.checked
              )
            }
            checked={props.data.recorder.autoApply}
          />{" "}
          Apply to all current and future recorders.
        </s.CheckInput>

        <s.ModalTableContainer
          style={{
            visibility: props.data.recorder.autoApply ? "hidden" : "visible",
          }}
        >
          <SearchRecorder
            isTab
            accountId={props.accountId}
            selectedRow={props.data.recorder.systems}
            selectedGroupRow={props.data.recorder.recorderGroupId}
            onSelectedRowsChange={(value: string[]) =>
              props.onChangeReportInfo(UPDATE_TYPE.RECORDER, value)
            }
            onSelectedGroupRowsChange={(value: number[]) =>
              props.onChangeReportInfo(UPDATE_TYPE.RECORDER_GROUP, value)
            }
            userId={props.isEdit ? props.data.preferences.userId : undefined}
          />
          <ValidateWord>{props.reportRuleValidateMsg.isRecorder}</ValidateWord>
        </s.ModalTableContainer>
      </div>
      {/* AI Camera View Verification */}
      <div style={{ display: props.page === 1 ? "block" : "none" }}>
        <s.CvvReportWrapper>
          <Text fontSize={18} bold>
            Camera View Verification (Optional)
          </Text>
          <mediaQuery.Default>
            <Text className="cvv-sub-contents">
              Using Artificial Intelligence (AI), CVV monitors the image quality
              of your cameras and alerts you immediately of any deterioration or
              obstructions of the lens such as spider webs and spray paint. CVV
              also detects scenery changes.
            </Text>
          </mediaQuery.Default>
        </s.CvvReportWrapper>

        <s.CvvReportStatus>
          <span>
            <Text bold color={COLORS.BUTTONS1}>
              Recorders:
            </Text>
            <Text>
              {props.selectRecorderCount !== undefined
                ? props.selectRecorderCount
                : 0}{" "}
              / {props.recorderCount}
            </Text>
          </span>
          <span>
            <Text bold color={COLORS.BUTTONS1}>
              Cameras:
            </Text>
            <Text>{props.cameraCount}</Text>
          </span>
        </s.CvvReportStatus>

        <SearchCameraWithImage
          selectAuto={props.data.recorder.autoApply}
          accountId={props.accountId}
          selectRecorder={props.data.recorder}
          selectCamera={props.data.recorderCameraId}
          page={props.page}
          onChangeCamera={(camera: CameraStep[]) =>
            props.onChangeReportInfo(UPDATE_TYPE.CAMERA, camera)
          }
          onChangeRecorderCount={props.onChangeRecorderCount}
          onChangeCameraCount={props.onChangeCameraCount}
        />
        <ValidateWord>{props.reportRuleValidateMsg.isCamera}</ValidateWord>
      </div>
      {/* Notification */}
      <div style={{ display: props.page === 2 ? "block" : "none" }}>
        <AddNotification
          data={props.data.notification}
          accountId={props.accountId}
          onChange={props.onChangeNotification}
          isEndOfMonth={props.data.notification.isEndOfMonth}
        />
      </div>
      {/* preferences */}
      <div style={{ display: props.page === 3 ? "block" : "none" }}>
        <ModalInput label="Report Titie" essential>
          <Input
            placeholder="Enter a title for this report"
            value={props.data.preferences.title}
            onChange={(e) =>
              props.onChangeReportInfo(UPDATE_TYPE.REPORT_NAME, e.target.value)
            }
          />
        </ModalInput>
        <ValidateWord>{props.reportRuleValidateMsg.isRuleName}</ValidateWord>
        <ModalInput label="Host">
          <Input disabled value={props.hostName} />
        </ModalInput>
        <ModalInput label="Description">
          <TextArea
            placeholder="Enter Description"
            value={props.data.preferences.description}
            onChange={(e) =>
              props.onChangeReportInfo(UPDATE_TYPE.REPORT_DESC, e.target.value)
            }
          />
        </ModalInput>
        <ModalInput label="AI License">
          <BigButton
            label="Contact"
            buttonType="cancel"
            onClickBtn={() => console.log("contact")}
          />
        </ModalInput>
        {props.isEdit && (
          <s.RowModalInput>
            <Text bold>Enable</Text>
            <Toggle
              isToggled={
                props.isEdit
                  ? (props.data.preferences.isEnable as boolean)
                  : true
              }
              checked={
                props.isEdit
                  ? (props.data.preferences.isEnable as boolean)
                  : true
              }
              onChange={(
                checked: boolean,
                event: React.MouseEvent<HTMLButtonElement>
              ) => {
                // e.stopPropagation();
                props.onChangeReportInfo(UPDATE_TYPE.REPORT_ENABLE, checked);
              }}
            />
          </s.RowModalInput>
        )}
      </div>
    </>
  );

  return (
    <>
      <StepperComponent
        activeStep={props.page}
        steps={props.steps}
        onClick={props.onChangePage}
      />
      <s.ModalInnerHalf>{pages}</s.ModalInnerHalf>
      <s.ButtonWrapper>
        {props.isEdit && (
          <BigButton
            buttonType="warning"
            label="Delete"
            onClickBtn={() =>
              props.setIsDeleteConfirm !== undefined
                ? props.setIsDeleteConfirm(true)
                : null
            }
            disabled={false}
          />
        )}
        {props.page !== 0 && (
          <BigButton
            buttonType="cancel"
            label="Previous"
            onClickBtn={() => props.onChangePage("previous")}
          />
        )}

        {props.page === 3 ? (
          <BigButton label="Save" onClickBtn={props.onClickCreate} />
        ) : (
          <BigButton
            buttonType="cancel"
            label="Next"
            onClickBtn={() => props.onChangePage("next")}
          />
        )}
        {props.isConfirm && (
          <DeleteConfirmModal
            label="report rule"
            onDelete={() => props.onDelete(false)}
            onConfirmDelete={props.onConfirmDelete}
          />
        )}
      </s.ButtonWrapper>
    </>
  );
}
