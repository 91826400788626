import * as s from "./WidgetStyled";

import RGL, { WidthProvider, Layout, Responsive } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import ActiveAlerts from "./items/ActiveAlerts";
import AlertResponse from "./items/AlertResponse";
import Livefeed from "./items/Livefeed";
import AlertsComponent from "./items/Alerts";
import PLUS_ICON from "assets/icons/plus_circle_blus.svg";
import * as mediaQuery from "components/MediaQuery";
import BigButton from "components/atoms/buttons/BigButton";
import AddLayout from "./AddLayout";
import { Text } from "components/atoms/text/Text";
import { changeLayoutToRes } from "utils/functions";
import EXPAND_HANDLE from "assets/icons/expand.svg";
import { AssignModalType } from "./WidgetContainer";
import ReportEmailPopup from "components/templates/modal/small/email/ReportEmailPopup";
import SendPhonePopup from "components/templates/modal/small/phone/SendPhonePopup";
import SendOneEmailPopup from "components/templates/modal/small/email/SendOneEmailPopup";
import {
  DashbaordAlertSendMessage,
  DashboarAlertLatest,
} from "api/interfaces/dashboardInterface.interface";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface Props {
  layout: Layout[];
  isAddModal: boolean;
  isLoading: boolean;
  rowHeight: number;
  isAssignModal: AssignModalType;
  assignInfo: { [key: string]: string };
  selectedAlertItem: DashbaordAlertSendMessage;
  onAddLayout: (type?: string, newItem?: string[]) => void;
  onLayoutChange: (currentLayout: Layout[]) => void;
  onDeleteWidget: (key: string) => void;
  onAssignModal: (type: string) => void;
  onSelectItem: (item: DashboarAlertLatest) => void;
}

export default function WidgetPresenter(props: Props): JSX.Element {
  const layoutComponent: any = {
    active: <ActiveAlerts key="active" onDeleteWidget={props.onDeleteWidget} />,
    speed: <AlertResponse key="speed" onDeleteWidget={props.onDeleteWidget} />,
    // livefeed: <Livefeed key="livefeed" onDeleteWidget={props.onDeleteWidget} />,
    alerts: (
      <AlertsComponent
        key="alerts"
        onAssignModal={props.onAssignModal}
        onDeleteWidget={props.onDeleteWidget}
        onSelectItem={props.onSelectItem}
      />
    ),
  };

  return props.isLoading ? (
    <></>
  ) : (
    <>
      <s.Container>
        <mediaQuery.Default>
          <ResponsiveReactGridLayout
            autoSize
            className="layout"
            // cols={8}
            // layout={props.layout}
            breakpoints={{ lg: 1800, md: 1200, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 8, md: 8, sm: 4, xs: 4, xxs: 2 }}
            layouts={{
              lg: props.layout,
              // lg: changeLayoutToRes(props.layout),
              md: props.layout,
              sm: props.layout,
              xs: props.layout,
              xxs: props.layout,
            }}
            // margin={[10, 10]}
            // resizeHandles={["se"]}
            resizeHandle={
              <span className="react-resizable-handle react-resizable-handle-se">
                <img src={EXPAND_HANDLE} alt="expand handle icon" />
              </span>
            }
            isResizable={true}
            // onLayoutChange={props.onLayoutChange}
            onDragStop={props.onLayoutChange}
            onResizeStop={props.onLayoutChange}
            rowHeight={props.rowHeight}
            containerPadding={[0, 10]}
            // width={1600}
            draggableCancel={".drag-cancel-content"}
          >
            {props.layout.map((l) => (
              <s.GridItem key={l.i}>{layoutComponent[l.i]}</s.GridItem>
            ))}
          </ResponsiveReactGridLayout>
        </mediaQuery.Default>
        <mediaQuery.Mobile>
          {Object.values(layoutComponent).map((ol: any, idx) => (
            <s.WidgetMobileItem key={idx}>{ol}</s.WidgetMobileItem>
          ))}
        </mediaQuery.Mobile>
      </s.Container>

      {props.layout.length <= Object.keys(layoutComponent).length - 1 && (
        <s.AddButton onClick={() => props.onAddLayout("open")}>
          <strong>+</strong>
          ADD WIDGET
        </s.AddButton>
      )}

      {props.isAddModal && (
        <AddLayout
          layouts={props.layout}
          isAddModal={props.isAddModal}
          onAddLayout={props.onAddLayout}
        />
      )}

      {props.isAssignModal.email && (
        <SendOneEmailPopup
          onModal={() => props.onAssignModal("email")}
          value={props.assignInfo.email}
          recorderId={props.selectedAlertItem.recorderId}
        />
      )}

      {props.isAssignModal.phone && (
        <SendPhonePopup
          onModal={() => props.onAssignModal("phone")}
          value={props.assignInfo.phone}
          alertRuleId={props.selectedAlertItem.alertRuleId}
          recorderId={props.selectedAlertItem.recorderId}
        />
      )}
    </>
  );
}
