import CvvPresenter from "./CvvPresenter";
import {
  CVVPairItem,
  CvvQueryString,
  CvvViewListItem,
  CvvViewResponse,
  DayNightItem,
  ReportDataRow,
} from "api/interfaces/reportInterface.interface";
import { Option } from "utils/options";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { SORT_DIRECTION } from "api/interfaces/commonInterface.interface";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  getCvvRecorderList,
  getCvvView,
  getReportInfoCVV,
  putCVVReferenceUpdate,
  putCVVReferenceUpdateAll,
} from "api/reportAPI";
import { notify } from "components/atoms/notification/Notification";
import { getStorageUsage } from "api/storageAPI";
import { StorageProps } from "components/blocks/storage/StorageLabel";
import { useMutation } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";

type Props = {
  state: ReportDataRow | undefined;
};

export enum CvvBase {
  REFERENCE,
  EVENT
}

const initialCvvListItem = {
  isWarning: false,
  date: "",
  usageCameras: 0,
  totalCameras: 0,
  warningCameras: 0,
  cvvCameras: 0,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  location: {
    id: 0,
    location: "",
    latitude: 0,
    longitude: 0,
  },
  cameras: [],
};

export default function CvvContainer(props: Props): JSX.Element {
  const intl = useIntl();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const location = useLocation();
  const params = useParams();
  
  const [cvvList, setCvvList] = useState<CvvViewListItem>(initialCvvListItem);
  const [currentCvv, setCurrentCvv] = useState<DayNightItem | null>(null);
  const [currentPairitem, setCurrentPairItem] = useState<CVVPairItem | null>(
    null
  );

  const reportId = params.reportId as string;
  const [reportTitle, setReportTitle] = useState<string>("");

  // const titleTemp =
  // location.state !== null
  //   ? location.state.title
  //   : props.state
  //   ? props.state.title
  //   : "";
  // const idTemp =
  // location.state !== null
  //   ? location.state.id
  //   : props.state
  //   ? props.state.id
  //   : "";
  // const [id, setId] = useState<string | undefined>(idTemp);
  // const [title, setTitle] = useState<string | undefined>(titleTemp);

  // useEffect(()=>{
  //   if(location.state){
  //     if(location.state.id){
  //       setId(location.state.id);
  //     }
  //     if(location.state.title){
  //       setTitle(location.state.title);
  //     }
  //   }
  // },[location])
  const reportCvvDetailInfo = useQuery(
    ["reportCvvDetailInfo", reportId, selectedAccount.accountId],
    () =>
      getReportInfoCVV({
        accountId: selectedAccount.accountId,
        reportId: reportId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: selectedAccount.accountId !== "",
      onSuccess: (res: any) => {
        if (res.result !== undefined && res.error === 0) {
          setReportTitle(res.result.preferences?.title);
        }else{
          setReportTitle("N/A")
        }
      },
      onError: (e: any) => {
        setReportTitle("N/A")
      }
    }
  );

  const [storageUsage, setStorageUsage] = useState<StorageProps>({
    totalStorage: 0,
    usedStorage: 0,
  });
  const storageQuery = useQuery(
    [" storageStatus", selectedAccount.accountId],
    () =>
      getStorageUsage({
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      enabled: selectedAccount.accountId !== "",
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        console.log(res);
        if (res.error === 0 && res.result !== undefined) {
          setStorageUsage({
            totalStorage: res.result.totalStorage,
            usedStorage: res.result.usedStorage,
          });
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const [recorderList, setRecorderList] = useState<Option[]>([]);
  const [selectFilterRecorder, setSelectFilterRecorder] = useState<string>("");
  const requestRecorderList = useQuery(
    ["requestRecorderList", selectedAccount.accountId, reportId],
    () =>
      getCvvRecorderList({
        accountId: selectedAccount.accountId,
        reportId: reportId as string,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled : reportId !== undefined && selectedAccount.accountId !== "",
      onSuccess: (res: any) => {
        if (res.error !== 0 || res.result === undefined || isEmpty(res.result)) {
          setRecorderList([]);
          return;
        }
        setRecorderList(
          res.result.map((data: any) => {
            return {
              label: data.name,
              value: data.id,
            };
          })
        );
        setSelectFilterRecorder(res.result[0].id);
        setCvvQueryInfo((info) => {
          return { ...info, recorderId: res.result[0].id as string };
        });
      },
      onError: (e: any) => {
        setRecorderList([]);
      },
    }
  );

  const [cvvQueryInfo, setCvvQueryInfo] = useState<CvvQueryString>({
    total: 0,
    pageNum: 0,
    pageLimit: 10,
    keyword: "",
    sortType: "",
    sortDirection: SORT_DIRECTION.ASC,
    cvvEnabled: false,
    recorderId: selectFilterRecorder,
  });

  const [ listCount, setListCount] = useState<number>(0);

  const { error, refetch } = useQuery(
    ["cvvList", cvvQueryInfo, reportId],
    () =>
      getCvvView({
        reportId: reportId as string,
        payload: cvvQueryInfo,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: selectFilterRecorder !== "" && reportId !== undefined,
      onSuccess: (res: CvvViewResponse) => {
        // console.log(res.result);
        if (res.error !== 0 || res.result === undefined || isEmpty(res.result)) {
          setListCount(0);
          // setCvvQueryInfo((info) => {
          //   return { ...info, total: 0 as number, pageNum: 0 };
          // });
          setCvvList(initialCvvListItem);
        } else {
          setListCount(res.page.total);
          // setCvvQueryInfo((info) => {
          //   return { ...info, total: res.page.total as number };
          // });
          setCvvList(res.result);
        }
      },
      onError: (err: any) => {
        notify("error", intl.formatMessage({
          id: "label.report.notify.cvv.list.error",
          defaultMessage: "CVV search error.",
        }));
        setListCount(0);
        setCvvList(initialCvvListItem);
        // setCvvQueryInfo((info) => {
        //   return { ...info, total: 0 as number, pageNum: 0 };
        // });
      },
    }
  );

  const onChangeFilterRecorder = useCallback((value: string) => {
    setSelectFilterRecorder(value);
    setCvvQueryInfo((info) => {
      return { ...info,  pageNum : 0 , recorderId: value };
    });
  }, []);

  const onClickSearch = useCallback(
    (keyword: string) => {
      //if(cvvQueryInfo.keyword !== keyword){
        setCvvQueryInfo((query) => {
          return {
            ...query,
            keyword,
          };
        });
      // }else{
      //   refetch();
      // }
      // //refetch();
    },
    [refetch]
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (page !== cvvQueryInfo.pageNum + 1) {
        setCvvQueryInfo((info) => {
          return { ...info, pageNum: (page - 1) as number };
        });
      }
    },
    [cvvQueryInfo.pageNum]
  );

  const onChangeIsCvv = useCallback((checked: boolean) => {
    setCvvQueryInfo((info) => {
      return { ...info, cvvEnabled: checked };
    });
  }, []);

  const mutationReferenceUpdate = useMutation(putCVVReferenceUpdate, {
    onSuccess: (res: any) => {
      notify("success", intl.formatMessage({
        id: "label.report.notify.cvv.refer.camera.success",
        defaultMessage: "Camera reference updated successfully.",
      }));
      refetch();
      // window.location.reload();
    },
    onError: () => {
      notify("error", intl.formatMessage({
        id: "label.report.notify.cvv.refer.camera.fail",
        defaultMessage: "Failed to update camera reference.",
      }));
    },
  });
  const onClickUpdate = useCallback(
    (data: DayNightItem, pairItem: CVVPairItem) => {
      mutationReferenceUpdate.mutate({
        reportId: reportId,
        payload: {
          recorderId: selectFilterRecorder,
          cameraId: data.cameraId,
          channel: data.channel,
          srcId: pairItem.referenceThumbnail.thumbnailId,
          dstId: pairItem.eventThumbnail.thumbnailId,
        },
      });
    },
    [mutationReferenceUpdate, reportId, selectFilterRecorder]
  );

  const mutationReferenceUpdateAll = useMutation(putCVVReferenceUpdateAll, {
    onSuccess: (res: any) => {
      notify("success", intl.formatMessage({
        id: "label.report.notify.cvv.refer.recorder.success",
        defaultMessage: "Recorder reference updated successfully.",
      }));
      refetch();
      // window.location.reload();
    },
    onError: () => {
      notify("error", intl.formatMessage({
        id: "label.report.notify.cvv.refer.recorder.fail",
        defaultMessage: "Failed to update recorder reference.",
      }));
    },
  });
  const onClickUpdateAll = useCallback(() => {
    mutationReferenceUpdateAll.mutate({
      reportId: reportId,
    });
  }, [mutationReferenceUpdateAll, reportId]);

  return (
    <CvvPresenter
      title={reportTitle}
      storageUsage={storageUsage}
      recorderList={recorderList}
      cvvList={cvvList}
      selectFilterRecorder={selectFilterRecorder}
      onChangeFilterRecorder={onChangeFilterRecorder}
      onClickSearch={onClickSearch}
      cvvQueryInfo={cvvQueryInfo}
      onChangePage={onChangePage}
      selectedAccount={selectedAccount}
      onChangeIsCvv={onChangeIsCvv}
      onClickUpdate={onClickUpdate}
      onClickUpdateAll={onClickUpdateAll}
      currentCvv={currentCvv}
      currentPairitem={currentPairitem}
      listCount={listCount}
      // setIsCvvOpen={setIsCvvOpen}
    />
  );
}
