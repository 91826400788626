import styled from "styled-components";
import { FLEX } from "styles/flex";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import WARNING_ICON from "assets/icons/warning_circle_red_border.svg";
import CLOUD_ICON from "assets/icons/cloud_white_full.svg";
import LOCAL_ICON from "assets/icons/local.svg";
import { calcRem } from "styles/theme";
import BigButton from "components/atoms/buttons/BigButton";
import { Text } from "components/atoms/text/Text";
import { calcStoragePercent } from "utils/functions";
import StorageBar from "components/blocks/storage/StorageBar";
import * as mediaQuery from "components/MediaQuery";
import SHOP_ICON from "assets/icons/shop.svg";
import { useEffect, useState } from "react";
import {
  ReportHealthDetailCamera,
  ReportHealthDetailCloudStorage,
  ReportHealthDetailStorage,
  ReportHealthDetailStorageResponse,
} from "api/interfaces/reportInterface.interface";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";
import { useQuery } from "react-query";
import { getReportHealthDetailStorage } from "api/reportAPI";
import { getStorageUsage } from "api/storageAPI";
import { StorageProps } from "components/blocks/storage/StorageLabel";
import { SubTitle } from "pages/dashboard/map/sub/leftAside/detail/RecorderDetailStyled";
import Spinner from "components/atoms/loader/Spinner";
import { IoCloud } from "react-icons/io5";
import { FaBoxArchive } from "react-icons/fa6";

type Props = {
  // data: ReportHealthDetailStorage[];
  // cloudStorage: ReportHealthDetailCloudStorage;
  // cameraData: ReportHealthDetailCamera;
  recorderId: string;
};

export default function Storage({
  recorderId,
}: // data,
// // cameraData,
// cloudStorage,
Props): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const isMap = location.pathname.includes("map");

  // const [data, setData] = useState<ReportHealthDetailStorage[]>([]);
  // const [cloudStorage, setCloudStorage] = useState<StorageProps>({
  //   totalStorage: 0,
  //   usedStorage: 0,
  // });

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  // const cloud_usedStorage = 0;
  // const cloud_totalStorage =
  //   cameraData.totalCamera !== undefined ? 1 * cameraData.totalCamera : 0;

  const storageQuery = useQuery(
    ["storageStatus", selectedAccount],
    () =>
      getStorageUsage({
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      staleTime: 60000, //1분
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        // console.log(res);
        // if (res.error === 0 && res.result !== undefined) {
        //   // console.log(res.result);
        //   setCloudStorage({
        //     totalStorage: res.result.totalStorage,
        //     usedStorage: res.result.usedStorage,
        //   });
        // }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const getHealthStorage = useQuery(
    ["reportHealthStorage", recorderId],
    () =>
      getReportHealthDetailStorage({
        recorderId: recorderId,
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      staleTime: 60000, //1분
      refetchOnWindowFocus: false,
      onSuccess: (res: ReportHealthDetailStorageResponse) => {
        // console.log(res, " health storage");
        // if (res.error === 0 && res.result !== undefined) {
        //   setData(res.result);
        // } else {
        //   setData([]);
        // }
      },
    }
  );

  const cloudStorage = storageQuery.data?.result
    ? {
        totalStorage: storageQuery.data.result.totalStorage,
        usedStorage: storageQuery.data.result.usedStorage,
      }
    : {
        totalStorage: 0,
        usedStorage: 0,
      };
  const data = getHealthStorage.data?.result
    ? getHealthStorage.data?.result
    : [];
  const cloudPercent =
    cloudStorage.totalStorage !== 0
      ? calcStoragePercent(cloudStorage.usedStorage, cloudStorage.totalStorage)
      : 0;

  return (
    <>
      {storageQuery.isFetching || getHealthStorage.isFetching ? (
        isMap ? (
          <></>
        ) : (
          <Spinner />
        )
      ) : (
        <Inner isMap={isMap}>
          {isMap && <SubTitle>Storage</SubTitle>}

          {/* {
        storage full 일때
        full && (
          
        )
      } */}
          {!isMap && cloudPercent > 90 && (
            <Warning>
              <img src={WARNING_ICON} alt="warning circle icon" />
              Cloud storage capacity is nearly full, consider purchasing more
              storage or deleting old footage.
            </Warning>
          )}
          <StorageWrapper isMap={isMap}>
            <StorageItem isMap={isMap}>
              <span className="title">
                <IoCloud />
                <Text bold fontSize={isMap ? 12 : 16}>
                  {"Cloud Storage"}
                </Text>
              </span>
              <div className="title">
                <StorageBar
                  usedStorage={cloudStorage.usedStorage}
                  totalStorage={cloudStorage.totalStorage}
                />
              </div>
              {!isMap && cloudPercent > 90 && (
                <ButtonWrapper>
                  {selectedAccount.accountLevel === LEVEL_TYPE.EU && (
                    <BigButton
                      // isDisabled
                      onClickBtn={() =>
                        navigate("/settings/license", {
                          state: { accountId: selectedAccount.accountId },
                        })
                      }
                      label="Buy More Storage"
                      icon={SHOP_ICON}
                    />
                  )}
                </ButtonWrapper>
              )}
            </StorageItem>

            <StorageItem isMap={isMap}>
              <span className="title">
                <FaBoxArchive />
                <Text bold fontSize={isMap ? 12 : 16}>
                  {"Local Storage"}
                </Text>
              </span>
              {data.map((item: ReportHealthDetailStorage, index: number) => (
                <LocalItem key={index}>
                  <HddLabel>{item.name.replaceAll("//","\\")}</HddLabel>
                  <StorageBar
                    usedStorage={item.total - item.free}
                    totalStorage={item.total}
                    isMB
                  />
                </LocalItem>
              ))}
            </StorageItem>
          </StorageWrapper>
        </Inner>
      )}
    </>
  );
}

const Inner = styled.div<{ isMap: boolean }>`
  ${FLEX.FlexStartCenter};
  flex-direction: column;
  width: 100%;

  ${({ isMap }) =>
    isMap
      ? `
      border-bottom: 1px solid ${COLORS.BORDER};
    `
      : `
  ${mediaQuery.isDefault} {
    padding-top: 30px;
  }
  `}
`;

const Warning = styled.span`
  color: ${COLORS.PROGRESSBAR};
  ${FLEX.FlexCenterCenter};
  gap: 10px;
  padding-bottom: 20px;

  img {
    width: 20px;
  }
`;
const LocalItem = styled.div`
  margin-bottom: 16px;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 6px;
`;
const HddLabel = styled.span`
  padding: 4px 6px;
  font-size: ${calcRem(14)};
  font-weight: 500;
  background-color: ${({ theme }) =>
    theme.value === "light" ? COLORS.BORDER : COLORS.PRIMARY1};
  color: ${COLORS.WHITE};
  border-radius: 2px;
  white-space: nowrap;
`;

const ButtonWrapper = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 15px;
  width: 100%;
`;

const StorageWrapper = styled.ul<{ isMap: boolean }>`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  padding-top: 10px;
  width: 100%;

  ${({ isMap }) =>
    isMap
      ? `
      gap: 10px;
    `
      : `
    ${mediaQuery.isDefault} {
    width: 60%;
    gap: 50px;
  }
  `}

  ${mediaQuery.isMobile} {
    gap: 20px;
  }
`;

const StorageItem = styled.li<{ isMap: boolean }>`
  width: 100%;

  ${({ isMap }) =>
    isMap &&
    `
    $${FLEX.FlexStartStart};
    flex-direction: column;

    > div {
        width: 100%;
      }
  `}

  .title {
    ${FLEX.FlexStartCenter};
    gap: 8px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.COLORS.WHITE};

    > img {
      width: 20px;
    }

    ${({ isMap }) =>
      isMap &&
      `
      width 100%;
      aligtn-items:flex-start;
      gap: 6px;

      > img {
        width: 14px;
      }
    `}
  }
`;
