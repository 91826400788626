/**
 * @author ydoni
 * @date 23.01.09
 * @desctription Alert History Detail - alert history list 에서 recorderId 로 상세 조회
 */
import { Table } from "components/atoms/table/Table";
import { Media, SortOrder, TableColumn } from "react-data-table-component";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import Status from "components/blocks/status/Status";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ActiveAlert,
  HistoryDetailAlert,
  SelectedAlertView,
} from "api/interfaces/alertInterface.interface";
import TableColumnFilterTitle from "components/blocks/table/TableColumnFilterTitle";
import { substractTime } from "utils/functions";
import TableButton from "components/atoms/buttons/TableButton";
import { useCallback, useEffect, useState } from "react";
import { getAlertHistoryListDetail } from "api/alertAPI";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useQuery, useQueryClient } from "react-query";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { getRecorderTimeFormat } from "utils/timeUtil";
import { notify } from "components/atoms/notification/Notification";
import moment from "moment";
import { StatusTxt } from "./../AlertStyled";
import {
  AlertCategoryTypeFilter,
  AlertStatusTypeFilter,
  displayAlert,
} from "utils/AlertUtil";
import { useIntl } from "react-intl";
import * as mediaQuery from "components/MediaQuery";
import AlertDetailModal from "components/templates/modal/alert";
import { isNull } from "lodash";
import { menuNavigation, queryNavigate } from "utils/MenuUtil";
import { getRecorder } from "api/recorderAPI";

type Props = {};

export default function AlertHistoryDetail(props: Props): JSX.Element {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const queryClient = useQueryClient();

  const { recorderId } = useParams();

  const [mergedCount, setMergedCount] = useState<number>(0);

  const [selectedAlert, setSelectedAlert] = useState<SelectedAlertView>({
    type: location.state ? location.state.type : "",
    recorderId: recorderId !== undefined ? recorderId : "",
    recorderName: location.state ? location.state.recorderName : "",
    mergedSystemName: location.state ? location.state.systemName : "",
    mergedSystemId: location.state ? location.state.mergedSystemId : "",
    alertType: location.state ? location.state.alertType : "",
  });

  const [isPending, setIsPending] = useState<boolean>(true);
  // console.log(location.state);

  useEffect(() => {
    const changeParam = new URLSearchParams(location.search);
    if (changeParam.size > 0) {
      setAlertHistoryDetailQueryInfo((info) => {
        return {
          ...info,
          pageNum: isNull(changeParam.get("pageNum"))
            ? 0
            : Number(changeParam.get("pageNum")),
          pageLimit: isNull(changeParam.get("pageLimit"))
            ? 20
            : Number(changeParam.get("pageLimit")),
          keyword: isNull(changeParam.get("keyword"))
            ? ""
            : (changeParam.get("keyword") as string),
          sortType: isNull(changeParam.get("sortType"))
            ? "alertTrigger"
            : (changeParam.get("sortType") as string),
          sortDirection: isNull(changeParam.get("sortDirection"))
            ? SORT_DIRECTION.DESC
            : (changeParam.get("sortDirection") as SORT_DIRECTION),
          category: isNull(changeParam.get("category"))
            ? null
            : (changeParam.get("category") as string),
          status: isNull(changeParam.get("status"))
            ? null
            : (changeParam.get("status") as string),
        };
      });
    } else {
      setAlertHistoryDetailQueryInfo((info) => {
        return {
          ...info,
          pageNum: 0,
          pageLimit: 20,
          keyword: "",
          sortType: "alertTrigger",
          sortDirection: SORT_DIRECTION.DESC,
          category: null,
          status: null,
        };
      });
    }
  }, [location]);

  const [isModal, setIsModal] = useState<boolean>(false);

  const onModal = () => {
    setIsModal(!isModal);
    alertDetailQuery.refetch();
  };

  const titleComponent =
    mergedCount !== undefined && mergedCount > 1
      ? [
          {
            label: "Alert History",
            onClick: () =>
              menuNavigation(selectedAccount, navigate, "/alert/history"),
            //navigate("/alert/history"),
          },
          {
            label: selectedAlert.mergedSystemName,
            onClick: () =>
              menuNavigation(
                selectedAccount,
                navigate,
                `/alert/history/merged/${selectedAlert.mergedSystemId}`
              ),
            // navigate(`/alert/history/merged/${selectedAlert.mergedSystemId}`, {
            //   state: {
            //     mergedSystemId: selectedAlert.mergedSystemId,
            //     systemName: selectedAlert.mergedSystemName,
            //   },
            // }),
          },
          {
            label: selectedAlert.recorderName,
          },
        ]
      : [
          {
            label: "Alert History",
            onClick: () =>
              menuNavigation(selectedAccount, navigate, "/alert/history"),
          },
          {
            label: selectedAlert.recorderName,
          },
        ];
  const [listTotal, setListTotal] = useState<number>(0);

  const detailStatus = {
    totalTitle: "Total Alerts",
    totalValue: listTotal,
  };

  const [alertHistoryDetailQueryInfo, setAlertHistoryDetailQueryInfo] =
    useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 20,
      keyword: "",
      sortType: "alertTrigger",
      sortDirection: SORT_DIRECTION.DESC,
      category: null,
      status: null,
    });

  const onClickAlertCategoryType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          queryNavigate(navigate, location.pathname, {
            pageLimit: alertHistoryDetailQueryInfo.pageLimit,
            pageNum: 0,
            keyword: alertHistoryDetailQueryInfo.keyword as string,
            sortType: alertHistoryDetailQueryInfo.sortType,
            sortDirection:
              alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
            category: null,
            status: alertHistoryDetailQueryInfo.status,
          });
          setIsPending(true);
          // setAlertHistoryDetailQueryInfo((info) => {
          //   return { ...info, category: null, pageNum: 0 };
          // });
        } else {
          queryNavigate(navigate, location.pathname, {
            pageLimit: alertHistoryDetailQueryInfo.pageLimit,
            pageNum: 0,
            keyword: alertHistoryDetailQueryInfo.keyword as string,
            sortType: alertHistoryDetailQueryInfo.sortType,
            sortDirection:
              alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
            category: value,
            status: alertHistoryDetailQueryInfo.status,
          });
          setIsPending(true);
          // setAlertHistoryDetailQueryInfo((info) => {
          //   return { ...info, category: value as string, pageNum: 0 };
          // });
        }
      }
    },
    [
      alertHistoryDetailQueryInfo.keyword,
      alertHistoryDetailQueryInfo.pageLimit,
      alertHistoryDetailQueryInfo.sortDirection,
      alertHistoryDetailQueryInfo.sortType,
      alertHistoryDetailQueryInfo.status,
      location.pathname,
      navigate,
    ]
  );

  const onClickAlertStatusType = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        if (value === "all") {
          queryNavigate(navigate, location.pathname, {
            pageLimit: alertHistoryDetailQueryInfo.pageLimit,
            pageNum: 0,
            keyword: alertHistoryDetailQueryInfo.keyword as string,
            sortType: alertHistoryDetailQueryInfo.sortType,
            sortDirection:
              alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
            category: alertHistoryDetailQueryInfo.category,
            status: null,
          });
          setIsPending(true);
          // setAlertHistoryDetailQueryInfo((info) => {
          //   return { ...info, status: null, pageNum: 0 };
          // });
        } else {
          queryNavigate(navigate, location.pathname, {
            pageLimit: alertHistoryDetailQueryInfo.pageLimit,
            pageNum: 0,
            keyword: alertHistoryDetailQueryInfo.keyword as string,
            sortType: alertHistoryDetailQueryInfo.sortType,
            sortDirection:
              alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
            category: alertHistoryDetailQueryInfo.category,
            status: value,
          });
          setIsPending(true);
          // setAlertHistoryDetailQueryInfo((info) => {
          //   return { ...info, status: value as string, pageNum: 0 };
          // });
        }
      }
    },
    [
      alertHistoryDetailQueryInfo.category,
      alertHistoryDetailQueryInfo.keyword,
      alertHistoryDetailQueryInfo.pageLimit,
      alertHistoryDetailQueryInfo.sortDirection,
      alertHistoryDetailQueryInfo.sortType,
      location.pathname,
      navigate,
    ]
  );

  const detailCenterChildren = <Status status={detailStatus} />;

  const detailMobileColumns: TableColumn<any>[] = [
    {
      name: (
        <TableColumnFilterTitle
          title="Category"
          filters={AlertCategoryTypeFilter}
          onClick={onClickAlertCategoryType}
        />
      ),
      grow: 1,
      selector: (row) => row.category,
    },
    {
      name: (
        <TableColumnFilterTitle
          title="Status"
          filters={AlertStatusTypeFilter}
          onClick={onClickAlertStatusType}
        />
      ),
      grow: 1,
      cell: (row) => (
        <StatusTxt string={row.status.toUpperCase()}>{row.status}</StatusTxt>
      ),
    },
    {
      name: "Alert",
      grow: 2,
      selector: (row) => displayAlert(row),
      // selector: (row) => row.alertType !== "Video Loss"
      // ? row.alertType
      // : row.alertType.concat(row.cameraName !== undefined &&
      //   row.cameraName === ""
      //     ? ` [ ${row.channel} ]`
      //     : ` [ ${row.cameraName} ]`),
    },
    {
      id: "alertTrigger",
      name: "Date",
      grow: 1.5,
      sortable: true,
      sortField: "alertTrigger",
      selector: (row) =>
        row.alertTriggered !== undefined
          ? getRecorderTimeFormat(
              row.alertTriggered,
              selectedAccount,
              row.timezone,
              true
            )
          : "",
    },

    // 마지막 버튼
    {
      name: "",

      cell: (row) => (
        <span className="row-click-button">
          <TableButton label="View Alert" onClickBtn={() => onRowClick(row)} />
        </span>
      ),
      right: true,
    },
  ];
  const detailColumns: TableColumn<any>[] = [
    {
      name: (
        <TableColumnFilterTitle
          title="Category"
          filters={AlertCategoryTypeFilter}
          onClick={onClickAlertCategoryType}
        />
      ),
      grow: 1,
      selector: (row) => row.category,
    },
    {
      name: "Rule Name",
      grow: 1,
      selector: (row) => row.ruleName,
    },
    {
      name: (
        <TableColumnFilterTitle
          title="Status"
          filters={AlertStatusTypeFilter}
          onClick={onClickAlertStatusType}
        />
      ),
      grow: 1,
      cell: (row) => (
        <StatusTxt string={row.status.toUpperCase()}>{row.status}</StatusTxt>
      ),
    },
    {
      name: "Alert",
      grow: 2,
      selector: (row) => displayAlert(row),
      hide: Media.SM,
      // selector: (row) => row.alertType !== "Video Loss"
      // ? row.alertType
      // : row.alertType.concat(row.cameraName !== undefined &&
      //   row.cameraName === ""
      //     ? ` [ ${row.channel} ]`
      //     : ` [ ${row.cameraName} ]`),
    },
    {
      id: "alertTrigger",
      name: "Trigger Time",
      grow: 1.5,
      sortable: true,
      sortField: "alertTrigger",
      selector: (row) =>
        row.alertTriggered !== undefined
          ? getRecorderTimeFormat(
              row.alertTriggered,
              selectedAccount,
              row.timezone,
              true
            )
          : "",
    },
    {
      id: "alertClose",
      name: "Closed Time",
      grow: 1.5,
      selector: (row) =>
        row.closedDate !== undefined
          ? getRecorderTimeFormat(
              row.closedDate,
              selectedAccount,
              row.timezone,
              true
            )
          : "",
    },
    {
      name: "Duration",
      grow: 1.2,
      selector: (row) =>
        row.status === "CLOSED" || row.status === "Closed"
          ? substractTime(row.alertTriggered, row.closedDate)
          : substractTime(row.alertTriggered, moment()),
    },
    {
      name: "Resolved By",
      grow: 1.5,
      selector: (row) => (row.resolvedUsername ? row.resolvedUsername : ""),
      sortable: true,
      hide: Media.SM,
    },
    // 마지막 버튼
    {
      name: "",
      minWidth: "150px",
      grow: Media.SM ? 1 : 5,
      cell: (row) => (
        <span className="row-click-button">
          <TableButton label="View Alert" onClickBtn={() => onRowClick(row)} />
        </span>
      ),
      right: true,
    },
  ];

  const [data, setData] = useState<HistoryDetailAlert[]>([]);

  const onRowClick = (row: HistoryDetailAlert) => {
    setSelectedAlert({
      ...selectedAlert,
      historyId: row.alertHistoryId,
    });
    setIsModal(true);
  };

  const recorderInfo = useQuery(
    ["recorder", selectedAccount.accountId],
    () =>
      getRecorder({
        accountId: selectedAccount.accountId,
        recorderId: recorderId as string,
      }),

    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: selectedAccount.accountId !== "",
      onSuccess: (res: any) => {
        if (res.result === undefined || res.error !== 0) {
          return;
        }
        setMergedCount(res.result.mergedCount as number);
        setSelectedAlert((info) => {
          return {
            ...info,
            type: res.result.type,
            recorderId: res.result.recorderId,
            recorderName: res.result.name,
            mergedSystemName: res.result.mergedSystemName,
            mergedSystemId: res.result.mergedSystemId,
          };
        });
      },
      onError: (e: any) => {
        // notify("error", intl.formatMessage({
        //   id: "label.recorder.notify.search.error",
        //   defaultMessage: "Registered Recorder not founded.",
        // }));
      },
      onSettled: (e: any) => {
        setIsPending(false);
      },
    }
  );

  const alertDetailQuery = useQuery(
    ["alertRuleDetail", alertHistoryDetailQueryInfo, selectedAccount.accountId],
    () =>
      getAlertHistoryListDetail({
        accountId: selectedAccount.accountId,
        recorderId: recorderId as string,
        payload: alertHistoryDetailQueryInfo,
      }),
    {
      retry: 0,
      enabled: recorderId !== undefined && selectedAccount.accountId !== "",
      onSuccess: (res: any) => {
        console.log(res.result, 5645465);
        if (
          res.error !== undefined &&
          res.error === 0 &&
          res.result !== undefined
        ) {
          setData(res.result.alerts);
          setListTotal(res.page.total);
        } else {
          setData([]);
          setListTotal(0);
        }
      },
      onError: (e: any) => {
        setData([]);
        setListTotal(0);
        notify(
          "error",
          intl.formatMessage({
            id: "label.alertHistory.notify.search.fail",
            defaultMessage: "Alert history search error.",
          })
        );
      },
      onSettled: (e: any) => {
        setIsPending(false);
      },
    }
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      console.log(page, totalRows);

      if (page !== alertHistoryDetailQueryInfo.pageNum + 1) {
        queryNavigate(navigate, location.pathname, {
          pageLimit: alertHistoryDetailQueryInfo.pageLimit,
          pageNum: page - 1,
          keyword: alertHistoryDetailQueryInfo.keyword as string,
          sortType: alertHistoryDetailQueryInfo.sortType,
          sortDirection:
            alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
          category: alertHistoryDetailQueryInfo.category,
          status: alertHistoryDetailQueryInfo.status,
        });
        setIsPending(true);
        // setAlertHistoryDetailQueryInfo((info) => {
        //   return { ...info, pageNum: (page - 1) as number };
        // });
      }
    },
    [
      alertHistoryDetailQueryInfo.category,
      alertHistoryDetailQueryInfo.keyword,
      alertHistoryDetailQueryInfo.pageLimit,
      alertHistoryDetailQueryInfo.pageNum,
      alertHistoryDetailQueryInfo.sortDirection,
      alertHistoryDetailQueryInfo.sortType,
      alertHistoryDetailQueryInfo.status,
      location.pathname,
      navigate,
    ]
  );

  const onClickSearch = useCallback(
    (keyword: string) => {
      if (alertHistoryDetailQueryInfo.keyword !== keyword) {
        queryNavigate(navigate, location.pathname, {
          pageLimit: alertHistoryDetailQueryInfo.pageLimit,
          pageNum: 0,
          keyword: keyword,
          sortType: alertHistoryDetailQueryInfo.sortType,
          sortDirection:
            alertHistoryDetailQueryInfo.sortDirection as SORT_DIRECTION,
          category: alertHistoryDetailQueryInfo.category,
          status: alertHistoryDetailQueryInfo.status,
        });
      } else {
        alertDetailQuery.refetch();
      }
      setIsPending(true);

      // setAlertHistoryDetailQueryInfo((query) => {
      //   return {
      //     ...query,
      //     keyword,
      //   };
      // });
      // alertDetailQuery.refetch();
    },
    [
      alertDetailQuery,
      alertHistoryDetailQueryInfo.category,
      alertHistoryDetailQueryInfo.keyword,
      alertHistoryDetailQueryInfo.pageLimit,
      alertHistoryDetailQueryInfo.sortDirection,
      alertHistoryDetailQueryInfo.sortType,
      alertHistoryDetailQueryInfo.status,
      location.pathname,
      navigate,
    ]
  );

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      setAlertHistoryDetailQueryInfo((info) => {
        return {
          ...info,
          sortType: selectedColumn.sortField as string,
          sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
        };
      });
      //setIsPending(true);
    }
  };

  return (
    <>
      <TableTemplate
        detail
        titleComponent={titleComponent}
        centerChildren={detailCenterChildren}
        onClickSearch={onClickSearch}
        keyword={alertHistoryDetailQueryInfo.keyword}
      >
        <mediaQuery.Default>
          <Table
            columns={detailColumns}
            data={data}
            onRowClicked={onRowClick}
            paginationTotalRows={listTotal}
            paginationPerPage={alertHistoryDetailQueryInfo.pageLimit}
            onChangePage={onChangePage}
            handleSort={handleSort}
            defaultSortFieldId={"alertTrigger"}
            defaultSortAsc={false}
            progressPending={isPending}
          />
        </mediaQuery.Default>
        <mediaQuery.Mobile>
          <Table
            columns={detailMobileColumns}
            data={data}
            //onRowClicked={onRowClick}
            paginationTotalRows={listTotal}
            paginationPerPage={alertHistoryDetailQueryInfo.pageLimit}
            onChangePage={onChangePage}
            handleSort={handleSort}
            defaultSortFieldId={"alertTrigger"}
            defaultSortAsc={false}
          />
        </mediaQuery.Mobile>
      </TableTemplate>
      {isModal && selectedAlert.historyId !== undefined && (
        <AlertDetailModal
          onModal={onModal}
          selectedAlert={selectedAlert}
          // historyId={data.alertHistoryId}
        />
      )}
    </>
  );
}
