import * as s from "../VideoShareStyled";
import ToggleComponent from "components/atoms/toggle/Toggle";
import ModalSelects from "components/atoms/select/modal/ModalSelect";
import { Option, videoShareStatusOptions } from "utils/options";
import { Text, UnderlineText } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import USER_ICON from "assets/icons/user-comment.svg";
import TRASH_ICON from "assets/icons/trash.svg";
import Input from "components/atoms/input/Input";
import WRITE_ICON from "assets/icons/edit_gray.svg";
import { changeStatusString, changeTimeStampFormat } from "utils/functions";
import {
  NewValueInterface,
  VideoShareCommentListResult,
  VideoShareViewResult,
} from "api/interfaces/videoshareInterface.interface";

import { Fragment, useCallback, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createVideoShareComment,
  deleteVideoShareComment,
  getVideoShareViewCommentByVideoId,
  updateVideoShareContent,
} from "api/videoshareAPI";
import { notify } from "components/atoms/notification/Notification";
import Hashtag from "components/blocks/hashtag/Hashtag";
import TextArea from "components/atoms/input/TextArea";
import { useAuth } from "components/Auth";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { convertVideoStartMs, getAccountTimeFormat } from "utils/timeUtil";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";
import useApiError from "hook/useApiError";
import { CustomError } from "api/interfaces/commonInterface.interface";
import { IoMdClose } from "react-icons/io";
import { FiSave } from "react-icons/fi";

type Props = {
  videoId: string;
  state: VideoShareViewResult;
  onDelete: (value: boolean) => void;
  onUpdateContentValues: (key: string, value: any) => void;
};

export default function ViewVSContent({
  videoId,
  state,
  onDelete,
}: Props): JSX.Element {
  // console.log(state);
  const { user } = useAuth();
  const intl = useIntl();
  const { handleError } = useApiError();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const queryClient = useQueryClient();

  const tabMenu = [
    {
      label: "Information",
    },
    {
      label: "Comments",
    },
  ];

  const [isSelected, setIsSelected] = useState<number>(0);
  const [isEditDetail, setIsEditDetail] = useState({
    title: false,
    tags: false,
  });

  const [savedValue, setSavedValue] = useState<NewValueInterface>({
    title: state.title,
    tags: state.tags ? state.tags.map((tag) => tag.tag) : [],
    status: state.status,
    sharing: state.sharing,
  });

  const [newValue, setNewValue] = useState<NewValueInterface>({
    title: state.title,
    tags: state.tags ? state.tags.map((tag) => tag.tag) : [],
    status: state.status,
    sharing: state.sharing,
  });

  const hashTagKeyDownHandler = useCallback(
    (event: CustomEvent<Tagify.KeydownEventData<Tagify.TagData>>) => {
      if (event.detail.event.key === "Escape") {
        setNewValue({
          ...newValue,
          tags: savedValue.tags,
        });
        setIsEditDetail((editD) => {
          return {
            ...editD,
            tags: false,
          };
        });
      }
    },
    [savedValue]
  );

  const onChangeViewValue = useCallback((e: any) => {
    let {
      target: { value, name },
    } = e;

    onChangeNewValue(name, value);
  }, []);

  const onChangeNewValue = useCallback((name: string, value: any) => {
    setNewValue((vValue) => {
      return {
        ...vValue,
        [name]: value,
      };
    });
  }, []);

  // hashtag 변경 이벤트
  const onChangeHashtag = useCallback(
    (e: any) => {
      // console.log(e, "onChangeHashtag");
      let tempTagsArr: string[] = [];
      var valueArr = e.detail.tagify.value;

      valueArr?.map((val: any) => tempTagsArr.push(val.value));

      setNewValue({
        ...newValue,
        tags: tempTagsArr,
      });
    },
    [newValue]
  );

  const mutationUpdateVS = useMutation(updateVideoShareContent, {
    onSuccess: (data: any, variables: any, context: any) => {
      setSavedValue((vValue) => {
        return {
          ...vValue,
          [variables.key]: variables.value,
        };
      });
      // if(variables.key === "title"){
      //   onUpdateContentValues(variables.key, variables.value);
      // }

      notify(
        "success",
        intl.formatMessage({
          id: "label.videoshare.notify.save.success",
          defaultMessage: "Video share information updated successfully.",
        })
      );
      queryClient.invalidateQueries("videoshareView");
      //RND-527 Refresh tag list
      if (variables.key === "tags") {
        queryClient.invalidateQueries("videoShareHashTag");
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.videoshare.notify.save.fail",
          defaultMessage: "Failed to update video share information.",
        })
      );
    },
  });

  const onUpdate = useCallback(
    (key: string) => {
      // if (key === "title" || key === "status") {
      //   console.log(newValue[key] === state[key]);
      //   if (newValue[key] === state[key]) return;
      // }

      mutationUpdateVS.mutate({
        videoId: videoId,
        key: key,
        value: newValue[key],
      });
    },
    [mutationUpdateVS, newValue, videoId]
  );

  const onChangeStatus = useCallback(
    (e: string) => {
      setCurrentStatus({ value: e, label: changeStatusString(e) });
      onChangeNewValue("status", e);
      mutationUpdateVS.mutate({
        videoId: videoId,
        key: "status",
        value: e,
      });
    },
    [mutationUpdateVS, onChangeNewValue, videoId]
  );

  const onChangeSharing = useCallback(
    (e: boolean) => {
      onChangeNewValue("sharing", e);
      mutationUpdateVS.mutate({
        videoId: videoId,
        key: "sharing",
        value: e,
      });
    },
    [mutationUpdateVS, onChangeNewValue, videoId]
  );

  const onSaveNewValue = (key: string) => {
    setIsEditDetail((editD) => {
      return {
        ...editD,
        [key]: false,
      };
    });
    onUpdate(key);
  };

  const onSaveUndo = useCallback(
    (key: string) => {
      setNewValue({
        ...newValue,
        [key]: savedValue[key],
      });

      setIsEditDetail((editD) => {
        return {
          ...editD,
          [key]: false,
        };
      });
    },
    [newValue, savedValue]
  );

  const onChangeSelected = useCallback((index: number) => {
    setIsSelected(index);
  }, []);

  const [currentStatus, setCurrentStatus] = useState<Option>({
    value: savedValue.status,
    label: changeStatusString(savedValue.status),
  });

  useEffect(() => {
    setCurrentStatus({
      value: state.status,
      label: changeStatusString(state.status),
    });
    setNewValue({
      title: state.title,
      tags: state.tags ? state.tags.map((tag) => tag.tag) : [],
      status: state.status,
      sharing: state.sharing,
    });

    setSavedValue({
      title: state.title,
      tags: state.tags ? state.tags.map((tag) => tag.tag) : [],
      status: state.status,
      sharing: state.sharing,
    });
  }, [state]);

  const [commentList, setCommentList] = useState<
    VideoShareCommentListResult[] | undefined
  >();

  const [commentValue, setCommentValue] = useState({
    comment: "",
    warning: false,
  });

  const onChangeCommentValue = useCallback((e: any) => {
    let {
      target: { checked, value, name },
    } = e;

    if (name === "comment" && value.length >= 250) return;
    setCommentValue((comValue) => {
      if (name === "warning") {
        return {
          ...comValue,
          [name]: Boolean(checked),
        };
      }
      return {
        ...comValue,
        [name]: value,
      };
    });
  }, []);

  const mutationCreateComment = useMutation(createVideoShareComment, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.videoshare.notify.commentAdd.success",
          defaultMessage: "Comment added successfully",
        })
      );
      commentListQuery.refetch();
      setCommentValue({
        comment: "",
        warning: false,
      });
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.videoshare.notify.commentAdd.fail",
          defaultMessage: "Failed to add comment.",
        })
      );
    },
  });

  const mutationDeleteComment = useMutation(deleteVideoShareComment, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.videoshare.notify.commentDel.success",
          defaultMessage: "Comment deleted successfully.",
        })
      );
      commentListQuery.refetch();
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.videoshare.notify.commentDel.fail",
          defaultMessage: "Failed to delete comment.",
        })
      );
    },
  });

  const onSendComment = useCallback(() => {
    if (user) {
      if (commentValue.comment.length >= 250) {
        notify(
          "warning",
          intl.formatMessage({
            id: "label.videoshare.notify.maxComment",
            defaultMessage: "Your comment cannot exceed 250 characters.",
          })
        );
        return;
      }
      if (commentValue.comment !== "") {
        mutationCreateComment.mutate({
          videoId: videoId,
          payload: { ...commentValue, username: user.name, email: user.email },
        });
      }
    }
  }, [commentValue, mutationCreateComment, user, videoId]);

  const onDeleteComment = useCallback(
    (item: VideoShareCommentListResult) => {
      mutationDeleteComment.mutate({
        commentId: item.id,
      });
    },
    [user]
  );

  const commentListQuery = useQuery(
    ["videoshareViewCommentList"],
    () =>
      getVideoShareViewCommentByVideoId({
        videoId: videoId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        // console.log(videoId);
        // console.log(res, "video share view comment");
        setCommentList(res.result);
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  return (
    <s.RightAside>
      <s.Tab>
        {tabMenu.map((menu, index) => (
          <s.TabItem
            key={`tab-menu-${index}`}
            isSelected={isSelected === index}
            onClick={() => onChangeSelected(index)}
          >
            {menu.label}
          </s.TabItem>
        ))}
      </s.Tab>
      {isSelected === 0 ? (
        <s.RightWrapper>
          <s.Contents isSelected={isSelected}>
            <div className="video-share-content">
              <s.Top>
                {/* <span>
                  <s.Title>Status</s.Title>
                  <s.StatusWrapper>
                    <ModalSelects
                      options={videoShareStatusOptions}
                      value={currentStatus}
                      onChange={onChangeStatus}
                    />
                  </s.StatusWrapper>
                </span> */}
                <span>
                  <s.Title>Sharing</s.Title>
                  <ToggleComponent
                    isToggled={savedValue.sharing}
                    checked={savedValue.sharing}
                    onChange={onChangeSharing}
                  />
                </span>
              </s.Top>

              <div className="part top-contents">
                <s.ContentsItem>
                  <s.SubTitle>Name of the Video</s.SubTitle>
                  <s.ButtonWrapper>
                    {isEditDetail.title ? (
                      <>
                        <Input
                          name="title"
                          maxLength={30}
                          value={newValue.title}
                          onChange={onChangeViewValue}
                        />
                        <button
                          className="edit"
                          onClick={() => onSaveUndo("title")}
                        >
                          <IoMdClose size={24} />
                        </button>
                        <button
                          className="edit"
                          onClick={() => onSaveNewValue("title")}
                        >
                          <FiSave size={24} />
                        </button>
                      </>
                    ) : (
                      <>
                        <Text className="title">
                          {newValue.title ? newValue.title : "Undefined"}
                        </Text>
                        <button
                          className="edit"
                          onClick={() => {
                            setIsEditDetail((editD) => {
                              return {
                                ...editD,
                                title: true,
                              };
                            });
                          }}
                        >
                          <img src={WRITE_ICON} alt="write icon" />
                        </button>
                      </>
                    )}
                  </s.ButtonWrapper>
                </s.ContentsItem>
                <s.ContentsItem>
                  <s.SubTitle>Recorder</s.SubTitle>
                  <Text>{state.recorderName}</Text>
                </s.ContentsItem>
                <s.ContentsItem>
                  <s.SubTitle>Camera</s.SubTitle>
                  <Text>{state.cameraName}</Text>
                </s.ContentsItem>
                <s.ContentsItem>
                  <s.SubTitle>Video range (From ~ To)</s.SubTitle>
                  <Text>
                    {convertVideoStartMs(
                      String(state.startTimeMs),
                      state.timeZone,
                      selectedAccount
                    ) +
                      " ~ " +
                      convertVideoStartMs(
                        String(state.startTimeMs + state.durationMs),
                        state.timeZone,
                        selectedAccount
                      )}
                  </Text>
                  {/* <Text>{changeTimeStampFormat(state.timestamp)}</Text> */}
                </s.ContentsItem>
                {state.description !== undefined && state.description !== "" && (
                  <s.ContentsItem>
                    <s.SubTitle>Description</s.SubTitle>
                    <Text>{state.description}</Text>
                  </s.ContentsItem>
                )}
              </div>

              <div className="bottom-contents">
                <s.ContentsRow className="border-top part">
                  <li>
                    <s.SubTitle>Uploaded Video Size</s.SubTitle>
                    <Text>{state.size} MB</Text>
                  </li>
                  <li>
                    <s.SubTitle>Uploaded Date and Time</s.SubTitle>
                    <Text>
                      {getAccountTimeFormat(state.date, selectedAccount, true)}
                    </Text>
                  </li>
                  {/* <li>
                    <s.SubTitle>Created by</s.SubTitle>
                    <Text>{state.username}</Text>
                  </li> */}
                </s.ContentsRow>
                <s.ContentsRow className="part">
                  <li>
                    <s.SubTitle>Created By</s.SubTitle>
                    <Text>{state.username}</Text>
                  </li>
                </s.ContentsRow>
              </div>
            </div>
            <div>
              <s.DeleteButton onClick={() => onDelete(true)}>
                <UnderlineText fontSize={14} color={COLORS.BUTTONS1}>
                  Delete Video
                </UnderlineText>
                <img src={TRASH_ICON} alt="trash icon" />
              </s.DeleteButton>
            </div>
          </s.Contents>
          <s.BottomTagWrapper>
            <s.SubTitle>Tags</s.SubTitle>
            {isEditDetail.tags ? (
              <s.HashtagInputWrapper>
                <Hashtag
                  defaultValue={savedValue.tags.join(",")}
                  onChange={onChangeHashtag}
                  onKeydown={hashTagKeyDownHandler}
                />
                <button className="edit" onClick={() => onSaveUndo("tags")}>
                  <IoMdClose size={24} />
                </button>
                <button className="edit" onClick={() => onSaveNewValue("tags")}>
                  <FiSave size={24} />
                </button>
              </s.HashtagInputWrapper>
            ) : (
              <s.TagWrapper>
                <s.TagInner>
                  {savedValue.tags?.map((tag: any, idx: number) =>
                    tag !== undefined && !isEmpty(tag) ? (
                      <s.ViewHashtag key={idx} isFilter={false}>
                        {`#${tag.trim()}`}
                      </s.ViewHashtag>
                    ) : (
                      <Fragment />
                    )
                  )}
                </s.TagInner>
                <button
                  className="edit"
                  onClick={() => {
                    setIsEditDetail((editD) => {
                      return {
                        ...editD,
                        tags: true,
                      };
                    });
                  }}
                >
                  <img src={WRITE_ICON} alt="write icon" />
                </button>
              </s.TagWrapper>
            )}
          </s.BottomTagWrapper>
        </s.RightWrapper>
      ) : (
        <s.RightWrapper>
          <s.CommentContainer>
            <div className="top">
              <div className="comment-title">
                <s.Title>Comments</s.Title>
              </div>
              <s.CommentWrapper>
                {commentList?.map((item, index) => (
                  <s.CommentItem key={index} isWarning={item.warning}>
                    <img
                      className="video-profile-image"
                      src={item.profileUrl ? item.profileUrl : USER_ICON}
                      alt="user icon"
                    />
                    <div className="contents">
                      <div className="top-info">
                        <div className="user-info">
                          <Text fontSize={14} color={COLORS.BUTTONS2} bold>
                            {item.username}
                          </Text>
                          <Text fontSize={14} color={COLORS.BUTTONS1}>
                            {getAccountTimeFormat(
                              item.creationTime,
                              selectedAccount,
                              true
                            )}
                          </Text>
                        </div>
                        {item.email === user.email && (
                          <button onClick={() => onDeleteComment(item)}>
                            <img src={TRASH_ICON} alt="trash-icon" />
                          </button>
                        )}
                      </div>
                      <div className="comment">{item.comment}</div>
                    </div>
                  </s.CommentItem>
                ))}
              </s.CommentWrapper>
            </div>
            <s.InputWrapper>
              <TextArea
                value={commentValue.comment}
                onChange={onChangeCommentValue}
                placeholder="Write a comment"
                name="comment"
              />
              <div className="send-bottom">
                <label>
                  <input
                    type="checkbox"
                    name="warning"
                    onChange={onChangeCommentValue}
                    checked={commentValue.warning}
                  />
                  Important
                </label>
                <button className="send" onClick={onSendComment}>
                  {/* <img src={SEND_ICON} alt="comment_send" /> */}
                  Save
                </button>
              </div>
            </s.InputWrapper>
          </s.CommentContainer>
        </s.RightWrapper>
      )}
    </s.RightAside>
  );
}
