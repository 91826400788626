import * as s from "./WidgetStyled";
import SmallPopupTemplate from "components/templates/modal/small/SmallPopupTemplate";
import { Layout } from "react-grid-layout";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { Text } from "components/atoms/text/Text";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { useCallback, useState } from "react";

interface Props {
  layouts: Layout[];
  isAddModal: boolean;
  onAddLayout: (type?: string, newItem?: string[]) => void;
}

export default function AddLayout(props: Props): JSX.Element {
  const layout = props.layouts.map((l) => l.i);

  const [newItem, setNewItem] = useState<string[]>([]);

  const addList = [
    {
      title: "Active Alerts",
      value: "active",
    },
    {
      title: "Alert Response Speed",
      value: "speed",
    },
    // {
    //   title: "Live Feed",
    //   value: "livefeed",
    // },
    {
      title: "Alerts",
      value: "alerts",
    },
  ];

  const onChange = useCallback(
    (checked: boolean, type: string) => {
      if (checked) {
        setNewItem([...newItem, type]);
        // 체크 해제할 시 CheckList에서 해당 id값이 `아닌` 값만 배열에 넣기
      } else {
        setNewItem(newItem.filter((checkedId) => checkedId !== type));
      }
    },
    [newItem]
  );

  const onSave = useCallback(
    (type?: string) => {
      props.onAddLayout(type, newItem);
    },
    [newItem, props]
  );

  return (
    <SmallPopupTemplate
      title="Add Widgets"
      subTitle="Select a type of widget"
      onClick={() => onSave("close")}
      onModal={() => props.onAddLayout("close")}
      cancel
    >
      <RadioWrapper>
        {addList.map((addItem, index) => {
          const isDisabled = layout.includes(addItem.value);
          // 이미 레이아웃에 있는 위젯은 리스트에 표현은 하지만 checkbox disabled 함.
          return (
            <RadioItem key={index} isDisabled={isDisabled}>
              <input
                type="checkbox"
                disabled={isDisabled}
                onChange={(e) =>
                  onChange(e.currentTarget.checked, addItem.value)
                }
              />
              <span>{addItem.title}</span>
            </RadioItem>
          );
        })}
      </RadioWrapper>
    </SmallPopupTemplate>
  );
}
const RadioWrapper = styled.div`
  ${FLEX.FlexBetweenStart};
  gap: 23px;
  flex-direction: column;
  width: 400px;
  max-width: 400px;
  /* height: 300px; */
`;

const RadioItem = styled.label<{ isDisabled: boolean }>`
  ${FLEX.FlexStartStart};
  gap: 10px;
  width: 100%;
  cursor: pointer;

  span {
    color: ${({ theme, isDisabled }) =>
      isDisabled ? COLORS.BUTTONS1 : theme.COLORS.WHITE};
    font-weight: 500;
    font-size: ${calcRem(18)};
  }

  .subtitle {
    padding-top: 4px;
    white-space: normal;
    line-height: ${calcRem(18)};
  }
`;
