import styled from "styled-components";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import { Space, Switch } from "antd";

interface Props {
  isToggled: boolean;
  label?: string;
  onChange: (
    checked: boolean,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
  id?: string;
  checked?: boolean;
  disabled?: boolean;
}

const ToggleComponent = ({
  label,
  isToggled,
  onChange,
  id,
  checked,
  disabled,
}: Props) => (
  <ToggleContainer>
    <Switch
      id={id}
      disabled={disabled}
      // style={{ transform: "scale(1.1)" }}
      checkedChildren={label ? label : "ON"}
      unCheckedChildren={label ? label : "OFF"}
      onChange={onChange}
      checked={checked}
      defaultChecked={isToggled}
    />
  </ToggleContainer>
);

const ToggleContainer = styled.div`
  > button {
    padding: 0;
  }

  .ant-switch {
    background-color: ${COLORS.BUTTONS1};
  }
  .ant-switch-checked {
    background-color: ${COLORS.TOGGLE};
  }
  .ant-switch-inner {
    font-weight: 500;

    > span {
      font-size: ${calcRem(12)} !important;
    }
  }
  .ant-switch-checked:focus {
    box-shadow: unset;
  }
`;

export default ToggleComponent;
