import * as s from "../WidgetStyled";
import TitleComponent from "../../common/title/TitleComponent";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import AlertPieChart from "pages/dashboard/common/alerts/AlertPieChart";
import { useState } from "react";
import { useQuery } from "react-query";
import { getDashboardAlertActive } from "api/dashboardAPI";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import NoData from "components/templates/nopage/NoData";

type Props = {
  onDeleteWidget: (key: string) => void;
};

export default function ActiveAlerts(props: Props): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [data, setData] = useState([
    {
      id: "systemAlerts",
      label: "System",
      value: 0,
      color: COLORS.BUTTONS2,
    },
    {
      id: "storageAlerts",
      label: "Disk",
      value: 0,
      color: "#0060B2",
    },
    {
      id: "videoAlerts",
      label: "Video",
      value: 0,
      color: COLORS.SELECTED,
    },
  ]);

  const getDashbaordAlertActive = useQuery(
    ["dashboard_activeAlerts", selectedAccount],
    () =>
      getDashboardAlertActive({
        accountId: selectedAccount.accountId,
      }),
    {
      retry: 0,
      refetchInterval: 10000,
      // enabled: false,
      onSuccess: (res: any) => {
        if (res.result) {
          // console.log(res.result);
          let tempData = data.map((d) => {
            return { ...d, value: res.result[d.id] };
          });

          setData(tempData);
        }
      },
      onError: (e: any) => {},
    }
  );

  const onRefresh = () => {
    getDashbaordAlertActive.refetch();
  };

  return (
    <>
      <TitleComponent
        title="Active Alerts"
        share
        onDeleteWidget={() => props.onDeleteWidget("active")}
        onRefresh={onRefresh}
      />
      <s.PieChartWrapper>
        {data.every((d) => {
          return d.value === 0;
        }) ? (
          <NoData />
        ) : (
          <AlertPieChart data={data} />
        )}
      </s.PieChartWrapper>
    </>
  );
}
