import { useCallback, useMemo, useRef, useState } from "react";
import { EditListPresenter } from "./EditListPresenter";
import { DataRow } from "pages/recorders/list/ListContainer";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAppSelector } from "redux/hooks";
import {
  RecorderEditInfo,
  RecorderGroup,
} from "api/interfaces/recorderInterface.interface";
import {
  deleteRecorder,
  getRecorder,
  getRecorderGroupList,
  putRecorder,
} from "api/recorderAPI";
import { notify } from "components/atoms/notification/Notification";
import {
  BasePagination,
  SORT_DIRECTION,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { Option } from "utils/options";
import { MultiValue } from "react-select";
import { useAuth } from "components/Auth";
// const googleMapsLibraries: LoadScriptProps["libraries"] = ["places"];
import imageCompression from "browser-image-compression";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

export enum UPDATE_TYPE {
  NAME,
  WEB_PORT,
  INSTALL_DATE,
  RECORDER_GROUP,
  DESC,
}

interface Props {
  type: string;
  selectRow: DataRow;
  onModal: () => void;
}

function AddListContainer(props: Props): JSX.Element {
  const intl = useIntl();
  const auth = useAuth();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const queryClient = useQueryClient();

  const [ruleType, setRuleType] = useState("directs");
  const [type, setType] = useState<string>("Spectrum");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isRecorder, setIsRecorder] = useState<boolean>(false);

  const [recorder, setRecorder] = useState<RecorderEditInfo>({
    id: 0,
    recorderId: "",
    name: "",
    webPortNum: 0,
    description: "",
    customMapUrl: "",
    timezone: "",
    installedDate: "",
    recorderGroupId: [],
    isMerged: false,
    location: {
      location: "",
      latitude: 0,
      longitude: 0,
    },
  });
  const [groups, setGroups] = useState<RecorderGroup[]>([]);

  const [selectGroup, setSelectGroup] = useState<Option[]>([]);

  const [selected, setSelected] = useState<google.maps.LatLngLiteral>({
    lat: 33.8583483,
    lng: -118.0647871,
  });

  const [validate, setValidate] = useState<Validate>({
    address: false,
  });
  const [validateMsg, setValidateMsg] = useState<ValidateMsg>({
    address: "",
  });
  // if ("geolocation" in navigator) {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     setSelected({
  //       lat: position.coords.latitude,
  //       lng: position.coords.longitude,
  //     });
  //   });
  // }

  const [imgFile, setImgFile] = useState<any>("");
  const [uploadFile, setUploadFile] = useState<File>();
  const imgRef = useRef<any>();
  const mapRef = useRef<any>();

  // 이미지 업로드 input의 onChange
  const saveImgFile = async (e: any) => {
    let files = e.target.files[0];
    const options = {
      maxSizeMB: 2,
      // maxWidthOrHeight: 60,
    };

    if (
      !(
        files.type === "image/png" ||
        files.type === "image/jpg" ||
        files.type === "image/jpeg"
      )
    ) {
      imgRef.current.value = "";
      notify(
        "error",
        intl.formatMessage({
          id: "label.recorder.notify.thumbnail.type.error",
          defaultMessage: "Only PNG or JPG files can be uploaded.",
        })
      );
      return;
    }

    try {
      const compressedFile = await imageCompression(files, options);

      setUploadFile(compressedFile);

      // resize된 이미지의 url을 받아 fileUrl에 저장
      const promise = imageCompression.getDataUrlFromFile(compressedFile);
      promise.then((result) => {
        setImgFile(result);
      });
    } catch (error) {
      console.log(error);
      notify(
        "error",
        intl.formatMessage({
          id: "label.recorder.notify.thumbnail.upload.error",
          defaultMessage: "Failed to upload custom thumbnail.",
        })
      );
    }
  };

  const onDeleteImgFile = () => {
    if (imgRef.current) {
      // console.log(fileInputRef.current.value, "fileInputRef.current.value");
      imgRef.current.value = "";
      setImgFile("");
      setRecorder((info) => {
        return {
          ...(info as RecorderEditInfo),
          customMapUrl: "",
        };
      });
    }
  };

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     //HTTPS가 아닌경우 현재 BLOCK됨.
  //     navigator.geolocation.getCurrentPosition(
  //       (position: GeolocationPosition) => {
  //         const pos = {
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         };

  //         setSelected(pos);
  //       }
  //     );
  //   }
  // }, []);

  const onChangeLocationSelect = useCallback(
    (address: google.maps.LatLngLiteral, addressString: string) => {
      setRecorder((recorder) => {
        return {
          ...recorder,
          location: {
            ...recorder.location,
            location: addressString as string,
            latitude: address.lat as number,
            longitude: address.lng as number,
          },
        };
      });
      setSelected(address);
      mapRef.current.updateInputLocation(address);
    },
    []
  );

  const onMarkerChange = useCallback(
    (address: google.maps.LatLngLiteral, addressString: string) => {
      setRecorder((recorder) => {
        return {
          ...recorder,
          location: {
            ...recorder.location,
            location: addressString as string,
            latitude: address.lat as number,
            longitude: address.lng as number,
          },
        };
      });
      setSelected(address);
    },
    []
  );

  const recorderInfo = useQuery(
    ["recorder", selectedAccount.accountId],
    () =>
      getRecorder({
        accountId: selectedAccount.accountId,
        recorderId: props.selectRow.recordId,
      }),

    {
      enabled: !isRecorder,
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result === undefined || res.error !== 0) {
          return;
        }
        setRecorder({
          id: res.result.id,
          recorderId: res.result.recorderId,
          name: res.result.name,
          webPortNum: res.result.webPortNum,
          description: res.result.description,
          timezone: res.result.timezone,
          installedDate: res.result.installedDate,
          recorderGroupId: res.result.recorderGroups,
          isMerged : props.selectRow.isMerged !== undefined ? props.selectRow.isMerged : false,
          location: {
            location:
              res.result.location !== undefined
                ? res.result.location.location
                : "",
            latitude:
              res.result.location !== undefined &&
              res.result.location.latitude !== undefined
                ? res.result.location.latitude
                : selected.lat,
            longitude:
              res.result.location !== undefined &&
              res.result.location.longitude !== undefined
                ? res.result.location.longitude
                : selected.lng,
          },
        });

        if (res.result.customMapUrl !== undefined) {
          setImgFile(`${res.result.customMapUrl}?v=${Date.now()}`);
        }
        setType(res.result.type);
        const location = {
          lat:
            res.result.location !== undefined &&
            res.result.location.latitude !== undefined
              ? res.result.location.latitude
              : selected.lat,
          lng:
            res.result.location !== undefined &&
            res.result.location.longitude !== undefined
              ? res.result.location.longitude
              : selected.lng,
        };
        setSelected(location);
        setIsRecorder(true);
      },
      onError: (e: any) => {
        setIsLoading(false);
        notify(
          "error",
          intl.formatMessage({
            id: "label.recorder.notify.search.error",
            defaultMessage: "Registered recorder not found.",
          })
        );
      },
    }
  );

  const [recorderGroupQueryInfo, setRecorderGroupQueryInfo] =
    useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 2000,
      keyword: "",
      sortType: "name",
      sortDirection: SORT_DIRECTION.ASC,
    });

  const recorderGroupInfo = useQuery(
    ["recorderGroup", selectedAccount.accountId],
    () =>
      getRecorderGroupList({
        payload: recorderGroupQueryInfo,
        accountId: selectedAccount.accountId,
      }),
    {
      enabled: isRecorder,
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        setGroups(res.result);
        if (recorder.recorderGroupId !== undefined) {
          setSelectGroup(
            res.result
              .map((group: any) => {
                const { recorderGroupId, name } = group;
                return {
                  value: String(recorderGroupId),
                  label: name,
                };
              })
              .filter((option: any) =>
                recorder.recorderGroupId.includes(Number(option.value))
              )
          );
        }
        setIsLoading(true);
      },
      onError: (e: any) => {
        setIsLoading(false);
        notify(
          "error",
          intl.formatMessage({
            id: "label.recorderGroup.notify.search.error",
            defaultMessage: "Recorder group not found.",
          })
        );
      },
    }
  );

  const groupList = useMemo((): Option[] => {
    if (groups !== undefined && groups.length > 0) {
      return Array.from(groups).map((group) => {
        const { recorderGroupId, name } = group;
        return {
          value: String(recorderGroupId),
          label: name,
        };
      });
    } else {
      return [];
    }
  }, [groups]);

  // const onSave = () => {
  //   if (recorder) {
  //     if (recorder.name === "") {
  //       return;
  //     } else props.onModal();
  //   }
  // };

  const onChangeRecordInfo = useCallback(
    (
      type: UPDATE_TYPE,
      value: number | string | boolean | MultiValue<Option>
    ) => {
      if (type === UPDATE_TYPE.NAME) {
        setRecorder((info) => {
          return { ...(info as RecorderEditInfo), name: value as string };
        });
      } else if (type === UPDATE_TYPE.WEB_PORT) {
        setRecorder((info) => {
          return { ...(info as RecorderEditInfo), webPortNum: value as number };
        });
      } else if (type === UPDATE_TYPE.INSTALL_DATE) {
        setRecorder((info) => {
          return {
            ...(info as RecorderEditInfo),
            installedDate: value as string,
          };
        });
      } else if (type === UPDATE_TYPE.DESC) {
        setRecorder((info) => {
          return {
            ...(info as RecorderEditInfo),
            description: value as string,
          };
        });
      } else if (type === UPDATE_TYPE.RECORDER_GROUP) {
        setRecorder((info) => {
          // setSelectGroup(value.);
          const onSelectValue = new Array<number>();
          (value as MultiValue<any>).forEach((option) => {
            onSelectValue.push(option.value);
          });
          return {
            ...(info as RecorderEditInfo),
            recorderGroupId: onSelectValue as number[],
          };
        });

        const onSetValue = new Array<Option>();
        (value as MultiValue<any>).forEach((option) => {
          onSetValue.push(option);
        });
        setSelectGroup(onSetValue);
      }
    },
    []
  );

  const mutationPutRecorder = useMutation(putRecorder, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.recorder.notify.update.success",
          defaultMessage: "Recorder updated successfully.",
        })
      );
      props.onModal();
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.recorder.notify.update.fail",
          defaultMessage: "Failed to update recorder.",
        })
      );
    },
  });

  // name, location 없으면 save 안되도록 작업
  const onSave = useCallback(() => {
    setValidate((info) => {
      return { ...info, address: false };
    });
    setValidateMsg((info) => {
      return { ...info, address: "" };
    });

    if (isEmpty(recorder.location.location)) {
      setValidate((info) => {
        return { ...info, address: true };
      });
      setValidateMsg((info) => {
        return {
          ...info,
          address: intl.formatMessage({
            id: "validateMsg.recorder.emptyAddress",
            defaultMessage: "Please enter the address of the recorder.",
          }) as string,
        };
      });
      return;
    }
    const formData = new FormData();
    if (imgFile !== undefined && imgFile !== "" && uploadFile !== undefined) {
      formData.append("file", uploadFile as File);
    }

    const json = JSON.stringify(recorder);
    const blob = new Blob([json], { type: "application/json" });
    formData.append("recorderUpdateDto", blob);
    mutationPutRecorder.mutate({
      form: formData, // Account -> UpdateRequest
      accountId: selectedAccount.accountId,
      recorderId: recorder?.recorderId as string,
    });
  }, [imgFile, uploadFile, recorder, mutationPutRecorder, selectedAccount.accountId]);

  const onChangeAddress = (e: any) => {
    setRecorder((recorder) => {
      return {
        ...recorder,
        location: {
          ...recorder.location,
          location: e.target.value as string,
        },
      };
    });
    if (mapRef.current) {
      if (isEmpty(e.target.value)) {
        mapRef.current.showMarker(false);
      }
    }
  };

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  const onClickDeleteConfirm = useCallback(() => {
    setIsDeleteConfirm(!isDeleteConfirm);
  }, [isDeleteConfirm]);

  const mutationDeleteRecorder = useMutation(deleteRecorder, {
    onSuccess: () => {
      notify(
        "success",
        intl.formatMessage({
          id: "label.recorder.notify.delete.success",
          defaultMessage: "Recorder deleted successfully.",
        })
      );
      queryClient.invalidateQueries("recorder");
      props.onModal();
    },
    onError: () => {
      notify(
        "error",
        intl.formatMessage({
          id: "label.recorder.notify.delete.fail",
          defaultMessage: "Failed to delete recorder.",
        })
      );
    },
  });
  const onClickDelete = useCallback(
    (result: boolean) => {
      setIsDeleteConfirm(false);
      if (result) {
        mutationDeleteRecorder.mutate({
          accountId: selectedAccount.accountId,
          recorderId: recorder.recorderId,
        });
      }
    },
    [selectedAccount.accountId, mutationDeleteRecorder, recorder.recorderId]
  );

  return (
    <>
      {isLoading && (
        <EditListPresenter
          type={type}
          onModal={props.onModal}
          data={recorder as RecorderEditInfo}
          groupData={groupList}
          selectGroup={selectGroup}
          ruleType={ruleType}
          isLoaded={true}
          selected={selected}
          onChangeLocationSelect={onChangeLocationSelect}
          onSave={onSave}
          imgFile={imgFile}
          saveImgFile={saveImgFile}
          onDeleteImgFile={onDeleteImgFile}
          imgRef={imgRef}
          onChangeRecordInfo={onChangeRecordInfo}
          onChangeAddress={onChangeAddress}
          mapRef={mapRef}
          onMarkerChange={onMarkerChange}
          onClickDeleteConfirm={onClickDeleteConfirm}
          onClickDelete={onClickDelete}
          isDeleteConfirm={isDeleteConfirm}
          validate={validate}
          validateMsg={validateMsg}
          selectedAccount={selectedAccount}
        />
      )}
    </>
  );
}

export default AddListContainer;
