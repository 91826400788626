import { AxiosInstance } from "api/axiosInstance";
import { getChartLayout, postChartLayout } from "api/dashboardAPI";
import {
  ChartLayoutResponse,
  DashbaordAlertSendMessage,
  DashboarAlertLatest,
} from "api/interfaces/dashboardInterface.interface";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { Layout } from "react-grid-layout";
import { useMutation, useQuery, useQueryClient } from "react-query";

import WidgetPresenter from "./WidgetPresenter";
import { useProSidebar } from "react-pro-sidebar";

export type AssignModalType = {
  [key: string]: boolean;
};

export default function WidgetContainer(): JSX.Element {
  const { collapsed } = useProSidebar();
  const queryClient = useQueryClient();
  const initialLayout = [
    { i: "active", x: 0, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
    { i: "speed", x: 0, y: 4, w: 4, h: 2, minW: 4, minH: 2 },
    { i: "alerts", x: 4, y: 0, w: 4, h: 4, minW: 4, minH: 2 },
  ];
  //{ i: "livefeed", x: 0, y: 4, w: 4, h: 2, minW: 4, minH: 2 },

  const [layout, setLayout] = useState<Layout[]>([]);
  const [isAddModal, setIsAddModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [rowHeight, setRowHeight] = useState<number>(150);

  // Alerts Widget - assign mdoal
  const [isAssignModal, setIsAssignModal] = useState<AssignModalType>({
    phone: false,
    email: false,
  });

  const [assignInfo, setAssignInfo] = useState<{ [key: string]: string }>({
    phone: "",
    email: "",
  });

  const [selectedAlertItem, setSelectedAlertItem] =
    useState<DashbaordAlertSendMessage>({
      alertRuleId: "",
      recorderId: "",
    });

  const onAssignModal = (type: string, value?: string) => {
    if (value !== undefined) {
      setAssignInfo({
        ...assignInfo,
        [type]: value,
      });
    }

    setIsAssignModal({
      ...isAssignModal,
      [type]: !isAssignModal[type],
    });
  };

  const onSelectItem = (item: DashboarAlertLatest) => {
    setSelectedAlertItem({
      alertRuleId: item.alertRuleId,
      recorderId: item.recorderId,
    });
  };

  // 창 크기에 따라 widget 높이를 다르게 설정해야 함.
  const changeRowHeight = () => {
    if (window.innerWidth >= 1800) {
      setRowHeight(200);
    } else {
      setRowHeight(150);
    }
  };

  useEffect(() => {
    changeRowHeight();
    window.addEventListener("resize", changeRowHeight);
    return () => {
      window.removeEventListener("resize", changeRowHeight);
    };
  }, []);

  useEffect(() => {
    let result = null;
    (async () => {
      try {
        ({ data: result } = await AxiosInstance.get(`/dashboard/chart/layout`));
        // console.log(data, "========");
        if (data) {
          console.log(data);
          setLayout(data.result);
        }
      } catch (e) {
        throw new Error();
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // side bar collapse 할 때 size 조절
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 200);
  }, [collapsed]);

  const mutationUpdateLayout = useMutation(postChartLayout, {
    onSuccess: () => {
      queryClient.invalidateQueries("dashboardLayout");
    },
    onError: () => {
      console.log("erre");
    },
  });

  const calEmptySpace = (layout: Layout[]) => {
    let max = 0,
      bottomY;
    layout.forEach((lay) => {
      bottomY = lay.y + lay.h;
      if (bottomY > max) max = bottomY;
    });
    return max;
  };
  const onAddLayout = useCallback(
    (type?: string, newItem?: string[]) => {
      if (type === "close") {
        setIsAddModal(false);
      } else {
        setIsAddModal(!isAddModal);
      }
      if (newItem !== undefined) {
        let tmpLayout = [...layout];
        newItem.forEach((item) => {
          const tmp: Layout = {
            i: item,
            x: 0,
            y: calEmptySpace(tmpLayout), // puts it at the bottom
            w: 4,
            h: 2,
            minW: 4,
            minH: 2,
          };
          tmpLayout.push(tmp);
        });
        setLayout(tmpLayout);
        mutationUpdateLayout.mutate({
          payload: tmpLayout,
        });
      }
    },
    [isAddModal, layout, mutationUpdateLayout]
  );

  const { error, data } = useQuery(
    ["dashboardLayout"],
    () => getChartLayout(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result) {
          // console.log(res.result, "dashboardLayout");
          setLayout(res.result);
        } else setLayout(initialLayout);
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const onLayoutChange = (layout: Layout[]) => {
    // console.log(layout, "onlayoutchange");
    let l = layout.map((cl) => {
      return {
        i: cl.i,
        x: cl.x,
        y: cl.y,
        w: cl.w,
        h: cl.h,
        minW: 4,
        minH: 2,
      };
    });
    mutationUpdateLayout.mutate({
      payload: l,
    });
  };

  const onDeleteWidget = (key: string) => {
    let filterLayout = layout.filter((lay) => lay.i !== key);
    mutationUpdateLayout.mutate({
      payload: filterLayout,
    });
  };

  return (
    <WidgetPresenter
      layout={layout}
      isAddModal={isAddModal}
      onAddLayout={onAddLayout}
      onLayoutChange={onLayoutChange}
      onDeleteWidget={onDeleteWidget}
      isLoading={isLoading}
      rowHeight={rowHeight}
      isAssignModal={isAssignModal}
      onAssignModal={onAssignModal}
      assignInfo={assignInfo}
      selectedAlertItem={selectedAlertItem}
      onSelectItem={onSelectItem}
    />
  );
}
