import styled from "styled-components";
import { FLEX } from "styles/flex";
import { InputLabel, ValidateWord } from "components/atoms/text/Text";
import * as mediaQuery from "components/MediaQuery";

interface ModalInputProps {
  label: string;
  essential?: boolean;
  placeholder?: string;
  children?: any;
  flex?: number;
  half?: boolean;
  noBold?: boolean;
  isValidated?: boolean;
  validatedWord?: string;
  isBubble?: boolean;
  bubbleContents?: any;
}

export const Wrapper = styled.div<{ flex?: number; half?: boolean }>`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  width: ${({ half }) => (half ? "50%" : "100%")};
  padding: 10px 0;
  gap: 8px;
  flex: ${({ flex }) => flex && flex};
  ${mediaQuery.isMobile} {
    width: 100%;
  }

  .validate-word {
    padding-top: 0;
  }

  textarea {
    min-width: 100%;
    max-width: 100%;
    max-height: 150px;
  }
`;

export function ModalInput({
  label,
  essential,
  children,
  flex,
  half,
  noBold,
  isValidated,
  validatedWord,
  isBubble,
  bubbleContents,
}: ModalInputProps): JSX.Element {
  return (
    <Wrapper flex={flex} half={half}>
      <InputLabel
        label={label}
        essential={essential}
        noBold={noBold}
        isBubble={isBubble}
        bubbleContents={bubbleContents}
      />
      {children}
      {isValidated && <ValidateWord>{validatedWord}</ValidateWord>}
    </Wrapper>
  );
}
