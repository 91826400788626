import { ResponsivePie, PieSvgProps, DefaultRawDatum } from "@nivo/pie";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";

export default function PieChart(
  props: Omit<PieSvgProps<DefaultRawDatum>, "width" | "height">
): JSX.Element {
  return (
    <ResponsivePie
      margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
      padAngle={0.7}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      enableArcLinkLabels={false}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      enableArcLabels={false}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      colors={{ datum: "data.color" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      legends={[
        {
          anchor: "top-right",
          direction: "column",
          justify: false,
          translateX: 80,
          translateY: 20,
          itemsSpacing: 9,
          itemWidth: 80,
          itemHeight: 18,
          itemTextColor: COLORS.WHITE,
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 8,
          symbolShape: "circle",
          // effects: [
          //   {
          //     on: "hover",
          //     style: {
          //       itemTextColor: "#000",
          //     },
          //   },
          // ],
        },
      ]}
      theme={{
        legends: { text: { fontSize: calcRem(15), fontWeight: "normal" } },
      }}
      {...props}
    />
  );
}
