import * as s from "./StatsStyled";
import * as Interface from "api/interfaces/dashboardInterface.interface";
import {
  changeFirstWordToUppercase,
  changeNumberToFormat,
} from "utils/functions";
import EARTH_ICON from "assets/icons/earth.svg";
import CALENDAR_ICON from "assets/icons/calendar.svg";
import { Text } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import { deviceOptions, intervalOptions } from "utils/options";
import ModalSelects from "components/atoms/select/modal/ModalSelect";
import { CheckInput } from "styles/ModalCommonStyled";
import BarChart, { barKeyName } from "components/atoms/chart/BarChart";
import LineChart from "components/atoms/chart/LineChart";
import { ExportButton, SendEmailButton } from "../common/button/Buttons";
import ReportEmailPopup from "components/templates/modal/small/email/ReportEmailPopup";
import { NoticeState } from "redux/reducers/notices/noticeReducer";
import { useAppSelector } from "redux/hooks";
import NoData from "components/templates/nopage/NoData";

export const changeLabel = (key: string) => {
  if (key.includes("total")) {
    return "Recorder Count";
  }
  if (key.includes("System")) {
    return "System";
  }
  if (key.includes("Storage")) {
    return "Disk";
  }
  if (key.includes("Video")) {
    return "Video";
  }
  return key;
};

export default function StatsPresenter(props: Interface.Props): JSX.Element {
  const notice: NoticeState = useAppSelector((state) => state.notice);
  return (
    <>
      <s.Container noticeLength={notice.notices.length}>
        {/* 1 */}
        <s.Section>
          <s.Inner first>
            <s.UserLoginWrapper>
              <s.MiddleTitle>
                Global Statistics
                <s.ButtonWrapper>
                  <ExportButton
                    onClick={() => props.useExcelDownload("overview")}
                  />
                  {/* <SendEmailButton onClick={props.onChangeSendEmail} /> */}
                </s.ButtonWrapper>
              </s.MiddleTitle>

              <s.GlobalWrapper>
                {props.totalData.map((item, index) => (
                  <s.GlobalItem
                    key={index}
                    noBorder={index === props.totalData.length - 1}
                  >
                    <s.GloablTitle>
                      <s.BigTitle>
                        {`Total ${changeFirstWordToUppercase(
                          String(Object.keys(item))
                        )}`}
                      </s.BigTitle>
                      <Text className="global-subtitle">New this week</Text>
                    </s.GloablTitle>
                    <s.CountWrapper>
                      <s.GlobalCount>
                        <s.StaticIcon>
                          <img src={EARTH_ICON} alt="EARTH_ICON" />
                        </s.StaticIcon>
                        <Text fontSize={24} color={COLORS.BUTTONS2}>
                          {changeNumberToFormat(
                            item[String(Object.keys(item))].total
                          )}
                        </Text>
                      </s.GlobalCount>
                      <s.GlobalCount>
                        <s.StaticIcon>
                          <img src={CALENDAR_ICON} alt="Calendar_ICON" />
                        </s.StaticIcon>
                        <Text color={COLORS.BUTTONS2}>
                          {changeNumberToFormat(
                            item[String(Object.keys(item))].thisWeek
                          )}
                        </Text>
                      </s.GlobalCount>
                    </s.CountWrapper>
                  </s.GlobalItem>
                ))}
              </s.GlobalWrapper>
            </s.UserLoginWrapper>
            <s.UserLoginWrapper>
              <s.UserLoginTitle className="border">
                <s.MiddleTitle>User Logins</s.MiddleTitle>
                <div className="select">
                  <ModalSelects
                    options={intervalOptions}
                    defaultValue={props.loginInterval}
                    value={props.loginInterval}
                    onChange={props.onChangeLoginInterval}
                  />
                </div>
              </s.UserLoginTitle>
              <s.UserLoginChartWrapper>
                {props.loginData.length === 0 ? (
                  <NoData />
                ) : (
                  <LineChart
                    data={[
                      {
                        id: "user-login",
                        color: COLORS.COMPANY,
                        data: props.loginData,
                      },
                    ]}
                  />
                )}
              </s.UserLoginChartWrapper>
            </s.UserLoginWrapper>
          </s.Inner>
        </s.Section>

        {/* 2 */}
        <s.Section>
          <s.Inner>
            <s.MiddleTitle>
              Alert Statistics
              <s.ButtonWrapper>
                <ExportButton
                  onClick={() => props.useExcelDownload("system")}
                />
                {/* <SendEmailButton onClick={props.onChangeSendEmail} /> */}
              </s.ButtonWrapper>
            </s.MiddleTitle>
            <s.SystemContainer>
              <s.SystemWrapper>
                <s.BigTitle>Spectrum</s.BigTitle>
                <s.SystemInner>
                  {props.spectrumData !== undefined &&
                    Object.entries(props.spectrumData.spectrumStats).map(
                      (item, index) => {
                        return (
                          <s.SystemItem key={index}>
                            <s.SystemText>{changeLabel(item[0])}</s.SystemText>
                            <s.SystemText value>
                              {changeNumberToFormat(item[1])}
                              {!item[0].includes("total") && "%"}
                            </s.SystemText>
                          </s.SystemItem>
                        );
                      }
                    )}
                </s.SystemInner>
              </s.SystemWrapper>
              <s.Border></s.Border>
              <s.SystemWrapper>
                <s.BigTitle>VMAX SERIES</s.BigTitle>
                <s.SystemInner>
                  {props.spectrumData !== undefined &&
                    Object.entries(props.spectrumData.nvrStats).map(
                      (item, index) => {
                        return (
                          <s.SystemItem key={index}>
                            <s.SystemText>{changeLabel(item[0])}</s.SystemText>
                            <s.SystemText value>
                              {changeNumberToFormat(item[1])}
                              {!item[0].includes("total") && "%"}
                            </s.SystemText>
                          </s.SystemItem>
                        );
                      }
                    )}
                </s.SystemInner>
              </s.SystemWrapper>
            </s.SystemContainer>
          </s.Inner>
        </s.Section>
        <s.Section>
          <s.Inner>
            <s.MiddleTitle>Client Devices</s.MiddleTitle>
            <s.ClientChartWrapper>
              {/* {props.isLoading.device ? (
                <div className="chart-empty"></div>
              ) : ( */}
              {props.deviceData.length === 0 ? (
                <NoData />
              ) : (
                <BarChart data={props.deviceData} />
              )}
              {/* )} */}
            </s.ClientChartWrapper>
            <s.OptionWrapper>
              {deviceOptions.map((option, index) => (
                <CheckInput key={index}>
                  <input
                    type="checkbox"
                    onChange={props.onChangeDeviceOption}
                    checked={props.isDevice[option.value]}
                    name={option.value}
                  />
                  <Text fontSize={14}>{option.label}</Text>
                </CheckInput>
              ))}
            </s.OptionWrapper>
          </s.Inner>
        </s.Section>
      </s.Container>
      {props.isSendEmail && (
        <ReportEmailPopup onModal={props.onChangeSendEmail} />
      )}
    </>
  );
}
