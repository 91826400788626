import * as s from "../WidgetStyled";
import { intervalMonthOptions, responseSpeedOptions } from "utils/options";
import TitleComponent from "../../common/title/TitleComponent";
import BarChart, { barKeyName } from "components/atoms/chart/BarChart";
import { CheckInput } from "styles/ModalCommonStyled";
import { Text } from "components/atoms/text/Text";
import { useEffect, useState } from "react";
import { AlertType } from "api/interfaces/dashboardInterface.interface";
import { useQuery } from "react-query";
import { getDashboardAlertSpeed } from "api/dashboardAPI";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { BarDatum } from "@nivo/bar";
import Selects from "components/atoms/select/Select";
import NoData from "components/templates/nopage/NoData";

type Props = {
  onDeleteWidget: (key: string) => void;
};

export const changeLabelAlertResponse = (label: string) => {
  if (label === "oneHour") {
    return "One Hour";
  }
  if (label === "oneDay") {
    return "One Day";
  }
  if (label === "oneWeek") {
    return "One Week";
  }
  if (label === "overOneWeek") {
    return "Over a Week";
  }
  if (label === "overOneMonth") {
    return "Over a Month";
  }
  return label;
};

export const changeColorLabelAlertResponse = (label: string) => {
  if (label === "oneHour") {
    return "#00856F";
  }
  if (label === "oneDay") {
    return "#008AFF";
  }
  if (label === "oneWeek") {
    return "#FF8000";
  }
  if (label === "overOneWeek") {
    return "#E92A00";
  }
  if (label === "overOneMonth") {
    return "#640F9D";
  }

  return "#ccc";
};

export default function AlertResponse(props: Props): JSX.Element {
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const [isAlert, setIsAlert] = useState<AlertType>({
    all: true,
    video: true,
    system: true,
    disk: true,
    // recording: true,
  });

  const [month, setMonth] = useState<number>(1);
  const [data, setData] = useState<BarDatum[]>([]);

  const onChangeMonth = (e: any) => {
    setMonth(Number(e));
  };

  const onChangeOption = (e: any) => {
    let {
      target: { checked, name },
    } = e;

    if (name === "all") {
      if (checked) {
        setIsAlert({
          all: true,
          video: true,
          system: true,
          disk: true,
        });
      } else {
        setIsAlert({
          all: false,
          video: false,
          system: false,
          disk: false,
        });
      }

      return;
    }
    setIsAlert({
      ...isAlert,
      all: false,
      [name]: checked,
    });
  };

  const statsOverview = useQuery(
    ["statsOverview", selectedAccount, month, isAlert],
    () => {
      let tempAlert = Object.entries(isAlert)
        .filter((alert, index) => alert[1] === true)
        .map((d) => d[0]);

      let alertTypes = tempAlert.includes("all") ? null : tempAlert;

      return getDashboardAlertSpeed({
        accountId: selectedAccount.accountId,
        durationInMonth: month,
        payload: {
          alertTypes: alertTypes,
        },
      });
    },
    {
      retry: 0,
      refetchInterval: 10000,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result) {
          let tempData: BarDatum[] = [];

          if (res.result.array) {
            // console.log(res.result, res.result.array, "array");
            tempData = res.result.array.map((stat: any) => {
              // console.log((stat.value / res.result.totalValue) * 100);
              let value =
                res.result.totalValue !== 0
                  ? ((stat.value / res.result.totalValue) * 100).toFixed(2)
                  : 0;

              return {
                indexName: changeLabelAlertResponse(stat.label),
                [barKeyName]: value,
                [`${barKeyName}Color`]: changeColorLabelAlertResponse(
                  stat.label
                ),
              };
            });
          }

          setData(tempData);
        }
      },
      onError: (e: any) => {
        console.log(e, "error");
      },
    }
  );

  const onRefresh = () => {
    statsOverview.refetch();
  };

  // console.log(data, "alert response data");

  return (
    <>
      <TitleComponent
        title="Alert Response Speed"
        share
        selectBox={
          <Selects
            options={intervalMonthOptions}
            value={intervalMonthOptions.filter(function (option) {
              return option.value === String(month);
            })}
            onChange={onChangeMonth}
          />
        }
        onDeleteWidget={() => props.onDeleteWidget("speed")}
        onRefresh={onRefresh}
      />
      <s.AlertSpeedInner>
        <s.BarChartWrapper>
          {data.length === 0 ||
          data.every((d) => {
            return Number(d.barChartKey) === 0.0 || Number(d.barChartKey) === 0;
          }) ? (
            <NoData />
          ) : (
            <BarChart data={data} />
          )}
        </s.BarChartWrapper>
        <s.OptionWrapper>
          {responseSpeedOptions.map((option, index) => (
            <CheckInput key={`speed-option-${index}`}>
              <input
                type="checkbox"
                onChange={onChangeOption}
                checked={isAlert[option.value]}
                name={option.value}
              />
              <Text fontSize={14}>{option.label}</Text>
            </CheckInput>
          ))}
        </s.OptionWrapper>
      </s.AlertSpeedInner>
    </>
  );
}
