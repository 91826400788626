import { useState } from "react";
import * as s from "../CommonStyled";
import { CenterlineText, Text } from "components/atoms/text/Text";
import SHARE_ICON from "assets/icons/share_gray.svg";
import REFRESH_ICON from "assets/icons/refresh_gray.svg";
import X_ICON from "assets/icons/cross2.svg";
import SearchInput from "components/atoms/input/search/SearchInput";
import OptionBubble from "components/blocks/bubble/OptionBubble";
import { COLORS } from "styles/colors";
import HIDE_ICON from "assets/icons/hide.svg";

type DualProps = {
  isSearch?: boolean;
  share?: boolean;
  title: string;
  isMap?: boolean;
  selectBox?: React.ReactNode;
  onDeleteWidget?: () => void;
  onRefresh: () => void;
  onClickSearch?: (value: string) => void;
};

const TitleComponent = (props: DualProps): JSX.Element => {
  return (
    <>
      <s.TitleWrapper>
        <s.Title>
          <Text bold fontSize={20}>
            {props.title}
          </Text>
        </s.Title>

        <s.IconWrapper>
          {props.selectBox && props.selectBox}
          {props.isSearch && (
            <s.Search>
              <SearchInput
                className="drag-cancel-content"
                placeholder="Search"
                onClickSearch={props.onClickSearch}
              />
            </s.Search>
          )}
          <button onClick={props.onRefresh}>
            <img src={REFRESH_ICON} alt="refresh icon" />
          </button>
          {/* {props.share && (
            <button>
              <img src={SHARE_ICON} alt="share icon" />
            </button>
          )} */}
          {!props.isMap && (
            <button className="close-button" onClick={props.onDeleteWidget}>
              <img src={X_ICON} alt="close icon" />
            </button>
          )}
        </s.IconWrapper>
      </s.TitleWrapper>
    </>
  );
};

export default TitleComponent;
