/**
 * @author ydoni
 * @description 검정색 말풍선 info icon 정보 bubble tooltip
 */
import styled from "styled-components";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";

type InfoBubbleProps = {
  children: React.ReactNode;
  right?: boolean;
};

function InfoBubble({ children, right }: InfoBubbleProps): JSX.Element {
  return (
    <Container className="info-bubble" right={right}>
      {children}
    </Container>
  );
}

const Container = styled.p<{ right?: boolean }>`
  position: relative;
  padding: 6px 10px;
  background: #000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: ${COLORS.WHITE};
  white-space: nowrap;
  font-size: ${calcRem(12)};
  line-height: 17px;
  min-width: max-content;

  :after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 0 10px 23px;
    border-color: #000 transparent;
    display: block;
    width: 0;
    z-index: 1;
    top: -15px;
    ${({ right }) =>
      right
        ? `
        right: 6px;
      `
        : `
        left: 50%;
        transform: translate(-50%, 0);
      `}
  }
`;

export default InfoBubble;
