import styled from "styled-components";
import BackgroundImage from "assets/images/backgrounds/login.jpg";
import { FLEX } from "styles/flex";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import { NOTICE_HEIGHT } from "components/element/NoticeBar";
import * as mediaQuery from "components/MediaQuery";

export const BG = styled.div<{ noticeLength: number }>`
  width: 100%;
  height: ${({ noticeLength }) => `
    calc(100dvh - ${NOTICE_HEIGHT * noticeLength}px);
  `};
  background: url(${BackgroundImage}) no-repeat fixed center center;
  background-size: cover;
`;

export const Container = styled.div`
  flex-direction: column;

  ${mediaQuery.isDefault} {
    ${FLEX.FlexStartCenter};
    padding: 20vh 0;
  }
  ${mediaQuery.isMobile} {
    ${FLEX.FlexCenterCenter};
    height: 100%;
  }
  /* min-height: 100vh; */
`;

export const LoginWrapper = styled.div`
  ${FLEX.FlexStartCenter};
  flex-direction: column;
  padding: 100px 0;
`;

export const Logo = styled.div``;

export const commonLoginHeight = "40px";

export const LoginBox = styled.section`
  background-color: ${(props) =>
    props.theme.value === "light" ? COLORS.WHITE : "#161d27"};
  border-radius: 10px;
  box-shadow: 0px 9px 6px #00000029;
  text-align: center;
  margin-top: 30px;
  padding: 30px 45px;
  ${mediaQuery.isDefault} {
    width: 400px;
    min-height: 300px;
  }

  ${mediaQuery.isMobile} {
    width: 90vw;
  }
  .mydw-logo {
    margin-bottom: 30px;
  }

  .title-wrapper {
    ${FLEX.FlexBetweenCenter};
  }

  .title {
    font-size: ${calcRem(16)};
    font-weight: 500;
    color: ${(props) => props.theme.COLORS.WHITE};
    margin-bottom: 26px;
    text-align: center;
  }

  .description {
    font-size: ${calcRem(14)};
    color: ${COLORS.BUTTONS1};
    margin-bottom: 10px;
    line-height: ${calcRem(18)};
  }

  .description > strong {
    color: ${(props) => props.theme.COLORS.WHITE};
    font-weight: normal;
  }
  label {
    font-size: ${calcRem(14)};
  }
  input[type="checkbox"] {
    width: 14px;
    height: 14px;
  }
`;

export const InputWrapper = styled.div`
  ${FLEX.FlexStartCenter};
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;

  > input {
    padding: 12px;
    height: ${commonLoginHeight};
    font-size: ${calcRem(14)};
  }
`;

export const BigButton = styled.button<{ dw?: boolean; cancel?: boolean }>`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.value === "light" ? COLORS.COMPANY : COLORS.BUTTONS2};
  border-radius: 4px;
  color: ${COLORS.WHITE};
  font-weight: 500;
  /* padding: 18px; */
  height: ${commonLoginHeight};
  font-size: ${calcRem(14)};
  ${FLEX.FlexCenterCenter};
  gap: 4px;

  > button {
    position: relative;
    ${FLEX.FlexCenterCenter};
    color: ${COLORS.WHITE};
    > img {
      width: 13px;
    }
  }
  ${({ dw }) =>
    dw &&
    `
    background-color:#F66214;
  `}
  ${({ cancel }) =>
    cancel &&
    `
    background-color: ${COLORS.BUTTONS1};
  `}
`;

export const EmailBadge = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: ${calcRem(12)};
  border-radius: 14px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  background-color: ${({ theme }) => theme.COLORS.PRIMARY3};
  padding: 8px 24px;
  margin: 8px 0 16px;
`;

export const Bottom = styled.div`
  width: 100%;
  ${FLEX.FlexCenterCenter};
  gap: 10px;

  .go-back-link {
    color: ${COLORS.BUTTONS1};
    text-decoration: underline;
    font-size: ${calcRem(14)};
  }
`;

export const MarginBottom = styled(Bottom)`
  flex-direction: column;
  ${mediaQuery.isDefault} {
    margin-top: 38px;
  }
  ${mediaQuery.isMobile} {
    margin-top: 20px;
  }
`;

export const CenterBox = styled.div`
  ${FLEX.FlexCenterCenter};
  flex-direction: column;
  gap: 10px;
  padding: 15px 0;
`;

export const LoginInputBadgeWrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
  margin-top: 0.875rem;
`;

export const ValidateWrapper = styled.span`
  width: 100%;
  ${FLEX.FlexStartCenter};
  font-size: ${calcRem(12)};
  padding: 6px 0;
  /* margin-top: -1px; */
`;
