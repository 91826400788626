import { Media, SortOrder, TableColumn } from "react-data-table-component";
import Type from "components/atoms/text/labels/Type";
import InventoryPresenter from "./InventoryPresenter";
import {
  ReportInventoryCameraRow,
  ReportInventoryRecorderRow,
} from "api/interfaces/reportInterface.interface";
import { ReportDataRow } from "api/interfaces/reportInterface.interface";
import { useCallback, useMemo, useState } from "react";
import { Text } from "components/atoms/text/Text";
import * as mediaQuery from "components/MediaQuery";
import { InforamtionTitle } from "./InventoryStyled";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import {
  getInventoryCamera,
  getInventoryRecorder,
  getReportInfo,
} from "api/reportAPI";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import { convertType, formatBytesFromMB } from "utils/functions";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useIntl } from "react-intl";
import { excelDateFormat, getAccountTimeFormat } from "utils/timeUtil";
import { useLocation, useParams } from "react-router-dom";
import { notify } from "components/atoms/notification/Notification";
import RecorderTitle from "components/blocks/recorder/RecorderTitle";
import { recorderTitleString } from "utils/RecorderUtil";
import qs from "qs";

type Props = {
  state: ReportDataRow | undefined;
};

export interface TotalCountInterface {
  [key: string]: number;
}

interface InventoryStateProps {
  title: string;
  reportId: string;
}

export default function InventoryContainer(props: Props): JSX.Element {
  const intl = useIntl();
  const location = useLocation();
  const params = useParams();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const paginationPerPage = 20;
  // const navigate = useNavigate();

  const [currentMenu, setCurrentMenu] = useState(0);

  const [recorderData, setRecorderData] = useState<
    ReportInventoryRecorderRow[]
  >([]);
  const [cameraData, setCameraData] = useState<ReportInventoryCameraRow[]>([]);
  const [totalDataCount, setTotalDataCount] = useState<TotalCountInterface>({
    recorder: 0,
    camera: 0,
  });

  // const [inventoryState, setInventoryState] = useState<InventoryStateProps>({
  //   title: props.state ? props.state.title : "",
  //   reportId: props.state ? props.state.id : "",
  // });

  const { account } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    decoder: (s) => decodeURIComponent(s),
  });
  const reportId = params.reportId as string;
  const [reportTitle, setReportTitle] = useState<string>("");

  // useEffect(() => {
  //   if (location.state) {
  //     setInventoryState(location.state);
  //   }
  // }, [location]);

  // useEffect(() => {
  //   if (props) {
  //     setInventoryState({
  //       title: props.state ? props.state.title : "",
  //       reportId: props.state ? props.state.id : "",
  //     });
  //   }
  // }, [props]);

  const [isExportEmail, setIsExportEmail] = useState<boolean>(false);

  const onChange = (index: number) => {
    setCurrentMenu(index);
  };
  // table recorderColumns column currentMenu === 0 일때
  const recorderColumns: TableColumn<ReportInventoryRecorderRow>[] = [
    {
      name: "Type",
      grow: 1,
      maxWidth: "120px",
      cell: (row) => <Type type={convertType(row.type)} />,
    },
    {
      name: "Title",
      grow: 3,
      // grow: Media.SM ? 1.2 : 3,
      sortable: true,
      sortField: "name",
      // minWidth: "240px",
      // minWidth: Media.SM ? "80px" : "240px",
      cell: (row) => (
        <InforamtionTitle>
          {/* <Text className="information-title">{row.title}</Text> */}
          <RecorderTitle
            mergedSystemName={row.mergedSystemName}
            recorderName={row.title}
            type={row.type}
          />
          <mediaQuery.Default>
            <Text fontSize={12} color="#828B9B">
              [{row.firmwareVersion}]
            </Text>
          </mediaQuery.Default>
        </InforamtionTitle>
      ),
    },
    {
      name: "MAC Address",
      grow: 2,
      // grow: Media.SM ? 1.4 : 3,
      // minWidth: "140px",
      // minWidth: Media.SM ? "120px" : "180px",
      selector: (row) => (row.macAddr ? row.macAddr.toUpperCase() : "N/A"),
    },
    {
      name: "Cameras",
      grow: 1,
      // grow: Media.SM ? 1 : 2,
      center: true,
      compact: Media.SM ? true : false,
      selector: (row) => row.cameraCount,
    },
    {
      name: "Installer",
      grow: 1.5,
      // minWidth: "180px",
      selector: (row) => row.installerName,
      hide: Media.SM,
    },
    {
      id: "installedDate",
      name: "Install Date",
      grow: 1.2,
      sortable: true,
      sortField: "installedDate",
      // minWidth: "160px",
      //BUG [RND-434] Date Format change
      selector: (row) =>
        getAccountTimeFormat(row.installedDate, selectedAccount, true),
      hide: Media.SM,
    },
    {
      name: "Location",
      grow: 1.5,
      // minWidth: "150px",
      sortable: true,
      sortField: "location",
      selector: (row) => (row.location ? row.location : "Unknown"),
      hide: Media.SM,
    },
  ];

  const recorderMobileColumns: TableColumn<ReportInventoryRecorderRow>[] = [
    {
      name: "Type",
      grow: 1,
      minWidth: "110px",
      cell: (row) => <Type type={convertType(row.type)} />,
    },
    {
      name: "Title",
      grow: 4,
      sortable: true,
      sortField: "name",
      minWidth: "250px",
      cell: (row) => (
        <InforamtionTitle>
          {/* <Text className="information-title">{row.title}</Text> */}
          <RecorderTitle
            mergedSystemName={row.mergedSystemName}
            recorderName={row.title}
            type={row.type}
          />
        </InforamtionTitle>
      ),
    },
    {
      name: "MAC Address",
      grow: 2,
      selector: (row) => (row.macAddr ? row.macAddr.toUpperCase() : "N/A"),
    },
    {
      name: "Cameras",
      grow: 1,
      center: true,
      compact: true,
      selector: (row) => row.cameraCount,
    },
  ];

  // table recorderColumns column currentMenu === 0 일때
  const cameraColumns: TableColumn<ReportInventoryCameraRow>[] = [
    {
      id: "recorderName",
      name: "Recorder",
      grow: 3,
      sortable: true,
      sortField: "mergedSystemName",
      cell: (row) => (
        <RecorderTitle
          mergedSystemName={row.mergedSystemName}
          recorderName={row.recorderName}
        />
      ),
    },
    {
      name: "Camera",
      grow: 3,
      sortable: true,
      sortField: "name",
      selector: (row) => row.name,
    },
    {
      name: "Manufacturer",
      grow: 2,
      selector: (row) => row.manufacturer,
      hide: Media.SM,
    },
    {
      name: "Model",
      grow: 3,
      selector: (row) => row.model,
      hide: Media.SM,
    },
    {
      name: "MAC address",
      grow: 2,
      selector: (row) => (row.macAddr ? row.macAddr : "N/A"),
    },
    {
      name: "Storage",
      grow: 1,
      selector: (row) => formatBytesFromMB(row.totalStorage),
      hide: Media.SM,
    },
    {
      name: "Resolution",
      grow: 2,
      selector: (row) => row.resolution,
      hide: Media.SM,
    },
  ];

  const reportDetailInfo = useQuery(
    ["reportDetailInfo", reportId],
    () =>
      getReportInfo({
        accountId: selectedAccount.accountId,
        reportId: reportId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result !== undefined && res.error === 0) {
          setReportTitle(res.result.preferences?.title);
        } else {
          setReportTitle("N/A");
        }
      },
      onError: (e: any) => {
        setReportTitle("N/A");
      },
    }
  );
  const excelFileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelFileExtension = ".xlsx";
  const excelFileName = `report-inventory-${excelDateFormat()}`;

  // const RecorderEnum: {
  //   [K in keyof Required<ReportInventoryRecorderRowXLS>]: K;
  // } = {
  //   type: "type",
  //   title: "title",
  //   macAddr: "macAddr",
  //   cameraCount: "cameraCount",
  //   installerName: "installerName",
  //   installedDate: "installedDate",
  //   location: "location",
  //   firmwareVersion: "firmwareVersion",
  // };
  // const CameraEnum: {
  //   [K in keyof Required<ReportInventoryCameraRow>]: K;
  // } = {
  //   recorderName: "recorderName",
  //   name: "name",
  //   manufacturer: "manufacturer",
  //   model: "model",
  //   macAddr: "macAddr",
  //   storage: "storage",
  //   streams: "streams",
  //   resolution: "resolution",
  //   cameraId: "cameraId",
  //   channel: "channel",
  //   id: "id",
  //   totalStorage: "totalStorage",
  // };
  const RecorderKeys: string[] = useMemo(() => {
    return [
      "Type",
      "Title",
      "Firmware Version",
      "MAC Address",
      "Cameras",
      "Installer",
      "Install Date",
      "location",
    ];
  }, []);
  const CameraKeys: string[] = useMemo(() => {
    return [
      "Recorder",
      "Camera",
      "Manufacturer",
      "Model",
      "MAC Address",
      "Storage",
      "Resolution",
    ];
  }, []);

  const [recorderQueryInfo, setRecorderQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: paginationPerPage,
    keyword: "",
    sortType: "installedDate",
    sortDirection: SORT_DIRECTION.DESC,
  });

  const [cameraQueryInfo, setCameraQueryInfo] = useState<BasePagination>({
    total: 0,
    pageNum: 0,
    pageLimit: paginationPerPage,
    keyword: "",
    sortType: "mergedSystemName",
    sortDirection: SORT_DIRECTION.ASC,
  });

  const useExcelDownload = useCallback(() => {
    // console.log({matchInfoArray});
    const rs = XLSX.utils.aoa_to_sheet([RecorderKeys]);
    const ws = XLSX.utils.aoa_to_sheet([CameraKeys]);

    getInventoryRecorder({
      payload: {
        total: 0,
        pageNum: 0,
        pageLimit: totalDataCount.recorder,
        keyword: recorderQueryInfo.keyword,
        sortType: recorderQueryInfo.sortType,
        sortDirection: recorderQueryInfo.sortDirection,
      },
      reportId: reportId,
    }).then((res: any) => {
      (res.result !== undefined
        ? res.result.map((recorder: any) => {
            return {
              ...recorder,
              location: recorder.location?.location,
            };
          })
        : []
      )?.map((data: ReportInventoryRecorderRow) => {
        XLSX.utils.sheet_add_aoa(
          rs,
          [
            [
              convertType(data.type),
              recorderTitleString(data.title, data.mergedSystemName, data.type),
              data.firmwareVersion,
              data.macAddr ? data.macAddr : "N/A",
              data.cameraCount,
              data.installerName,
              getAccountTimeFormat(data.installedDate, selectedAccount, true),
              data.location,
            ] as any[],
          ],
          { origin: -1 }
        );
        rs["!cols"] = [
          { wpx: 200 },
          { wpx: 200 },
          { wpx: 200 },
          { wpx: 120 },
          { wpx: 100 },
          { wpx: 100 },
          { wpx: 50 },
          { wpx: 100 },
        ];
        return false;
      });

      getInventoryCamera({
        payload: {
          total: 0,
          pageNum: 0,
          pageLimit: totalDataCount.camera,
          keyword: cameraQueryInfo.keyword,
          sortType: cameraQueryInfo.sortType,
          sortDirection: cameraQueryInfo.sortDirection,
        },
        reportId: reportId,
      }).then((res: any) => {
        (res.result !== undefined ? res.result : []).map(
          (data: ReportInventoryCameraRow) => {
            let resolution = data.streams
              ?.filter((stream: any) => {
                if (stream.encoderIndex === 0) {
                  return stream.resolution;
                } else {
                  return null;
                }
                // else return;
              })
              .map(
                (stream: any) => stream.encoderIndex === 0 && stream.resolution
              );
            XLSX.utils.sheet_add_aoa(
              ws,
              [
                [
                  recorderTitleString(data.recorderName, data.mergedSystemName),
                  data.name,
                  data.manufacturer,
                  data.model,
                  data.macAddr ? data.macAddr : "N/A",
                  formatBytesFromMB(
                    data.storage !== undefined
                      ? data.storage?.reduce(function (prev: any, next: any) {
                          return prev + next.total;
                        }, 0)
                      : 0
                  ),
                  resolution ? resolution.toString() : "Not Detected",
                ] as any[],
              ],
              { origin: -1 }
            );
            ws["!cols"] = [
              { wpx: 200 },
              { wpx: 200 },
              { wpx: 120 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 50 },
              { wpx: 100 },
            ];
            return false;
          }
        );
        const wb: any = {
          Sheets: { Recorder: rs, Cameras: ws },
          SheetNames: ["Recorder", "Cameras"],
        };
        console.log(wb);
        const excelButter = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const excelFile = new Blob([excelButter], { type: excelFileType });
        FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
      });
    });
  }, [
    reportId,
    CameraKeys,
    RecorderKeys,
    cameraQueryInfo.keyword,
    cameraQueryInfo.sortDirection,
    cameraQueryInfo.sortType,
    excelFileName,
    recorderQueryInfo.keyword,
    recorderQueryInfo.sortDirection,
    recorderQueryInfo.sortType,
    selectedAccount,
    totalDataCount.camera,
    totalDataCount.recorder,
  ]);

  const [recorderPending, setRecorderPending] = useState<boolean>(true);

  const inventoryRecorderQuery = useQuery(
    ["inventoryRecorder", recorderQueryInfo],
    () =>
      getInventoryRecorder({
        payload: recorderQueryInfo,
        reportId: reportId,
      }),
    {
      retry: 0,
      enabled: reportId !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res.result === undefined) {
          return;
        }
        setTotalDataCount((count) => {
          return {
            ...count,
            recorder: res.page.total,
          };
        });
        setRecorderData(
          res.result.map((recorder: any) => {
            // console.log(recorder);
            return {
              ...recorder,
              location: recorder.location?.location,
            };
          })
        );
      },
      onError: (e: any) => {
        notify(
          "error",
          intl.formatMessage({
            id: "label.report.notify.inventory.list.error",
            defaultMessage: "Inventory search error.",
          })
        );
      },
      onSettled: (e: any) => {
        setRecorderPending(false);
      },
    }
  );

  const [cameraPending, setCameraPending] = useState<boolean>(true);
  const inventoryCameraQuery = useQuery(
    ["inventoryCamera", cameraQueryInfo],
    () =>
      getInventoryCamera({
        payload: cameraQueryInfo,
        reportId: reportId,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: reportId !== undefined,
      onSuccess: (res: any) => {
        if (res.result === undefined) {
          return;
        }
        setTotalDataCount((count) => {
          return {
            ...count,
            camera: res.page.total,
          };
        });
        setCameraData(
          res.result.map((camera: any) => {
            // 우선은 encoderIndex === 0 인것을 표시
            let resolution = camera.streams
              ?.filter((stream: any) => {
                if (stream.encoderIndex === 0) {
                  return stream.resolution;
                } else {
                  return null;
                }
                // else return;
              })
              .map(
                (stream: any) => stream.encoderIndex === 0 && stream.resolution
              );
            return {
              ...camera,
              resolution: resolution?.toString(),
              totalStorage:
                camera.storage !== undefined
                  ? camera.storage?.reduce(function (prev: any, next: any) {
                      return prev + next.total;
                    }, 0)
                  : 0,
            };
          })
        );
      },
      onError: (e: any) => {
        // console.error(e, "error");
        if (e.data) {
          e.data.error === 7001 && setCameraData([]);

          return;
        } else throw e;
      },
      onSettled: (e: any) => {
        setCameraPending(false);
      },
    }
  );

  const onChangePage = useCallback(
    (page: number, totalRows: number) => {
      if (currentMenu === 0) {
        if (page !== recorderQueryInfo.pageNum + 1) {
          setRecorderQueryInfo((info) => {
            return { ...info, pageNum: (page - 1) as number };
          });
        }
      }
      if (currentMenu === 1) {
        if (page !== cameraQueryInfo.pageNum + 1) {
          setCameraQueryInfo((info) => {
            return { ...info, pageNum: (page - 1) as number };
          });
        }
      }
    },
    [cameraQueryInfo, recorderQueryInfo, currentMenu]
  );

  const onClickSearch = useCallback((keyword: string) => {
    setRecorderQueryInfo((query) => {
      return {
        ...query,
        pageNum: 0,
        keyword,
      };
    });
    setCameraQueryInfo((query) => {
      return {
        ...query,
        pageNum: 0,
        keyword,
      };
    });
  }, []);

  const handleSort = async (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => {
    if (selectedColumn.sortField !== undefined) {
      if (currentMenu === 0) {
        setRecorderQueryInfo((info) => {
          return {
            ...info,
            sortType: selectedColumn.sortField as string,
            sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
          };
        });
      }
      if (currentMenu === 1) {
        setCameraQueryInfo((info) => {
          return {
            ...info,
            sortType: selectedColumn.sortField as string,
            sortDirection: sortDirection.toUpperCase() as SORT_DIRECTION,
          };
        });
      }
    }
  };

  const onClickExcelEmail = useCallback(() => {
    setIsExportEmail(true);
  }, []);
  // const onClickExcelEmail = useCallback(()=>{
  //   getSendEmailReport(selectedAccount.accountId,props.state ? props.state.id : "")
  //   .then((res : SendEmailReportResponse) =>{
  //     if(res.error === 0 ){
  //       notify("success",intl.formatMessage({
  //         id: "label.report.notify.email.success",
  //         defaultMessage: "Success : Success to send Email",
  //       }));
  //     }else{
  //       notify("error",intl.formatMessage({
  //         id: "label.report.notify.email.fail",
  //         defaultMessage: "Error : Failed to send Email",
  //       }));
  //     }
  //   })
  //   .catch();
  // },[intl, props.state, selectedAccount.accountId])

  const onModalExportMail = useCallback(() => {
    setIsExportEmail(false);
  }, []);

  return (
    <InventoryPresenter
      title={reportTitle}
      cameraColumns={cameraColumns}
      recorderColumns={recorderColumns}
      currentMenu={currentMenu}
      onChange={onChange}
      excelDownload={useExcelDownload}
      excelEmail={onClickExcelEmail}
      cameraData={cameraData}
      recorderData={recorderData}
      totalDataCount={totalDataCount}
      paginationPerPage={paginationPerPage}
      onChangePage={onChangePage}
      onClickSearch={onClickSearch}
      handleSort={handleSort}
      isExportEmail={isExportEmail}
      onModalExportMail={onModalExportMail}
      reportId={reportId}
      recorderMobileColumns={recorderMobileColumns}
      recorderTablePending={recorderPending}
      cameraTablePending={cameraPending}
      selectedAccount={selectedAccount}
      recorderPaginationInfo={recorderQueryInfo}
      cameraPaginationInfo={cameraQueryInfo}
    />
  );
}
