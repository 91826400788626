import { createPortal } from "react-dom";
import * as s from "./ModalTemplateStyled";
import { forwardRef } from "react";

type Props = {
  center?: boolean;
  children?: React.ReactNode;
};

const ModalTemplate = forwardRef<HTMLDivElement, Props>(
  ({ center, children }: Props, ref) => {
    return (
      <>
        {createPortal(
          <s.Container ref={ref} center={center}>
            {children}
          </s.Container>,
          document.body
        )}
      </>
    );
  }
);

export default ModalTemplate;
