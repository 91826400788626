/**
 * @author ydoni
 * @description 16:9 비율 유지하는 기본 섬네일
 *
 *
 * 2:1 : padding-top: 50%;
 * 1:2 : padding-top: 200%;
 * 4:3: padding-top: 75%;
 * 16:9 : padding-top: 56.25%
 */
import styled from "styled-components";
import { COLORS } from "styles/colors";

const DefaultThumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  > img,
  .video-loss-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .transcoding-image {
    * {
      color: ${COLORS.WARNING};
    }
  }

  .thumbnail-url {
    display: block;
  }
`;

export default DefaultThumbnail;
