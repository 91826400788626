import MissingList from "../missingList";
import * as s from "./SubHeaderStyled";
import WIDGET_CLOSE from "assets/icons/widget_close.svg";
import WIDGET_CLOSE_LIGHT from "assets/icons/widget_close_light.svg";
import WIDGET_OPEN from "assets/icons/widget_open.svg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AccountFilter from "./filters/account/AccountFilter";
import RecorderFilter from "./filters/recorder/RecorderFilter";
import AlertFilter from "./filters/alert/AlertFilter";
import { AnimatePresence, useTransform } from "framer-motion";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { BsCaretDownFill, BsPlusLg } from "react-icons/bs";
import MapSearchInput from "components/atoms/input/search/MapSearchInput";
import { useMutation, useQuery } from "react-query";
import { getKeywordList } from "api/dashboardAPI";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import {
  BasePagination,
  SORT_DIRECTION,
} from "api/interfaces/commonInterface.interface";
import {
  MapFilter,
  MapFilterKeyword,
  mapFilterInitialState,
  setKeyword,
} from "redux/reducers/map/mapFilter";
import { useQueryClient } from "react-query";
import { Modal, Popover } from "antd";
import * as mediaQuery from "components/MediaQuery";
import StatusFilter from "./filters/status/StatusFilter";
import MoreFilter from "./filters/more/MoreFilter";
import { IoMdClose } from "react-icons/io";
import TypeFilter, { typeList } from "./filters/type/TypeFilter";
import {
  DashboardData,
  DashboardFilter,
  KeywordSearch,
  KeywordSearchRecorder,
  KeywordSearchResposne,
  MapStatusListType,
} from "api/interfaces/dashboardInterface.interface";
import { changeFirstWordToUppercase } from "utils/functions";
import CHEVRON_LEFT from "assets/icons/chevron_left_slide.svg";
import CHEVRON_RIGHT from "assets/icons/chevron_right_slide.svg";

type Props = {
  isCollapsed: boolean;
  // onChangeStatus: (isCheckStatus: MapStatusListType) => void;
  onChangeCollapsed: () => void;
  onChangeSearchMove: (checked: boolean) => void;
  onSearch: (e: MapFilterKeyword) => void;
  onResetSearchInput: () => void;
  more: More;
  onSelectMoreFilter: (e: any) => void;
  onDeleteMoreFilter: (name: string) => void;
  onClickRecorder: (id: string) => void;
  onClickRecorderKeyword: (rec: KeywordSearchRecorder) => void;
};

export type More = {
  [status: string]: boolean;
};

interface Open {
  [key: string]: boolean;
}

interface FilterLabel {
  [key: string]: string;
}
const SubHeader = (props: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const mapFilter: DashboardFilter = useAppSelector(
    (state) => state.mapFilter.filters
  );
  const mapFilterKeyword: MapFilterKeyword = useAppSelector(
    (state) => state.mapFilter.keyword
  );
  const theme = useAppSelector((state) => state.theme.theme);

  const [keywordListListQueryInfo, setKeywordListQueryInfo] =
    useState<BasePagination>({
      total: 0,
      pageNum: 0,
      pageLimit: 10,
      keyword: "",
      sortType: "",
      sortDirection: SORT_DIRECTION.ASC,
    });

  const [isSelectOpen, setIsSelectOpen] = useState<Open>({
    status: false,
    accounts: false,
    alerts: false,
    types: false,
    recorders: false,
    recorderGroup: false,
    more: false,
  });
  const [isSearchMove, setIsSearchMove] = useState<boolean>(false);

  const [isFiltering, setIsFiltering] = useState<Open>({
    status: false,
    accounts: false,
    alerts: false,
    types: false,
    recorders: false,
    recorderGroup: false,
    more: false,
  });

  const [searchKeywordList, setSearchKeywordList] = useState<KeywordSearch[]>(
    []
  );

  const [filterLabels, setFilterLabels] = useState<FilterLabel>({
    status: "Status",
    accounts: "Organization",
    alerts: "Alert",
    types: "Type",
    recorders: "Recorder",
    recorderGroup: "Recorder Group",
    more: "More",
  });

  const onClickSearch = (keyword: string) => {
    props.onSearch({ type: "", keyword: keyword });
  };

  const onClickKeyword = (content: KeywordSearch) => {
    if (content.recorder !== undefined) {
      // dispatch(setKeyword(content.contents));
      props.onClickRecorder(content.recorder.recorderId);
      props.onClickRecorderKeyword(content.recorder);
    } else {
      props.onSearch({ type: content.type, keyword: content.contents });
    }
  };

  const onSelectMoreFilter = (e: any) => {
    let {
      target: { checked, name },
    } = e;

    props.onSelectMoreFilter(e);

    setIsSelectOpen({
      ...isSelectOpen,
      more: false,
      [name]: true,
    });
  };

  const onChangeSearchMove = (checked: boolean) => {
    setIsSearchMove(checked);
    props.onChangeSearchMove(checked);
  };

  const onChangeSelectOpen = (name: string) => {
    const tempNewState = { ...isSelectOpen };
    Object.keys(tempNewState).forEach((key) => {
      if (key !== name) {
        tempNewState[key] = false;
      } else {
        tempNewState[key] = !tempNewState[key];
      }
    });
    setIsSelectOpen(tempNewState);
  };

  const onApply = (type: string) => {
    onChangeSelectOpen(type);
  };
  const onChangeFiltering = useCallback(
    (name: string, value: boolean) => {
      // console.log(isFiltering);
      setIsFiltering((state: Open) => {
        return {
          ...state,
          [name]: value,
        };
      });
    },
    [isFiltering]
  );

  const onChangeFilterName = (label: string, changeFilterName: string) => {
    // console.log("onChangeFilterName", filterLabels, label, changeFilterName);
    setFilterLabels((state: FilterLabel) => {
      return {
        ...state,
        [label]: changeFilterName,
      };
    });
  };

  const horizontalScrollRef = useRef<any>(null);

  const handleNextButtonClick = (nextType: "prev" | "next") => {
    if (!horizontalScrollRef.current) return;
    if (nextType === "prev") {
      horizontalScrollRef.current.scrollTo({
        left:
          horizontalScrollRef.current.scrollLeft -
          horizontalScrollRef.current.offsetWidth,
        behavior: "smooth",
      });
    } else {
      horizontalScrollRef.current.scrollTo({
        left:
          horizontalScrollRef.current.scrollLeft +
          horizontalScrollRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  };

  const [isOverflow, setIsOverflow] = useState<{ [key: string]: boolean }>({
    left: false,
    right: false,
  });

  const checkOverflow = () => {
    if (!horizontalScrollRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } =
      horizontalScrollRef.current;

    setIsOverflow({
      left: scrollLeft > 0,
      right: scrollLeft + clientWidth < scrollWidth,
    });
  };

  useEffect(() => {
    if (!horizontalScrollRef.current) return;

    const ref = horizontalScrollRef.current;
    checkOverflow(); // Initial check

    ref.addEventListener("scroll", checkOverflow);
    window.addEventListener("resize", checkOverflow);

    return () => {
      ref.removeEventListener("scroll", checkOverflow);
      window.removeEventListener("resize", checkOverflow);
    };
  }, [horizontalScrollRef, isSelectOpen]);

  const moreMenu = {
    label: "More",
    name: "more",
    open: isSelectOpen.more,
    filter: <MoreFilter more={props.more} onChange={onSelectMoreFilter} />,
  };
  const selectMenus = useMemo(() => {
    return [
      {
        label: "Status",
        name: "status",
        open: isSelectOpen.status,
        filter: (
          <StatusFilter
            onApply={() => onApply("status")}
            onChangeFiltering={onChangeFiltering}
          />
        ),
      },
      {
        label: "Organization",
        name: "accounts",
        open: isSelectOpen.accounts,
        filter: (
          <AccountFilter
            onApplyAccount={() => onApply("accounts")}
            onChangeFiltering={onChangeFiltering}
            onChangeFilterName={(filterName: string) =>
              onChangeFilterName("accounts", filterName)
            }
          />
        ),
      },
      {
        label: "Recorder",
        name: "recorders",
        open: isSelectOpen.recorder,
        filter: (
          <RecorderFilter
            type="recorder"
            onApply={() => onApply("recorders")}
            onChangeFiltering={onChangeFiltering}
            onChangeFilterName={(filterName: string) =>
              onChangeFilterName("recorders", filterName)
            }
          />
        ),
      },
    ];
  }, [isSelectOpen]);

  const moreMenus = useMemo(() => {
    return [
      {
        label: "Type",
        name: "types",
        open: isSelectOpen.type,
        filter: (
          <TypeFilter
            onApply={() => onApply("types")}
            onChangeFiltering={onChangeFiltering}
          />
        ),
      },
      {
        label: "Recorder",
        name: "recorders",
        open: isSelectOpen.recorder,
        filter: (
          <RecorderFilter
            type="recorder"
            onApply={() => onApply("recorders")}
            onChangeFiltering={onChangeFiltering}
            onChangeFilterName={(filterName: string) =>
              onChangeFilterName("recorders", filterName)
            }
          />
        ),
      },
      {
        label: "Alert",
        name: "alerts",
        open: isSelectOpen.alert,
        filter: (
          <AlertFilter
            onApply={() => onApply("alerts")}
            onChangeFiltering={onChangeFiltering}
            onChangeFilterName={(filterName: string) =>
              onChangeFilterName("alerts", filterName)
            }
          />
        ),
      },
      {
        label: "Recorder Group",
        name: "recorderGroup",
        open: isSelectOpen.recorderGroup,
        filter: (
          <RecorderFilter
            type="group"
            onApply={() => onApply("recorderGroup")}
            onChangeFiltering={onChangeFiltering}
            onChangeFilterName={(filterName: string) =>
              onChangeFilterName("recorderGroup", filterName)
            }
          />
        ),
      },
    ];
  }, [isSelectOpen]);

  const onPowerSearch = (e: any) => {
    setKeywordListQueryInfo({
      ...keywordListListQueryInfo,
      keyword: e.target.value,
    });
  };

  const searchKeywordListQuery = useQuery(
    ["keywordList", keywordListListQueryInfo.keyword],
    () =>
      getKeywordList({
        accountId: selectedAccount.accountId,
        payload: keywordListListQueryInfo,
      }),
    {
      retry: 0,
      enabled:
        keywordListListQueryInfo.keyword !== undefined &&
        keywordListListQueryInfo.keyword !== "" &&
        keywordListListQueryInfo.keyword.length > 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        // console.log(res, "search keyword 2 2222222");
        if (res.result) {
          // dispatch(setDashboardSearchData(res.result));
          setSearchKeywordList(res.result);
        } else {
          // dispatch(setDashboardSearchData([]));
          setSearchKeywordList([]);
        }
      },
      // onSettled: () => {},
    }
  );

  useEffect(() => {
    if (
      keywordListListQueryInfo.keyword !== undefined &&
      (keywordListListQueryInfo.keyword === "" ||
        keywordListListQueryInfo.keyword.length > 0)
    ) {
      setSearchKeywordList([]);
    }
  }, [keywordListListQueryInfo.keyword]);

  useEffect(() => {
    // console.log(mapFilter.keyword, "subheader keyword");
    setKeywordListQueryInfo({
      ...keywordListListQueryInfo,
      keyword: mapFilterKeyword.keyword,
    });
  }, [mapFilterKeyword]);

  useEffect(() => {
    let tempStatusArr = Object.entries(mapFilter.status);
    if (isFiltering.status) {
      // console.log(
      //   tempStatusArr.filter((stat) => stat[1]),
      //   tempStatusArr.filter((stat) => stat[1]).length > 1
      // );

      // 모두 filtering 일 때
      if (
        tempStatusArr.filter((stat) => stat[1]).length === tempStatusArr.length
      ) {
        onChangeFilterName("status", "All Status");
      }
      // 1,2개만 filtering 일 때
      else {
        onChangeFilterName(
          "status",
          tempStatusArr
            .filter((stat) => stat[1])
            .map((st) => changeFirstWordToUppercase(st[0]))
            .join()
        );
      }
    } else {
      onChangeFilterName("status", "Status");
    }
  }, [isFiltering.status, mapFilter.status]);

  useEffect(() => {
    let tempTypeArr = Object.entries(mapFilter.types);
    if (isFiltering.types) {
      console.log(tempTypeArr);

      // 모두 filtering 일 때
      if (tempTypeArr.filter((stat) => stat[1]).length === tempTypeArr.length) {
        onChangeFilterName("types", "All Types");
      }
      // 1,2개만 filtering 일 때
      else {
        onChangeFilterName(
          "types",
          tempTypeArr
            .filter((stat) => stat[1])
            .map((st) => {
              return typeList.find((type) => type.value === st[0])?.label;
            })
            .join()
        );
      }
    } else {
      onChangeFilterName("types", "Types");
    }
  }, [isFiltering.types, mapFilter.types]);

  useEffect(() => {
    dispatch(setKeyword(mapFilterInitialState.keyword));
  }, [selectedAccount]);

  return (
    <>
      <mediaQuery.Default>
        <s.Container>
          <s.Inner>
            <s.Left>
              <AnimatePresence>
                <s.FilterLeft
                  initial={{
                    rotateX: 0,
                    // scaleY: -1,
                  }}
                  animate={{
                    rotateX: 180,
                    scaleY: -1,
                    // transition: { duration: 1 },
                  }}
                  exit={{
                    rotateX: 0,
                    // scaleY: -1
                  }}
                >
                  <MapSearchInput
                    keyword={keywordListListQueryInfo.keyword}
                    placeholder="Search In Result"
                    onChange={onPowerSearch}
                    onClickKeyword={onClickKeyword}
                    onClickSearch={onClickSearch}
                    searchKeywordList={searchKeywordList}
                  />
                  {selectMenus.map((menu, index) => {
                    // console.log(filterLabels, " filterlabels");
                    if (
                      (selectedAccount.accountLevel === "EU" &&
                        menu.name !== "accounts") ||
                      ((selectedAccount.accountLevel === "CP" ||
                        selectedAccount.accountLevel === "DW") &&
                        menu.name !== "recorders")
                    )
                      return (
                        <Popover
                          key={menu.label}
                          content={menu.filter}
                          placement="bottomLeft"
                          trigger={"click"}
                          arrow={false}
                          open={isSelectOpen[menu.name]}
                          onOpenChange={(open: boolean) => {
                            setIsSelectOpen({
                              ...isSelectOpen,
                              [menu.name]: open,
                            });
                          }}
                        >
                          <s.SelectFilter
                            key={index}
                            isOpen={isSelectOpen[menu.name]}
                            onClick={() => onChangeSelectOpen(menu.name)}
                            isFilter={isFiltering[menu.name]}
                          >
                            {filterLabels[menu.name]}
                            <BsCaretDownFill size={12} />
                          </s.SelectFilter>
                        </Popover>
                      );
                  })}
                  {moreMenus.map((m, index) => {
                    if (props.more[m.name]) {
                      return (
                        <Popover
                          key={m.name}
                          content={m.filter}
                          placement="bottomLeft"
                          trigger={"click"}
                          arrow={false}
                          open={isSelectOpen[m.name]}
                          onOpenChange={(open: boolean) => {
                            setIsSelectOpen({
                              ...isSelectOpen,
                              [m.name]: open,
                            });
                          }}
                        >
                          <s.SelectFilter
                            key={`more-${index}`}
                            onClick={() => onChangeSelectOpen(m.name)}
                            isOpen={isSelectOpen[m.name]}
                            isFilter={isFiltering[m.name]}
                          >
                            {filterLabels[m.name]}

                            <button
                              onClick={() => props.onDeleteMoreFilter(m.name)}
                            >
                              <IoMdClose size={12} />
                            </button>
                          </s.SelectFilter>
                        </Popover>
                      );
                    }
                  })}

                  <Popover
                    content={moreMenu.filter}
                    placement="bottomLeft"
                    trigger={"click"}
                    arrow={false}
                    open={isSelectOpen[moreMenu.name]}
                    onOpenChange={(open: boolean) => {
                      setIsSelectOpen({
                        ...isSelectOpen,
                        [moreMenu.name]: open,
                      });
                    }}
                  >
                    <s.SelectFilter
                      isOpen={isSelectOpen[moreMenu.name]}
                      onClick={() => onChangeSelectOpen(moreMenu.name)}
                      isFilter={isFiltering[moreMenu.name]}
                    >
                      {filterLabels[moreMenu.name]}
                      <BsCaretDownFill size={12} />
                    </s.SelectFilter>
                  </Popover>
                </s.FilterLeft>
              </AnimatePresence>
            </s.Left>
            <s.Right>
              <s.SearchInMap>
                <input
                  type="checkbox"
                  checked={isSearchMove}
                  onChange={(e) => onChangeSearchMove(e.currentTarget.checked)}
                />
                Show only recorders on map
              </s.SearchInMap>

              <MissingList />
              <s.CollapseButton
                isCollapsed={props.isCollapsed}
                onClick={props.onChangeCollapsed}
              >
                <img
                  src={
                    props.isCollapsed
                      ? WIDGET_OPEN
                      : theme === "light"
                      ? WIDGET_CLOSE_LIGHT
                      : WIDGET_CLOSE
                  }
                  alt="widget icon"
                />
              </s.CollapseButton>
            </s.Right>
          </s.Inner>
        </s.Container>
      </mediaQuery.Default>
      <mediaQuery.Mobile>
        <s.Container>
          <s.MobileMapInner>
            {isOverflow.left && (
              <button
                className="button-left"
                onClick={() => handleNextButtonClick("prev")}
              >
                <img src={CHEVRON_LEFT} />
              </button>
            )}

            {isOverflow.right && (
              <button
                className="button-right"
                onClick={() => handleNextButtonClick("next")}
              >
                <img src={CHEVRON_RIGHT} />
              </button>
            )}

            <s.MobileMapFilter ref={horizontalScrollRef}>
              <s.SelectFilter
                isOpen={isSelectOpen[moreMenu.name]}
                onClick={() => onChangeSelectOpen(moreMenu.name)}
                isFilter={isFiltering[moreMenu.name]}
              >
                {filterLabels[moreMenu.name]}
                <BsCaretDownFill size={12} />
              </s.SelectFilter>
              <Modal
                centered
                open={isSelectOpen[moreMenu.name]}
                onCancel={() => {
                  setIsSelectOpen({
                    ...isSelectOpen,
                    [moreMenu.name]: false,
                  });
                }}
                destroyOnClose={true}
                footer={null}
                classNames={{ content: "antd-filter-content" }}
                closable={false}
              >
                {moreMenu.filter}
              </Modal>
              {selectMenus.map((menu, index) => {
                // console.log(filterLabels, " filterlabels");
                if (
                  (selectedAccount.accountLevel === "EU" &&
                    menu.name !== "accounts") ||
                  ((selectedAccount.accountLevel === "CP" ||
                    selectedAccount.accountLevel === "DW") &&
                    menu.name !== "recorders")
                )
                  return (
                    <>
                      <s.SelectFilter
                        key={index}
                        isOpen={isSelectOpen[menu.name]}
                        onClick={() => onChangeSelectOpen(menu.name)}
                        isFilter={isFiltering[menu.name]}
                      >
                        {filterLabels[menu.name]}
                        <BsCaretDownFill size={12} />
                      </s.SelectFilter>
                      <Modal
                        centered
                        open={isSelectOpen[menu.name]}
                        onCancel={() => {
                          setIsSelectOpen({
                            ...isSelectOpen,
                            [menu.name]: false,
                          });
                        }}
                        footer={null}
                        closable={false}
                        classNames={{ content: "antd-filter-content" }}
                      >
                        {menu.filter}
                      </Modal>
                    </>
                  );
              })}
              {moreMenus.map((m, index) => {
                if (props.more[m.name]) {
                  return (
                    <>
                      <s.SelectFilter
                        key={`more-${index}`}
                        onClick={() => onChangeSelectOpen(m.name)}
                        isOpen={isSelectOpen[m.name]}
                        isFilter={isFiltering[m.name]}
                      >
                        {filterLabels[m.name]}

                        <button
                          onClick={() => props.onDeleteMoreFilter(m.name)}
                        >
                          <IoMdClose size={12} />
                        </button>
                      </s.SelectFilter>
                      <Modal
                        centered
                        open={isSelectOpen[m.name]}
                        onCancel={() => {
                          setIsSelectOpen({
                            ...isSelectOpen,
                            [m.name]: false,
                          });
                        }}
                        footer={null}
                        closable={false}
                        classNames={{ content: "antd-filter-content" }}
                      >
                        {m.filter}
                      </Modal>
                    </>
                  );
                }
              })}
            </s.MobileMapFilter>
          </s.MobileMapInner>
          <s.MapSearchWrapper>
            <MapSearchInput
              keyword={keywordListListQueryInfo.keyword}
              placeholder="Search In Result"
              onChange={onPowerSearch}
              onClickKeyword={onClickKeyword}
              onClickSearch={onClickSearch}
              searchKeywordList={searchKeywordList}
            />
          </s.MapSearchWrapper>
        </s.Container>
      </mediaQuery.Mobile>
    </>
  );
};

export default SubHeader;
