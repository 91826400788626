import { useState } from "react";
import * as s from "styles/ModalCommonStyled";
import StepperComponent, { StepType } from "components/blocks/stepper/Stepper";
import BigButton from "components/atoms/buttons/BigButton";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import TextArea from "components/atoms/input/TextArea";
import { Text, ValidateWord } from "components/atoms/text/Text";
import { COLORS } from "styles/colors";
import { UPDATE_TYPE } from "./AddThumbnailContainer";
import { SearchRecorder } from "components/element/SearchRecorder";
import { SearchCameraWithImage } from "components/element/SearchCameraWithImage";
import {
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import TimeDaySelect, {
  TimeDaySelectIF,
} from "components/atoms/select/TimeDaySelect";
import {
  CameraStep,
  ReportDataRow,
  ThumbnailReport,
} from "api/interfaces/reportInterface.interface";
import AddNotification, { NOTIFICATION_TYPE } from "../common/AddNotification";
import styled from "styled-components";
import { calcRem } from "styles/theme";
import DeleteConfirmModal from "components/templates/modal/small/confirm/delete/DeleteConfirmModal";
import Toggle from "components/atoms/toggle/Toggle";

type Props = {
  steps: Array<StepType>;
  page: number;
  onChangePage: (name: string, index?: number) => void;
  onChangeReportInfo: (
    type: UPDATE_TYPE,
    value:
      | string
      | number
      | boolean
      | string[]
      | boolean[]
      | number[]
      | CameraStep[]
  ) => void;
  onChangeNotification: (
    type: NOTIFICATION_TYPE,
    value: string | number | boolean | string[] | boolean[]
  ) => void;
  accountId: string;
  hostName: string;
  onClickCreate: () => void;
  thumbnailReport: ThumbnailReport;
  recorderCount: number;
  cameraCount: number;
  isEdit: boolean | undefined;
  isConfirm: boolean;
  onDelete: (value: boolean) => void;
  onConfirmDelete: () => void;
  setIsDeleteConfirm?: (confirm: boolean) => void;
  onChangeEnable: (value: boolean) => void;
  onChangeDayOfWeek?: (selectDayOfWeek: TimeDaySelectIF[]) => void;
  onChangeRecorderCount: (recorderCount: number) => void;
  onChangeCameraCount: (cameraCount: number) => void;
  reportRuleValidate: Validate;
  reportRuleValidateMsg: ValidateMsg;
  estimateStorage: string;
  selectRecorderCount: number;
  reportRow?: ReportDataRow;
  // scheduleItems: ScheduleItem[];
  // addScheduleItem: () => void;
  // deleteScheduleItem: (index: number) => void;
};

export default function AddThumbnailPresenter(props: Props): JSX.Element {
  const [interval, setInterval] = useState(true);

  const pages = (
    // recorders
    <>
      <div style={{ display: props.page === 0 ? "block" : "none" }}>
        <s.ModalTableContainer>
          <SearchRecorder
            isTab={true}
            selectedRow={props.thumbnailReport.recorder.systems}
            selectedGroupRow={props.thumbnailReport.recorder.recorderGroupId}
            accountId={props.accountId}
            onSelectedRowsChange={(value: string[]) =>
              props.onChangeReportInfo(UPDATE_TYPE.RECORDER, value)
            }
            onSelectedGroupRowsChange={(value: number[]) =>
              props.onChangeReportInfo(UPDATE_TYPE.RECORDER_GROUP, value)
            }
            userId={
              props.isEdit
                ? props.thumbnailReport.preferences.userId
                : undefined
            }
          />
          <ValidateWord>{props.reportRuleValidateMsg.isRecorder}</ValidateWord>
        </s.ModalTableContainer>
      </div>
      {/* Camera */}
      <div style={{ display: props.page === 1 ? "block" : "none" }}>
        <s.CvvReportStatus>
          <span>
            <Text bold color={COLORS.BUTTONS1}>
              Recorders:
            </Text>
            <Text>
              {props.selectRecorderCount !== undefined
                ? props.selectRecorderCount
                : 0}{" "}
              / {props.recorderCount}
            </Text>
          </span>
          <span>
            <Text bold color={COLORS.BUTTONS1}>
              Cameras:
            </Text>
            <Text>{props.cameraCount}</Text>
          </span>
        </s.CvvReportStatus>
        <SearchCameraWithImage
          accountId={props.accountId}
          selectRecorder={props.thumbnailReport.recorder}
          selectCamera={props.thumbnailReport.recorderCameraId}
          page={props.page}
          onChangeCamera={(camera: CameraStep[]) =>
            props.onChangeReportInfo(UPDATE_TYPE.CAMERA, camera)
          }
          onChangeRecorderCount={props.onChangeRecorderCount}
          onChangeCameraCount={props.onChangeCameraCount}
        />
        <ValidateWord>{props.reportRuleValidateMsg.isCamera}</ValidateWord>
      </div>
      {/* Scehdule */}
      <div style={{ display: props.page === 2 ? "block" : "none" }}>
        <s.CvvReportStatus>
          <span>
            <Text bold color={COLORS.BUTTONS1}>
              Recorders:
            </Text>
            <Text>{props.recorderCount}</Text>
          </span>
          <span>
            <Text bold color={COLORS.BUTTONS1}>
              Cameras:
            </Text>
            <Text>{props.cameraCount}</Text>
          </span>
        </s.CvvReportStatus>
        <s.MarginBox>
          <TimeDaySelect
            selected={props.thumbnailReport.schedule}
            onChangeDayOfWeek={props.onChangeDayOfWeek}
          />
          {/* <PrimaryButton
            add
            label="Add"
            onClickBtn={() => console.log("add")}
          /> */}
        </s.MarginBox>
        <ModalInput label="Storage">
          <s.CheckInput>
            <Input
              className="storage"
              defaultValue="12"
              value={props.thumbnailReport.storage}
              onChange={(e) =>
                props.onChangeReportInfo(UPDATE_TYPE.STORAGE, e.target.value)
              }
            />
            Week(s)
            <s.CvvReportStatus className="storage-space">
              <span>
                <Text color={COLORS.BUTTONS1} bold>
                  Estimated Storage Space:
                </Text>
                <Text>{props.estimateStorage}</Text>
              </span>
            </s.CvvReportStatus>
          </s.CheckInput>
        </ModalInput>
      </div>
      {/* Notification */}
      <div style={{ display: props.page === 3 ? "block" : "none" }}>
        <AddNotification
          data={props.thumbnailReport.notification}
          accountId={props.accountId}
          onChange={props.onChangeNotification}
          isEndOfMonth={props.thumbnailReport.notification.isEndOfMonth}
        />
      </div>
      {/* preferences */}
      <div style={{ display: props.page === 4 ? "block" : "none" }}>
        <ModalInput label="Report Titie" essential>
          <Input
            placeholder="Enter a title for this report"
            value={props.thumbnailReport.preferences.title}
            onChange={(e) =>
              props.onChangeReportInfo(UPDATE_TYPE.REPORT_NAME, e.target.value)
            }
          />
          <ValidateWord>{props.reportRuleValidateMsg.isRuleName}</ValidateWord>
        </ModalInput>
        <ModalInput label="Host">
          <Input disabled value={props.hostName} />
        </ModalInput>
        <ModalInput label="Description">
          <TextArea
            placeholder="Enter Description"
            value={props.thumbnailReport.preferences.description}
            onChange={(e) =>
              props.onChangeReportInfo(UPDATE_TYPE.REPORT_DESC, e.target.value)
            }
          />
        </ModalInput>
        {props.isEdit && (
          <s.RowModalInput>
            <Text bold>Enable</Text>
            <Toggle
              isToggled={
                props.thumbnailReport.preferences.isEnable !== undefined
                  ? props.thumbnailReport.preferences.isEnable
                  : true
              }
              checked={
                props.thumbnailReport.preferences.isEnable !== undefined
                  ? props.thumbnailReport.preferences.isEnable
                  : true
              }
              onChange={(
                checked: boolean,
                event: React.MouseEvent<HTMLButtonElement>
              ) => {
                // e.stopPropagation();
                props.onChangeReportInfo(UPDATE_TYPE.REPORT_ENABLE, checked);
              }}
            />
          </s.RowModalInput>
        )}
      </div>
    </>
  );

  return (
    <>
      <StepperComponent
        activeStep={props.page}
        steps={props.steps}
        onClick={props.onChangePage}
      />
      <s.ModalInnerHalf>{pages}</s.ModalInnerHalf>
      <s.ButtonWrapper>
        {props.isEdit && (
          <BigButton
            buttonType="warning"
            label="Delete"
            onClickBtn={() =>
              props.setIsDeleteConfirm !== undefined
                ? props.setIsDeleteConfirm(true)
                : null
            }
            disabled={false}
          />
        )}
        {props.page !== 0 && (
          <BigButton
            buttonType="cancel"
            label="Previous"
            onClickBtn={() => props.onChangePage("previous")}
          />
        )}

        {props.page === 4 ? (
          <BigButton label="Save" onClickBtn={props.onClickCreate} />
        ) : (
          <BigButton
            buttonType="cancel"
            label="Next"
            onClickBtn={() => props.onChangePage("next")}
          />
        )}
        {props.isConfirm && (
          <DeleteConfirmModal
            label="report rule"
            onDelete={() => props.onDelete(false)}
            onConfirmDelete={props.onConfirmDelete}
          />
        )}
      </s.ButtonWrapper>
    </>
  );
}

// const AddButton = styled.button`
//   border: 1px dashed ${COLORS.BORDER};
//   background-color: ${COLORS.WHITE}10;
//   width: 100%;
//   padding: 10px;
//   color: ${COLORS.WHITE};
//   font-size: ${calcRem(14)};
// `;
