import { Table } from "components/atoms/table/Table";
import { SortOrder, TableColumn } from "react-data-table-component";
import { TableTemplate } from "components/templates/default/table/TableTemplate";
import Status, { StatusType } from "components/blocks/status/Status";
import { useLocation } from "react-router-dom";
import AddRule from "./../modal/add/rules";
import EditRule from "./../modal/edit/EditRuleContainer";
import {
  AlertRuleListResponse,
  PostAlertRequest,
} from "api/interfaces/alertInterface.interface";
import { ClickSearch } from "components/atoms/input/search/SearchInput";
import { LEVEL_TYPE, ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import * as mediaQuery from "components/MediaQuery";
import { BasePagination } from "api/interfaces/commonInterface.interface";

type Props = {
  columns: TableColumn<AlertRuleListResponse>[];
  mobileColumns: TableColumn<AlertRuleListResponse>[];
  data: Array<AlertRuleListResponse>;
  onModal: (type?: string) => void;
  isAddModal: boolean;
  isEditModal: boolean;
  isViewModal: boolean;
  status: StatusType;
  onRowClick: (row: AlertRuleListResponse) => void;
  onClickCreate: (alertInfo: PostAlertRequest) => void;
  onClickSearch: ClickSearch;
  paginationPerPage: number;
  onChangePage: (page: number, totalRows: number) => void;
  handleSort: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
  paginationTotalRows: number;
  paginationInfo: BasePagination;
  selectedRule: AlertRuleListResponse | undefined;
  selectedAccount: ProfileAccountInfo;
  isRowClickEditable: boolean;
  tablePending: boolean;
};

export function AlertPresenter(props: Props): JSX.Element {
  const location = useLocation();
  const centerChildren = <Status status={props.status} />;

  // const data = props.alertRules ? props.alertRules.result[0].ruleList : [];

  return (
    <>
      <TableTemplate
        onClickBtn={() => props.onModal("add")}
        buttonLabel="Add New Rule"
        onClickSearch={props.onClickSearch}
        centerChildren={centerChildren}
        //requiredPermissions={"manageAlertRules"}
        requiredPermissions={
          props.selectedAccount.accountLevel !== "EU" ? "" : "manageAlertRules"
        }
        keyword={props.paginationInfo.keyword}
      >
        <mediaQuery.Default>
          <Table
            columns={props.columns}
            data={props.data}
            onRowClicked={props.onRowClick}
            paginationTotalRows={props.paginationTotalRows}
            paginationPerPage={props.paginationPerPage}
            onChangePage={props.onChangePage}
            handleSort={props.handleSort}
            defaultSortFieldId="createdAt"
            defaultSortAsc={false}
            isRowClickEditable={props.isRowClickEditable}
            progressPending={props.tablePending}
          />
        </mediaQuery.Default>
        <mediaQuery.Mobile>
          <Table
            columns={props.mobileColumns}
            data={props.data}
            onRowClicked={props.onRowClick}
            paginationTotalRows={props.paginationTotalRows}
            paginationPerPage={props.paginationPerPage}
            onChangePage={props.onChangePage}
            handleSort={props.handleSort}
            defaultSortFieldId="createdAt"
            defaultSortAsc={false}
            isRowClickEditable={props.isRowClickEditable}
            progressPending={props.tablePending}
          />
        </mediaQuery.Mobile>
      </TableTemplate>
      {props.isAddModal && (
        <AddRule isEdit={false} onModal={() => props.onModal("add")} />
      )}
      {props.isEditModal ? (
        <EditRule
          isEdit={props.isEditModal}
          alertRule={props.selectedRule}
          isView={props.selectedAccount.accountLevel !== LEVEL_TYPE.EU}
          onModal={() => props.onModal("edit")}
        />
      ) : null}
    </>
  );
}
