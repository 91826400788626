import styled from "styled-components";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import { calcRem } from "styles/theme";
import { noScrollBar } from "styles/commonStyles";
import DefaultThumbnail from "components/atoms/image/DefaultThumbnail";
import * as mediaQuery from "components/MediaQuery";

const minHeight = `min-height: calc(100vh - 360px)`;

export const Grid = styled.ul`
  width: 100%;
  max-width: 100%;
  min-width: 0;
  ${minHeight};
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px;
  grid-auto-rows: max-content;
  grid-row-gap: 50px;
  margin: 30px 0 50px;

  ${mediaQuery.isTablet} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${mediaQuery.isMobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.COLORS.PRIMARY3};
  border-radius: 3px;
  ${FLEX.FlexBetweenCenter};

  ${mediaQuery.isDefault} {
    padding: 10px 14px;
    gap: 10px;
  }

  ${mediaQuery.isMobile} {
    padding: 6px;

    .select-header {
      margin-right: 10px;
      min-width: 90px;
    }
  }
`;

export const SearchWrapper = styled.div`
  /* width: 380px; */
  max-width: 250px;
  ${FLEX.FlexEndCenter};
  flex: 1;
`;

export const TitleComponent = styled.div`
  font-weight: 400;
  font-size: ${calcRem(22)};
  ${FLEX.FlexStartCenter};
  gap: 5px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  line-height: 16px;

  .icon {
    height: 16px;

    svg {
      height: 100%;
      path {
        fill: ${({ theme }) => theme.COLORS.WHITE};
      }
    }
  }
`;

export const TitleWrapper = styled.div`
  min-width: 28vw;
`;

export const LeftFilter = styled.ul`
  ${FLEX.FlexStartCenter};
  flex: 1;
  gap: 8px;
`;

export const LeftItem = styled.li<{ isFilter: boolean }>`
  border-radius: 20px;
  padding: 5px 8px;
  cursor: pointer;
  background-color: ${COLORS.WHITE};
  color: ${COLORS.BLACK};
  font-size: ${calcRem(14)};

  ${({ isFilter }) =>
    isFilter &&
    `
      background-color: ${COLORS.BUTTONS2};
      color: ${COLORS.WHITE};
      font-weight: 500;
  `};
`;

export const RightFilter = styled.div`
  flex: 1;

  /* padding-right: 20px; */
  position: relative;

  ${mediaQuery.isDefault} {
    width: 50%;
    max-width: 60%;
    padding: 0 20px;
  }

  ${mediaQuery.isMobile} {
    width: 100%;
    padding-left: 15px;
  }
`;
export const RightScroll = styled(LeftFilter)`
  flex: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  ${noScrollBar};

  /* ${mediaQuery.isDefault} {
    padding-left: 20px;
  } */
`;

export const RightItem = styled(LeftItem)`
  white-space: nowrap;
  ${({ isFilter }) =>
    isFilter &&
    `
      background-color: ${COLORS.RED};
      color: ${COLORS.WHITE};
    `};
`;

export const RightScrollButton = styled.button`
  position: absolute;
  right: -15px;
  z-index: 44;
  height: 100%;
  text-align: right;
  width: max-content;
  color: ${({ theme }) => theme.COLORS.WHITE};

  ${mediaQuery.isMobile} {
    padding: 0 3px;
  }
`;

export const LeftScrollButton = styled(RightScrollButton)`
  text-align: left;

  ${mediaQuery.isDefault} {
    left: 0;
  }
  ${mediaQuery.isMobile} {
    left: -5px;
  }
`;

export const VideoItem = styled.li`
  /* overflow: hidden; */
  color: ${({ theme }) => theme.COLORS.WHITE};
  //RND-536 Remove mouse point cursor
  //cursor: pointer;
  width: 100%;
  max-width: 100%;
  min-width: 0;

  .time {
    padding: 6px 0;
  }
`;

export const VideoImage = styled.div<{ uploadStatus?: string }>`
  width: 100%;
  height: max-content;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  ${({ uploadStatus }) =>
    uploadStatus === "success" &&
    `
    cursor: pointer;
  `}
`;

export const VideoSection = styled.div`
  ${FLEX.FlexBetweenStart};
  padding-top: 8px;
`;

export const VideoTitle = styled.div<{ title: string | undefined }>`
  /* margin-right: 30px; */
  width: 80%;
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 4px;

  .video-title-inner {
    line-height: ${calcRem(20)};
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 2; // 원하는 라인수
    -webkit-box-orient: vertical;
    ::-webkit-scrollbar {
      display: none;
    }

    color: ${({ title, theme }) =>
      title === undefined ? COLORS.LIGHTGRAY : theme.COLORS.WHITE};
  }
`;

export const Status = styled.span<{ status?: string }>`
  padding: 1px 8px;
  border-radius: 3px;
  font-size: ${calcRem(12)};
  font-weight: 500;
  margin-right: 3px;
  background-color: ${COLORS.WHITE};
  white-space: nowrap;
  color: ${COLORS.WHITE};

  ${({ status }) =>
    status === "open" &&
    `
    background-color: ${COLORS.BUTTONS2};
  `}
  ${({ status }) =>
    status === "closed" &&
    `
    background-color: ${COLORS.BUTTONS1};
  `}
  ${({ status }) =>
    status === "ongoing" &&
    `
    background-color: #ED9C22;
  `}
`;

export const TagBox = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 4px;
  width: 100%;
  margin-top: 6px;

  .hidden-count {
    font-size: ${calcRem(14)};
  }
`;

export const FilterWrapper = styled(LeftFilter)`
  gap: 2px;
  overflow: hidden;
  width: 100%;
  margin-top: 2px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Hashtag = styled(Status)<{ isFilter: boolean }>`
  font-weight: 300;
  color: ${COLORS.BLACK};
  padding: 3px 8px;

  ${({ theme }) =>
    theme.value === "light" &&
    `
    background-color: ${theme.COLORS.PRIMARY3};
  `}

  ${({ isFilter }) =>
    isFilter &&
    `
    color: ${COLORS.WHITE};
    background-color: ${COLORS.RED};
  `}
`;

export const ViewHashtag = styled(Hashtag)`
  background-color: ${COLORS.BUTTONS1};
  color: ${COLORS.WHITE};
  font-size: ${calcRem(14)};
  min-width: 60px;
`;

export const ImageLabel = styled(Status)<{ time?: boolean }>`
  background-color: ${COLORS.BLACK}90;
  position: absolute;
  z-index: 33;
  padding: 2px 4px;

  ${({ time }) =>
    time
      ? `
        bottom: 6px;
        right: 6px;
      `
      : `
        top: 6px;
        left: 6px;
      `}
`;

/**
 * View Video Share
 */

export const ViewContainer = styled.div`
  /* height: 100%; */
`;

export const ViewInner = styled.div`
  ${mediaQuery.isDefault} {
    display: grid;
    grid-template-columns: 1fr 450px;
    gap: 30px;
    margin-bottom: 30px;
    height: 60vh;
    max-height: 60vh;

    > div {
      height: 60vh;
      max-height: 60vh;
    }
  }

  ${mediaQuery.isMobile} {
    ${FLEX.FlexStartStart};
    width: 100%;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;
  }
`;

export const ViewVideoImage = styled.div`
  height: 100%;

  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

export const TableWrapper = styled.div`
  height: 30%;
`;

export const RightAside = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 0;
  ${FLEX.FlexStartStart};
  flex-direction: column;
`;

export const Tab = styled.ul`
  width: 60%;
  max-height: 30px;
  border-radius: 7px 7px 0 0;
  background-color: ${({ theme }) =>
    theme.value === "light" ? LIGHT_THEME_COLORS.LIGHTGRAYBORDER : "#0d1119"};
  ${FLEX.FlexStartStart};
  overflow: hidden;

  ${mediaQuery.isDefault} {
    width: 90%;
    flex: 1 0 30px;
  }

  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

export const TabItem = styled.li<{ isSelected: boolean }>`
  color: ${({ theme }) =>
    theme.value === "light" ? `${COLORS.BLACK}95` : "#acb8de50"};
  text-align: center;
  padding: 8px 10px;
  font-size: ${calcRem(12)};
  border-radius: 7px 7px 0 0;
  cursor: pointer;
  flex: 1;
  ${({ isSelected, theme }) =>
    isSelected &&
    `
    color: ${theme.value === "light" ? COLORS.BLACK : "#acb8de"};
    background-color: ${
      theme.value === "light" ? LIGHT_THEME_COLORS.LIGHTGRAY : "#1b2332"
    };
    
  `}

  ${mediaQuery.isDefault} {
    :nth-child(2) {
      flex: 2;
    }
  }
  flex: 1 !important;
  background-color: ${({ theme }) =>
    theme.value === "light" ? LIGHT_THEME_COLORS.LIGHTGRAYBORDER : "#0d1119"};
  height: 100%;

  ${({ isSelected, theme }) =>
    isSelected &&
    `

    background-color:${
      theme.value === "light" ? LIGHT_THEME_COLORS.LIGHTGRAY : "#0C1724"
    };
    
  `}
`;

export const RightWrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: calc(100% - 30px);
  flex: 5;
`;
const CommonRadius = "13px";
const CommonContents = styled.div`
  /* border-radius: ${CommonRadius}; */
  /* color: ${COLORS.WHITE}; */
  overflow: hidden;
  box-shadow: 10px 10px 6px rgba(0, 0, 0, 0.16);
  background-color: #1b2332;
`;

export const Contents = styled(CommonContents)<{ isSelected?: number }>`
  background-color: #1b2332;
  border-top-right-radius: ${CommonRadius};
  border-top-left-radius: ${({ isSelected }) =>
    isSelected === 0 ? 0 : CommonRadius};
  .hide-window-button {
    text-align: center;
    padding: 10px;
    color: #acb8de50;
    width: 100%;
    background-color: #171f37;
  }

  ${mediaQuery.isMobile} {
    height: 90%;
    ${FLEX.FlexBetweenCenter};
    width: 100%;
    flex-direction: column;
    border-top-right-radius: ${({ isSelected }) =>
      isSelected === 0 ? CommonRadius : 0};
  }
  background-color: ${({ theme }) =>
    theme.value === "light" ? LIGHT_THEME_COLORS.LIGHTGRAY : "#0c1724"};
  padding: 14px;
  /* box-sizing: border-box; */
  box-shadow: none;
  border-radius: 7px;
  border-top-left-radius: 0;
  width: 100%;
  height: 100%;
  ${FLEX.FlexBetweenStart};
  flex-direction: column;

  .video-share-content {
    width: 100%;
    flex: 1;
    ${FLEX.FlexStartStart};
    flex-direction: column;
  }
  .part {
    /* padding: 30px 0; */

    /* flex: 1; */
    padding-top: 10px;
    width: 100%;
    > div {
      /* flex: 1; */
    }
  }
  .top-contents {
    width: 100%;
    ${FLEX.FlexStartStart};
    flex: 1.5;
    flex-direction: column;

    ${mediaQuery.isMobile} {
      gap: 6px;
      margin-bottom: 10px;
    }
  }
  .border-top {
    border-top: 1px solid ${COLORS.BORDER};
  }
  .bottom-contents {
    ${FLEX.FlexStartStart};
    flex-direction: column;
    width: 100%;
    flex: 1;
  }

  ${mediaQuery.isMobile} {
    width: 100%;

    .part,
    > p {
      width: 100%;
    }
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-weight: 500;
  font-size: ${calcRem(20)};
`;

export const SubTitle = styled.div`
  color: ${COLORS.BUTTONS1};
  font-weight: 500;
  margin-bottom: 6px;
  font-size: ${calcRem(12)};
`;

export const ContentsRow = styled.ul`
  ${FLEX.FlexBetweenCenter};

  > li {
    ${FLEX.FlexStartStart};
    flex: 1;
    flex-direction: column;
  }
`;

export const ContentsItem = styled.div`
  /* padding-bottom: 20px; */
  flex: 1;
  width: 100%;
`;

export const Top = styled(ContentsRow)`
  gap: 20px;
  padding: 10px 0;
  width: 100%;
  span {
    flex: 1;
    ${FLEX.FlexStartCenter};
    gap: 16px;
  }
  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

export const CommentContainer = styled(Contents)`
  ${FLEX.FlexBetweenStart};
  flex-direction: column;
  flex: 1;
  height: 100%;
  .top {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 100%;
    width: 100%;
    height: calc(100% - 100px);
  }
`;

export const CommentWrapper = styled.ul`
  margin-top: 16px;
  width: 100%;
  flex: 1;
  /* height: 100%;
  max-height: 100%; */
  height: 100%;
  overflow-y: auto;

  ${mediaQuery.isMobile} {
    max-height: 600px;
    overflow-y: auto;
  }
`;

export const CommentItem = styled.li<{ isWarning?: boolean }>`
  width: 100%;
  max-width: 100%;
  padding: 10px;
  min-height: 58px;
  ${FLEX.FlexStartStart};
  gap: 8px;
  flex: 1;

  ${({ isWarning, theme }) =>
    isWarning
      ? `
    border: 1px solid ${theme.COLORS.SELECTED};
  `
      : `
    border-top: 1px solid ${COLORS.BUTTONS1};
  `}
  .video-profile-image {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }

  .contents {
    /* ${FLEX.FlexBetweenStart}; */
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  .top-info {
    width: 100%;
    ${FLEX.FlexBetweenCenter};
  }
  .user-info {
    ${FLEX.FlexStartStart};
    gap: 8px;
    flex: 1;
  }
  .comment {
    word-break: break-all;
    white-space: normal;
    flex-grow: 1;
    font-size: ${calcRem(14)};
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

export const InputWrapper = styled.div`
  ${FLEX.FlexCenterStart};
  flex: 1;
  max-width: 100%;
  width: 100%;
  gap: 6px;
  padding-top: 8px;
  flex-direction: column;

  > textarea {
    min-height: 50px;
    resize: none;
  }

  .send-bottom {
    ${FLEX.FlexBetweenCenter};
    width: 100%;
  }

  .send-bottom label {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }

  .send {
    border-radius: 4px;
    padding: 6px;
    ${FLEX.FlexCenterCenter};
    gap: 5px;
    font-size: ${calcRem(14)};
    color: ${COLORS.WHITE};
    background-color: ${COLORS.BUTTONS2};

    img {
      height: 10px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  ${FLEX.FlexBetweenCenter};
  max-width: 100%;
  padding-bottom: 8px;
  gap: 4px;

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }

  button {
    background: none;
    padding: 2px;
    ${FLEX.FlexCenterCenter};
    color: ${({ theme }) => theme.COLORS.WHITE};

    img {
      width: 20px;
      ${mediaQuery.isMobile} {
        height: 14px;
      }
    }
  }

  input {
    padding: 5px;
    flex: 2;
  }
`;

export const SendWrapper = styled.div`
  width: 100%;
  flex: 1;
  ${FLEX.FlexEndCenter};
  padding-top: 10px;
`;

export const StatusWrapper = styled.div`
  max-width: 200px;
`;

export const EmailSelectContainer = styled.div`
  width: 100%;
  max-width: 600px;

  input {
    font-size: ${calcRem(14)} !important;
  }
`;

export const MessageOptional = styled.div`
  padding: 20px 0;

  > div {
    padding-bottom: 8px;
  }
`;

export const AddCheck = styled.div`
  ${FLEX.FlexBetweenCenter};
  margin-top: 20px;

  .date-select {
    flex: 1;
    width: 60%;
    max-width: 60%;
  }
  .select-wrapper {
    padding: 5px;
  }
`;

export const DeleteTextWrapper = styled.div`
  text-align: center;
  padding: 20px 0;
  line-height: ${calcRem(28)};
`;

export const EditTextWrapper = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 10px;
  padding-bottom: 10px;

  .title {
    flex: 1;
  }
  .content {
    flex: 4;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => `${theme.COLORS.WHITE}70`};
  }
`;

export const DeleteButton = styled.button`
  ${FLEX.FlexStartCenter};
  gap: 6px;

  > img {
    width: 14px;
  }

  ${mediaQuery.isMobile} {
    margin-top: 10px;
    > img {
      height: 12px;
    }
  }
`;

export const HashtagInputWrapper = styled.div`
  width: 100%;
  ${FLEX.FlexBetweenCenter};
  margin-top: 10px;
  gap: 4px;

  > div {
    flex: 9;
    width: calc(100% - 60px);
  }

  > button {
    padding: 2px;
    text-align: right;
    ${FLEX.FlexCenterCenter};
    color: ${({ theme }) => theme.COLORS.WHITE};

    > img {
      width: 20px;
    }
  }
`;

export const TagWrapper = styled.div`
  width: 100%;
  ${FLEX.FlexBetweenCenter};
  gap: 20px;
`;

export const TagInner = styled.div`
  width: 100%;
  overflow-y: auto;
  ${FLEX.FlexStartCenter};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome , Safari , Opera */
  }
`;

export const ShareWrapper = styled.span`
  position: relative;

  .share-img {
    ${mediaQuery.isMobile} {
      height: 12px;
    }
    ${mediaQuery.isDefault} {
      width: 18px;
    }
  }
`;

export const ShareBubble = styled.div<{ index: number }>`
  background-color: ${({ theme }) =>
    theme.value === "light" ? COLORS.WHITE : COLORS.BLACK};
  border-radius: 9px;
  padding: 15px;
  width: 280px;
  max-width: 280px;
  margin-top: 6px;

  ${({ index }) => (index % 6 === 0 ? `left: 0;` : `right: 0;`)};
  ${({ theme }) =>
    theme.value === "light" &&
    `
    border: 1px solid ${theme.COLORS.BORDER};
  `}

  ${mediaQuery.isDefault} {
    position: absolute;
    margin-top: 6px;
    z-index: 43;
  }

  ${mediaQuery.isMobile} {
    width: 100%;

    .close-button-add {
      width: 100%;
      ${FLEX.FlexEndCenter};
      padding-bottom: 10px;

      img {
        width: 12px;
      }
    }
  }
`;

export const ShareTitle = styled.div`
  ${FLEX.FlexBetweenCenter};
`;

export const ShareInner = styled.div`
  margin-top: 20px;
`;

export const SnapshotImage = styled.div`
  height: 350px;
  overflow: hidden;
  position: relative;
  border: 0.5px solid ${COLORS.BLACK};

  .capture-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .snapshot-bar {
    position: absolute;
    bottom: 0;
    background-color: ${COLORS.BLACK}60;
    width: 100%;
    padding: 6px;
    color: ${COLORS.WHITE};
    font-size: ${calcRem(14)};
    ${FLEX.FlexBetweenCenter};
  }
`;

export const SendBox = styled.div`
  ${FLEX.FlexStartStart};
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

export const MobileModalInner = styled.div`
  ${FLEX.FlexCenterCenter};
  width: 100%;
  height: 100%;
`;

export const BottomTagWrapper = styled.div`
  width: 100%;
`;
