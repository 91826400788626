import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import TextArea from "components/atoms/input/TextArea";
import BigButton from "components/atoms/buttons/BigButton";
import StepperComponent from "components/blocks/stepper/Stepper";
import { Text, ValidateWord } from "components/atoms/text/Text";
import { SearchUsers } from "components/element/SearchUsers";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { SearchRecorder } from "components/element/SearchRecorder";
import { recorderLevelOptions } from "utils/options";
import CheckList, { ListInterface } from "./CheckList";
import {
  Group,
  RecorderPermission,
  UserInfo,
  UserPermission,
} from "api/interfaces/userInterface.interface";
import { useMutation } from "react-query";
import { notify } from "components/atoms/notification/Notification";
import {
  createGroup,
  deleteGroup,
  getUserDetailAccount,
  updateGroup,
} from "api/userAPI";
import { SearchAccount } from "components/element/SearchAccount";
import { useIntl } from "react-intl";
import Selects from "components/atoms/select/Select";
import { checkWhiteSpaceRegEx } from "utils/regEx";
import {
  CustomError,
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import {
  checkPermission,
  checkRecorderPermission,
  getAccountInfo,
} from "utils/AccountUtil";
import type { Option } from "utils/options";
import { setAccountSetting } from "redux/reducers/account/accountSlice";
import { useAuth } from "components/Auth";
import useApiError from "hook/useApiError";
import DeleteConfirmModal from "components/templates/modal/small/confirm/delete/DeleteConfirmModal";

interface Props {
  isEdit?: boolean; // 편집 모드일 때
  onModal: (type?: string) => void;
  onClickSave: (data: any) => void;
  selectedEdit?: Group;
}

export enum UPDATE_TYPE {
  USERS,
  USER_PERMISSION,
  ACCOUNT,
  AUTO_APPLY,
  AUTO_APPLY_ACCOUNT,
  RECORDERS,
  RECORDER_GROUPS,
  RECORDER_LEVEL,
  RECORDER_PERMISSION,
  NAME,
  DESC,
}

const defaultGroupName = ["administrator", "manager", "viewer"];

function AddUser(props: Props): JSX.Element {
  const steps_eu = [
    {
      label: "Users",
    },
    {
      label: "User Permissions",
    },
    {
      label: "Recorders",
    },
    {
      label: "Recorder Permissions",
    },
    {
      label: "Preferences",
    },
  ];

  const steps_cp = [
    {
      label: "Users",
    },
    {
      label: "User Permissions",
    },
    {
      label: "Organizations",
    },
    {
      label: "Preferences",
    },
  ];
  const dispatch = useAppDispatch();

  const [page, setPage] = useState<number>(0);
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  const { handleError } = useApiError();
  const auth = useAuth();

  const accountRef = useRef<HTMLInputElement>(null!);
  const recorderRef = useRef<HTMLInputElement>(null!);

  const [userGroupValidate, setUserGroupValidate] = useState<Validate>({
    isUserGroupName: false,
    isUserPermission: false,
    isUsers: false,
  });

  const [userGroupValidateMsg, setUserGroupValidateMsg] = useState<ValidateMsg>(
    {
      isUserGroupName: "",
      isUserPermission: "",
      isUsers: "",
    }
  );

  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);

  const [selectRecorderPermission, setSelectRecorderPermission] =
    useState<Option>(recorderLevelOptions[4]);
  const [recorderPermissionOption, setRecorderPermissionOption] =
    useState<Option[]>(recorderLevelOptions);
  const intl = useIntl();

  const [userGroup, setUserGroup] = useState<Group>({
    users: [],
    autoApplyAccount: false,
    accounts: [],
    userPermission: {
      enableAllPermissions: false,
      manageAlertRules: true,
      viewAlerts: true,
      manageUsersAndGroups: true,
      viewUsersAndGroups: true,
      manageReportRules: true,
      viewReports: true,
      manageRecordersAndGroups:
        selectedAccount.accountLevel !== "EU" ? false : true,
      viewRecordersAndGroups:
        selectedAccount.accountLevel !== "EU" ? false : true,
      manageAccount: selectedAccount.accountLevel !== "EU" ? true : false,
      viewAccount: selectedAccount.accountLevel !== "EU" ? true : false,
      view: true,
      manageBillingAndLicenses:
        selectedAccount.accountLevel !== "EU" ? true : false,
    },
    autoApplyRecorder: false,
    systems: [],
    recorderGroups: [],
    recorderPermission: {
      recorderPermissionLevel: "Custom", // custome,admin,manager,viewer,liveviewer
      cameraSetting: true,
      viewArchive: true,
      exportArchive: true,
      viewBookmark: true,
      modifyBookmark: true,
      viewEventLog: true,
      cameraUserInput: true,
    },
    name: "",
    description: "",
    hostEmail: "",
    enableDelete: true,
    userId :undefined
  });

  const applyPage = selectedAccount.accountLevel !== "EU" ? steps_cp : steps_eu;

  const userPermitAdmin = {
    title: "Administrator",
    check: [
      {
        value: 1,
        label: intl.formatMessage({
          id: "user.permission.enableAllPermissions",
          defaultMessage: "Administrator",
        }),
        isChecked: userGroup.userPermission.enableAllPermissions,
      },
    ],
  };
  const userPermitAlert = {
    title: "Alert",
    check: [
      {
        value: 2,
        label: intl.formatMessage({
          id: "user.permission.manageAlertRules",
          defaultMessage: "Manage Alert Rules",
        }),
        isChecked: userGroup.userPermission.manageAlertRules,
      },
      {
        value: 3,
        label: intl.formatMessage({
          id: "user.permission.viewAlerts",
          defaultMessage: "View Alerts",
        }),
        isChecked: userGroup.userPermission.viewAlerts,
      },
    ],
  };
  const userPermitUser = {
    title: "User Management",
    check: [
      {
        value: 4,
        label: intl.formatMessage({
          id: "user.permission.manageUsersAndGroups",
          defaultMessage: "Manage Users and Groups",
        }),
        isChecked: userGroup.userPermission.manageUsersAndGroups,
      },
      {
        value: 5,
        label: intl.formatMessage({
          id: "user.permission.viewUsersAndGroups",
          defaultMessage: "View Users and Groups",
        }),
        isChecked: userGroup.userPermission.viewUsersAndGroups,
      },
    ],
  };
  const userPermitReport = {
    title: "Report",
    check: [
      {
        value: 6,
        label: intl.formatMessage({
          id: "user.permission.manageReportRules",
          defaultMessage: "Manage Report Rules",
        }),
        isChecked: userGroup.userPermission.manageReportRules,
      },
      {
        value: 7,
        label: intl.formatMessage({
          id: "user.permission.viewReports",
          defaultMessage: "View Reports",
        }),
        isChecked: userGroup.userPermission.viewReports,
      },
    ],
  };

  const userPermiRecorder = {
    title: "Recorders",
    check: [
      {
        value: 8,
        label: intl.formatMessage({
          id: "user.permission.manageRecordersAndGroups",
          defaultMessage: "Manage Recorders and Groups",
        }),
        isChecked: userGroup.userPermission.manageRecordersAndGroups,
      },
      {
        value: 9,
        label: intl.formatMessage({
          id: "user.permission.viewRecordersAndGroups",
          defaultMessage: "View Recorders and Groups",
        }),
        isChecked: userGroup.userPermission.viewRecordersAndGroups,
      },
    ],
  };
  const userPermitAccount = {
    title: "Organization Management",
    check: [
      {
        value: 10,
        label: intl.formatMessage({
          id: "user.permission.manageAccount",
          defaultMessage: "Manage Organization",
        }),
        isChecked: userGroup.userPermission.manageAccount,
      },
      {
        value: 11,
        label: intl.formatMessage({
          id: "user.permission.viewAccount",
          defaultMessage: "View Organization",
        }),
        isChecked: userGroup.userPermission.viewAccount,
      },
    ],
  };

  const userPermitVideo = {
    title: "Video Share",
    check: [
      {
        value: 12,
        label: intl.formatMessage({
          id: "user.permission.videoShare",
          defaultMessage: "View",
        }),
        isChecked: userGroup.userPermission.view,
      },
    ],
  };

  const userPermitBilling = {
    title: "Billing and License",
    check: [
      {
        value: 13,
        label: intl.formatMessage({
          id: "user.permission.manageBillingAndLicenses",
          defaultMessage: "Manage Billing and Licenses",
        }),
        isChecked: userGroup.userPermission.manageBillingAndLicenses,
      },
    ],
  };

  const UserPermit1 = [userPermitAdmin];
  const UserPermit2 = [userPermitUser, userPermitAlert];
  const UserPermit3 = [
    selectedAccount.accountLevel !== "EU"
      ? userPermitAccount
      : userPermiRecorder,
    userPermitReport,
  ];
  const UserPermit4 =
    selectedAccount.accountLevel !== "EU"
      ? [userPermitBilling, userPermitVideo]
      : [userPermitVideo];

  const allDisabledList = Array(13).fill(true);
  const [userCheckedList, setUserCheckedList] = useState(Array(13).fill(false));
  const [userDisabledList, setUserDisabledList] = useState(
    Array(13).fill(false)
  );
  const [permissionDisabled, setPermissionDisabled] = useState<boolean>(false);
  const [recorderCheckedList, setRecorderCheckedList] = useState(
    Array(7).fill(true)
  );
  const [disableList, setDisableList] = useState(Array(7).fill(false));
  const [hasRecorderPermission, setHasRecorderPermission] = useState<
    RecorderPermission[]
  >([]);

  const [isEditableUser, setIsEditableUser] = useState<boolean>(false);

  useEffect(() => {
    getUserDetailAccount(selectedAccount.accountId)
      .then((response: UserInfo) => {
        const mergePermission: UserPermission = checkPermission(
          response.accounts.userPermission
        );

        if (
          props.isEdit &&
          (selectedAccount.isAdmin ||
            props.selectedEdit?.hostEmail === auth.user.email)
        ) {
          setIsEditableUser(true);
        }

        if (props.isEdit && props.selectedEdit !== undefined) {
          setUserGroup({
            groupId: props.selectedEdit.groupId,
            users:
              props.selectedEdit.users !== undefined
                ? props.selectedEdit.users
                : [],
            accounts:
              props.selectedEdit.accounts !== undefined
                ? props.selectedEdit.accounts
                : [],
            userPermission: {
              enableAllPermissions:
                props.selectedEdit.userPermission.enableAllPermissions,
              manageAlertRules:
                props.selectedEdit.userPermission.manageAlertRules,
              viewAlerts: props.selectedEdit.userPermission.viewAlerts,
              manageUsersAndGroups:
                props.selectedEdit.userPermission.manageUsersAndGroups,
              viewUsersAndGroups:
                props.selectedEdit.userPermission.viewUsersAndGroups,
              manageReportRules:
                props.selectedEdit.userPermission.manageReportRules,
              viewReports: props.selectedEdit.userPermission.viewReports,
              manageRecordersAndGroups:
                props.selectedEdit.userPermission.manageRecordersAndGroups,
              viewRecordersAndGroups:
                props.selectedEdit.userPermission.viewRecordersAndGroups,
              manageAccount: props.selectedEdit.userPermission.manageAccount,
              viewAccount: props.selectedEdit.userPermission.viewAccount,
              view: props.selectedEdit.userPermission.view,
              manageBillingAndLicenses:
                props.selectedEdit.userPermission.manageBillingAndLicenses,
            },
            autoApplyAccount: props.selectedEdit.autoApplyAccount,
            autoApplyRecorder: props.selectedEdit.autoApplyRecorder,
            systems:
              props.selectedEdit.systems !== undefined
                ? props.selectedEdit.systems
                : [],
            recorderGroups:
              props.selectedEdit.recorderGroups !== undefined
                ? props.selectedEdit.recorderGroups
                : [],
            recorderPermission: {
              recorderPermissionLevel:
                props.selectedEdit.recorderPermission
                  .recorderPermissionLevel !== undefined
                  ? props.selectedEdit.recorderPermission
                      .recorderPermissionLevel
                  : "", // custome,admin,manager,viewer,liveviewer
              cameraSetting:
                props.selectedEdit.recorderPermission.cameraSetting,
              viewArchive: props.selectedEdit.recorderPermission.viewArchive,
              exportArchive:
                props.selectedEdit.recorderPermission.exportArchive,
              viewBookmark: props.selectedEdit.recorderPermission.viewBookmark,
              modifyBookmark:
                props.selectedEdit.recorderPermission.modifyBookmark,
              viewEventLog: props.selectedEdit.recorderPermission.viewEventLog,
              cameraUserInput:
                props.selectedEdit.recorderPermission.cameraUserInput,
            },
            name: props.selectedEdit.name,
            description: props.selectedEdit.description,
            enableDelete: props.selectedEdit.enableDelete,
            userId : props.selectedEdit.userId
          });

          setUserCheckedList([
            props.selectedEdit.userPermission.enableAllPermissions,
            props.selectedEdit.userPermission.manageAlertRules,
            props.selectedEdit.userPermission.viewAlerts,
            props.selectedEdit.userPermission.manageUsersAndGroups,
            props.selectedEdit.userPermission.viewUsersAndGroups,
            props.selectedEdit.userPermission.manageReportRules,
            props.selectedEdit.userPermission.viewReports,
            props.selectedEdit.userPermission.manageRecordersAndGroups,
            props.selectedEdit.userPermission.viewRecordersAndGroups,
            props.selectedEdit.userPermission.manageAccount,
            props.selectedEdit.userPermission.viewAccount,
            props.selectedEdit.userPermission.view,
            props.selectedEdit.userPermission.manageBillingAndLicenses,
          ]);

          setUserDisabledList([
            (
              selectedAccount.userPermission.enableAllPermissions &&
              selectedAccount.isAdmin
            ) ? !mergePermission.enableAllPermissions :true,
            !mergePermission.manageAlertRules,
            !mergePermission.viewAlerts,
            (
              selectedAccount.userPermission.enableAllPermissions &&
              selectedAccount.isAdmin
            ) ? !mergePermission.manageUsersAndGroups : true,
            (
              selectedAccount.userPermission.enableAllPermissions &&
              selectedAccount.isAdmin
            ) ? !mergePermission.viewUsersAndGroups : true,
            !mergePermission.manageReportRules,
            !mergePermission.viewReports,
            !mergePermission.manageRecordersAndGroups,
            !mergePermission.viewRecordersAndGroups,
            !mergePermission.manageAccount,
            !mergePermission.viewAccount,
            !mergePermission.view,
            !mergePermission.manageBillingAndLicenses,
          ]);
          //TODO User Detail에 Record Permission 정보가 담겨오면 아래에서 세팅해줘야 함.
          setRecorderCheckedList([
            props.selectedEdit.recorderPermission.cameraSetting,
            props.selectedEdit.recorderPermission.viewArchive,
            props.selectedEdit.recorderPermission.exportArchive,
            props.selectedEdit.recorderPermission.viewBookmark,
            props.selectedEdit.recorderPermission.modifyBookmark,
            props.selectedEdit.recorderPermission.viewEventLog,
            props.selectedEdit.recorderPermission.cameraUserInput,
          ]);

          if (!props.selectedEdit.enableDelete) {
            setUserDisabledList(Array(13).fill(true));
            recorderRef.current.disabled = true;
            recorderRef.current.checked = true;
            accountRef.current.checked = true;
          } else {
            recorderRef.current.checked = props.selectedEdit
              .autoApplyRecorder as boolean;
            accountRef.current.checked = props.selectedEdit
              .autoApplyAccount as boolean;
          }

          //TODO Recorder Permission Check 변경 필요.
          if (selectedAccount.accountLevel === "EU") {
            if (
              selectedAccount.checkedUserAdmin ||
              props.selectedEdit.hostEmail === auth.user.email
            ) {
              changeRecorderPermission(
                props.selectedEdit.recorderPermission.recorderPermissionLevel
              );
              if (selectedAccount.checkedUserAdmin) {
                setPermissionDisabled(false);
              } else {
                setPermissionDisabled(true);
                const permmitRecorderPermission = checkRecorderPermission(
                  response.accounts.recorderPermission
                );
                setDisableList([
                  !permmitRecorderPermission.cameraSetting,
                  !permmitRecorderPermission.viewArchive,
                  !permmitRecorderPermission.exportArchive,
                  !permmitRecorderPermission.viewBookmark,
                  !permmitRecorderPermission.modifyBookmark,
                  !permmitRecorderPermission.viewEventLog,
                  !permmitRecorderPermission.cameraUserInput,
                ]);
              }
              if (!props.selectedEdit.enableDelete) {
                setPermissionDisabled(true);
                setDisableList(Array(7).fill(true));
              }
            } else {
              setPermissionDisabled(true);
              setDisableList(Array(7).fill(true));
            }

            // let level =
            //   defaultCheckList[
            //     props.selectedEdit.recorderPermission
            //       .recorderPermissionLevel as keyof typeof defaultCheckList
            //   ];
            // if (!props.selectedEdit.enableDelete && ( selectedAccount.isAdmin || props.selectedEdit.hostEmail === auth.user.email)) {
            //   setPermissionDisabled(true);
            //   setDisableList(Array(7).fill(true));
            //   changeRecorderPermission(
            //     props.selectedEdit.recorderPermission.recorderPermissionLevel
            //   );
            // } else {
            //   setPermissionDisabled(false);

            //   if (response.accounts.recorderPermission !== undefined) {
            //     if (!mergePermission.enableAllPermissions) {
            //       setHasRecorderPermission(
            //         response.accounts.recorderPermission as RecorderPermission[]
            //       );
            //       const idList = response.accounts.recorderPermission.map(
            //         (permit) => permit.recorderPermissionLevel
            //       );
            //       idList.push(
            //         props.selectedEdit.recorderPermission
            //           .recorderPermissionLevel
            //       );
            //       const newOption = recorderLevelOptions.map((option) => {
            //         return {
            //           value: option.value,
            //           label: option.label,
            //           isDisabled: !idList.includes(option.value),
            //         };
            //       });
            //       setRecorderPermissionOption(newOption);
            //       if (props.selectedEdit !== undefined) {
            //         const selected = newOption.find(
            //           (option) =>
            //             option.value ===
            //             props.selectedEdit?.recorderPermission
            //               .recorderPermissionLevel
            //         );
            //         if (selected && selected.value === "Custom") {
            //           const selectCustom =
            //             response.accounts.recorderPermission.filter(
            //               (permit) =>
            //                 permit.recorderPermissionLevel === "Custom"
            //             ) as unknown as RecorderPermission;
            //           setRecorderCheckedList([
            //             selectCustom.cameraSetting,
            //             selectCustom.viewArchive,
            //             selectCustom.exportArchive,
            //             selectCustom.viewBookmark,
            //             selectCustom.modifyBookmark,
            //             selectCustom.viewEventLog,
            //             selectCustom.cameraUserInput,
            //           ]);
            //           setSelectRecorderPermission(selected);
            //         }
            //       }
            //     } else {
            //       const selected = recorderLevelOptions.find(
            //         (option) =>
            //           option.value ===
            //           props.selectedEdit?.recorderPermission
            //             .recorderPermissionLevel
            //       );
            //       if (selected && selected.value !== "Custom") {
            //         setDisableList(Array(7).fill(true));
            //       }
            //       setSelectRecorderPermission(selected as Option);
            //     }
            //   }
            // }
          }
        } else {
          setUserGroup({
            users: [],
            accounts: [],
            userPermission: {
              enableAllPermissions:
                selectedAccount.userPermission.enableAllPermissions,
              manageAlertRules: selectedAccount.userPermission.manageAlertRules,
              viewAlerts: selectedAccount.userPermission.viewAlerts,
              manageUsersAndGroups:
                selectedAccount.userPermission.manageUsersAndGroups,
              viewUsersAndGroups:
                selectedAccount.userPermission.viewUsersAndGroups,
              manageReportRules:
                selectedAccount.userPermission.manageReportRules,
              viewReports: selectedAccount.userPermission.viewReports,
              manageRecordersAndGroups:
                selectedAccount.userPermission.manageRecordersAndGroups,
              viewRecordersAndGroups:
                selectedAccount.userPermission.viewRecordersAndGroups,
              manageAccount: selectedAccount.userPermission.manageAccount,
              viewAccount: selectedAccount.userPermission.viewAccount,
              view: selectedAccount.userPermission.view,
              manageBillingAndLicenses:
                selectedAccount.userPermission.manageBillingAndLicenses,
            },
            autoApplyAccount:
              selectedAccount.userPermission.enableAllPermissions,
            autoApplyRecorder:
              selectedAccount.userPermission.enableAllPermissions,
            systems: [],
            recorderGroups: [],
            recorderPermission: {
              recorderPermissionLevel: "Custom", // custome,admin,manager,viewer,liveviewer
              cameraSetting: false,
              viewArchive: false,
              exportArchive: false,
              viewBookmark: false,
              modifyBookmark: false,
              viewEventLog: false,
              cameraUserInput: false,
            },
            name: "",
            hostEmail: "",
            description: "",
            userId : undefined
          });

          if (
            selectedAccount.userPermission.enableAllPermissions &&
            selectedAccount.isAdmin
          ) {
            setUserCheckedList(Array(13).fill(true));
            setUserDisabledList(Array(13).fill(false));
            if (selectedAccount.accountLevel !== "EU") {
              accountRef.current.checked = true;
              accountRef.current.disabled = false;
            } else {
              recorderRef.current.checked = true;
            }
          } else {
            setUserCheckedList([
              false, //selectedAccount.userPermission.enableAllPermissions,
              selectedAccount.userPermission.manageAlertRules,
              selectedAccount.userPermission.viewAlerts,
              false, //selectedAccount.userPermission.manageUsersAndGroups,
              false, //selectedAccount.userPermission.viewUsersAndGroups,
              selectedAccount.userPermission.manageReportRules,
              selectedAccount.userPermission.viewReports,
              selectedAccount.userPermission.manageRecordersAndGroups,
              selectedAccount.userPermission.viewRecordersAndGroups,
              selectedAccount.userPermission.manageAccount,
              selectedAccount.userPermission.viewAccount,
              selectedAccount.userPermission.view,
              selectedAccount.userPermission.manageBillingAndLicenses,
            ]);
            setUserDisabledList([
              true, //!selectedAccount.userPermission.enableAllPermissions,
              !selectedAccount.userPermission.manageAlertRules,
              !selectedAccount.userPermission.viewAlerts,
              true, //!selectedAccount.userPermission.manageUsersAndGroups,
              true, //!selectedAccount.userPermission.viewUsersAndGroups,
              !selectedAccount.userPermission.manageReportRules,
              !selectedAccount.userPermission.viewReports,
              !selectedAccount.userPermission.manageRecordersAndGroups,
              !selectedAccount.userPermission.viewRecordersAndGroups,
              !selectedAccount.userPermission.manageAccount,
              !selectedAccount.userPermission.viewAccount,
              !selectedAccount.userPermission.view,
              !selectedAccount.userPermission.manageBillingAndLicenses,
            ]);

            setUserGroup((info) => {
              return {
                ...info,
                userPermission: {
                  ...info.userPermission,
                  enableAllPermissions: false,
                  manageUsersAndGroups: false,
                  viewUsersAndGroups: false,
                },
                autoApplyRecorder: selectedAccount.userPermission
                  .enableAllPermissions as boolean,
                autoApplyAccount: selectedAccount.userPermission
                  .enableAllPermissions as boolean,
              };
            });
            recorderRef.current.disabled = false;
            recorderRef.current.checked = selectedAccount.userPermission
              .enableAllPermissions as boolean;
            accountRef.current.checked = selectedAccount.userPermission
              .enableAllPermissions as boolean;
          }

          if (selectedAccount.checkedUserAdmin) {
            setRecorderCheckedList(
              defaultCheckList["Custom" as keyof typeof defaultCheckList].check
            );
            setDisableList(
              defaultCheckList["Custom" as keyof typeof defaultCheckList]
                .disable
            );
            setUserGroup((info) => {
              return {
                ...info,
                recorderPermission: {
                  ...info.recorderPermission,
                  recorderPermissionLevel: "Custom",
                  cameraSetting:
                    defaultCheckList["Custom" as keyof typeof defaultCheckList]
                      .check[0],
                  viewArchive:
                    defaultCheckList["Custom" as keyof typeof defaultCheckList]
                      .check[2],
                  exportArchive:
                    defaultCheckList["Custom" as keyof typeof defaultCheckList]
                      .check[3],
                  viewBookmark:
                    defaultCheckList["Custom" as keyof typeof defaultCheckList]
                      .check[4],
                  modifyBookmark:
                    defaultCheckList["Custom" as keyof typeof defaultCheckList]
                      .check[5],
                  viewEventLog:
                    defaultCheckList["Custom" as keyof typeof defaultCheckList]
                      .check[1],
                  cameraUserInput:
                    defaultCheckList["Custom" as keyof typeof defaultCheckList]
                      .check[6],
                },
              };
            });
          } else {
            const permmitRecorderPermission = checkRecorderPermission(
              response.accounts.recorderPermission
            );
            setDisableList([
              !permmitRecorderPermission.cameraSetting,
              !permmitRecorderPermission.viewArchive,
              !permmitRecorderPermission.exportArchive,
              !permmitRecorderPermission.viewBookmark,
              !permmitRecorderPermission.modifyBookmark,
              !permmitRecorderPermission.viewEventLog,
              !permmitRecorderPermission.cameraUserInput,
            ]);
            setRecorderCheckedList([
              false,
              false,
              false,
              false,
              false,
              false,
              permmitRecorderPermission.cameraUserInput,
            ]);
            const newOption = recorderLevelOptions.map((option) => {
              return {
                value: option.value,
                label: option.label,
                isDisabled: !"Custom".includes(option.value),
              };
            });
            setRecorderPermissionOption(newOption);

            setUserGroup((info) => {
              return {
                ...info,
                recorderPermission: {
                  ...info.recorderPermission,
                  recorderPermissionLevel: "Custom",
                  cameraSetting:
                    defaultCheckList["Custom" as keyof typeof defaultCheckList]
                      .check[0],
                  viewArchive:
                    defaultCheckList["Custom" as keyof typeof defaultCheckList]
                      .check[2],
                  exportArchive:
                    defaultCheckList["Custom" as keyof typeof defaultCheckList]
                      .check[3],
                  viewBookmark:
                    defaultCheckList["Custom" as keyof typeof defaultCheckList]
                      .check[4],
                  modifyBookmark:
                    defaultCheckList["Custom" as keyof typeof defaultCheckList]
                      .check[5],
                  viewEventLog:
                    defaultCheckList["Custom" as keyof typeof defaultCheckList]
                      .check[1],
                  cameraUserInput: permmitRecorderPermission.cameraUserInput
                    ? defaultCheckList[
                        "Custom" as keyof typeof defaultCheckList
                      ].check[6]
                    : false,
                },
              };
            });
          }
        }

        if (selectedAccount.accountLevel === "EU") {
          setUserGroup((info) => {
            return {
              ...info,
              autoApplyAccount: false,
            };
          });
        } else {
          setUserGroup((info) => {
            return {
              ...info,
              autoApplyRecorder: false,
            };
          });
        }
      })
      .catch(function (err) {
        notify(
          "error",
          intl.formatMessage({
            id: "label.user.group.notify.user.noData",
            defaultMessage: "User Information not found.",
          })
        );
      });
  }, [props.isEdit, props.selectedEdit, selectedAccount]);

  const onChangePage = (name: string, index?: number) => {
    if (name === "next") {
      setPage(page + 1);
    }
    if (name === "previous") {
      setPage(page - 1);
    }
    if (name === "save") {
      props.onModal();
    }
    if (name === "step" && index !== undefined) {
      setPage(index);
    }
  };

  const recorderPermissionList = [
    {
      title: "Recorder Permissions",
      check: [
        {
          value: 1,
          label: "Camera Settings",
          isChecked: userGroup.recorderPermission.cameraSetting,
          disabled: false,
          checkCondition: ["admin"],
        },
        {
          value: 6,
          label: "View Event Log",
          isChecked: userGroup.recorderPermission.viewEventLog,
          disabled: false,
          checkCondition: ["admin", "advance"],
        },
        {
          value: 2,
          label: "View Archive",
          isChecked: userGroup.recorderPermission.viewArchive,
          disabled: false,
          checkCondition: ["admin", "advance", "viewer"],
        },
        {
          value: 3,
          label: "Export Archive",
          isChecked: userGroup.recorderPermission.exportArchive,
          disabled: false,
          checkCondition: ["admin", "advance", "viewer"],
        },
        {
          value: 4,
          label: "View Bookmarks",
          isChecked: userGroup.recorderPermission.viewBookmark,
          disabled: false,
          checkCondition: ["admin", "advance", "viewer"],
        },
        {
          value: 5,
          label: "Modify Bookmarks",
          isChecked: userGroup.recorderPermission.modifyBookmark,
          disabled: false,
          checkCondition: ["admin", "advance"],
        },
        {
          value: 7,
          label: "Camera User Input",
          isChecked: userGroup.recorderPermission.cameraUserInput,
          extra: "(PTZ, Output, 2-Way Audio)",
          disabled: false,
          checkCondition: ["admin", "advance"],
        },
      ],
    },
  ];

  const mutationCreateUserGroup = useMutation(createGroup, {
    onSuccess: (res: any) => {
      if (res.error !== 0) {
        notify(
          "error",
          intl.formatMessage({
            id: "label.user.group.notify.add.fail",
            defaultMessage: "Failed to add User group.",
          })
        );
      } else {
        notify(
          "success",
          intl.formatMessage({
            id: "label.user.group.notify.add.success",
            defaultMessage: "User group added successfully.",
          })
        );
        getAccountInfo(selectedAccount.accountId).then((res: any) => {
          dispatch(setAccountSetting(res));
        });
        props.onModal("edit");
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.user.group.notify.add.fail",
          defaultMessage: "Failed to add User group.",
        })
      );
    },
  });

  const mutationUpdateUserGroup = useMutation(updateGroup, {
    onSuccess: (res: any) => {
      if (res.error !== 0) {
        notify(
          "error",
          intl.formatMessage({
            id: "label.user.group.notify.update.fail",
            defaultMessage: "Failed to update User group.",
          })
        );
      } else {
        notify(
          "success",
          intl.formatMessage({
            id: "label.user.group.notify.update.success",
            defaultMessage: "User group updated successfully.",
          })
        );
        getAccountInfo(selectedAccount.accountId).then((res: any) => {
          dispatch(setAccountSetting(res));
        });
        props.onModal("edit");
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.user.group.notify.update.fail",
          defaultMessage: "Failed to update User group.",
        })
      );
    },
  });

  const onClickCreate = useCallback(() => {
    //setIsAddModal(true);
    console.log("userGroup", userGroup);

    if (checkWhiteSpaceRegEx(userGroup.name)) {
      setUserGroupValidate((info) => {
        return { ...info, isUserGroupName: true as boolean };
      });

      setUserGroupValidateMsg((info) => {
        return {
          ...info,
          isUserGroupName: intl.formatMessage({
            id: "validateMsg.userGroup.name",
            defaultMessage: "Please enter a user group name.",
          }) as string,
        };
      });
      return;
    }

    if (
      userGroup.enableDelete &&
      defaultGroupName.includes(userGroup.name.toLowerCase())
    ) {
      setUserGroupValidate((info) => {
        return { ...info, isUserGroupName: true as boolean };
      });

      setUserGroupValidateMsg((info) => {
        return {
          ...info,
          isUserGroupName: intl.formatMessage({
            id: "validateMsg.userGroup.sameDefault",
            defaultMessage:
              "Administrator, Manager, and Viewer group names are not allowed as they already exist.",
          }) as string,
        };
      });
      return;
    }

    if (!userCheckedList.includes(true)) {
      setUserGroupValidate((info) => {
        return { ...info, isUserPermission: true as boolean };
      });

      setUserGroupValidateMsg((info) => {
        return {
          ...info,
          isUserPermission: intl.formatMessage({
            id: "validateMsg.userGroup.permissionEmpty",
            defaultMessage: "Please select permissions.",
          }) as string,
        };
      });
      setPage(1);
      return;
    }

    if (
      userGroup.name.toLowerCase() === "administrator" &&
      userGroup.users.length === 0
    ) {
      setUserGroupValidate((info) => {
        return { ...info, isUsers: true as boolean };
      });

      setUserGroupValidateMsg((info) => {
        return {
          ...info,
          isUsers: intl.formatMessage({
            id: "validateMsg.userGroup.emptyAdmin",
            defaultMessage: "Select one or more users.",
          }) as string,
        };
      });
      setPage(0);
      return;
    }

    if (props.isEdit) {
      mutationUpdateUserGroup.mutate({
        payload: userGroup,
        accountId: selectedAccount.accountId,
        groupId: userGroup.groupId as string,
      });
    } else {
      mutationCreateUserGroup.mutate({
        payload: userGroup,
        accountId: selectedAccount.accountId,
      });
    }
  }, [userGroup, mutationCreateUserGroup, selectedAccount.accountId]);

  const mutationDeleteUserGroup = useMutation(deleteGroup, {
    onSuccess: (res: any) => {
      if (res.error !== 0) {
        notify(
          "error",
          intl.formatMessage({
            id: "label.user.group.notify.delete.fail",
            defaultMessage: "Failed to delete User group.",
          })
        );
      } else {
        notify(
          "success",
          intl.formatMessage({
            id: "label.user.group.notify.delete.success",
            defaultMessage: "User group deleted successfully.",
          })
        );
        props.onModal("edit");
      }
    },
    onError: (err: CustomError) => {
      handleError(
        err,
        intl.formatMessage({
          id: "label.userGroup.notify.delete.fail",
          defaultMessage: "Failed to delete user group.",
        })
      );
    },
  });

  const onClickDelete = useCallback(() => {
    setIsDeleteConfirm(false);
    mutationDeleteUserGroup.mutate({
      accountId: selectedAccount.accountId,
      groupId: userGroup.groupId as string,
    });
  }, [mutationDeleteUserGroup, selectedAccount.accountId, userGroup.groupId]);

  const defaultCheckList = {
    Administrator: {
      check: [true, true, true, true, true, true, true],
      disable: [true, true, true, true, true, true, true],
    },
    "Advanced Viewer": {
      check: [false, true, true, true, true, true, true],
      disable: [true, true, true, true, true, true, true],
    },
    Viewer: {
      check: [false, true, true, true, true, false, false],
      disable: [true, true, true, true, true, true, true],
    },
    "Live Viewer": {
      check: [false, false, false, false, false, false, false],
      disable: [true, true, true, true, true, true, true],
    },
    Custom: {
      check: [false, false, false, false, false, false, true],
      disable: [false, false, true, true, true, false, false],
    },
  };

  const changeRecorderPermission = (value: string) => {
    const findOption: Option = recorderLevelOptions.find(
      (option) => option.value === value
    ) as Option;
    setSelectRecorderPermission(findOption);
  };

  const onChangeRecorderLevel = (value: string) => {
    let level = defaultCheckList[value as keyof typeof defaultCheckList];
    changeRecorderPermission(value);
    setRecorderCheckedList(level.check);
    setDisableList(level.disable);

    setUserGroup((info) => {
      return {
        ...info,
        recorderPermission: {
          ...info.recorderPermission,
          recorderPermissionLevel: value,
          cameraSetting:
            defaultCheckList[value as keyof typeof defaultCheckList].check[0],
          viewArchive:
            defaultCheckList[value as keyof typeof defaultCheckList].check[2],
          exportArchive:
            defaultCheckList[value as keyof typeof defaultCheckList].check[3],
          viewBookmark:
            defaultCheckList[value as keyof typeof defaultCheckList].check[4],
          modifyBookmark:
            defaultCheckList[value as keyof typeof defaultCheckList].check[5],
          viewEventLog:
            defaultCheckList[value as keyof typeof defaultCheckList].check[1],
          cameraUserInput:
            defaultCheckList[value as keyof typeof defaultCheckList].check[6],
        },
      };
    });
    // setUserGroup((info) => {
    //   return {
    //     ...info,
    //     recorderPermission: {
    //       ...info.recorderPermission,
    //       recorderPermissionLevel: value,
    //       cameraSetting: level.check[0],
    //       viewArchive: level.check[2],
    //       exportArchive: level.check[3],
    //       viewBookmark: level.check[4],
    //       modifyBookmark: level.check[5],
    //       viewEventLog: level.check[1],
    //       cameraUserInput: level.check[6],
    //     },
    //   };
    // });
  };

  const onChangeGroupInfo = useCallback(
    (
      type: UPDATE_TYPE,
      value: boolean | string | number | string[] | number[],
      checkField?: number
    ) => {
      if (type === UPDATE_TYPE.USERS) {
        setUserGroup((info) => {
          return { ...info, users: value as string[] };
        });
      } else if (type === UPDATE_TYPE.ACCOUNT) {
        setUserGroup((info) => {
          return { ...info, accounts: value as string[] };
        });
      } else if (type === UPDATE_TYPE.USER_PERMISSION && checkField === 1) {
        setUserCheckedList((prevState) =>
          prevState.map((item, idx) => (idx === checkField - 1 ? !item : item))
        );

        setUserGroup((info) => {
          return {
            ...info,
            userPermission: {
              ...info.userPermission,
              enableAllPermissions: value as boolean,
            },
          };
        });
        setPermissionDisabled(value as boolean);

        //Admin이 Check되면 Account는 무조건 전체선택되어야함.
        if (selectedAccount.accountLevel !== "EU") {
          setUserGroup((info) => {
            return { ...info, autoApplyAccount: value as boolean };
          });
          accountRef.current.checked = value as boolean;
          //accountRef.current.disabled = !value as boolean;
        } else {
          setUserGroup((info) => {
            return { ...info, autoApplyRecorder: value as boolean };
          });
          recorderRef.current.checked = value as boolean;
          //recorderRef.current.disabled = !value as boolean;
        }

        if (value) {
          setUserCheckedList((prevState) =>
            prevState.map((item, idx) => value)
          );
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                enableAllPermissions: value as boolean,
                manageAlertRules: value as boolean,
                viewAlerts: value as boolean,
                manageUsersAndGroups: value as boolean,
                viewUsersAndGroups: value as boolean,
                manageReportRules: value as boolean,
                viewReports: value as boolean,
                manageRecordersAndGroups:
                  selectedAccount.accountLevel !== "EU"
                    ? false
                    : (value as boolean),
                viewRecordersAndGroups:
                  selectedAccount.accountLevel !== "EU"
                    ? false
                    : (value as boolean),
                manageAccount:
                  selectedAccount.accountLevel !== "EU"
                    ? (value as boolean)
                    : false,
                viewAccount:
                  selectedAccount.accountLevel !== "EU"
                    ? (value as boolean)
                    : false,
                view: value as boolean,
                manageBillingAndLicenses:
                  selectedAccount.accountLevel !== "EU"
                    ? (value as boolean)
                    : false,
              },
            };
          });
        } else {
        }
      } else if (type === UPDATE_TYPE.USER_PERMISSION && checkField === 2) {
        // Alert
        setUserCheckedList((prevState) =>
          prevState.map((item, idx) => (idx === checkField - 1 ? !item : item))
        );
        setUserGroup((info) => {
          return {
            ...info,
            userPermission: {
              ...info.userPermission,
              manageAlertRules: value as boolean,
            },
          };
        });
        if (value) {
          setUserCheckedList((prevState) =>
            prevState.map((item, idx) => (idx === checkField ? value : item))
          );
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                viewAlerts: value as boolean,
              },
            };
          });
        }
      } else if (type === UPDATE_TYPE.USER_PERMISSION && checkField === 3) {
        setUserCheckedList((prevState) =>
          prevState.map((item, idx) => (idx === checkField - 1 ? !item : item))
        );
        setUserGroup((info) => {
          return {
            ...info,
            userPermission: {
              ...info.userPermission,
              viewAlerts: value as boolean,
            },
          };
        });
        if (!value) {
          setUserCheckedList((prevState) =>
            prevState.map((item, idx) =>
              idx === checkField - 2 ? value : item
            )
          );
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                manageAlertRules: value as boolean,
              },
            };
          });
        }
      } else if (type === UPDATE_TYPE.USER_PERMISSION && checkField === 4) {
        //  User
        setUserCheckedList((prevState) =>
          prevState.map((item, idx) => (idx === checkField - 1 ? !item : item))
        );
        setUserGroup((info) => {
          return {
            ...info,
            userPermission: {
              ...info.userPermission,
              manageUsersAndGroups: value as boolean,
            },
          };
        });
        if (value) {
          setUserCheckedList((prevState) =>
            prevState.map((item, idx) => (idx === checkField ? value : item))
          );
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                viewUsersAndGroups: value as boolean,
              },
            };
          });
        }
      } else if (type === UPDATE_TYPE.USER_PERMISSION && checkField === 5) {
        setUserCheckedList((prevState) =>
          prevState.map((item, idx) => (idx === checkField - 1 ? !item : item))
        );
        setUserGroup((info) => {
          return {
            ...info,
            userPermission: {
              ...info.userPermission,
              viewUsersAndGroups: value as boolean,
            },
          };
        });
        if (!value) {
          setUserCheckedList((prevState) =>
            prevState.map((item, idx) =>
              idx === checkField - 2 ? value : item
            )
          );
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                manageUsersAndGroups: value as boolean,
              },
            };
          });
        }
      } else if (type === UPDATE_TYPE.USER_PERMISSION && checkField === 6) {
        // Report
        setUserCheckedList((prevState) =>
          prevState.map((item, idx) => (idx === checkField - 1 ? !item : item))
        );
        setUserGroup((info) => {
          return {
            ...info,
            userPermission: {
              ...info.userPermission,
              manageReportRules: value as boolean,
            },
          };
        });
        if (value) {
          setUserCheckedList((prevState) =>
            prevState.map((item, idx) => (idx === checkField ? value : item))
          );
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                viewReports: value as boolean,
              },
            };
          });
        }
      } else if (type === UPDATE_TYPE.USER_PERMISSION && checkField === 7) {
        setUserCheckedList((prevState) =>
          prevState.map((item, idx) => (idx === checkField - 1 ? !item : item))
        );
        setUserGroup((info) => {
          return {
            ...info,
            userPermission: {
              ...info.userPermission,
              viewReports: value as boolean,
            },
          };
        });
        if (!value) {
          setUserCheckedList((prevState) =>
            prevState.map((item, idx) =>
              idx === checkField - 2 ? value : item
            )
          );
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                manageReportRules: value as boolean,
              },
            };
          });
        }
      } else if (type === UPDATE_TYPE.USER_PERMISSION && checkField === 8) {
        //recorder
        setUserCheckedList((prevState) =>
          prevState.map((item, idx) => (idx === checkField - 1 ? !item : item))
        );
        setUserGroup((info) => {
          return {
            ...info,
            userPermission: {
              ...info.userPermission,
              manageRecordersAndGroups: value as boolean,
            },
          };
        });
        if (value) {
          setUserCheckedList((prevState) =>
            prevState.map((item, idx) => (idx === checkField ? value : item))
          );
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                viewRecordersAndGroups: value as boolean,
              },
            };
          });
        }
      } else if (type === UPDATE_TYPE.USER_PERMISSION && checkField === 9) {
        setUserCheckedList((prevState) =>
          prevState.map((item, idx) => (idx === checkField - 1 ? !item : item))
        );
        setUserGroup((info) => {
          return {
            ...info,
            userPermission: {
              ...info.userPermission,
              viewRecordersAndGroups: value as boolean,
            },
          };
        });
        if (!value) {
          setUserCheckedList((prevState) =>
            prevState.map((item, idx) =>
              idx === checkField - 2 ? value : item
            )
          );
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                manageRecordersAndGroups: value as boolean,
              },
            };
          });
        }
      } else if (type === UPDATE_TYPE.USER_PERMISSION && checkField === 10) {
        //account
        setUserCheckedList((prevState) =>
          prevState.map((item, idx) => (idx === checkField - 1 ? !item : item))
        );
        setUserGroup((info) => {
          return {
            ...info,
            userPermission: {
              ...info.userPermission,
              manageAccount: value as boolean,
            },
          };
        });
        if (value) {
          setUserCheckedList((prevState) =>
            prevState.map((item, idx) => (idx === checkField ? value : item))
          );
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                viewAccount: value as boolean,
              },
            };
          });
        }
      } else if (type === UPDATE_TYPE.USER_PERMISSION && checkField === 11) {
        setUserCheckedList((prevState) =>
          prevState.map((item, idx) => (idx === checkField - 1 ? !item : item))
        );
        setUserGroup((info) => {
          return {
            ...info,
            userPermission: {
              ...info.userPermission,
              viewAccount: value as boolean,
            },
          };
        });
        if (!value) {
          setUserCheckedList((prevState) =>
            prevState.map((item, idx) =>
              idx === checkField - 2 ? value : item
            )
          );
          setUserGroup((info) => {
            return {
              ...info,
              userPermission: {
                ...info.userPermission,
                manageAccount: value as boolean,
              },
            };
          });
        }
      } else if (type === UPDATE_TYPE.USER_PERMISSION && checkField === 12) {
        setUserCheckedList((prevState) =>
          prevState.map((item, idx) => (idx === checkField - 1 ? !item : item))
        );
        setUserGroup((info) => {
          return {
            ...info,
            userPermission: {
              ...info.userPermission,
              view: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.USER_PERMISSION && checkField === 13) {
        setUserCheckedList((prevState) =>
          prevState.map((item, idx) => (idx === checkField - 1 ? !item : item))
        );
        setUserGroup((info) => {
          return {
            ...info,
            userPermission: {
              ...info.userPermission,
              manageBillingAndLicenses: value as boolean,
            },
          };
        });
      } else if (type === UPDATE_TYPE.AUTO_APPLY) {
        setUserGroup((info) => {
          return { ...info, autoApplyRecorder: value as boolean };
        });
      } else if (type === UPDATE_TYPE.AUTO_APPLY_ACCOUNT) {
        setUserGroup((info) => {
          return { ...info, autoApplyAccount: value as boolean };
        });
      } else if (type === UPDATE_TYPE.RECORDERS) {
        setUserGroup((info) => {
          return { ...info, systems: value as string[] };
        });
      } else if (type === UPDATE_TYPE.RECORDER_GROUPS) {
        setUserGroup((info) => {
          return { ...info, recorderGroups: value as number[] };
        });
      } else if (type === UPDATE_TYPE.RECORDER_PERMISSION) {
        setRecorderCheckedList((prevState) =>
          prevState.map((item, idx) =>
            idx === (checkField as number) - 1 ? !item : item
          )
        );

        if (selectedAccount.checkedUserAdmin) {
          if ((checkField as number) === 2) {
            setDisableList((prevState) =>
              prevState.map((item, idx) =>
                idx === 2 || idx === 3 ? !value : item
              )
            );
            if (!value) {
              setDisableList((prevState) =>
                prevState.map((item, idx) => (idx === 4 ? !value : item))
              );
              setRecorderCheckedList((prevState) =>
                prevState.map((item, idx) =>
                  idx === 2 || idx === 3 || idx === 4 ? false : item
                )
              );
            }
          }
          if ((checkField as number) === 4) {
            setDisableList((prevState) =>
              prevState.map((item, idx) => (idx === 4 ? !value : item))
            );
            if (!value) {
              setRecorderCheckedList((prevState) =>
                prevState.map((item, idx) => (idx === 4 ? false : item))
              );
            }
          }
        }
        switch ((checkField as number) % 7) {
          case 0:
            setUserGroup((info) => {
              return {
                ...info,
                recorderPermission: {
                  ...info.recorderPermission,
                  cameraUserInput: value as boolean,
                },
              };
            });
            break;
          case 1:
            setUserGroup((info) => {
              return {
                ...info,
                recorderPermission: {
                  ...info.recorderPermission,
                  cameraSetting: value as boolean,
                },
              };
            });
            break;
          case 6:
            setUserGroup((info) => {
              return {
                ...info,
                recorderPermission: {
                  ...info.recorderPermission,
                  viewEventLog: value as boolean,
                },
              };
            });
            break;
          case 2:
            setUserGroup((info) => {
              return {
                ...info,
                recorderPermission: {
                  ...info.recorderPermission,
                  viewArchive: value as boolean,
                },
              };
            });
            break;
          case 3:
            setUserGroup((info) => {
              return {
                ...info,
                recorderPermission: {
                  ...info.recorderPermission,
                  exportArchive: value as boolean,
                },
              };
            });
            break;
          case 4:
            setUserGroup((info) => {
              return {
                ...info,
                recorderPermission: {
                  ...info.recorderPermission,
                  viewBookmark: value as boolean,
                },
              };
            });
            break;
          case 5:
            setUserGroup((info) => {
              return {
                ...info,
                recorderPermission: {
                  ...info.recorderPermission,
                  modifyBookmark: value as boolean,
                },
              };
            });
            break;

          default:
            break;
        }
      } else if (type === UPDATE_TYPE.NAME) {
        setUserGroup((info) => {
          return { ...info, name: value as string };
        });
      } else if (type === UPDATE_TYPE.DESC) {
        setUserGroup((info) => {
          return { ...info, description: value as string };
        });
      }
    },
    []
  );
  // 1 users
  const usersPage = [
    <>
      <ModalInput label="Add Users">
        <SearchUsers
          isTab={false}
          userGroupId={props.selectedEdit?.groupId}
          accountId={selectedAccount.accountId}
          selectedUserId={props.selectedEdit?.users}
          onSelectedRowsChangeUser={(value: string[]) => {
            onChangeGroupInfo(UPDATE_TYPE.USERS, value);
          }}
        />
        <ValidateWord>{userGroupValidateMsg.isUsers}</ValidateWord>
      </ModalInput>
    </>,
  ];
  // 2 user permissions
  const userPermitPage = [
    <>
      <CheckList
        list={UserPermit1}
        isCheck={userCheckedList}
        isDisabled={
          props.isEdit
            ? isEditableUser
              ? userDisabledList
              : allDisabledList
            : userDisabledList
        }
        onCheckedElement={(checked: boolean, name: number) => {
          onChangeGroupInfo(UPDATE_TYPE.USER_PERMISSION, checked, name);
        }}
        isNoTitle={true}
      />
      {!userCheckedList[0] && (
        <>
          <CheckList
            list={UserPermit2}
            isCheck={userCheckedList}
            isDisabled={
              props.isEdit
                ? isEditableUser
                  ? userDisabledList
                  : allDisabledList
                : userDisabledList
            }
            onCheckedElement={(checked: boolean, name: number) => {
              onChangeGroupInfo(UPDATE_TYPE.USER_PERMISSION, checked, name);
            }}
          />
          <CheckList
            list={UserPermit3}
            isCheck={userCheckedList}
            isDisabled={
              props.isEdit
                ? isEditableUser
                  ? userDisabledList
                  : allDisabledList
                : userDisabledList
            }
            onCheckedElement={(checked: boolean, name: number) => {
              onChangeGroupInfo(UPDATE_TYPE.USER_PERMISSION, checked, name);
            }}
          />
          <CheckList
            list={UserPermit4}
            isCheck={userCheckedList}
            isDisabled={
              props.isEdit
                ? isEditableUser
                  ? userDisabledList
                  : allDisabledList
                : userDisabledList
            }
            onCheckedElement={(checked: boolean, name: number) => {
              onChangeGroupInfo(UPDATE_TYPE.USER_PERMISSION, checked, name);
            }}
          />
          <ValidateWord>{userGroupValidateMsg.isUserPermission}</ValidateWord>
        </>
      )}
    </>,
  ];

  const accountsPage = [
    <>
      <s.CheckInput>
        <input
          type="checkbox"
          ref={accountRef}
          defaultChecked={userGroup.userPermission.enableAllPermissions}
          checked={
            userGroup.userPermission.enableAllPermissions ||
            userGroup.autoApplyAccount
          }
          disabled={
            isEditableUser
              ? defaultGroupName.includes(userGroup.name.toLowerCase()) ||
                userGroup.userPermission.enableAllPermissions
              : false
          }
          onChange={(e) =>
            onChangeGroupInfo(UPDATE_TYPE.AUTO_APPLY_ACCOUNT, e.target.checked)
          }
        />
        Apply to all current and future Organization.
      </s.CheckInput>
      <s.ModalTableContainer
        style={{
          visibility: 
          userGroup.userPermission.enableAllPermissions || userGroup.autoApplyAccount ? "hidden" : "visible",
        }}
      >
        <ModalInput label="Add Organization">
          <SearchAccount
            accountId={selectedAccount.accountId}
            selectedAccountId={props.selectedEdit?.accounts}
            disabled={props.isEdit && !props.selectedEdit?.enableDelete}
            allSelect={userGroup.userPermission.enableAllPermissions}
            onSelectedRowsChange={(value: string[]) => {
              onChangeGroupInfo(UPDATE_TYPE.ACCOUNT, value);              
            }}
            userId={props.isEdit ? userGroup.userId : auth.user.userId}
          />
        </ModalInput>
      </s.ModalTableContainer>
    </>,
  ];
  // 3 Recorders
  const recordersPage = [
    <>
      <s.CheckInput>
        <input
          type="checkbox"
          ref={recorderRef}
          defaultChecked={userGroup.userPermission.enableAllPermissions}
          checked={
            userGroup.userPermission.enableAllPermissions ||
            userGroup.autoApplyRecorder
          }
          onChange={(e) =>
            onChangeGroupInfo(UPDATE_TYPE.AUTO_APPLY, e.target.checked)
          }
          disabled={
            isEditableUser
              ? defaultGroupName.includes(userGroup.name.toLowerCase()) ||
                userGroup.userPermission.enableAllPermissions
              : false
          }
        />
        Apply to all current and future Recorders.
      </s.CheckInput>

      <s.ModalTableContainer
        style={{
          visibility:
            userGroup.userPermission.enableAllPermissions ||
            userGroup.autoApplyRecorder
              ? "hidden"
              : "visible",
        }}
      >
        <SearchRecorder
          isTab={true}
          accountId={selectedAccount.accountId}
          selectedRow={userGroup.systems}
          selectedGroupRow={userGroup.recorderGroups}
          onSelectedRowsChange={(value: string[]) =>
            onChangeGroupInfo(UPDATE_TYPE.RECORDERS, value)
          }
          onSelectedGroupRowsChange={(value: number[]) =>
            onChangeGroupInfo(UPDATE_TYPE.RECORDER_GROUPS, value)
          }
          //isEditable={false}
          userId={props.isEdit ? props.selectedEdit?.userId: auth.user.userId}
        />
      </s.ModalTableContainer>
    </>,
  ];
  // 4 recorder permissions
  const recorderPermitPage = [
    <>
      <ModalInput label="Role">
        <Selects
          options={recorderPermissionOption}
          onChange={onChangeRecorderLevel}
          value={selectRecorderPermission}
          isDisabled={permissionDisabled}
        />
      </ModalInput>
      <CheckList
        list={recorderPermissionList}
        isCheck={recorderCheckedList}
        isDisabled={disableList}
        onCheckedElement={(checked: boolean, name: number) => {
          onChangeGroupInfo(UPDATE_TYPE.RECORDER_PERMISSION, checked, name);
        }}
      />
    </>,
  ];
  // 5 preferences
  const preferencesPage = [
    <>
      <ModalInput label="Group Name" essential>
        <Input
          placeholder="Enter Name"
          disabled={
            props.isEdit
              ? isEditableUser
                ? props.selectedEdit?.enableDelete
                  ? false
                  : true
                : true
              : false
          }
          defaultValue={props.selectedEdit?.name}
          onChange={(e) => onChangeGroupInfo(UPDATE_TYPE.NAME, e.target.value)}
          isValidated={userGroupValidate.isUserGroupName}
          onFocus={() => {
            setUserGroupValidate((info) => {
              return { ...info, isUserGroupName: false as boolean };
            });

            setUserGroupValidateMsg((info) => {
              return { ...info, isUserGroupName: "" };
            });
          }}
        />
        <ValidateWord>{userGroupValidateMsg.isUserGroupName}</ValidateWord>
      </ModalInput>
      <ModalInput label="Description">
        <TextArea
          placeholder="Enter description"
          disabled={
            props.isEdit
              ? isEditableUser
                ? props.selectedEdit?.enableDelete
                  ? false
                  : true
                : true
              : false
          }
          defaultValue={props.selectedEdit?.description}
          onChange={(e) => onChangeGroupInfo(UPDATE_TYPE.DESC, e.target.value)}
        />
      </ModalInput>
    </>,
  ];

  return (
    <BigPopupTemplate
      title={props.isEdit ? "Edit User Group" : "Add User Group"}
      onModal={props.onModal}
    >
      <StepperComponent
        activeStep={page}
        steps={applyPage}
        onClick={onChangePage}
      />
      <s.ModalInnerHalf>
        <div style={{ display: page === 0 ? "block" : "none" }}>
          {usersPage}
        </div>
        <div style={{ display: page === 1 ? "block" : "none" }}>
          {userPermitPage}
        </div>
        <div
          style={{
            display:
              page === 2 && selectedAccount.accountLevel !== "EU"
                ? "block"
                : "none",
          }}
        >
          {accountsPage}
        </div>
        <div
          style={{
            display:
              page === 3 && selectedAccount.accountLevel !== "EU"
                ? "block"
                : "none",
          }}
        >
          {preferencesPage}
        </div>

        <div
          style={{
            display:
              page === 2 && selectedAccount.accountLevel === "EU"
                ? "block"
                : "none",
          }}
        >
          {recordersPage}
        </div>
        <div
          style={{
            display:
              page === 3 && selectedAccount.accountLevel === "EU"
                ? "block"
                : "none",
          }}
        >
          {recorderPermitPage}
        </div>
        <div
          style={{
            display:
              page === 4 && selectedAccount.accountLevel === "EU"
                ? "block"
                : "none",
          }}
        >
          {preferencesPage}
        </div>
      </s.ModalInnerHalf>
      <s.ButtonWrapper>
        {props.isEdit && props.selectedEdit?.enableDelete && isEditableUser ? (
          <BigButton
            buttonType="warning"
            label="Delete"
            onClickBtn={() => setIsDeleteConfirm(true)}
          />
        ) : null}
        {page !== 0 && (
          <BigButton
            buttonType="cancel"
            label="Previous"
            onClickBtn={() => onChangePage("previous")}
          />
        )}

        {page === applyPage.length - 1 ? (
          (!props.isEdit || isEditableUser) && (
            <BigButton label="Save" onClickBtn={onClickCreate} />
          )
        ) : (
          <BigButton
            buttonType="cancel"
            label="Next"
            onClickBtn={() => onChangePage("next")}
          />
        )}
      </s.ButtonWrapper>
      {props.isEdit && isDeleteConfirm && (
        <DeleteConfirmModal
          label={`group`}
          onDelete={() => setIsDeleteConfirm(false)}
          onConfirmDelete={onClickDelete}
        />
      )}
    </BigPopupTemplate>
  );
}

export default AddUser;
