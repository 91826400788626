import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { calcRem } from "styles/theme";
import { OptionWrapper } from "pages/dashboard/widgets/WidgetStyled";
export const LoadingWrapper = styled.div`
  /* background-color: rgba(0, 0, 0, 10); */
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 33;
  ${FLEX.FlexCenterCenter};
`;
export const OptionContainer = styled.div`
  padding: 10px 0;
  width: 100%;
`;

export const DisplayWrapper = styled(OptionWrapper)`
  ${FLEX.FlexStartCenter};
  .period-option {
    flex: 3;
  }
  /* gap: 30px; */
`;

export const OptionTitleContainer = styled.div<{ center?: boolean }>`
  border-bottom: 1px solid ${COLORS.BUTTONS1};
  color: ${COLORS.BUTTONS1};
  /* font-size: ${calcRem(18)}; */
  padding: 10px 0;
  margin: 10px 0;
  width: 100%;
  ${({ center }) =>
    center &&
    `
    text-align: center;
  `}
`;

export const Row = styled.div`
  ${FLEX.FlexBetweenStart};
  gap: 8px;
  width: 100%;
  flex: 1;

  ${mediaQuery.isMobile} {
    flex-direction: column;
  }
`;

export const RowMobileNoColumn = styled(Row)`
  ${mediaQuery.isMobile} {
    flex-direction: row;
  }
`;

export const ToggleContainer = styled.div`
  width: 100%;
  ${FLEX.FlexBetweenCenter};
  color: ${({ theme }) => theme.COLORS.WHITE};
  padding: 10px 0;

  ${mediaQuery.isMobile} {
    padding: 10px 0;
  }
`;

export const ToggleWrapper = styled.div`
  width: 100%;
  padding: 20px 0;
`;

export const ToggleItem = styled(ToggleContainer)`
  width: 50%;
  ${FLEX.FlexBetweenCenter};
  padding: 10px 0;

  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

export const AddWrapper = styled.div`
  /* width: 550px; */
  width: 100%;
  ${FLEX.FlexCenterCenter};
  flex-direction: column;

  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

export const EditWrapper = styled.div`
  ${FLEX.FlexBetweenStart};
  gap: 10px;

  flex-direction: column;
  width: 100%;
`;

export const EditTwopart = styled.div`
  ${FLEX.FlexBetweenStart};
  width: 100%;
  gap: 8px;

  ${mediaQuery.isDefault} {
    > div {
      width: 50%;
    }
  }
  ${mediaQuery.isMobile} {
    width: 100%;
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`;

export const EditAccountCompany = styled(EditTwopart)`
  ${mediaQuery.isDefault} {
    height: 190px;
  }
  .company-logo {
    height: 160px;
  }
  > div {
    height: 100%;
    ${FLEX.FlexBetweenCenter};
    flex-direction: column;
  }

  ${mediaQuery.isMobile} {
    width: 100%;
    flex-direction: column;
  }
`;

export const EditLeftWrapper = styled.div`
  /* flex: 3; */
  /* padding-bottom: 80px; */

  ${mediaQuery.isMobile} {
    padding: 0;
  }
`;

export const Image = styled.img`
  width: 300px;
  height: 270px;
`;

export const ButtonWrapper = styled.div`
  ${FLEX.FlexCenterCenter};
  gap: 20px;
  padding-top: 30px;

  ${mediaQuery.isMobile} {
    button {
      max-width: 70px;
    }
  }
`;
export const BottomButtonWrapper = styled(ButtonWrapper)`
  padding-top: 80px;
`;

export const DeleteButtonWrapper = styled.div`
  ${FLEX.FlexBetweenEnd};

  ${mediaQuery.isMobile} {
    button {
      max-width: 70px;
    }
  }
`;

export const description = styled.p<{ center?: boolean }>`
  color: ${COLORS.BUTTONS1};
  font-size: ${calcRem(14)};
  line-height: 20px;
  padding: 10px 0;
  /* border-top: 1px solid ${COLORS.BORDER}; */
  ${({ center }) =>
    center &&
    `
  text-align:center;
    `}
`;

export const DwCloudContainer = styled.div`
  /* border-bottom: 1px solid ${COLORS.BORDER}; */
  width: 100%;
  padding: 30px 0;
  ${FLEX.FlexStartEnd};
  color: ${COLORS.WHITE};
  font-weight: 500;
  font-size: ${calcRem(20)};
  gap: 20px;
`;

export const CloudContainer = styled(AddWrapper)`
  width: 100%;
`;

export const CloudWrapper = styled.div`
  ${FLEX.FlexStartStart};
  /* padding: 10px 0; */
  border-top: 1px solid ${COLORS.BORDER};
  width: 100%;
  height: max-content;
  gap: 20px;
  /* min-height: 600px; */

  ${mediaQuery.isMobile} {
    flex-direction: column;
  }
`;
export const CloudLeftWrapper = styled.section`
  flex: 1.5;
  padding: 10px 0 20px;
  min-height: 100%;

  ${mediaQuery.isMobile} {
    width: 100%;
  }
`;

export const CloudRightWrapper = styled(CloudLeftWrapper)`
  flex: 2;
  border-bottom: 1px solid ${COLORS.BORDER};
  ${FLEX.FlexBetweenStart};
  flex-direction: column;
  min-height: 100%;
`;

export const Map = styled.div`
  width: 100%;
  height: 200px;
`;

export const TypeContainer = styled.div``;

export const TypeItem = styled.label`
  gap: 10px;
  ${FLEX.FlexStartStart};
  padding-bottom: 30px;
`;

export const TypeContents = styled.div`
  ${FLEX.FlexStartStart};
  flex-direction: column;
  gap: 8px;
`;

export const ModalInnerHalf = styled.div`
  /* width: 75%; */
  width: 100%;
  color: ${({ theme }) => theme.COLORS.WHITE};

  .period-month {
    padding: 10px;
    width: 40px;
    margin: 0 10px;
  }

  .storage {
    width: 120px;
  }

  .storage-space {
    width: 100%;
    margin-left: 20px;
  }
`;

export const ModalTableTitleContainer = styled.div`
  ${FLEX.FlexBetweenEnd};
  padding: 15px 0;
  color: ${({ theme }) => theme.COLORS.WHITE};
`;

export const ModalTableContainer = styled.div`
  padding-top: 6px;
  width: 100%;

  input {
    ::placeholder {
      font-size: ${calcRem(14)};
    }
  }

  .input-name {
    ${FLEX.FlexStartCenter};
    gap: 4px;
  }

  .search-title {
    font-weight: 500;
    padding: 8px 0;
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;

export const ModalTabSearchWrapper = styled.div`
  ${FLEX.FlexBetweenStart};
  gap: 30px;

  .search-box {
    max-width: 30%;
    padding-top: 15px;
  }
`;

export const CheckInput = styled.label`
  ${FLEX.FlexStartCenter};
  flex: 1;
  gap: 10px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  /* padding: 10px 0; */
  ${mediaQuery.isMobile} {
    gap: 6px;
  }
`;

export const RadioButtonContainer = styled.div`
  flex: 1;
  padding: 15px 20px;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY3};
  width: 100%;
  border-radius: 4px;
  ${mediaQuery.isDefault} {
    ${FLEX.FlexStartCenter};
    gap: 30px;
  }
  ${mediaQuery.isMobile} {
    ${FLEX.FlexBetweenCenter};
  }
`;

export const CvvReportWrapper = styled.div`
  width: 100%;

  div {
    white-space: normal;
    margin-bottom: 10px;

    &:nth-child(2) {
      line-height: ${calcRem(20)};
    }
  }

  .cvv-sub-contents {
    color: ${({ theme }) => `${theme.COLORS.WHITE}80`};
  }
`;

export const CvvReportStatus = styled(RadioButtonContainer)`
  ${FLEX.FlexCenterCenter};
  border-radius: 5px;
  gap: 10px;

  span {
    ${FLEX.FlexStartCenter};
    gap: 6px;
  }
`;

export const CvvAddContainer = styled.div`
  margin-top: 20px;
  height: 400px;
  max-height: 400px;
  overflow-y: scroll;
`;

export const CvvListTitle = styled(RadioButtonContainer)`
  gap: 10px;
`;

export const CvvListWrapper = styled.div`
  .inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 10px;
    background-color: ${({ theme }) =>
      theme.value === "light" ? "transparent" : "#131b3350"};

    ${mediaQuery.isMobile} {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;
export const CvvListItem = styled.li`
  ${FLEX.FlexStartCenter};
  gap: 6px;
`;

export const CvvCameraThumbnail = styled.label`
  width: 100%;
  position: relative;

  input {
    position: absolute;
    bottom: 3px;
    left: 3px;
    z-index: 66;
  }
`;

export const CvvCameraThumbnailTitle = styled.div`
  font-size: ${calcRem(12)};
  font-weight: 500;
  background-color: ${COLORS.BLACK}90;
  color: ${COLORS.WHITE};
  position: absolute;
  width: 100%;
  top: 0;
  padding: 3px;
  z-index: 66;
`;

export const TimelapseWrapper = styled.ul`
  padding: 15px 0;
`;

export const TimelapseIntervalItem = styled.li`
  ${FLEX.FlexStartCenter};
  width: 100%;
  border-bottom: 1px solid ${COLORS.PRIMARY3};
  padding: 10px 20px;
  .camera-name {
    flex: 2;
  }

  ${mediaQuery.isMobile} {
    flex-direction: column;
    align-items: start;
  }
`;

export const InputBorder = styled.div`
  border-top: 1px solid ${COLORS.BORDER};
  border-bottom: 1px solid ${COLORS.BORDER};
  width: 100%;
  padding: 20px 0;
`;

export const InvervalTop = styled(TimelapseIntervalItem)`
  border: none;
  background-color: ${COLORS.PRIMARY3};
  border-radius: 5px;
`;

export const TimelapseIntervalFrame = styled.div<{ isDisabled?: boolean }>`
  flex: 3;
  ${FLEX.FlexStartCenter};
  gap: 6px;

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.25;
  `};

  > span {
    width: 25%;
  }
  ${mediaQuery.isMobile} {
    flex-direction: column;
    align-items: start;
    margin-top: 14px;
  }
`;

export const TimelapseIntervalTopItem = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 8px;
`;

export const InstallationGroupTitle = styled.div`
  border-bottom: 1px solid ${COLORS.BORDER};
  padding-bottom: 10px;
  font-weight: 500;
  color: #828b9b;
  font-size: ${calcRem(18)};
  margin-bottom: 10px;
`;

export const SelectBox = styled.div`
  ${mediaQuery.isDefault} {
    width: 215px;
  }

  ${mediaQuery.isMobile} {
    min-width: 150px;
    max-width: 200px;
  }
`;

export const MarginBox = styled.div`
  height: 400px;
  margin: 20px 0 40px;
`;

export const RowModalInput = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 10px;
  padding: 10px 0;
`;

export const ValidateWrapper = styled.span`
  width: 100%;
  ${FLEX.FlexStartCenter};
  font-size: ${calcRem(16)};
  /* margin-top: -1px; */
`;
