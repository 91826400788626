import Select, { StylesConfig } from "react-select";
import { COLORS } from "styles/colors";
import { Option } from "../util";
import TRIANGLE_DOWN from "assets/icons/triangle_down.svg";
import styled from "styled-components";

type OptionsType = Array<Option>;

type CommonProps = {
  // clearValue: () => void,
  // getStyles: (string, any) => {},
  // getValue: () => ValueType,
  // hasValue: boolean,
  isMulti?: boolean;
  options: OptionsType;
  // selectOption: OptionType => void,
  // selectProps: any,
  // setValue: (ValueType, ActionTypes) => void,
  // emotion: any,
  defaultValue?: Option;
  value?: any;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  isAlertView?: boolean;
};

const customStyles: StylesConfig<Option> = {
  control: (styles, { isDisabled }) => {
    const backgroundColor = isDisabled ? COLORS.BUTTONS1 : COLORS.WHITE;
    const borderColor = "#cacaca";
    // const padding = "6px 8px 6px 0";
    const paddingRight = "8px";
    const minWidth = "120px";
    const minHeight = "35px";
    return {
      ...styles,
      backgroundColor,
      borderColor,
      paddingRight,
      minWidth,
      minHeight,
    };
  },
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  container: (styles) => ({ ...styles, width: "100%", color: COLORS.BLACK }),
  placeholder: (styles) => ({ ...styles, color: "#abb5c5" }),
  // dropdownIndicator: (styles) => ({ ...styles, color: COLORS.BUTTONS1 }),
  indicatorSeparator: () => ({}),
};
const DropdownIndicator = () => (
  <DropDown src={TRIANGLE_DOWN} alt="TRIANGLE_DOWN" />
);

const ModalSelects = (props: CommonProps) => (
  <Select
    {...props}
    styles={customStyles}
    components={{ DropdownIndicator }}
    placeholder="Select"
    menuPortalTarget={document.querySelector("body")}
    defaultValue={props.defaultValue}
    onChange={({ value }) => {
      if (props.onChange) {
        props.onChange(value);
      }
    }}
    className="modal-select-container"
  />
);

export default ModalSelects;

const DropDown = styled.img`
  width: 10px;
`;
