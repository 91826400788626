import { CenterlineText } from "components/atoms/text/Text";
import * as s from "./LicenseStyled";
import * as SubheaderStyled from "components/blocks/subheader/SubHeaderStyled";
import StorageBar from "components/blocks/storage/StorageBar";
import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import TextArea from "components/atoms/input/TextArea";
import ONLINE_GREEN from "assets/icons/online_green.svg";
import NO_LICENSE_ICON from "assets/icons/no-license.svg";
import STARNDARD_ICON from "assets/icons/standard-license.svg";
import PREMIUM_ICON from "assets/icons/premium-license.svg";
import OFFLINE from "assets/icons/offline.svg";
import { FaInfoCircle } from "react-icons/fa";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";
import { useAppSelector } from "redux/hooks";
import { COLORS } from "styles/colors";
import InfoBubble from "components/blocks/bubble/InfoBubble";
import { InputNumber } from "antd";
import { Text } from "components/atoms/text/Text";
import LicenseRequestModal, { RequestLicenseType } from "./LicenseRequestModal";
import { LEVEL_TYPE } from "api/interfaces/commonInterface.interface";
import CHEVRON_RIGHT from "assets/icons/chevron_right_breadcrumb.svg";
import { useNavigate } from "react-router-dom";
import { BubbleType } from "./LicenseContainer";
import Input from "components/atoms/input/Input";
import { LicenseInfo } from "api/interfaces/licenseInterface.interface";
import INFO_ICON from "assets/icons/info-blue.svg";
import * as mediaQuery from "components/MediaQuery";
import { menuNavigation } from "utils/MenuUtil";

interface LicenseListType {
  title: string;
  value: RequestLicenseType;
  icon: any;
  list?: { label: string; isActive: boolean }[];
}
interface Props {
  accountId: string;
  accountName: string;
  requestType: "license" | "storage";
  request: LicenseInfo;
  isModal: boolean;
  isBubble: BubbleType;
  minChannelNewAmount: number;
  onChangeModal: () => void;
  onSendRequest: (requestType: "license" | "storage") => void;
  onChangeRequest: (e: any) => void;
  onChangeBubble: (name: string) => void;
  onChangeRequestLicenseType: (type: RequestLicenseType) => void;
  onChangeNewAmount: (value: number | string | null, name: string) => void;
}
const licenseList: LicenseListType[] = [
  {
    title: "Free",
    value: "none",
    icon: NO_LICENSE_ICON,
    list: [
      {
        label: "Remote Viewing",
        isActive: true,
      },
      {
        label: "Health Alerts",
        isActive: false,
      },
      {
        label: "Video Sharing",
        isActive: false,
      },
      {
        label: "Reports",
        isActive: false,
      },
      {
        label: "CVV",
        isActive: false,
      },
      {
        label: "Timelapse",
        isActive: false,
      },
    ],
  },
  {
    title: "Standard Licenses",
    value: "standard",
    icon: STARNDARD_ICON,
    list: [
      {
        label: "Remote Viewing",
        isActive: true,
      },
      {
        label: "Health Alerts",
        isActive: true,
      },
      {
        label: "Video Sharing",
        isActive: true,
      },
      {
        label: "Reports",
        isActive: true,
      },
      {
        label: "CVV",
        isActive: true,
      },
      {
        label: "Timelapse",
        isActive: false,
      },
    ],
  },
  {
    title: "Premium Licenses",
    value: "premium",
    icon: PREMIUM_ICON,
  },
];

export default function LicensePresenter(props: Props): JSX.Element {
  const navigate = useNavigate();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );

  return (
    <>
      <s.Container>
        <s.Title>
          {selectedAccount.accountLevel === LEVEL_TYPE.CP ? (
            <SubheaderStyled.Title>
              <SubheaderStyled.TitleWrapper>
                <SubheaderStyled.TitleCrumb
                  index={0}
                  onClick={() => {
                    menuNavigation(
                      selectedAccount,
                      navigate,
                      "billing/customeraccount"
                    );
                    //navigate(-1)}
                  }}
                >
                  Licenses
                </SubheaderStyled.TitleCrumb>
                <img
                  className="chevron-right"
                  src={CHEVRON_RIGHT}
                  alt="chevron right icon"
                />

                <SubheaderStyled.TitleCrumb index={1}>
                  {props.accountName}
                </SubheaderStyled.TitleCrumb>
              </SubheaderStyled.TitleWrapper>
            </SubheaderStyled.Title>
          ) : (
            <s.LicenseTitle>
              <Text fontSize={20} bold>
                Licenses
              </Text>
              {(props.request.channelIsPreparing ||
                props.request.storageIsPreparing) && (
                <span className="preparing">
                  <img src={INFO_ICON} alt="info-icon" />
                  Preparing
                </span>
              )}
              {selectedAccount.isTrial && !selectedAccount.isSuspended &&
                selectedAccount.accountLevel === LEVEL_TYPE.EU && (
                  <span className="preparing">
                    <img src={INFO_ICON} alt="info-icon" />
                    Requests of Cloud Storage are not allowed during the trial
                    period.
                  </span>
              )}
              {selectedAccount.isSuspended &&
                selectedAccount.accountLevel === LEVEL_TYPE.EU && (
                  <span className="preparing">
                    <img src={INFO_ICON} alt="info-icon" />
                    The organization has been temporarily suspended.
                  </span>
              )}

            </s.LicenseTitle>
          )}
        </s.Title>
        <s.HealthWrapper disabled={selectedAccount.isSuspended}>
          <CenterlineText>Health Monitoring</CenterlineText>
          <s.LicenseList>
            {licenseList.map((lic: LicenseListType, idx: number) => {
              let licenseType: RequestLicenseType = lic.value;
              let isSelected = lic.value === props.request.cartLicenseType;
              let isCurrent = lic.value === props.request.currentLicenseType;
              return (
                <s.LicenseItem
                  key={`license-type-${idx}`}
                  isSelected={isSelected}
                  onClick={() => props.onChangeRequestLicenseType(licenseType)}
                >
                  <s.LicenseTypeTitle>
                    <img src={lic.icon} alt={`${lic.title} icon`} />
                    {lic.title}
                  </s.LicenseTypeTitle>

                  <s.LicenseTypeList>
                    {lic.list && lic.list.length >= 0
                      ? lic.list.map((l: any, index: number) => (
                          <li key={`licenst-type-list-${index}`}>
                            {l.isActive ? (
                              <img src={ONLINE_GREEN} alt="online-icon" />
                            ) : (
                              <img src={OFFLINE} />
                            )}
                            {l.label}
                          </li>
                        ))
                      : "Coming Soon..."}
                  </s.LicenseTypeList>

                  {isCurrent && (
                    <span className="current-plan">* CURRENT PLAN</span>
                  )}
                </s.LicenseItem>
              );
            })}
            <s.LicenseItem>
              <s.LicenseTypeTitle className="add-title">
                Add/Remove Licenses
              </s.LicenseTypeTitle>
              <s.LicenseAdd>
                <li>
                  <div className="required-item">
                    Licensed:{" "}
                    <span className="count">
                      {props.request.channelLicenseQuota}
                    </span>{" "}
                    / Cameras:{" "}
                    <span className="count">
                      {props.request.totalCameraCount}
                    </span>
                  </div>
                  <div className="amount-item">
                    <mediaQuery.Default>
                      <div>
                        <button
                          className="bubble-parent"
                          onMouseEnter={() => props.onChangeBubble("required")}
                          onMouseLeave={() => props.onChangeBubble("required")}
                        >
                          <FaInfoCircle />

                          {props.isBubble.required && (
                            <s.BubbleContainer right>
                              <InfoBubble right>
                                License count must be equal or greater
                                <br />
                                than the number of cameras.
                              </InfoBubble>
                            </s.BubbleContainer>
                          )}
                        </button>
                      </div>
                    </mediaQuery.Default>
                    <span>Required: {props.request.channelRequired}</span>
                  </div>
                </li>
                <li className="amount-wrapper">
                  <div className="amount-item">
                    <span className="title">Size to increase or decrease</span>
                    <InputNumber
                      type="number"
                      name="channelNewAmount"
                      value={props.request.channelNewAmount}
                      max={100000}
                      min={props.minChannelNewAmount}
                      defaultValue={props.request.channelRequired}
                      onChange={(value) =>
                        props.onChangeNewAmount(value, "channelNewAmount")
                      }
                      // disabled={
                      //   selectedAccount.accountLevel === LEVEL_TYPE.CP &&
                      //   props.request.channelNewAmount > 0
                      // }
                    />
                  </div>
                </li>
                <li>
                  <div className="message-wrapper">
                    <span className="title">Message to installer</span>

                    <TextArea
                      placeholder="Enter your message here"
                      name="cartMessage"
                      value={props.request.cartMessage}
                      onChange={props.onChangeRequest}
                      disabled={selectedAccount.accountLevel === "CP"}
                    />
                  </div>
                </li>
              </s.LicenseAdd>
              <s.ButtonWrapper>
                <PrimaryButton
                  buttonType={
                    props.request.channelNewAmount <
                      props.minChannelNewAmount ||
                    props.request.channelNewAmount === 0
                      ? "cancel"
                      : "primary"
                  }
                  label={
                    selectedAccount.accountLevel === "CP"
                      ? "Proceed to Checkout"
                      : "Send Request"
                  }
                  onClickBtn={() => props.onSendRequest("license")}
                />
                {selectedAccount.accountLevel === "EU" && (
                  <button
                    className="bubble-parent"
                    onMouseEnter={() => props.onChangeBubble("sendLicense")}
                    onMouseLeave={() => props.onChangeBubble("sendLicense")}
                  >
                    <FaInfoCircle />
                    {props.isBubble.sendLicense && (
                      <s.BubbleContainer>
                        <InfoBubble>
                          The request will be sent to your Channel Partner.
                          <br />
                          Monthly charge will be updated based on the
                          adjustment.
                        </InfoBubble>
                      </s.BubbleContainer>
                    )}
                  </button>
                )}
              </s.ButtonWrapper>
            </s.LicenseItem>
          </s.LicenseList>
        </s.HealthWrapper>
        <s.HealthWrapper disabled={selectedAccount.isTrial}>
          <CenterlineText>Cloud Storage</CenterlineText>

          <s.StorageList>
            <s.StorageItem>
              <div className="title-wrapper">
                <span className="title">Storage</span>
                <span>Used / Total</span>
              </div>
              <s.StorageWrapper>
                <StorageBar
                  totalStorage={props.request.storageQuotaInBytes}
                  usedStorage={props.request.usedStorageInBytes}
                />
              </s.StorageWrapper>
            </s.StorageItem>

            <s.StorageItem>
              <span className="title">Size to increase or decrease</span>
              <s.AmountWrapper>
                <div className="amount-input">
                  <InputNumber
                    type="number"
                    min={0} //storage 는 용량 줄일 수 없음
                    // formatter={(value) => `${value}TB`}
                    value={props.request.storageNewAmount}
                    onChange={(value) =>
                      props.onChangeNewAmount(value, "storageNewAmount")
                    }
                    disabled={
                      (selectedAccount.accountLevel === LEVEL_TYPE.CP &&
                        props.request.storageNewAmount > 0) ||
                      selectedAccount.isTrial
                    }
                  />
                  TB
                </div>
              </s.AmountWrapper>
            </s.StorageItem>
            <s.StorageItem className="storage-message ">
              <span className="title">Message to installer</span>
              <s.AmountWrapper>
                <Input
                  name="storageMessage"
                  value={props.request.storageMessage}
                  placeholder="Enter your message here."
                  disabled={
                    selectedAccount.accountLevel === "CP" ||
                    selectedAccount.isTrial
                  }
                  onChange={props.onChangeRequest}
                />
                <s.ButtonWrapper>
                  <PrimaryButton
                    buttonType={
                      props.request.storageNewAmount <= 0 ? "cancel" : "primary"
                    }
                    label={
                      selectedAccount.accountLevel === "CP"
                        ? "Proceed to Checkout"
                        : "Send Request"
                    }
                    disabled={selectedAccount.isTrial}
                    onClickBtn={() => props.onSendRequest("storage")}
                  />
                  {selectedAccount.accountLevel === "EU" && (
                    <button
                      className="bubble-parent"
                      onMouseEnter={() => props.onChangeBubble("sendStorage")}
                      onMouseLeave={() => props.onChangeBubble("sendStorage")}
                    >
                      <FaInfoCircle />
                      {props.isBubble.sendStorage && (
                        <s.BubbleContainer right>
                          <InfoBubble right>
                            The request will be sent to your Channel Partner.
                            <br />
                            Monthly charge will be updated based on the
                            adjustment.
                          </InfoBubble>
                        </s.BubbleContainer>
                      )}
                    </button>
                  )}
                </s.ButtonWrapper>
              </s.AmountWrapper>
            </s.StorageItem>
          </s.StorageList>
        </s.HealthWrapper>
      </s.Container>
      {props.isModal && (
        <LicenseRequestModal
          accountId={props.accountId}
          requestType={props.requestType}
          request={props.request}
          onChangeModal={props.onChangeModal}
        />
      )}
    </>
  );
}
