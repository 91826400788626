import { AxiosResponse } from "axios";
import { AxiosInstance } from "./axiosInstance";
import qs from "qs";

import {
  GetRecorderRequest,
  RecorderListResposne,
  UpdateRecorderRequest,
  GetRecorderGroupRequest,
  RecorderGroupListResponse,
  CreateRecorderGroupRequest,
  UpdateRecorderGroupRequest,
  RecorderResposne,
  RecorderWithCameraResponse,
  CreateRecorderGroupResponse,
  CRUDRecorderResponse,
  CRUDRecorderGroupResponse,
  RecorderListDetailResposne,
  RecorderTotalListResposne,
  UnknownRecorderListResposne,
  MissingLocation,
  MergedRecorderListResponse,
  LocalConnectionInfo,
  LocalConnectionInfoResponse,
} from "api/interfaces/recorderInterface.interface";
import { filterNonNull } from "utils/functions";
import { CommonResponse, CustomError } from "./interfaces/commonInterface.interface";
import { CloudTokenResponse, NVRTokenResponse, NvrLinkTokenResponse } from "./interfaces/authInterface.interface";

const RECORDER_PATH = "/recorder";
const RECORDER_GROUP_PATH = "/group";


export const getNvrLinkToken = async ({
  accountId,
  recorderId,
}: {
  accountId: string;
  recorderId: string;
}): Promise<NvrLinkTokenResponse> => {
  try {
    const { data }: AxiosResponse<NvrLinkTokenResponse> = await AxiosInstance.get(
      `${RECORDER_PATH}/getNVRLink/${accountId}/${recorderId}`
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getPfToken = async ({
  accountId,
  recorderId,
}: {
  accountId: string;
  recorderId: string;
}): Promise<NVRTokenResponse> => {
  try {
    const { data }: AxiosResponse<NVRTokenResponse> = await AxiosInstance.get(
      `${RECORDER_PATH}/webLink/${accountId}/${recorderId}`
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getRecorderList = async ({
  payload,
  accountId,
}: {
  payload: GetRecorderRequest;
  accountId: string;
}): Promise<MergedRecorderListResponse> => {
  try {
    const res: AxiosResponse<MergedRecorderListResponse> = await AxiosInstance.get(
      `${RECORDER_PATH}/list/mergedSystem/${accountId}?${qs.stringify(payload,{ skipNulls: true })}`
    );
    return res.data;
  } catch (e:any) {
    console.error(e);
    const customErr = e as CustomError
    throw customErr;
  }
};

export const getRecorderListDetail = async ({
  payload,
  accountId,
  mergedSystemId,
}: {
  payload: GetRecorderRequest;
  accountId: string;
  mergedSystemId: string;
}): Promise<RecorderListDetailResposne> => {
  try {
    const res: AxiosResponse<RecorderListDetailResposne> =
      await AxiosInstance.get(
        `${RECORDER_PATH}/list/mergedSystem/${accountId}/${mergedSystemId}?${qs.stringify(
          payload,{ skipNulls: true }
        )}`
      );
    return res.data;
  } catch (e:any) {
    console.error(e);
    throw new Error();
  }
};

export const getRecorderTotalList = async ({
  accountId,
  userId
}: {
  accountId: string;
  userId : string | undefined
}): Promise<RecorderTotalListResposne> => {
  try {
    const res: AxiosResponse<RecorderTotalListResposne> = userId !== undefined ? 
      await AxiosInstance.get(
        `${RECORDER_PATH}/totalList/${accountId}/${userId}`
      ) :
      await AxiosInstance.get(
        `${RECORDER_PATH}/totalList/${accountId}`
      );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getRecorder = async ({
  accountId,
  recorderId,
}: {
  accountId: string;
  recorderId: string;
}): Promise<RecorderResposne> => {
  try {
    const res: AxiosResponse<RecorderResposne> = await AxiosInstance.get(
      `${RECORDER_PATH}/${accountId}/${recorderId}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getMissingRecorderList = async ({
  payload,
  accountId,
}: {
  payload: GetRecorderRequest;
  accountId: string;
}): Promise<UnknownRecorderListResposne> => {
  try {
    const res: AxiosResponse<UnknownRecorderListResposne> = await AxiosInstance.get(
      `${RECORDER_PATH}/list/unknownLocation/${accountId}?${qs.stringify(
        payload
      )}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const putRecorder = async ({
  form,
  accountId,
  recorderId,
}: {
  form: FormData;
  accountId: string;
  recorderId: string;
}): Promise<CRUDRecorderResponse> => {
  try {
    const res: AxiosResponse<CRUDRecorderResponse> = await AxiosInstance.put(
      `${RECORDER_PATH}/${accountId}/${recorderId}`,
      form
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const deleteRecorder = async ({
  accountId,
  recorderId,
}: {
  accountId: string;
  recorderId: string;
}): Promise<void> => {
  try {
    await AxiosInstance.delete(
      `${RECORDER_PATH}/delete/${accountId}/${recorderId}`
    );
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const deleteMergedSystem = async ({
  accountId,
  mergedSystemId,
}: {
  accountId: string;
  mergedSystemId: string;
}): Promise<void> => {
  try {
    await AxiosInstance.delete(
      `${RECORDER_PATH}/delete/mergedSystem/${accountId}/${mergedSystemId}`
    );
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getRecorderGroupList = async ({
  payload,
  accountId,
}: {
  payload: GetRecorderGroupRequest;
  accountId: string;
}): Promise<RecorderGroupListResponse> => {
  try {
    const res: AxiosResponse<RecorderGroupListResponse> =
      await AxiosInstance.get(
        `${RECORDER_PATH}/list${RECORDER_GROUP_PATH}/${accountId}?${qs.stringify(
          payload
        )}`
      );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};
export const getRecorderGroupTotalList = async ({
  accountId,
  userId
}: {
  accountId: string;
  userId : string | undefined
}): Promise<RecorderGroupListResponse> => {
  try {
    const res: AxiosResponse<RecorderGroupListResponse> = userId !== undefined ? 
      await AxiosInstance.get(
        `${RECORDER_PATH}/listTotal${RECORDER_GROUP_PATH}/${accountId}/${userId}`
      ) : 
      await AxiosInstance.get(`${RECORDER_PATH}/listTotal${RECORDER_GROUP_PATH}/${accountId}`);
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postRecorderGroup = async ({
  payload,
  accountId,
}: {
  payload: CreateRecorderGroupRequest;
  accountId: string;
}): Promise<CreateRecorderGroupResponse> => {
  try {
    const { data }: AxiosResponse<CreateRecorderGroupResponse> =
      await AxiosInstance.post(
        `${RECORDER_PATH}/create${RECORDER_GROUP_PATH}/${accountId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putRecorderGroup = async ({
  payload,
  accountId,
  recorderGroupId,
}: {
  payload: UpdateRecorderGroupRequest;
  accountId: string;
  recorderGroupId: number;
}): Promise<CRUDRecorderGroupResponse> => {
  try {
    const { data }: AxiosResponse<CRUDRecorderGroupResponse> =
      await AxiosInstance.put(
        `${RECORDER_PATH}/update${RECORDER_GROUP_PATH}/${accountId}/${recorderGroupId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const deleteRecorderGroup = async ({
  accountId,
  recorderGroupId,
}: {
  accountId: string;
  recorderGroupId: number;
}): Promise<void> => {
  try {
    await AxiosInstance.delete(
      `${RECORDER_PATH}/delete${RECORDER_GROUP_PATH}/${accountId}/${recorderGroupId}`
    );
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getCameratListRecorderList = async ({
  payload,
  accountId,
}: {
  payload: string[];
  accountId: string;
}): Promise<RecorderWithCameraResponse> => {
  try {
    const res: AxiosResponse<RecorderWithCameraResponse> =
      await AxiosInstance.post(
        `${RECORDER_PATH}/totalList/${accountId}`,
        payload
      );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putMissingLocation = async ({
  payload,
  accountId,
}: {
  payload: MissingLocation;
  accountId: string;
}): Promise<CRUDRecorderResponse> => {
  try {
    const res: AxiosResponse<CRUDRecorderResponse> =
      await AxiosInstance.put(
        `${RECORDER_PATH}/unknownLocation/${accountId}`,
          payload
        );
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postLocalConnectTest = async ({
  payload,
}: {
  payload: LocalConnectionInfo;
}): Promise<CommonResponse> => {
  try {
    const { data }: AxiosResponse<CommonResponse> =
      await AxiosInstance.post(
        `${RECORDER_PATH}/webLink/test`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postLocalConnectCreate = async ({
  accountId,
  mergedSystemId,
  payload,
}: {
  accountId: string;
  mergedSystemId : string
  payload: LocalConnectionInfo;
}): Promise<LocalConnectionInfoResponse> => {
  try {
    const { data }: AxiosResponse<LocalConnectionInfoResponse> =
      await AxiosInstance.post(
        `${RECORDER_PATH}/webLink/${accountId}/${mergedSystemId}`,
        payload
      );
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getLocalSpectrumWebLinkToken = async ({
  accountId,
  mergedSystemId
}: {
  accountId: string;
  mergedSystemId : string
}): Promise<LocalConnectionInfoResponse> => {
  try {
    const { data }: AxiosResponse<LocalConnectionInfoResponse> =
      await AxiosInstance.get(
        `${RECORDER_PATH}/spectrum/webLink/${accountId}/${mergedSystemId}`
      );
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getLocalSpectrumAppLinkToken = async ({
  accountId,
  mergedSystemId
}: {
  accountId: string;
  mergedSystemId : string
}): Promise<LocalConnectionInfoResponse> => {
  try {
    const { data }: AxiosResponse<LocalConnectionInfoResponse> =
      await AxiosInstance.get(
        `${RECORDER_PATH}/spectrum/appLink/${accountId}/${mergedSystemId}`
      );
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getDWStatus = async (): Promise<CommonResponse> => {
  try {
    const { data }: AxiosResponse<CommonResponse> =
      await AxiosInstance.get(
        `/health/isAlive`
      );
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};