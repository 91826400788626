import styled from "styled-components";
import { COLORS, LEVELS } from "styles/colors";
import { FLEX } from "styles/flex";
import * as mediaQuery from "components/MediaQuery";
import { SidebarLevel } from "components/blocks/sidenav/SideNavBarStyled";
import { calcRem } from "styles/theme";

export const Container = styled.div`
  ${mediaQuery.isDefault} {
    width: 65%;
    max-width: 780px;
    margin: 0 auto;
    padding: 50px 0;
  }
  ${mediaQuery.isMobile} {
    width: 100%;
    padding: 2.5% 0;
  }
`;

export const Title = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

export const Wrapper = styled.section`
  height: 100%;
  display: grid;

  ${mediaQuery.isDefault} {
    gap: 10px;
  }
`;

export const Name = styled.span`
  ${FLEX.FlexCenterCenter};
  gap: 5px;
`;

export const ProfileInner = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  ${FLEX.FlexStartCenter};
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;

  .account-date-info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 90%;

    ${mediaQuery.isMobile} {
      width: 220px;
    }
  }
`;

export const ProfileImageWrapper = styled.div<{ level: string }>`
  border-radius: 50%;
  background-color: ${({ level }) => LEVELS[level]};
  width: 140px;
  height: 140px;
  ${FLEX.FlexCenterCenter};
  position: relative;
`;

export const ProfileImage = styled.div`
  width: 85%;
  height: 85%;
`;

export const Camera = styled.button`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const CameraInner = styled.div`
  position: relative;
  /* border: 1px solid yellow; */
`;

export const RowInput = styled.div`
  ${FLEX.FlexBetweenStart};
  gap: 15px;
  width: 100%;

  .account-block {
    flex: 1;
    width: 100%;
    height: 100%;
  }
  > button {
    padding: 10px;
  }
`;

export const ButtonWrapper = styled.div`
  ${FLEX.FlexStartCenter};
  gap: 10px;
`;

export const RowItem = styled.ul`
  ${mediaQuery.isDefault} {
    display: grid;
    grid-template-columns: 295px 1fr;
    gap: 10px;
    width: 100%;
    overflow: auto;
  }
  ${mediaQuery.isMobile} {
  }
`;
export const Item = styled.div<{ flex?: number }>`
  width: 100%;
  /* height: 100%; */

  background-color: ${({ theme }) => theme.COLORS.WIDGETCOLOR};
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;

  /* margin-bottom: 16px; */

  ${mediaQuery.isDefault} {
    .input-item {
      gap: 15px;
    }
  }

  ${mediaQuery.isMobile} {
    margin-bottom: 8px;
    .input-item {
      flex-direction: column;
      width: 100%;
    }
  }
`;

export const Level = styled(SidebarLevel)`
  width: 24px;
  height: 24px;
`;

export const PhotoMenuBubble = styled.div`
  position: absolute;
  top: -55%;
  right: -165px;
  /* transform: translateY(-50%); */
  z-index: 8;
`;

export const ToggleWrapper = styled.div`
  padding: 4px 0;
`;

export const PhotoMenu = styled.ul`
  position: relative;
  /* height: 120px; */
  padding: 8px 13px;
  background: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #abb5c5;
  box-shadow: 0px 6px 6px #00000016;

  :after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 10px 18px 10px 0;
    border-color: transparent #ffffff;
    display: block;
    width: 0;
    z-index: 1;
    left: -18px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const PhotoMenuItem = styled.li<{ status?: string }>`
  cursor: pointer;
  min-width: 116px;
  ${FLEX.FlexBetweenCenter};
  white-space: nowrap;
  padding: 8px 0;
  font-size: ${calcRem(14)};
  color: #828b9b;

  > img {
    height: 15px;
  }

  ${({ status }) =>
    status === "warning" &&
    `
    color: ${COLORS.RED};
    `};

  ${({ status }) =>
    status === "disabled" &&
    `
    cursor: default;
    opacity: 0.6;
    `}
`;
