import React, { useCallback, useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./VideoPlayer.css";
import BackwardIcon from "assets/icons/video_backward.svg";
import ForwardIcon from "assets/icons/video_forward.svg";
import ScreenshotIcon from "assets/icons/video_screenshot.svg";
import DownloadIcon from "assets/icons/video_download.svg";

import { FaShareFromSquare } from "react-icons/fa6";
import styled from "styled-components";
import FileSaver from "file-saver";
import {
  DownloadUrlResponse,
  VideoShareViewResult,
} from "api/interfaces/videoshareInterface.interface";
import Snapshot from "pages/videoshare/view/Snapshot";
import { getVideoDownloadUrl } from "api/videoshareAPI";
import * as mediaQuery from "components/MediaQuery";
import moment from "moment";
import {
  generateEpochToFileName,
  generateTimeToFileName,
} from "utils/timeUtil";
import { openNewWindow } from "utils/functions";
import { useAppSelector } from "redux/hooks";
import { ProfileAccountInfo } from "api/interfaces/accountInterface.interface";

type VideoPlayerProps = {
  videoUrl: string;
  videoResult?: VideoShareViewResult;
  options: any;
  onReady: (player: any) => void;
  canDownload?: boolean;
  videoId?: string;
  isAuth?: boolean;
  title?: string;
  timezone: string;
  onClickShare?: () => void;
  isDisabledAdd?: boolean;
  guestStartMs?: string;
};

const videoJsOptions = {
  autoplay: false,
  controls: true,
  responsive: true,
  fill: true,
  //fluid: true,
  crossorigin: "anonymous",
  breakpoints: {
    medium: 500,
  },
  controlBar: {
    pictureInPictureToggle: false,
  },
};

const clickEvent = (function () {
  if ("ontouchend" in document.documentElement === true) {
    return "touchend";
  } else {
    return "click";
  }
})();

export const VideoPlayer = ({
  videoUrl,
  videoResult,
  options,
  onReady,
  canDownload,
  videoId,
  isAuth,
  title,
  timezone,
  onClickShare,
  isDisabledAdd,
  guestStartMs,
}: VideoPlayerProps) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<any>(null);
  const canvasRef = useRef<any>(null);

  const [isSnapshot, setIsSnapshot] = useState<boolean>(false);
  const [capturedBlob, setCapturedBlob] = useState<Blob>();
  const [capturedVtt, setCapturedVtt] = useState<string>();
  const [isOpenPlaylist, setIsOpenPlaylist] = useState<boolean>(false);
  const [nowUrl, setNowUrl] = useState<string>();

  useEffect(() => {
    if (playerRef.current) {
      const player: videojs.Player = playerRef.current;
      const findShareButton = player.controlBar.getChildById("shareButtonComp");
      if (findShareButton) {
        if (!isDisabledAdd) {
          findShareButton.hide();
        } else {
          findShareButton.show();
        }
      }
    }
  }, [isDisabledAdd]);

  const onModelSnapshot = useCallback(() => {
    setIsSnapshot(!isSnapshot);
  }, [isSnapshot]);

  const capture = useCallback(() => {
    if (capturedBlob !== undefined) {
      const fileDate =
        capturedVtt !== undefined
          ? capturedVtt.replaceAll(`/`, ``).replace(/ /g, "_")
          : moment().format("MMDDyyyy_HHmmss");

      FileSaver.saveAs(
        capturedBlob as Blob,
        (title ? title : "guest") + ")" + fileDate + ".png"
      );
    }
  }, [capturedBlob, capturedVtt, title]);

  const onCapture = async () => {
    const player: videojs.Player = playerRef.current;

    const canvas: any = document.createElement("CANVAS");

    canvas.width = player.videoWidth();
    canvas.height = player.videoHeight();

    const textTracks = player.textTracks();
    for (var i = 0; i < textTracks.length; i++) {
      var track = textTracks[i];
      if (track.mode === "showing") {
        const cues = track.activeCues;
        if (cues !== null && cues.length > 0) {
          setCapturedVtt((cues[0] as VTTCue).text);
        }
        break;
      }
    }
    canvas
      .getContext("2d")
      .drawImage(player.children_[0], 0, 0, canvas.width, canvas.height);

    var dataUri = canvas.toDataURL("image/png");
    var data = dataUri.split(",")[1];
    var mimeType = dataUri.split(";")[0].slice(5);

    var bytes = window.atob(data);
    var buf = new ArrayBuffer(bytes.length);
    var arr = new Uint8Array(buf);

    for (var i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i);
    }

    var blob = new Blob([arr], { type: mimeType });
    setCapturedBlob(blob);
    setIsSnapshot(true);
    //FileSaver.saveAs(blob as Blob, "capture.png");
  };

  // const download = () => {
  //   const title = state !== undefined ? state.title : new Date().toISOString();
  //   fetch(options.sources[0].src+"&response-content-disposition=attachment", { method: "GET" })
  //     .then((response) => response.blob())
  //     .then((blob) => FileSaver.saveAs(blob, title + ".mp4"))
  //     .catch((err) => {
  //       console.error("err: ", err);
  //     });
  // };

  const download = useCallback(() => {
    if (videoId === undefined) {
      return;
    }
    const player: videojs.Player = playerRef.current;
    const playUrl = player.getMedia().src as videojs.Tech.SourceObject[];

    if (playUrl !== undefined && playUrl.length > 0) {
      // const findVideo: string | undefined = videoUrl;
      // let numberOfsegment = 0;
      // const segment = extractFileName(playUrl[0].src);
      // if (segment === undefined || isEmpty(segment) || !isNumber(segment)) {
      //   numberOfsegment = 0;
      // } else {
      //   numberOfsegment = Number(segment);
      // }

      const downTime = generateEpochToFileName(
        guestStartMs !== undefined
          ? guestStartMs
          : String(videoResult?.startTimeMs),
        timezone
      );
      //BUG [RND-411] 자막다운로드 추가
      getVideoDownloadUrl(videoId, 0, title + "_" + downTime)
        .then((res: DownloadUrlResponse) => {
          if (res.error === 0 && res.result !== undefined) {
            if (res.result[0] !== undefined) {
              //window.open(res.result[0]);
              openNewWindow(res.result[0]);
            }

            if (res.result[1] !== undefined) {
              window.open(res.result[1]);
            }

            // if(res.result[1] !== undefined){
            //   const link2 = document.createElement("a");
            //   link2.href = res.result[1] ;
            //   link2.target = "_self";
            //   link2.setAttribute('download', `22222222222222222`);
            //   link2.click();
            //    link2.remove();
            // }
            // axios({
            //   url: res.result,
            //   method: "GET",
            //   responseType: "blob",
            // }).then((response) => {
            //   const url = window.URL.createObjectURL(new Blob([response.data]));
            //   const link = document.createElement("a");
            //   link.href = url;
            //   link.setAttribute("download", `${title}_${moment().format("MMDDyyyy_HHmmss")}.mp4`);
            //   document.body.appendChild(link);
            //   link.click();
            // });
          }
        })
        .catch();
    }
  }, [nowUrl, videoId]);

  const backward = () => {
    const player: videojs.Player = playerRef.current;

    const now = player.currentTime();
    player.currentTime(Math.max(0, now - 10));
  };

  const forward = () => {
    const player: videojs.Player = playerRef.current;
    let duration = player.duration();

    const now = player.currentTime();

    if (player.liveTracker && player.liveTracker.isLive()) {
      duration = player.liveTracker.seekableEnd();
    }
    player.currentTime(Math.min(now + 10, duration));
  };

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.className = "videojs-big-play-centered";

      if (videoRef.current) {
        videoRef.current.appendChild(videoElement);
      }

      const player: videojs.Player = (playerRef.current = videojs(
        videoElement,
        videoJsOptions,
        () => {
          videojs.log("player is ready");
          onReady && onReady(player);
        }
      ));

      player.crossOrigin("anonymous");
      var playButton: any = player.controlBar.getChild("playToggle");
      var indexPlay = player.controlBar.children().indexOf(playButton) + 1;

      //forward
      var forwardButton = player.controlBar.addChild("Button", {}, indexPlay);
      var forwardButtonDom: Element = forwardButton.el();
      const forwardImage = new Image();
      forwardImage.className = "custom-video-button";
      forwardImage.src = ForwardIcon;
      forwardButtonDom.appendChild(forwardImage);
      forwardButtonDom.addEventListener(clickEvent, () => forward());

      //backward
      var backButton = player.controlBar.addChild("Button", {}, indexPlay);
      var backButtonDom: Element = backButton.el();
      const backImage = new Image();
      backImage.className = "custom-video-button";
      backImage.src = BackwardIcon;
      backButtonDom.appendChild(backImage);
      //backButtonDom.onclick = backward;
      backButtonDom.addEventListener(clickEvent, () => backward());

      var fullscreen: any = player.controlBar.getChild("FullscreenToggle");
      var index = player.controlBar.children().indexOf(fullscreen);

      //Share
      if (isAuth !== undefined && isAuth === true) {
        const shareButton = player.controlBar.addChild(
          "Button",
          { id: "shareButtonComp" },
          index
        );
        var shareButtonDom: Element = shareButton.el();
        const root = createRoot(shareButtonDom);

        const shareIcon = <FaShareFromSquare size={16} />;
        root.render(shareIcon);
        //shareButtonDom.onclick = onClickShare;
        shareButtonDom.addEventListener(clickEvent, () => {
          if (onClickShare) {
            onClickShare();
          }
        });

        if (!isDisabledAdd) {
          shareButton.hide();
        }
      }

      //download
      if (canDownload !== undefined && canDownload === false) {
        // not support download
      } else {
        var downloadButton = player.controlBar.addChild(
          "Button",
          { id: "" },
          index
        );
        var downloadButtonDom: Element = downloadButton.el();
        const downloadImage = new Image();
        downloadImage.className = "custom-video-button";
        downloadImage.src = DownloadIcon;
        downloadButtonDom.appendChild(downloadImage);

        //downloadButtonDom.onclick = download;
        downloadButtonDom.addEventListener(clickEvent, () => download());
      }

      //capture
      // if (isAuth !== undefined && isAuth === false) {
      //   // not support capture
      // } else {
      var captureButton = player.controlBar.addChild("Button", {}, index);
      var captureButtonDom: Element = captureButton.el();
      const captureImage = new Image();
      captureImage.className = "custom-video-button";
      captureImage.src = ScreenshotIcon;
      captureButtonDom.appendChild(captureImage);
      //captureButtonDom.onclick = onCapture;
      captureButtonDom.addEventListener(clickEvent, () => onCapture());
      // }
      //Spacer
      var betweenSpacer = player.controlBar.addChild("Spacer", {}, index);
      betweenSpacer.addClass("vjs-spacecenter");

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      // const player: videojs.Player = playerRef.current;
      // //TODO player List 관리 필요. video url is multi!!!
      // const firstUrl: string =
      //   videoUrl !== undefined && videoUrl.length > 0 ? videoUrl[0].url : "";
      // setNowUrl(firstUrl);
      // // player.autoplay(options.autoplay);
      // player.src({
      //   src: firstUrl,
      //   type: "video/mp4",
      // });
      // const trackEl = player.addRemoteTextTrack(
      //   {
      //     kind: "captions",
      //     label: "timestamp",
      //     language: "en",
      //     src: getCaptionUrl(firstUrl),
      //   },
      //   false
      // );
      // trackEl.addEventListener("load", function () {
      //   var tracks = player.textTracks();
      //   for (var i = 0; i < tracks.length; i++) {
      //     var track = tracks[i];
      //     if (track.label === "timestamp") {
      //       track.mode = "showing";
      //     }
      //   }
      // });
      // player.on('ended', onPlayEnded);
    }
  }, [onReady, options]);

  useEffect(() => {
    if (videoUrl !== undefined) {
      const player: videojs.Player = playerRef.current;

      //TODO player List 관리 필요. video url is multi!!!
      if (videoUrl !== "") {
        setNowUrl(videoUrl);
        // if (videoUrl.length > 1) {
        //   setIsSegment(true);
        //   setIsOpenPlaylist(true);
        // } else {
        //   setIsSegment(false);
        // }
        // player.autoplay(options.autoplay);

        const videoType = videoUrl.endsWith("m3u8")
          ? "application/x-mpegURL"
          : "video/mp4";

        player.src({
          src: encodeURI(videoUrl).replace("#", "%23"),
          type: videoType,
        });
        player.autoplay();

        const trackEl = player.addRemoteTextTrack(
          {
            kind: "captions",
            label: "timestamp",
            language: "en",
            src: encodeURI(getCaptionUrl(videoUrl)).replace("#", "%23"),
          },
          false
        );
        trackEl.addEventListener("load", function () {
          var tracks = player.textTracks();

          for (var i = 0; i < tracks.length; i++) {
            var track = tracks[i];
            if (track.label === "timestamp") {
              track.mode = "showing";
            }
          }
        });
      }
    }
  }, [videoUrl]);

  const getCaptionUrl = (url: string) => {
    return url.replace(/(.mp4|.m3u8)$/, ".vtt");
  };

  const onChangeOpenPlaylist = () => {
    setIsOpenPlaylist(!isOpenPlaylist);
  };

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player: videojs.Player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const onClickThumbnail = useCallback(
    (url: string) => {
      const player: videojs.Player = playerRef.current;

      if (player && videoUrl !== "") {
        setNowUrl(videoUrl);

        const videoType = videoUrl.endsWith("m3u8")
          ? "application/x-mpegURL"
          : "video/mp4";

        player.src({
          src: encodeURI(videoUrl).replace("#", "%23"),
          type: videoType,
        });

        const trackEl = player.addRemoteTextTrack(
          {
            kind: "captions",
            label: "timestamp",
            language: "en",
            src: encodeURI(getCaptionUrl(videoUrl)).replace("#", "%23"),
          },
          false
        );
        trackEl.addEventListener("load", function () {
          var tracks = player.textTracks();

          for (var i = 0; i < tracks.length; i++) {
            var track = tracks[i];
            if (track.label === "timestamp") {
              track.mode = "showing";
            }
          }
        });

        player.play();
      }
    },
    [videoUrl]
  );

  // const onPlayEnded = useCallback(() => {
  //   const findIndex: number = videoUrl.findIndex(
  //     (video) => video.url === nowUrl?.url
  //   );

  //   if (findIndex !== -1 && findIndex !== videoUrl.length) {
  //     onClickThumbnail(videoUrl[findIndex + 1].url);
  //   }
  // }, [videoUrl, nowUrl]);

  return (
    <>
      <Wrapper data-vjs-player id="videop">
        <VideoWrapper
          ref={videoRef}
          onContextMenu={(e) => e.preventDefault()}
        />
        <div id="captureDiv" style={{ overflow: "hidden", height: 0 }}>
          <canvas id="captureCanvas" ref={canvasRef} />
        </div>
        {/* {!isOpenPlaylist && videoUrl !== undefined && videoUrl.length > 1 && (
          <button
            className="playlist-button"
            onClick={() => setIsOpenPlaylist(true)}
          >
            <img src={LIST_ICON} alt="chevron left button" />
          </button>
        )}

        {isOpenPlaylist && (
          <Playlist
            playList={videoUrl}
            onClickHideList={() => setIsOpenPlaylist(false)}
            onClickThumbnail={onClickThumbnail}
            nowUrl={nowUrl?.url}
            timezone={timezone}
          />
        )} */}
      </Wrapper>
      {isSnapshot && (
        <Snapshot
          isAuth={isAuth}
          videoId={videoId}
          capture={capture}
          capturedBlob={capturedBlob}
          capturedVtt={capturedVtt}
          onModelSnapshot={onModelSnapshot}
        />
      )}
    </>
  );
};

export const MemoVideoPlayer = VideoPlayer;
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  .vjs-control-bar {
    z-index: 9;
    width: 100%;
  }

  ${mediaQuery.isMobile} {
    .vjs-button {
      flex: 1;
    }
  }

  .playlist-button {
    z-index: 10;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .custom-video-button {
    height: 70%;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  ${mediaQuery.isDefault} {
    height: 100%;
    padding-top: 0px;
  }
  ${mediaQuery.isMobile} {
    height: 20vh;
    max-height: 20vh;
  }
`;
export default VideoPlayer;
