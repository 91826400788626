import PrimaryButton from "components/atoms/buttons/PrimaryButton";
import { Text } from "components/atoms/text/Text";
import SmallPopupDefaultTemplate from "components/templates/modal/small/SmallPopupDefaultTemplate";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import * as s from "./resendCodeStyled";

export enum VERIFY_TYPE {
  PHONE = "SMS",
  EMAIL = "EMAIL",
}

interface Props {
  onClickCancel: () => void;
  onClickSend: (type: VERIFY_TYPE) => void;
  email: string;
  phone: string;
}

export function ResendCodePopup({
  phone,
  email,
  onClickSend,
  onClickCancel,
}: Props): JSX.Element {
  const [resendType, setResendType] = useState<VERIFY_TYPE>(VERIFY_TYPE.EMAIL);

  const button = (
    <s.ButtonWrapper>
      <PrimaryButton
        buttonType="cancel"
        label="Cancel"
        onClickBtn={onClickCancel}
      />
      <PrimaryButton label="Send" onClickBtn={() => onClickBtn(resendType)} />
    </s.ButtonWrapper>
  );

  const onClickBtn = useCallback(
    (type: VERIFY_TYPE) => {
      onClickSend(resendType);
    },
    [onClickSend, resendType]
  );

  const onchangeHandle = useCallback((e: any) => {
    setResendType(e.currentTarget.value);
  }, []);

  return (
    <SmallPopupDefaultTemplate onModal={onClickCancel} button={button}>
      <s.Title>
        <Text bold fontSize={20}>
          Resend Code
        </Text>
        <Text color="#828B9B" fontSize={14}>
          Choose where you'd like the code to be sent to.
        </Text>
      </s.Title>

      <s.Inner>
        <s.Label>
          <input
            type="radio"
            name="resendRadio"
            value={VERIFY_TYPE.PHONE}
            onChange={onchangeHandle}
            disabled={phone === undefined || phone === ""}
            //disabled={phone === undefined || phone === ""}
          />
          <Text bold>Tel:</Text>
          {phone === undefined || phone === "" ? (
            <Text color="#828B9B">Not Registered</Text>
          ) : (
            phone
          )}
        </s.Label>
        <s.Label>
          <input
            type="radio"
            name="resendRadio"
            value={VERIFY_TYPE.EMAIL}
            onChange={onchangeHandle}
            defaultChecked
          />
          <Text bold>Email:</Text>
          {email}
        </s.Label>
      </s.Inner>
    </SmallPopupDefaultTemplate>
  );
}
