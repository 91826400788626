import {
  BasePagination,
  HTTP_RESULT,
  SORT_DIRECTION,
  SORT_TYPE,
} from "api/interfaces/commonInterface.interface";
import {
  ProfileAccountInfo,
  ProfileAccountInfoFromServer,
} from "api/interfaces/accountInterface.interface";
import { info } from "console";

export enum START_PAGE {
  MAP_VIEW = "MapView",
  CHART_VIEW = "ChartView",
  ADMIN_VIEW = "AdminView",
  ACCOUNT_VIEW = "AccountList",
  RECORDER_VIEW = "RecorderList",
}

export interface resetInfo {
  email: string;
}

export interface GetTokenResponse {
  error: number;
  result: {
    email: string;
    accountId: string;
    isActive: boolean;
    dwAccountActive: boolean;
  };
}
export interface UserInfo {
  userId: string;
  email: string;
  name: string;
  profileImg: string;
  isAccount: number;
  startPage: number;
  totalAccount: number;
  theme: "light" | "dark";

  accounts: ProfileAccountInfoFromServer;
}

export interface ResponseUserInfo {
  error: number;
  errorString: string;
  result: UserInfo;
}

export interface GetUserResponse {
  userId: number;
  accountId: number;
  userGroupId: number;
  name: string;
  email: string;
  phone: string;
  address: string;
  photo: string;
  startPage: START_PAGE;
  newsLetter: boolean;
  companyName: string;
  companyAddress: string;
  companyPhoneNumber: string;
  department: string;
  description: string;
  notifyAlertMail: string;
  notifyReportMail: string;
  notifySms: string;
  createAt: string;
  lastLoggedOn: string;
  uerRole: {
    id: number;
    viewCameraFootage: boolean;
    viewRecordedFootage: boolean;
    viewReport: boolean;
    viewAlert: boolean;
    ptzControl: boolean;
    accountManagement: boolean;
    addEditReport: boolean;
    addEditAlert: boolean;
    userManagement: boolean;
  };
  accountDto: {
    accountId: number;
    parentId: number;
    name: string;
    level: string;
    accountNumber: string;
    timezone: string;
    dateFormat: string;
    timeFormat: string;
  };
}

export interface UserDetail {
  userId: string;
  name: string;
  email: string;
  phone: string;
  address: string;
  photo: string;
  startPage: START_PAGE;
  newsLetter: boolean;
  companyName: string;
  companyAddress: string;
  companyPhoneNumber: string;
  createAt?: string;
  lastLoggedOn?: string;
  provider: string;
  level?: string[];
}

export interface GetUserDetailResponse {
  error: number;
  errorString: string;
  result: UserDetail;
}

export interface GetUserProfileResponse {
  error: number;
  errorString: string;
  result: any;
}

export interface UserList {
  userId: string;
  accountId: string;
  userGroupId: number;
  name: string;
  email: string;
  phone: string;
  address: string;
  photo: string;
  startPage: START_PAGE;
  newsLetter: string;
  companyName: string;
  companyAddress: string;
  companyPhoneNumber: string;
  department: string;
  description: string;
  notifyAlertMail: string;
  notifyReportMail: string;
  notifySms: string;
  createAt: string;
  lastLoggedOn: string;

  groupName: string;
  roleName: string;
}

export interface UserListResponse {
  result: HTTP_RESULT;
  error_code: string;
  count: number;
  pageNum: number;
  pageLimit: number;
  keyword: string;
  sortType: string;
  sortDirection: SORT_TYPE;
  data: UserList[];
}

export type GetUserRequest = BasePagination;

export interface User {
  userId: string;
  userName: string;
  roleId: string;
  roleName: string;
  groupId: string;
  groupName: string;
  description: string;
}

export interface UserListByAccountId {
  userId: string;
  email: string;
  name: string;
  roleId: string;
  roleName: string;
  groupId: string;
  groupName: string;
  description: string;
}

export interface GetUserListResposne {
  error: number;
  errorString: string;
  result: ({
    isActivate: boolean;
    enableEdit: boolean;
    createAt: string;
    profileImg: string;
    userGroupCount: number;
    userGroups: {
      name: string;
      groupId: string;
      enableDelete: boolean;
      description: string;
    }[];
  } & UserDto)[];
  page: {
    total: number;
    pageNum: number;
    pageLimit: number;
    keyword: string;
    sortType: SORT_TYPE;
    sortDirection: SORT_DIRECTION;
  };
}

export interface UserDto {
  [x: string]: string | boolean | number | Group[] | undefined;
  userId: string;
  name: string;
  email: string;
  parentAccount: string;
  companyName: string;
  companyPhoneNumber: string;
  department: string;
  description: string;
  notifyAlertMail: boolean;
  notifyReportMail: boolean;
  notifySms: boolean;
  userTimezone: string;
  // uerRole : {
  //   roleId :string;
  // }
  startPage: number;
  userGroups: Group[];
}

export type CreateUserRequest = UserDto;

export interface CRUDUserResponse {
  error: number;
  errorString: string;
  result: UserDto;
}
export type UpdateUserRequest = UserDto;

export type GetGroupRequest = BasePagination;

export interface UserPermission {
  enableAllPermissions: boolean;
  manageAlertRules: boolean;
  viewAlerts: boolean;
  manageUsersAndGroups: boolean;
  viewUsersAndGroups: boolean;
  manageReportRules: boolean;
  viewReports: boolean;
  manageRecordersAndGroups: boolean;
  viewRecordersAndGroups: boolean;
  manageAccount: boolean;
  viewAccount: boolean;
  view: boolean;
  manageBillingAndLicenses: boolean;
  groupName?: string;
  enableDelete?: boolean;
  isHidden?: true;
}
export interface RecorderPermission {
  recorderPermissionLevel: string; // custome,admin,manager,viewer,liveviewer
  cameraSetting: boolean;
  viewArchive: boolean;
  exportArchive: boolean;
  viewBookmark: boolean;
  modifyBookmark: boolean;
  viewEventLog: boolean;
  cameraUserInput: boolean;
}

export interface Group {
  groupId?: string;
  users: string[];
  accounts: string[];
  userPermission: {
    enableAllPermissions: boolean;
    manageAlertRules: boolean;
    viewAlerts: boolean;
    manageUsersAndGroups: boolean;
    viewUsersAndGroups: boolean;
    manageReportRules: boolean;
    viewReports: boolean;
    manageRecordersAndGroups: boolean;
    viewRecordersAndGroups: boolean;
    manageAccount: boolean;
    viewAccount: boolean;
    view: boolean;
    manageBillingAndLicenses: boolean;
  };
  autoApplyAccount: boolean;
  autoApplyRecorder: boolean;
  systems: string[];
  recorderGroups: number[];
  recorderPermission: {
    recorderPermissionLevel: string; // custome,admin,manager,viewer,liveviewer
    cameraSetting: boolean;
    viewArchive: boolean;
    exportArchive: boolean;
    viewBookmark: boolean;
    modifyBookmark: boolean;
    viewEventLog: boolean;
    cameraUserInput: boolean;
  };
  name: string;
  hostEmail?: string;
  description: string;
  enableDelete?: boolean;
  userId:string | undefined;
}

export interface GetGroupListResposne {
  error: number;
  errorString: string;
  result: ({
    hostName: string;
    hostEmail: string;
    hasUsers: number;
    enableDelete: boolean;
  } & Group)[];
  page: {
    total: number;
    pageNum: number;
    pageLimit: number;
    keyword: string;
    sortType: SORT_TYPE;
    sortDirection: SORT_DIRECTION;
  };
}

export type CreateGroupRequest = Group;
export type UpdateGroupRequest = Group;

export interface CUDGroupResponse {
  error: number;
  errorString: string;
  result: Group;
}

export type GetRoleRequest = BasePagination;

export interface Role {
  roleId: string;
  name: string;
  description: string;
  viewCameraFootage: boolean;
  viewRecordedFootage: boolean;
  viewReport: boolean;
  viewAlert: boolean;
  ptzControl: boolean;
  accountManagement: boolean;
  addEditReport: boolean;
  addEditAlert: boolean;
  userManagement: boolean;
}

export interface GetRoleListResposne {
  error: number;
  errorString: string;
  result: ({
    roleId: string;
    name: string;
    description: string;
    viewCameraFootage: boolean;
    viewRecordedFootage: boolean;
    viewReport: boolean;
    viewAlert: boolean;
    ptzControl: boolean;
    accountManagement: boolean;
    addEditReport: boolean;
    addEditAlert: boolean;
    userManagement: boolean;
  } & Role)[];
  page: {
    total: number;
    pageNum: number;
    pageLimit: number;
    keyword: string;
    sortType: SORT_TYPE;
    sortDirection: SORT_DIRECTION;
  };
}

export type CreateRoleRequest = Role;
export type UpdateRoleRequest = Role;

export interface SearchUserList {
  userId: string;
  name: string;
  email: string;
}

export interface SearchUserAllList {
  // userId: string;
  name: string;
  email: string;
}
export interface updateProfileIamgeResponse {
  error: number;
  result: string;
}

export interface GetUserAllList {
  error: number;
  errorString: string;
  result: SearchUserAllList[];
}
