import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import BigButton from "components/atoms/buttons/BigButton";
import EmailInput from "components/atoms/input/editor/EmailEditor";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import Selects from "components/atoms/select/Select";
import { Option, recipentsOptions } from "utils/options";
import DateTimeSelect from "components/atoms/select/DateTimeSelect";
import styled from "styled-components";

import SelectsCombo from "components/atoms/select/SelectCombo";
import { SearchUserAllList } from "api/interfaces/userInterface.interface";
import { AddEmail } from "api/interfaces/emailInterface.interface";

export enum UPDATE_TYPE {
  SUBJECT,
  MESSAGE,
  EMAIL_TYPE,
  SCHEDULE_TIME,
  USERS,
}
interface Props {
  state: AddEmail;
  users: SearchUserAllList[];
  isEdit?: boolean;
  onModal: (type?: string) => void;
  onClickSend: (isSchedule?: boolean) => void;
  onChange: (type: UPDATE_TYPE, value: any) => void;
  onChangeMessage: (value: string) => void;
  onChangeTime: (value: Date) => void;
}
export function AddModalPresenter(props: Props): JSX.Element {
  const { onModal, onClickSend, onChange, onChangeMessage, onChangeTime } =
    props;

  return (
    <BigPopupTemplate
      title={props.isEdit ? "Edit an Email" : "Write an Email"}
      onModal={onModal}
    >
      <s.Row>
        <ModalInput label="Recipients" essential>
          <Selects
            options={recipentsOptions}
            onChange={(value) => onChange(UPDATE_TYPE.EMAIL_TYPE, value)}
            value={recipentsOptions.filter((option) => {
              return option.value === props.state.recipientType;
            })}
          />
        </ModalInput>
        <ModalInput label="Send Date / Time">
          <DateTimeSelectWrapper>
            <DateTimeSelect
              value={props.state.scheduleTime}
              onChange={onChangeTime}
            />
          </DateTimeSelectWrapper>
        </ModalInput>
      </s.Row>
      {props.state.recipientType === "USER" && (
        <SelectsCombo
          options={props.users.map((user) => {
            return {
              name: user.name,
              label: `${user.name} [${user.email}]`,
              value: user.email,
            };
          })}
          value={props.state.users?.map((user) => {
            return {
              name: user.name,
              label: `${user.name} [${user.email}]`,
              value: user.email,
            };
          })}
          onChange={(value) => {
            onChange(UPDATE_TYPE.USERS, value);
          }}
        />
      )}
      <ModalInput label="Subject" essential>
        <Input
          value={props.state.subject}
          placeholder="Enter Subject"
          onChange={(e) => onChange(UPDATE_TYPE.SUBJECT, e.target.value)}
        />
      </ModalInput>
      <ModalInput label="Message" essential>
        <EmailInput
          value={props.state.message}
          placeholder="Write your email message."
          onChange={onChangeMessage}
        />
      </ModalInput>
      <s.ButtonWrapper>
        <BigButton
          buttonType="cancel"
          label="Send Now"
          onClickBtn={() => onClickSend()}
        />
        <BigButton
          label="Schedule Email"
          onClickBtn={() => onClickSend(true)}
        />
      </s.ButtonWrapper>
    </BigPopupTemplate>
  );
}

const DateTimeSelectWrapper = styled.div`
  width: 100%;
`;
