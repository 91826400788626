import * as s from "./AlertViewStyled";
import ModalTemplate from "components/templates/modal/ModalTemplate";

import { Text } from "components/atoms/text/Text";
import CLOSE_ICON from "assets/icons/sidenav/x.svg";

import * as mediaQuery from "components/MediaQuery";

import {
  AlertActiveSummary,
  AlertList2,
  AlertTraceList,
  AlertType,
  SelectedAlertView,
} from "api/interfaces/alertInterface.interface";

import Type from "components/atoms/text/labels/Type";
import { useAppSelector } from "redux/hooks";
import {
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import WebAppLink from "components/blocks/link/WebAppLink";
import Status, {
  StatusName,
  StatusValue,
} from "components/blocks/status/Status";
import TraceList from "./components/TraceList";
import AlertInfo from "./components/AlertInfo";
import { COLORS } from "styles/colors";

import TinyButton from "components/atoms/buttons/TinyButton";
import AlertChangeConfirm from "../small/alert/AlertChangeConfirm";
import AlertTypeList from "./components/AlertTypeList";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { FaCircleCheck } from "react-icons/fa6";
import { convertType } from "utils/functions";
import Spinner from "components/atoms/loader/Spinner";
import NoData from "components/templates/nopage/NoData";
import { isCheckPermission } from "utils/AccountUtil";
interface Props {
  selectedAlert: SelectedAlertView;
  currentAlert: AlertList2 | undefined;
  alertTypeList: AlertType[];
  traceList: AlertTraceList[];
  count: AlertActiveSummary;
  onModal: () => void;
  isFetching: boolean;
  onCloseAllStatus: () => void;
  isConfirmStatus: boolean;
  onConfirmCancel: () => void;
  onConfirmOk: (checked: boolean) => void;
  onChangeCurrentAlert: (alertType: AlertType, value?: string) => void;
  onCheckNoConfirm: (value: boolean) => void;
  checkNoConfirm: boolean;
}

export interface TraceListData {
  action: string;
  createdAt: string; // 추후 Date로 변경
  username: string;
  comment: string;
  assigned?: string;
}

const alertStatusStyled = s.AlertStatusStyled;
const AlertViewPresenter = (props: Props) => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const selectedAccount: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  const isMap = location.pathname.includes("map");
  const isClosed =
    (props.selectedAlert.historyId !== undefined &&
      props.currentAlert?.status === "Closed") ||
    selectedAccount.isHidden;

  const status = {
    items: [
      <>
        <StatusName>System</StatusName>

        <StatusValue color={COLORS.RED}>{props.count.systemCount}</StatusValue>
      </>,
      <>
        <StatusName>Disk</StatusName>

        <StatusValue color={COLORS.RED}>{props.count.diskCount}</StatusValue>
      </>,
      <>
        <StatusName>Video</StatusName>

        <StatusValue color={COLORS.RED}>{props.count.videoCount}</StatusValue>
      </>,
    ],
  };

  return isMap ? (
    props.isFetching ? (
      <Spinner />
    ) : props.currentAlert === undefined ||
      props.currentAlert.status === "Healthy" ||
      props.currentAlert.status === "Warning" ? (
      <s.AlertViewNoData>
        <FaCircleCheck color={COLORS.GREEN} size={42} />
        No Alert
      </s.AlertViewNoData>
    ) : (
      <s.AlertMapViewWrapper>
        <AlertTypeList
          selectedAlert={props.selectedAlert}
          alertTypeList={props.alertTypeList}
          currentAlert={props.currentAlert}
          onChangeCurrentAlert={props.onChangeCurrentAlert}
          onModal={props.onModal}
          onCheckNoConfirm={props.onCheckNoConfirm}
          checkNoConfirm={props.checkNoConfirm}
        />
        <AlertInfo data={props.currentAlert} />
        <TraceList
          data={props.traceList}
          alertHistoryId={props.currentAlert.alertHistoryId}
          recorderId={props.selectedAlert.recorderId}
          assignee={props.currentAlert.assignee}
          isClosed={isClosed}
        />
      </s.AlertMapViewWrapper>
    )
  ) : (
    <>
      <ModalTemplate>
        <s.AlertModal>
          <s.AlertModalHeader>
            <mediaQuery.Default>
              <s.CloseButton
                onClick={() => {
                  props.onModal();
                  queryClient.invalidateQueries("activeAlert");
                }}
              >
                <img alt="close button" src={CLOSE_ICON} />
              </s.CloseButton>
              <s.HeaderInner>
                <s.HeaderSub>
                  <Type type={convertType(props.selectedAlert.type)} />
                  {/* <div className="title-wrapper"> */}
                  <div className="system-title">
                    <Text bold className="system-name" fontSize={24}>
                      {props.selectedAlert.mergedSystemName !== undefined
                        ? props.selectedAlert.mergedSystemName
                        : props.selectedAlert.recorderName}
                    </Text>
                    {props.selectedAlert.type === "Spectrum" && (
                      <Text
                        fontSize={14}
                        color="#828B9B"
                        className="server-name"
                      >
                        {props.selectedAlert.recorderName}
                      </Text>
                    )}
                  </div>
                  <div className="web-app-link-wrapper">
                    {
                      <WebAppLink
                        type={props.selectedAlert.type}
                        accountId={selectedAccount.accountId}
                        recorderId={props.selectedAlert.recorderId}
                        mergedSystemId={props.selectedAlert.mergedSystemId}
                        cloudSystemId={props.selectedAlert.cloudSystemId}
                      />
                    }
                  </div>
                  {/* </div> */}
                </s.HeaderSub>
              </s.HeaderInner>
            </mediaQuery.Default>
            <mediaQuery.Mobile>
              <s.HeaderInner>
                <div className="wep-app-link">
                  {props.currentAlert !== undefined && (
                    <WebAppLink
                      type={props.selectedAlert.type}
                      accountId={selectedAccount.accountId}
                      recorderId={props.selectedAlert.recorderId}
                      mergedSystemId={props.selectedAlert.mergedSystemId}
                      cloudSystemId={props.selectedAlert.cloudSystemId}
                    />
                  )}
                </div>
                <s.CloseButton
                  onClick={() => {
                    props.onModal();
                    queryClient.invalidateQueries("activeAlert");
                  }}
                >
                  <img alt="close button" src={CLOSE_ICON} />
                </s.CloseButton>
              </s.HeaderInner>
              <s.HeaderInner>
                <s.HeaderSub>
                  <Type type={convertType(props.selectedAlert.type)} />
                  <div className="system-title">
                    <Text fontSize={24} bold className="system-name">
                      {props.selectedAlert.mergedSystemName !== undefined
                        ? props.selectedAlert.mergedSystemName
                        : props.selectedAlert.recorderName}
                    </Text>
                    {props.selectedAlert.type === "Spectrum" && (
                      <Text
                        fontSize={14}
                        color="#828B9B"
                        className="server-name"
                      >
                        {props.selectedAlert.recorderName}
                      </Text>
                    )}
                  </div>
                </s.HeaderSub>
              </s.HeaderInner>
            </mediaQuery.Mobile>
          </s.AlertModalHeader>
          <s.Inners>
            {props.isFetching ? (
              <Spinner />
            ) : props.currentAlert === undefined ? (
              <s.AlertViewNoData>
                <NoData />
              </s.AlertViewNoData>
            ) : (
              <>
                {!props.selectedAlert.historyId && (
                  <alertStatusStyled.AlertStatusWrapper>
                    <Status status={status} />
                    {isCheckPermission("manageAlertRules", selectedAccount) &&
                      !(
                        props.selectedAlert.historyId &&
                        props.currentAlert?.status === "Closed"
                      ) && (
                        <alertStatusStyled.AlertStatusChange>
                          <TinyButton
                            onClickBtn={props.onCloseAllStatus}
                            label="CLOSE ALL"
                            buttonType="warning"
                          />
                        </alertStatusStyled.AlertStatusChange>
                      )}
                  </alertStatusStyled.AlertStatusWrapper>
                )}

                <s.InnerContents>
                  <AlertTypeList
                    selectedAlert={props.selectedAlert}
                    alertTypeList={props.alertTypeList}
                    currentAlert={props.currentAlert}
                    onChangeCurrentAlert={props.onChangeCurrentAlert}
                    onModal={props.onModal}
                    onCheckNoConfirm={props.onCheckNoConfirm}
                    checkNoConfirm={props.checkNoConfirm}
                  />
                  <AlertInfo data={props.currentAlert} />
                  <TraceList
                    data={props.traceList}
                    alertHistoryId={props.currentAlert.alertHistoryId}
                    recorderId={props.selectedAlert.recorderId}
                    assignee={props.currentAlert.assignee}
                    isClosed={isClosed}
                  />
                </s.InnerContents>
              </>
            )}
          </s.Inners>
        </s.AlertModal>
      </ModalTemplate>
      {props.currentAlert !== undefined && props.isConfirmStatus && (
        <AlertChangeConfirm
          isAll
          onConfirmCancel={props.onConfirmCancel}
          onConfirmOk={props.onConfirmOk}
          selectedAlert={props.selectedAlert}
          currentAlert={props.currentAlert}
        />
      )}
    </>
  );
};

export default AlertViewPresenter;
