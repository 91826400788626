import {
  postCheckEmail,
  postForgotEmail,
  postForgotPassword,
  postLogin,
  postResendCode,
  postVerify,
} from "api/authAPI";
import { useAuth } from "components/Auth";
import {
  getItemWithExpireTime,
  setItemWithExpireTime,
  useLocalStorage,
} from "hook/useLocalStorage";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginPresenter } from "./LoginPresenter";
import { isEmpty } from "lodash";
import { getUserDetail, getUserDetailAccount } from "api/userAPI";
import { notify } from "components/atoms/notification/Notification";
import { getClientDevice, getStartPageUrl } from "utils/functions";
import { checkEmailRegEx } from "utils/regEx";
import qs from "qs";
import { ACCESS_TOKEN, AxiosInstance, REFRESH_TOKEN } from "api/axiosInstance";
import { VERIFY_TYPE } from "popups/resendCode";
import { useAppDispatch } from "redux/hooks";
import { setMenu } from "redux/reducers/menu/menuSlice";
import { UserInfo } from "api/interfaces/userInterface.interface";
import { checkGroup, checkTrialShowMenu } from "utils/AccountUtil";
import { getCookieExpireTime } from "utils/timeUtil";
import { setCookie } from "cookies/cookie";
import { useIntl } from "react-intl";
import { LEVEL_TYPE } from "api/interfaces/accountInterface.interface";
import { CustomError } from "api/interfaces/commonInterface.interface";
import { menuNavigation } from "utils/MenuUtil";
import { datadogRum } from "@datadog/browser-rum";
import useApiError from "hook/useApiError";

export enum LOGIN_STEP {
  INPUT_EMAIL = "INPUT_EMAIL",
  INPUT_PASSWORD = "INPUT_PASSWORD",
  INPUT_VERIFY_CODE = "INPUT_VERIFY_CODE",
  RESEND_VERIFY_CODE = "RESEND_VERIFY_CODE",
  FORGOT_EMAIL = "FORGOT_EMAIL",
  FORGOT_EMAIL_SENT = "FORGOT_EMAIL_SENT",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
}

export interface LoginInfo {
  email: string;
  password: string;
  tel?: string;
}

export interface Validate {
  [key: string]: boolean;
}
export interface ValidateMsg {
  [key: string]: string;
}

type Props = {
  code?: string;
};
export function LoginPage({ code }: Props): JSX.Element {
  const auth = useAuth();
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { handleError } = useApiError();

  const [step, setSteps] = useState<LOGIN_STEP>(LOGIN_STEP.INPUT_EMAIL);
  const [loginInfo, setLoginInfo] = useState<LoginInfo>({
    email: "",
    password: "",
    tel: "",
  });

  const [isValidated, setIsValidated] = useState<Validate>({
    email: false,
    password: false,
    tel: false,
    resend: false,
    verifyCode: false,
  });

  const [validateMsg, setValidateMsg] = useState<ValidateMsg>({
    email: "",
    password: "",
    tel: "",
    resend: "",
    verifyCode: "",
  });
  const [retVerifyCode, setRetVerifyCode] = useState<String>("");
  const [verifyCode, setVerifyCode] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [targetAccount, setTargetAccount] = useState<string>("");

  //로컬스토리지 저장.
  const [emailLocal, setEmailLocal] = useLocalStorage("email", "");
  const [rememberLocal, setRememberLocal] = useLocalStorage("remember", false);

  const [trustDevice, setTrustDevice] = useState<boolean>(false);
  const [verifyType, setVerifyType] = useState<VERIFY_TYPE>(VERIFY_TYPE.EMAIL);
  const [session, setSession] = useState<string>("");
  const [refreshSession, setRefreshSession] = useState<string>("");

  const [cloudLoading, setCloudLoading] = useState<boolean>(false);

  const [rememberInfo, setRememberInfo] = useState<{
    email: string;
    isRemember: boolean;
  }>({
    email: "",
    isRemember: false,
  });

  const qsSearchParam = qs.parse(location.state?.search, {
    ignoreQueryPrefix: true,
    decoder: (s) => decodeURIComponent(s),
  });
  const email =
    qsSearchParam.email !== null ? (qsSearchParam.email as string) : "";
  const toSearchParam = new URLSearchParams(location.state?.search);
  //const email = toSearchParam.get("email") !== null ? toSearchParam.get("email") as string : "";

  const [isSpectrumMouseOver, setIsSpectrumMouseOver] =
    useState<boolean>(false);

  const onMouseOverSpectrumLogin = () => {
    setIsSpectrumMouseOver(!isSpectrumMouseOver);
  };

  useEffect(() => {
    (async () => {
      const device = getClientDevice();
      if (code !== undefined && code !== "") {
        window.opener.postMessage({ code }, window.location.origin);
        // try {
        //   setCloudLoading(true);
        //   // code를 이용해서 token들을 받아옴
        //   sessionStorage.setItem("code", code);
        //   const resp = await AxiosInstance.post("/auth/token", {
        //     code,
        //     device,
        //   });

        //   if (resp.status === 404) {
        //     setIsValidated({ ...isValidated, email: true });
        //     setValidateMsg({
        //       ...validateMsg,
        //       email: intl.formatMessage({
        //         id: "validateMsg.signup.accountNotFound",
        //         defaultMessage: "Couldn't find your myDW account",
        //       }),
        //     });
        //   } else if (200 > resp.status || 300 < resp.status) {
        //     setIsValidated({ ...isValidated, email: true });
        //     setValidateMsg({
        //       ...validateMsg,
        //       email: intl.formatMessage({
        //         id: "validateMsg.signup.loginIncorrect",
        //         defaultMessage: "INCORRECT LOGIN CREDENTIALS",
        //       }),
        //     });
        //   } else {
        //     AxiosInstance.defaults.headers.common[
        //       "Authorization"
        //     ] = `Bearer ${resp.data.token}`;
        //     setCookie({
        //       name: ACCESS_TOKEN,
        //       value: resp.data.token,
        //       options: {
        //         path: "/",
        //         expires: getCookieExpireTime(6),
        //       },
        //     });
        //     //sessionStorage.setItem("token", resp.data.token);
        //     setUserInfo(resp.data.email)
        //       .then(function (resolvedData) {
        //         dispatch(setMenu("Dashboard"));
        //         navigate("/dashboard/map", { replace: true });
        //       })
        //       .catch(function (err) {
        //         setIsValidated({ ...isValidated, email: true });
        //         setValidateMsg({
        //           ...validateMsg,
        //           email: intl.formatMessage({
        //             id: "validateMsg.signup.loginIncorrect",
        //             defaultMessage: "INCORRECT LOGIN CREDENTIALS",
        //           }),
        //         });
        //         //console.log('then error : ', err); // then error :  Error: Error in then()
        //       });
        //   }
        // } catch (err) {
        //   setIsValidated({ ...isValidated, email: true });
        //   setValidateMsg({
        //     ...validateMsg,
        //     email: intl.formatMessage({
        //       id: "validateMsg.signup.loginIncorrect",
        //       defaultMessage: "INCORRECT LOGIN CREDENTIALS",
        //     }),
        //   });
        // } finally {
        //   setCloudLoading(false);
        // }
      } else if (email !== undefined) {
        setLoginInfo((info) => ({
          ...info,
          email: email as string,
        }));
      }
    })();
  }, [location]);

  const setStep = (string: LOGIN_STEP) => {
    setIsValidated({
      email: false,
      password: false,
      tel: false,
      resend: false,
      verifyCode: false,
    });
    setValidateMsg({
      email: "",
      password: "",
      tel: "",
      resend: "",
      verifyCode: "",
    });
    setSteps(string);
  };

  useLayoutEffect(() => {
    if (rememberLocal) {
      // setRememberInfo({
      //   email: emailLocal,
      //   isRemember: rememberLocal,
      // });
      setLoginInfo((info) => {
        return {
          ...info,
          email: emailLocal as string,
        };
      });
    } else {
      setLoginInfo((info) => {
        return {
          ...info,
          email: "",
        };
      });
    }
  }, []);

  const onClickResend = useCallback(() => {
    setStep(LOGIN_STEP.RESEND_VERIFY_CODE);
  }, []);

  const onChangePhoneNumber = useCallback((number: string) => {
    setLoginInfo((info) => ({
      ...info,
      tel: number,
    }));
    setPhoneNumber(number);
  }, []);

  const onChangeLoginInfo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === "email") {
        setLoginInfo((info) => ({
          ...info,
          email:
            e.target.value !== undefined && e.target.value !== ""
              ? e.target.value.trim()
              : "",
        }));
      }

      if (e.target.name === "password") {
        setLoginInfo((info) => ({
          ...info,
          password: e.target.value,
        }));
      }
    },
    []
  );

  const onErrorPassword = (msg?: string) => {
    setIsValidated({ ...isValidated, password: true });
    setLoginInfo({
      ...loginInfo,
      password: "",
    });
    setValidateMsg({
      ...validateMsg,
      password: msg
        ? msg
        : intl.formatMessage({
            id: "validateMsg.signup.loginIncorrect",
            defaultMessage: "Incorrect Login Credentials",
          }),
    });
  };

  const onErrorVerify = (msg?: string) => {
    setIsValidated({ ...isValidated, verifyCode: true });
    setValidateMsg({
      ...validateMsg,
      verifyCode: msg
        ? msg
        : intl.formatMessage({
            id: "validateMsg.signup.verifyNumber",
            defaultMessage: "The code you entered does not match.",
          }),
    });
  };

  const onClickVerifyCancel = useCallback(() => {
    setStep(LOGIN_STEP.INPUT_VERIFY_CODE);
  }, []);

  const setUserInfo = useCallback((email: string) => {
    return new Promise(function (resolve, reject) {
      // AxiosInstance.defaults.headers.common[
      //   "Authorization"
      // ] = `Bearer ${accessToken.accessTokenValue}`;
      // sessionStorage.setItem('token', session);
      if (
        toSearchParam !== undefined &&
        toSearchParam.get("accountId") !== null
      ) {
        getUserDetailAccount(toSearchParam.get("accountId") as string)
          .then((response) => {
            auth.login(response);
            resolve(response);
          })
          .catch(function (err: CustomError) {
            reject(err);
            //resolve(err);
          });
      } else {
        getUserDetail()
          .then((response) => {
            auth.login(response);
            resolve(response);
          })
          .catch(function (err: CustomError) {
            reject(err);
            //resolve(err);
          });
      }
    });
  }, []);

  const mutationLogin = useMutation(postLogin, {
    onSuccess: (data: any) => {
      setSession(data.result.accessToken);
      setRefreshSession(data.result.refreshToken);
      setTargetAccount(data.result.accountId);

      datadogRum.setUser({
        email: loginInfo.email,
      });

      if (data.result.phoneNumber) {
        setLoginInfo((info) => ({
          ...info,
          tel: data.result.phoneNumber,
        }));
      }

      const storedTrustDevice = getItemWithExpireTime(
        "trust_" + loginInfo.email + data.result.accountId
      );

      if (data.result && data.result.requiredTwoFactor && !storedTrustDevice) {
        mutationResendCode.mutate({
          payload: {
            twoFactorType: VERIFY_TYPE.EMAIL,
            email: loginInfo.email,
          },
        });
        setStep(LOGIN_STEP.INPUT_VERIFY_CODE);
        return;
      }

      //sessionStorage.setItem("token", data.result.accessToken);
      setCookie({
        name: ACCESS_TOKEN,
        value: data.result.accessToken,
        options: {
          path: "/",
          expires: getCookieExpireTime(6),
          // secure : true,
          // httpOnly : true
        },
      });
      setCookie({
        name: REFRESH_TOKEN,
        value: data.result.refreshToken,
        options: {
          path: "/",
          expires: getCookieExpireTime(72),
          //secure : true,
          //httpOnly : true
        },
      });

      AxiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data.result.accessToken}`;

      setUserInfo(loginInfo.email)
        .then((resolvedData) => {
          const accountData = resolvedData as UserInfo;
          //BUG [RND-330] email link
          if (accountData !== undefined && accountData.isAccount !== 0) {
            console.log(accountData, "accountData");
            if (toSearchParam.size > 0 && toSearchParam.get("url") !== null) {
              auth.processLinkNavigate(
                toSearchParam.get("url") as string,
                toSearchParam,
                accountData
              );
              // if (toPathParam.includes("alert")) {
              //   const accountId = toSearchParam.get("accountId") as string;
              //   const recorderId = toSearchParam.get("recorderId") as string;
              //   getRecorder({ accountId, recorderId })
              //     .then((response) => {
              //       if (response.error === 0 && response.result !== undefined) {
              //         navigate("/alert/history/detail", {
              //           state: {
              //             recorderName: response.result.name,
              //             recorderId: response.result.recorderId,
              //             recorderType: response.result.type,
              //           },
              //           replace: true,
              //         });
              //         dispatch(setMenu("Alert"));
              //       } else {
              //         dispatch(setMenu("Dashboard"));
              //         navigate("/dashboard/map", { replace: true });
              //       }
              //     })
              //     .catch((e) => {
              //       dispatch(setMenu("Dashboard"));
              //       navigate("/dashboard/map", { replace: true });
              //     });
              // } else if (toPathParam.includes("report")) {
              //   const accountId = toSearchParam.get("accountId") as string;
              //   const reportId = toSearchParam.get("reportId") as string;
              //   getReportPreferenceInfo({ accountId, reportId })
              //     .then((response) => {
              //       if (response.error === 0 && response.result !== undefined) {
              //         const preference = response.result as PreferenceStep;
              //         dispatch(setMenu("Reports"));
              //         navigate(toPathParam, {
              //           state: {
              //             id: reportId,
              //             title: preference.title,
              //           },
              //           replace: true,
              //         });
              //       } else {
              //         navigate("/dashboard/map", { replace: true });
              //       }
              //     })
              //     .catch((e) => {
              //       dispatch(setMenu("Dashboard"));
              //       navigate("/dashboard/map", { replace: true });
              //     });
              // } else if (toPathParam.includes("videoShare")) {
              //   dispatch(setMenu("Video Share"));
              //   navigate(toPathParam);
              // } else {
              //   dispatch(setMenu("Dashboard"));
              //   navigate("/dashboard/map", { replace: true });
              // }
            } else {
              const isAdmin = checkGroup(
                accountData.accounts.userPermission,
                "administrator"
              );
              if (checkTrialShowMenu(accountData.accounts)) {
                dispatch(setMenu("Recorders"));
                menuNavigation(
                  accountData.accounts.accountId,
                  navigate,
                  getStartPageUrl(5),
                  true
                );
                // navigate(
                //   `${getStartPageUrl(5)}/${accountData.accounts.accountId}`,
                //   {
                //     replace: true,
                //   }
                // );
              } else if ([1, 2, 3].includes(accountData.startPage)) {
                if (accountData.accounts.accountLevel === LEVEL_TYPE.DW) {
                  if (isAdmin) {
                    if (accountData.startPage === 1) {
                      dispatch(setMenu("Dashboard"));
                      menuNavigation(
                        accountData.accounts.accountId,
                        navigate,
                        getStartPageUrl(2),
                        true
                      );
                    } else {
                      dispatch(setMenu("Dashboard"));
                      menuNavigation(
                        accountData.accounts.accountId,
                        navigate,
                        getStartPageUrl(accountData.startPage),
                        true
                      );
                    }
                  } else {
                    dispatch(setMenu("Dashboard"));
                    menuNavigation(
                      accountData.accounts.accountId,
                      navigate,
                      getStartPageUrl(2),
                      true
                    );
                  }
                } else if (
                  (accountData.accounts.accountLevel === LEVEL_TYPE.CP ||
                    accountData.accounts.accountLevel === LEVEL_TYPE.EU) &&
                  !isAdmin &&
                  accountData.startPage === 3
                ) {
                  dispatch(setMenu("Dashboard"));
                  menuNavigation(
                    accountData.accounts.accountId,
                    navigate,
                    getStartPageUrl(1),
                    true
                  );
                  // navigate(getStartPageUrl(1), {
                  //   replace: true,
                  // });
                } else {
                  dispatch(setMenu("Dashboard"));
                  menuNavigation(
                    accountData.accounts.accountId,
                    navigate,
                    getStartPageUrl(accountData.startPage),
                    true
                  );
                  // navigate(getStartPageUrl(accountData.startPage), {
                  //   replace: true,
                  // });
                }
              } else if (accountData.startPage === 4) {
                if (accountData.accounts.accountLevel !== LEVEL_TYPE.EU) {
                  dispatch(setMenu("Organization"));
                  menuNavigation(
                    accountData.accounts.accountId,
                    navigate,
                    getStartPageUrl(accountData.startPage),
                    true
                  );
                  // navigate(
                  //   `${getStartPageUrl(accountData.startPage)}/${
                  //     accountData.accounts.accountId
                  //   }`,
                  //   {
                  //     replace: true,
                  //   }
                  // );
                } else {
                  dispatch(setMenu("Dashboard"));
                  menuNavigation(
                    accountData.accounts.accountId,
                    navigate,
                    "/dashboard/map",
                    true
                  );
                  //navigate("/dashboard/map", { replace: true });
                }
              } else if (accountData.startPage === 5) {
                if (accountData.accounts.accountLevel === LEVEL_TYPE.EU) {
                  dispatch(setMenu("Recorders"));
                  menuNavigation(
                    accountData.accounts.accountId,
                    navigate,
                    getStartPageUrl(5),
                    true
                  );
                  // navigate(
                  //   `${getStartPageUrl(5)}/${accountData.accounts.accountId}`,
                  //   {
                  //     replace: true,
                  //   }
                  // );
                } else {
                  dispatch(setMenu("Dashboard"));
                  menuNavigation(
                    accountData.accounts.accountId,
                    navigate,
                    "/dashboard/map",
                    true
                  );
                  //navigate("/dashboard/map", { replace: true });
                }
              } else {
                dispatch(setMenu("Dashboard"));
                menuNavigation(
                  accountData.accounts.accountId,
                  navigate,
                  "/dashboard/map",
                  true
                );
                //navigate("/dashboard/map", { replace: true });
              }
            }
            // if (accountData.isAccount === 1) {
            //   dispatch(setMenu("Dashboard"));
            //   navigate("/dashboard/map", { replace: true });
            // } else {
            //   dispatch(setMenu("Account"));
            //   navigate("/account", {
            //     state: {
            //       isSwitchMenu: true,
            //     },
            //     replace: true,
            //   });
            // }
          } else {
            onErrorPassword(
              intl.formatMessage({
                id: "validateMsg.login.password.incorrect",
                defaultMessage: "You entered an incorrect password",
              })
            );
          }
        })
        .catch(function (err) {
          if (toSearchParam.size > 0 && toSearchParam.get("url") !== null) {
            navigate("/error");
          } else {
            onErrorPassword();
          }
          //console.log('then error : ', err); // then error :  Error: Error in then()
        });
    },
    onError: () => {
      onErrorPassword();
    },
  });

  const mutationVerify = useMutation(postVerify, {
    onSuccess: () => {
      setCookie({
        name: ACCESS_TOKEN,
        value: session,
        options: {
          path: "/",
          expires: getCookieExpireTime(6),
          // secure : true,
          // httpOnly : true
        },
      });
      setCookie({
        name: REFRESH_TOKEN,
        value: refreshSession,
        options: {
          path: "/",
          expires: getCookieExpireTime(72),
          //secure : true,
          //httpOnly : true
        },
      });

      AxiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${session}`;

      setUserInfo(loginInfo.email)
        .then(function (resolvedData) {
          const accountData = resolvedData as UserInfo;
          //BUG [RND-330] email link
          if (accountData !== undefined && accountData.isAccount !== 0) {
            console.log(accountData, "accountData");
            if (toSearchParam.size > 0 && toSearchParam.get("url") !== null) {
              auth.processLinkNavigate(
                toSearchParam.get("url") as string,
                toSearchParam,
                accountData
              );
            } else {
              auth.loginNavigator("/", accountData);
            }
          } else {
            console.error("Account information bot found");
          }
        })
        .catch(function (err) {});
      // auth.login(loginInfo.email);
      // navigate("/dashboard/map", { replace: true });
    },
    onError: () => {
      onErrorVerify(
        intl.formatMessage({
          id: "validateMsg.signup.verifyNumber",
          defaultMessage: "The code you entered does not match.",
        })
      );
    },
  });
  const onClickVerify = useCallback(() => {
    // mutationVerify.mutate(verifyCode.join(""));
    if (retVerifyCode === verifyCode) {
      if (trustDevice) {
        setItemWithExpireTime(
          "trust_" + loginInfo.email + targetAccount,
          String(true),
          2592000000
        );
      }

      setCookie({
        name: ACCESS_TOKEN,
        value: session,
        options: {
          path: "/",
          expires: getCookieExpireTime(6),
          // secure : true,
          // httpOnly : true
        },
      });
      setCookie({
        name: REFRESH_TOKEN,
        value: refreshSession,
        options: {
          path: "/",
          expires: getCookieExpireTime(72),
          //secure : true,
          //httpOnly : true
        },
      });

      AxiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${session}`;

      setUserInfo(loginInfo.email)
        .then(function (resolvedData) {
          const accountData = resolvedData as UserInfo;
          //BUG [RND-330] email link
          if (accountData !== undefined && accountData.isAccount !== 0) {
            console.log(accountData, "accountData");
            if (toSearchParam.size > 0 && toSearchParam.get("url") !== null) {
              auth.processLinkNavigate(
                toSearchParam.get("url") as string,
                toSearchParam,
                accountData
              );
            } else {
              auth.loginNavigator("/", accountData);
            }
          } else {
            console.error("Account information bot found");
          }
        })
        .catch(function (err) {
          setIsValidated({ ...isValidated, password: true });
          setValidateMsg({
            ...validateMsg,
            password: intl.formatMessage({
              id: "validateMsg.signup.loginIncorrect",
              defaultMessage: "Incorrect Login Credentials.",
            }),
          });
          //console.log('then error : ', err); // then error :  Error: Error in then()
        });
    } else {
      notify(
        "error",
        intl.formatMessage({
          id: "label.login.notify.verify.fail",
          defaultMessage: "The verification code does not match.",
        })
      );
    }
  }, [mutationVerify, verifyCode, trustDevice]);

  const onClickLogin = useCallback(() => {
    if (rememberInfo.isRemember) {
      setEmailLocal(loginInfo.email);
      setRememberLocal(true);
    } else {
      setEmailLocal("");
      setRememberLocal(false);
    }
    mutationLogin.mutate({
      email: loginInfo.email,
      password: loginInfo.password,
      device: getClientDevice(),
      trustDevice: trustDevice,
      // accountId: "dd",
    });
  }, [loginInfo, mutationLogin, trustDevice, setEmailLocal]);

  const mutationCheckEmail = useMutation(postCheckEmail, {
    onSuccess: (res: any) => {
      if (
        res.result.provider !== undefined &&
        res.result.provider !== "AUTH0"
      ) {
        setIsValidated({ ...isValidated, email: true });
        setValidateMsg({
          ...validateMsg,
          email: intl.formatMessage({
            id: "validateMsg.signup.checkEmail.cloudUser",
            defaultMessage: "Please try logging in with your DW Spectrum Cloud account.",
          }),
        });
      } else if (res.result.isExist && res.result.isActivate) {
        if (res.result?.hasAccount === undefined || !res.result?.hasAccount) {
          setIsValidated({ ...isValidated, email: true });
          setValidateMsg({
            ...validateMsg,
            email: intl.formatMessage({
              id: "validateMsg.signup.accountNotFound",
              defaultMessage: "Organization does not exist.",
            }),
          });
        } else {
          setStep(LOGIN_STEP.INPUT_PASSWORD);
        }
        //setStep(LOGIN_STEP.INPUT_PASSWORD);
      } else if (res.result.isExist && !res.result.isActivate) {
        // setIsValidated({ ...isValidated, email: true });
        // setValidateMsg({
        //   ...validateMsg,
        //   email: intl.formatMessage({
        //     id: "validateMsg.signup.disActivateUser",
        //     defaultMessage:
        //       "Please accept the signup invite sent to this address",
        //   }),
        // });
        if (res.result?.hasAccount === undefined || res.result?.hasAccount) {
          setIsValidated({ ...isValidated, email: true });
          setValidateMsg({
            ...validateMsg,
            email: intl.formatMessage({
              id: "validateMsg.signup.disActivateUser",
              defaultMessage:
                "Please accept the sign-up invitation sent to this email address.",
            }),
          });
        } else {
          setIsValidated({ ...isValidated, email: true });
          setValidateMsg({
            ...validateMsg,
            email: intl.formatMessage({
              id: "validateMsg.signup.accountNotFound",
              defaultMessage: "Organization does not exist.",
            }),
          });
        }
      } else {
        setIsValidated({ ...isValidated, email: true });
        setValidateMsg({
          ...validateMsg,
          email: intl.formatMessage({
            id: "validateMsg.login.checkEmail.noExist",
            defaultMessage: "The email address entered is not registered",
          }),
        });
      }
    },
    onError: () => {
      setIsValidated({ ...isValidated, email: true });
      setValidateMsg({
        ...validateMsg,
        email: intl.formatMessage({
          id: "validateMsg.signup.accountNotFound",
          defaultMessage: "Couldn't find your myDW organization",
        }),
      });
    },
  });

  const onClickNext = useCallback(() => {
    if (isEmpty(loginInfo.email)) {
      setIsValidated({ ...isValidated, email: true });
      setValidateMsg({
        ...validateMsg,
        email: intl.formatMessage({
          id: "validateMsg.signup.invalidEmail",
          defaultMessage: "Invalid email address",
        }),
      });
      return;
    }

    if (!checkEmailRegEx(loginInfo.email)) {
      setIsValidated({ ...isValidated, email: true });
      setValidateMsg({
        ...validateMsg,
        email: intl.formatMessage({
          id: "validateMsg.signup.invalidEmail",
          defaultMessage: "Invalid email address",
        }),
      });
      return;
    }
    if (rememberInfo.isRemember) {
      setEmailLocal(loginInfo.email.replace(/^\s+|\s+$/gm, ""));
      setRememberLocal(true);
    } else {
      setEmailLocal("");
      setRememberLocal(false);
    }
    setLoginInfo((info) => ({
      ...info,
      password: "",
    }));
    setIsValidated({ ...isValidated, password: false });
    setValidateMsg({
      ...validateMsg,
      password: "",
    });
    setEmailLocal(loginInfo.email);

    try {
      delete AxiosInstance.defaults.headers.common["Authorization"];
    } catch {
      console.error("cant not delete Authorization");
    }
    mutationCheckEmail.mutate({
      email: loginInfo.email.replace(/^\s+|\s+$/gm, ""),
    });
  }, [
    intl,
    isValidated,
    loginInfo,
    mutationCheckEmail,
    rememberInfo.isRemember,
    setEmailLocal,
    setRememberLocal,
    validateMsg,
  ]);

  // useLayoutEffect(() => {
  //   if (rememberLocal) {
  //     // setRememberInfo({
  //     //   email: emailLocal,
  //     //   isRemember: rememberLocal,
  //     // });
  //     setLoginInfo((info) => {
  //       return {
  //         ...info,
  //         email: emailLocal as string,
  //       };
  //     });
  //   } else {
  //     setLoginInfo((info) => {
  //       return {
  //         ...info,
  //         email: "",
  //       };
  //     });
  //   }
  // }, []);

  useEffect(() => {
    console.log(emailLocal);
  }, [emailLocal]);

  const onChangeRemember = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRememberInfo((rememberInfo) => {
        return {
          ...rememberInfo,
          isRemember: e.target.checked,
        };
      });
      setRememberLocal(e.target.checked);
    },
    []
  );

  const onChangeVerifyCode = useCallback(
    (value: string) => {
      console.log(value);
      setVerifyCode(value);
    },
    [verifyCode]
  );

  const mutationForgotEmail = useMutation(postForgotEmail, {
    onSuccess: () => {
      if (step === LOGIN_STEP.FORGOT_EMAIL_SENT) {
        notify(
          "info",
          intl.formatMessage({
            id: "label.login.forgotEmail.resend.sms",
            defaultMessage: "Email reminder was sent successfully.",
          }),
          "top"
        );
      } else {
        setStep(LOGIN_STEP.FORGOT_EMAIL_SENT);
      }
    },
    onError: () => {
      setIsValidated({ ...isValidated, tel: true });
      setValidateMsg({
        ...validateMsg,
        tel: intl.formatMessage({
          id: "validateMsg.signup.noExistPhone",
          defaultMessage: "Phone number entered not found in the system.",
        }),
      });
    },
  });

  const onClickForgotEmail = useCallback(() => {
    mutationForgotEmail.mutate({
      payload: {
        phoneNumber: phoneNumber,
      },
    });
    //setStep(LOGIN_STEP.FORGOT_EMAIL_SENT);
  }, [phoneNumber]);

  const onClickStepChange = useCallback((step: LOGIN_STEP) => {
    setStep(step);
  }, []);

  const onClickForgotPassword = useCallback(() => {
    mutationForgotPassword.mutate({
      payload: {
        email: loginInfo.email,
      },
    });
  }, [loginInfo]);

  const mutationForgotPassword = useMutation(postForgotPassword, {
    onSuccess: () => {
      setStep(LOGIN_STEP.FORGOT_PASSWORD);
    },
    onError: (err: CustomError) => {
      notify(
        "error",
        intl.formatMessage({
          id: "validateMsg.signup.noExistEmail",
          defaultMessage: "Email does not exist",
        })
      );
    },
  });

  const onClickCloudLogin = () => {
    const OATUH_HOST = "https://dwspectrum.digital-watchdog.com/authorize";
    const client_id = "mydw-api";
    const redirect_uri = process.env.REACT_APP_OAUTH_CALLBACK_URL;
    //const redirect_uri = "http://localhost:3000";
    const response_type = "code";

    const AUTHORIZE_URI = `${OATUH_HOST}?${qs.stringify({
      client_id,
      redirect_uri,
      response_type,
    })}`;

    window.location.href = AUTHORIZE_URI;
  };
  const onChangeTrustDevice = useCallback((checked: boolean) => {
    setTrustDevice(checked);
  }, []);

  const mutationResendCode = useMutation(postResendCode, {
    onSuccess: (res: any) => {
      if (res.error === 0) {
        setRetVerifyCode(res.result.verifiedNumber);
        setStep(LOGIN_STEP.INPUT_VERIFY_CODE);
      } else {
        setIsValidated({ ...isValidated, resend: true });
        setValidateMsg({
          ...validateMsg,
          resend: intl.formatMessage({
            id: "validateMsg.signup.noExistEmail",
            defaultMessage: "Email is not exist",
          }),
        });
      }
    },
    onError: () => {
      setIsValidated({ ...isValidated, resend: true });
      setValidateMsg({
        ...validateMsg,
        resend: intl.formatMessage({
          id: "validateMsg.signup.noExistEmail",
          defaultMessage: "Email is not exist",
        }),
      });
    },
  });

  const onClickVerifyResend = useCallback(
    (type: VERIFY_TYPE) => {
      setVerifyType(type);
      mutationResendCode.mutate({
        payload: {
          twoFactorType: type,
          email: loginInfo.email,
        },
      });
    },
    [loginInfo.email, mutationResendCode]
  );

  const onCode = async (code: string, params: URLSearchParams) => {
    if (code !== undefined && code !== "") {
      try {
        const device = getClientDevice();
        setCloudLoading(true);
        // code를 이용해서 token들을 받아옴
        sessionStorage.setItem("code", code);
        const resp = await AxiosInstance.post("/auth/token", {
          code,
          device,
        });

        if (resp.status === 404) {
          setIsValidated({ ...isValidated, email: true });
          setValidateMsg({
            ...validateMsg,
            email: intl.formatMessage({
              id: "validateMsg.signup.accountNotFound",
              defaultMessage: "Couldn't find your myDW organization",
            }),
          });
        } else if (200 > resp.status || 300 < resp.status) {
          setIsValidated({ ...isValidated, email: true });
          setValidateMsg({
            ...validateMsg,
            email: intl.formatMessage({
              id: "validateMsg.signup.loginIncorrect",
              defaultMessage: "Incorrect Login Credentials.",
            }),
          });
        } else {
          AxiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${resp.data.result.accessToken}`;
          setCookie({
            name: ACCESS_TOKEN,
            value: resp.data.result.accessToken,
            options: {
              path: "/",
              expires: getCookieExpireTime(6),
            },
          });
          setCookie({
            name: REFRESH_TOKEN,
            value: resp.data.result.refreshToken,
            options: {
              path: "/",
              expires: getCookieExpireTime(72),
              //secure : true,
              //httpOnly : true
            },
          });
          //sessionStorage.setItem("token", resp.data.token);
          setUserInfo(resp.data.result.email)
            .then(function (resolvedData) {
              const accountData = resolvedData as UserInfo;
              //BUG [RND-330] email link
              if (accountData !== undefined && accountData.isAccount !== 0) {
                if (
                  toSearchParam.size > 0 &&
                  toSearchParam.get("url") !== null
                ) {
                  auth.processLinkNavigate(
                    toSearchParam.get("url") as string,
                    toSearchParam,
                    accountData
                  );
                } else {
                  auth.loginNavigator("/", accountData);
                }
              } else {
                console.error("Account information bot found");
              }

              // dispatch(setMenu("Dashboard"));
              // navigate("/dashboard/map", { replace: true });
            })
            .catch(function (err) {
              setIsValidated({ ...isValidated, email: true });
              setValidateMsg({
                ...validateMsg,
                email: intl.formatMessage({
                  id: "validateMsg.signup.loginIncorrect",
                  defaultMessage: "Incorrect Login Credentials.",
                }),
              });
              //console.log('then error : ', err); // then error :  Error: Error in then()
            });
        }
      } catch (err) {
        setIsValidated({ ...isValidated, email: true });
        setValidateMsg({
          ...validateMsg,
          email: intl.formatMessage({
            id: "validateMsg.signup.loginIncorrect",
            defaultMessage: "Incorrect Login Credentials.",
          }),
        });
      } finally {
        setCloudLoading(false);
      }
    }
  };
  const onPopupClose = () => console.log("closed!");

  return (
    <LoginPresenter
      step={step}
      loginInfo={loginInfo}
      checkedRemember={rememberInfo.isRemember}
      verifyType={verifyType}
      verifyCode={verifyCode}
      isValidated={isValidated}
      validateMsg={validateMsg}
      onClickNext={onClickNext}
      onClickLogin={onClickLogin}
      onClickResend={onClickResend}
      onChangeLoginInfo={onChangeLoginInfo}
      onChangePhoneNumber={onChangePhoneNumber}
      onClickVerify={onClickVerify}
      onClickVerifyCancel={onClickVerifyCancel}
      onChangeRemember={onChangeRemember}
      onChangeVerifyCode={onChangeVerifyCode}
      onClickForgotEmail={onClickForgotEmail}
      onClickStepChange={onClickStepChange}
      onClickForgotPassword={onClickForgotPassword}
      onClickCloudLogin={onClickCloudLogin}
      onChangeTrustDevice={onChangeTrustDevice}
      trustDevice={trustDevice}
      onClickVerifyResend={onClickVerifyResend}
      cloudLoading={cloudLoading}
      onCode={onCode}
      onPopupClose={onPopupClose}
      isSpectrumMouseOver={isSpectrumMouseOver}
      onMouseOverSpectrumLogin={onMouseOverSpectrumLogin}
    />
  );
}
function displayError(err: CustomError, arg1: string): string {
  throw new Error("Function not implemented.");
}
