import PieChart from "components/atoms/chart/PieChart";
import { COLORS } from "styles/colors";
import { calcRem } from "styles/theme";
import { LegendProps } from "@nivo/legends";
import * as mediaQuery from "components/MediaQuery";
import { useMediaQuery } from "react-responsive";
import { ComputedDatum, DefaultRawDatum } from "@nivo/pie";
import { BasicTooltip } from "@nivo/tooltip";
import { useAppSelector } from "redux/hooks";

type Props = {
  map?: boolean;
  data: any[];
};

const changeTypeName = (type: string) => {};

export default function AlertPieChart(props: Props): JSX.Element {
  const theme = useAppSelector((state) => state.theme.theme);
  const isNotMobile = useMediaQuery({ minWidth: mediaQuery.tabletMin });

  const margin = props.map
    ? { top: 10, right: 110, bottom: 10, left: 0 }
    : { top: 20, right: 150, bottom: 20, left: 0 };

  const legends: LegendProps[] = props.map
    ? [
        {
          anchor: "top-right",
          direction: "column",
          justify: false,
          translateX: 80,
          translateY: 25,
          itemsSpacing: 6,
          itemWidth: 80,
          itemHeight: 18,
          itemTextColor: theme === "light" ? COLORS.BLACK : COLORS.WHITE,
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 8,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]
    : [
        {
          anchor: "top-right",
          direction: "column",
          justify: false,
          translateX: !isNotMobile ? 100 : 130,
          translateY: !isNotMobile ? 45 : 60,
          itemsSpacing: 9,
          itemWidth: !isNotMobile ? 100 : 200,
          itemHeight: 24,
          itemTextColor: theme === "light" ? COLORS.BLACK : COLORS.WHITE,
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 12,
          symbolShape: "circle",
          // effects: [
          //   {
          //     on: "hover",
          //     style: {
          //       itemTextColor: "#000",
          //     },
          //   },
          // ],
        },
      ];

  // hover 했을 때 tooltip
  const PieTooltip = <R,>({ datum }: { datum: ComputedDatum<R> }) => (
    <BasicTooltip
      id={datum.label}
      value={datum.formattedValue}
      enableChip={true}
      color={datum.color}
    />
  );

  return (
    <PieChart
      data={props.data}
      margin={margin}
      legends={legends}
      tooltip={PieTooltip}
      theme={
        props.map
          ? {
              legends: {
                text: {
                  fontSize: calcRem(12),
                },
              },
            }
          : {}
      }
    />
  );
}
