import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { COLORS, LIGHT_THEME_COLORS } from "./colors";
import { FLEX } from "./flex";
import * as mediaQuery from "components/MediaQuery";
import LoaderImage from "assets/images/dwloader.png";
import X from "assets/icons/x_map.svg";
// const defaultRadius = "4px";
// const defaultPadding = "10px";

export const GlobalStyle = createGlobalStyle`
    ${reset};
    /* HTML5 display-role reset for older browsers */
    /* font-size: 14px; */
    * {
        margin: 0;
        padding: 0;
        line-height: none;
    }
    html {

        font-size: 15px;
        font-weight: 300;
        ${mediaQuery.is4K} {
            font-size: 18px;
        }
        ${mediaQuery.isMobile} {
            font-size: 13px;
            height: -webkit-fill-available;
        }
    }

    body {
        box-sizing: border-box;
        background-color: ${(props) => props.theme.COLORS.BACKGROUND};

        font-family: "Roboto", sans-serif;

        ${mediaQuery.isMobile} {
            min-height: -webkit-fill-available;
        }
    }
    @supports (-webkit-touch-callout: none) {
     height: -webkit-fill-available;
  }

    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    ol, ul, li {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    /*
    */

    button {
        background: none;
        border: 0;
        cursor: pointer;
        min-width: max-content;

        &:focus {
            outline: none;
        }
    }

    span {
        font-weight: 300;
    }

    a {
        text-decoration: none;
        color: #000;

        &:active {
            color: none;
        }
    }

    strong {
        font-weight: 500;
    }


    img {
        border: none;
        /* object-fit: cover; */
    }
    
    textarea:focus, input:focus {
      outline: none;
    }

    label {
        ${FLEX.FlexStartCenter};
        gap: 8px;
    }

    /* IE */
    select::-ms-expand { 
        display: none;
    }
    .select {
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    }

    // input search x 버튼 초기화
    /* IE의 경우 */
    input::-ms-clear,input::-ms-reveal{
        opacity: 0;
        position: absolute;
        right: 0;}
    /* 크롬의 경우 */
    input::-webkit-search-decoration,
    input::-webkit-search-cancel-button,
    input::-webkit-search-results-button,
    input::-webkit-search-results-decoration{
        opacity: 0;
        position: absolute;
        right: 0;
    }

    input[type='checkbox'],
    input[type='checkbox']:checked,
    input[type='radio'],
    input[type='radio']:checked
     {
        appearance: none;
    }


    input[type='search'] > .search-clear-button {
        position: absolute;  // 위치는 clear button이 생기는곳에 놔주시면 됩니다. 
        top: 0; 
        right: 0; 
        width: 30px; 
        height: inherit; 
        background: ${X} center center no-repeat; 
        background-size: 50%; 
        border: none; 
        outline: none; 
        cursor: pointer;  //해당 버튼이 아닌 뒤의 clear 버튼이 클릭되게하기위해 해당 엘리먼트의  
        pointer-events: none; //클릭을 막아줍니다 
    }


    input[type='checkbox'] {
        background-color: ${COLORS.WHITE};
        width: 15px;
        height: 15px;
        border: 1px solid #ACACAC;
    }
    input[type='checkbox']:checked {
        background-color: ${({ theme }) => theme.COLORS.SELECTED};
        border: none;
        background: ${({ theme }) =>
          theme.COLORS.SELECTED} no-repeat center center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.607' height='11.639'%3E%3Cpath data-name='Icon awesome-check' d='M5.3 11.41.229 6.338a.78.78 0 0 1 0-1.1l1.1-1.1a.78.78 0 0 1 1.1 0l3.417 3.417L13.165.236a.78.78 0 0 1 1.1 0l1.1 1.1a.78.78 0 0 1 0 1.1L6.4 11.41a.78.78 0 0 1-1.1 0Z' fill='%23fff'/%3E%3C/svg%3E");
    }
    input[type='checkbox']:disabled {
        background-color: ${COLORS.BUTTONS1};
        border: none;
        border: 1px solid ${COLORS.BORDER};       
    }
    input[type='checkbox']:indeterminate {
        background-color: ${COLORS.GREEN};
        border: none;
        background: ${COLORS.GREEN} no-repeat center center;
        position: relative;
        // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='4' viewBox='0 0 12 4'%3E%3Cline id='선_289' data-name='선 289' x2='12' transform='translate(0 2)' fill='none' stroke='%23fff' stroke-width='4'/%3E%3C/svg%3E%0A"); 
    }
    /* input[type='checkbox']:indeterminate::before {
        content: "";
        display: block;
        color: white;
        width: 15px;
        height: 15px;
    } */
    input[type='checkbox']:indeterminate::after {
        content: "";
        display: block;
        width: 10px;
        /* height: 10px; */
        border: solid white;
        border-width: 2px 0 0 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    input[type='radio'] {
        background-color: ${COLORS.WHITE};
        width: 15px;
        height: 15px;
        border-radius: 50%;
        outline: 2px solid ${({ theme }) => theme.COLORS.SELECTED};
       
    }
    input[type='radio']:checked {
        background-color: ${({ theme }) => theme.COLORS.SELECTED};
        border: 2px solid ${COLORS.WHITE};
        padding: 2px;
        outline: 2px solid ${({ theme }) => theme.COLORS.SELECTED};
        box-sizing: border-box;
    }
    input[type='radio']:disabled {
        background-color: ${({ theme }) =>
          theme.value === "light"
            ? `${COLORS.BUTTONS1}10`
            : `${COLORS.WHITE}90`};
        border: 1px solid ${COLORS.BUTTONS1};
        padding: 2px;
        outline: 2px solid ${COLORS.BUTTONS1};
        box-sizing: border-box;
    }
    input[type='radio']:disabled:checked {
        background-color: ${COLORS.BUTTONS1};
        border: 2px solid ${COLORS.WHITE};
        padding: 2px;
        outline: 2px solid ${COLORS.BUTTONS1};
        box-sizing: border-box;
    }

 

    
.ant-input-number {
    background-color: ${COLORS.WHITE} !important;
    border-radius: 3px;

    input {
    background-color: ${COLORS.WHITE} !important;
    padding: 4px;

    }
}
.ant-popover .ant-popover-inner {
    padding: 0 !important;
    background-color: transparent;
  }
.ant-input-number-input-wrap {
    > input {
        background-color: ${COLORS.WHITE} !important;
    }
}
    body::-webkit-scrollbar {
        width: 10px;  /* 세로축 스크롤바 폭 너비 */
        height: 10px;  /* 가로축 스크롤바 폭 너비 */

    }
    body::-webkit-scrollbar-thumb {
        background: ${({ theme }) =>
          theme.value === "light"
            ? COLORS.LIGHTGRAY
            : "#545E77"}; //스크롤바의 색상
        
        border-radius: 7px;
    }

    body::-webkit-scrollbar-track {
        background: ${({ theme }) =>
          theme.value === "light"
            ? LIGHT_THEME_COLORS.LIGHTGRAY
            : "#273457"}; //스크롤바의 색상

    }

    ::-webkit-scrollbar {
        width: 5px; // 스크롤바의 너비
        height: 8px;  /* 가로축 스크롤바 폭 너비 */
    }
    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) =>
          theme.value === "light"
            ? COLORS.LIGHTGRAY
            : "#545E77"}; //스크롤바의 색상
        border-radius: 7px;
    }
    ::-webkit-scrollbar-track {
        background: ${({ theme }) =>
          theme.value === "light"
            ? LIGHT_THEME_COLORS.LIGHTGRAY
            : "#273457"}; //스크롤바의 색상
    }



    .progress-indicator {
        min-height: 12px;
        min-width: 12px;
    }
    
    .progress-indicator:before {
        animation: progressSpin .8s linear infinite;
        background-image: url(${LoaderImage});
        background-size: contain;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%
    }
    
    .progress-indicator.progress-indicator--responsive {
        height: 48px;
        width: 48px
    }
    
    @media(min-width: 480px) {
        .progress-indicator.progress-indicator--responsive {
            height:72px;
            width: 72px
        }
    }
    
    .progress-indicator.progress-indicator--size-12 {
        height: 12px;
        width: 12px
    }
    
    .progress-indicator.progress-indicator--size-24 {
        height: 24px;
        width: 24px
    }
    
    .progress-indicator.progress-indicator--size-36 {
        height: 36px;
        width: 36px
    }
    
    .progress-indicator.progress-indicator--size-48 {
        height: 48px;
        width: 48px
    }
    
    .progress-indicator.progress-indicator--size-72 {
        height: 72px;
        width: 72px
    }
    
    .progress-indicator.progress-indicator--size-100 {
        height: 100px;
        width: 100px
    }
    
    .progress-indicator.progress-indicator--size-150 {
        height: 150px;
        width: 150px
    }
    
    .progress-indicator.progress-indicator--size-200 {
        height: 200px;
        width: 200px
    }
    
    @keyframes progressSpin {
        to {
            transform: rotate(1turn)
        }
    }
`;
