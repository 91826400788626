import styled from "styled-components";
import { COLORS } from "styles/colors";
import { FLEX } from "styles/flex";

export const Row = styled.div<{ isAdmin?: boolean }>`
  ${FLEX.FlexStartCenter};
  gap: 6px;
  ${({ isAdmin, theme }) =>
    isAdmin &&
    `
    color: ${theme.COLORS.SELECTED}
  `}
`;

interface Props {
  isDeleted?: boolean;
}

export const Text = styled.div<Props>`
  color: ${(props) =>
    props.isDeleted ? props.theme.COLORS.BUTTONS1 : props.theme.COLORS.WHITE};
`;
// export const Icon = styled.div`
//   width: 20px;
//   img {
//     width: 100%;
//   }
// `;
