// import styled from "styled-components";
import DataTable, {
  Media,
  TableColumn,
  createTheme,
  SortOrder,
  TableProps,
} from "react-data-table-component";
import { COLORS, LIGHT_THEME_COLORS } from "styles/colors";
import { ReactComponent as LIST_SORT_DESC_ICON } from "assets/icons/list_arrow_down.svg";
import Pagination from "components/blocks/pagination";
import { calcRem } from "styles/theme";
import styled from "styled-components";
import { FLEX } from "styles/flex";
import { useAppSelector } from "redux/hooks";
import * as mediaQuery from "components/MediaQuery";
import { useLocation } from "react-router-dom";
import NoData from "components/templates/nopage/NoData";
import Spinner from "../loader/Spinner";
import PaginationComponent from "components/blocks/pagination";

type Props = TableProps<any> & {
  // columns: TableColumn<any>[];
  // data: Array<any>;
  // onRowClicked?: (row: any, event: any) => void;
  // onSelectedRowsChange?: (selected: {
  //   allSelected: boolean;
  //   selectedCount: number;
  //   selectedRows: any[];
  // }) => void;
  // onChangePage?: (page: number, totalRows: number) => void;
  // paginationPerPage?: number;
  // selectableRowsSingle?: boolean;
  // disabled?: boolean;
  isRowClickEditable?: boolean;
  handleSort?: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder
  ) => void;
  selectedPage?: number;
  // expandableRowsComponent?: any;
};

createTheme("dark", {
  text: {
    primary: COLORS.WHITE,
  },
  background: {
    default: "transparent",
  },
  context: {
    background: "#cb4b16",
    text: COLORS.WHITE,
  },
  divider: {
    default: COLORS.BORDER,
  },
  button: {
    default: COLORS.BUTTONS1,
    hover: "rgba(0,0,0,.08)",
    focus: "rgba(255,255,255,.12)",
    disabled: "rgba(255, 255, 255, .34)",
  },
  sortFocus: {
    default: "#2aa198",
  },
});
createTheme("light", {
  text: {
    primary: COLORS.BLACK,
  },
  background: {
    default: "transparent",
  },
  context: {
    background: "#cb4b16",
    text: COLORS.WHITE,
  },
  divider: {
    default: LIGHT_THEME_COLORS.LIGHTGRAYBORDER,
  },
  button: {
    default: COLORS.BUTTONS1,
    hover: "rgba(0,0,0,.08)",
    focus: "rgba(255,255,255,.12)",
    disabled: "rgba(255, 255, 255, .34)",
  },
  sortFocus: {
    default: "#2aa198",
  },
});
createTheme(
  "mydw-dark-modal",
  {
    text: {
      primary: COLORS.WHITE,
    },
    background: {
      default: "transparent",
    },
    context: {
      background: "#cb4b16",
      text: COLORS.WHITE,
    },
    divider: {
      default: "transparent",
    },
    button: {
      default: COLORS.BUTTONS1,
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "#2aa198",
    },
  },
  "dark"
);
createTheme(
  "mydw-light-modal",
  {
    text: {
      primary: COLORS.BLACK,
    },
    background: {
      default: "transparent",
    },
    context: {
      background: "#cb4b16",
      text: COLORS.BLACK,
    },
    divider: {
      default: "transparent",
    },
    button: {
      default: LIGHT_THEME_COLORS.BUTTONS1,
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "#2aa198",
    },
  },
  "light"
);

// column 정렬 아이콘
const sortIcon = (
  <span className="custom-sort-icon">
    <LIST_SORT_DESC_ICON />
  </span>
);

//  Internally, customStyles will deep merges your customStyles with the default styling.
const customStyles = (theme: string) => {
  const isLightTheme = theme === "light";
  return {
    rows: {
      style: {
        minHeight: "60px", // override the row height
        // minHeight: Media.SM ? "40px" : "60px", // override the row height
        // fontSize: 14,
        fontSize: calcRem(14),
        "&:hover": {
          backgroundColor: isLightTheme
            ? `${COLORS.BLACK}04 !important`
            : `${COLORS.WHITE}08 !important`,
        },
        padding: "6px 0",
      },
    },
    headCells: {
      style: {
        minHeight: "55px", // override the row height
        // minHeight: Media.SM ? "45px" : "65px", // override the row height
        backgroundColor: isLightTheme
          ? LIGHT_THEME_COLORS.LIGHTGRAY
          : COLORS.PRIMARY3,
        fontWeight: "500",
        fontSize: Media.SM ? calcRem(16) : calcRem(18),
        color: isLightTheme ? COLORS.BLACK : COLORS.WHITE,
      },
    },
    head: {
      style: {
        borderRadius: "3px",
        // overflow: "hidden",
      },
    },
    headRow: {
      style: {
        border: "none",
      },
    },
  };
};
//BUG [RND-419] header zIndex 0 problem
const customModalStyles = (theme: string) => {
  const isLightTheme = theme === "light";
  return {
    rows: {
      style: {
        minHeight: "16px", // override the row height
        fontSize: calcRem(12),
        padding: "3px 0",
        color: isLightTheme ? COLORS.BLACK : COLORS.WHITE,
      },
    },
    head: {
      style: {
        zIndex: 10,
      },
    },
    headCells: {
      style: {
        minHeight: "35px", // override the row height
        maxHeight: "30px",
        backgroundColor: isLightTheme
          ? LIGHT_THEME_COLORS.BORDER
          : COLORS.PRIMARY3,
        color: isLightTheme ? `${COLORS.BLACK}99` : COLORS.WHITE,
        fontWeight: "500",
        fontSize: calcRem(13),
      },
    },
    headRow: {
      style: {
        minHeight: "35px",
      },
    },
  };
};

const customReportStyles = (theme: string) => {
  const isLightTheme = theme === "light";

  return {
    // table: {
    //   style: {
    //     display: "block",
    //   },
    // },
    rows: {
      style: {
        // minHeight: "50px", // override the row height
        fontSize: calcRem(14),
        "&:hover": {
          backgroundColor: isLightTheme
            ? `${COLORS.BLACK}20 !important`
            : `${COLORS.WHITE}08 !important`,
          color: isLightTheme ? COLORS.BLACK : COLORS.WHITE,
        },
        color: isLightTheme ? COLORS.BLACK : COLORS.WHITE,
        padding: "6px 0",
      },
    },

    headCells: {
      style: {
        height: "40px", // override the row height
        minHeight: "40px !important", // override the row height
        backgroundColor: isLightTheme
          ? LIGHT_THEME_COLORS.BORDER
          : COLORS.PRIMARY3,
        fontWeight: "500",
        fontSize: calcRem(14),
        color: isLightTheme ? COLORS.BORDER : COLORS.WHITE,
      },
    },
    headRow: {
      style: {
        minHeight: "40px", // override the row height
        border: "none",
      },
    },
  };
};

const customDashboardStyles = (theme: string) => {
  const isLightTheme = theme === "light";

  return {
    table: {
      style: {
        height: "100%",
      },
    },
    rows: {
      style: {
        // height: "50px", // override the row height
        fontSize: calcRem(14),
        "&:hover": {
          backgroundColor: isLightTheme
            ? `${COLORS.BLACK}08 !important`
            : `${COLORS.WHITE}08 !important`,
          color: isLightTheme ? COLORS.BLACK : COLORS.WHITE,
        },
        color: isLightTheme ? COLORS.BLACK : COLORS.WHITE,
      },
    },
    headCells: {
      style: {
        height: "40px", // override the row height
        minHeight: "40px !important", // override the row height
        backgroundColor: isLightTheme
          ? LIGHT_THEME_COLORS.BORDER
          : COLORS.PRIMARY3,
        fontWeight: "500",
        fontSize: calcRem(14),
        color: isLightTheme ? COLORS.BORDER : COLORS.WHITE,
      },
    },
    headRow: {
      style: {
        border: "none",
        minHeight: "40px",
        maxHeight: "40px",
      },
    },
  };
};
const paginationComponentOptions = {
  noRowsPerPage: true,
  selectAllRowsItem: true,
  //   rangeSeparatorText: "",
};

export function Table(props: Props): JSX.Element {
  const location = useLocation();
  const theme = useAppSelector((state) => state.theme.theme);
  const noMinHeight = location.pathname.includes("/videoShare");

  return (
    <Container className="table-container" noMinHeight={noMinHeight}>
      <DataTable
        {...props}
        theme={theme}
        persistTableHead
        //pagination
        paginationServer
        // fixedHeader={true}
        // fixedHeaderScrollHeight="calc(100vh - 150px)"
        // sortIcon={sortIcon}
        onSort={props.handleSort}
        sortServer
        customStyles={customStyles(theme)}
        paginationComponent={undefined}
        paginationPerPage={
          props.paginationPerPage ? props.paginationPerPage : 20
        }
        highlightOnHover
        pointerOnHover={
          props.isRowClickEditable !== undefined
            ? props.isRowClickEditable
            : false
        }
        noDataComponent={
          <NoDataContainer>
            <NoData />
          </NoDataContainer>
        }
        responsive={true}
        progressComponent={
          <Spinner
            size={18}
            cssOverride={{
              marginTop: 20,
            }}
          />
        }
      />
      <div className="pagination-wrapper">
        <PaginationComponent
          rowsPerPage={props.paginationPerPage ? props.paginationPerPage : 20}
          rowCount={props.paginationTotalRows ? props.paginationTotalRows : 1}
          onChangePage={(page: number, totalRows: number) => {
            if (props.onChangePage) {
              props.onChangePage(page, totalRows);
            }
          }}
          currentPage={props.selectedPage ? props.selectedPage : 0}
          onChangeRowsPerPage={() => {
            return false;
          }}
        />
      </div>
    </Container>
  );
}

export function NoPageTable(props: Props): JSX.Element {
  const location = useLocation();
  const theme = useAppSelector((state) => state.theme.theme);
  const noMinHeight = location.pathname.includes("/videoShare");

  return (
    <Container className="table-container" noMinHeight={noMinHeight}>
      <DataTable
        {...props}
        theme={theme}
        persistTableHead
        paginationServer
        // fixedHeader={true}
        // fixedHeaderScrollHeight="calc(100vh - 150px)"
        // sortIcon={sortIcon}
        onSort={props.handleSort}
        sortServer
        customStyles={customStyles(theme)}
        paginationComponent={Pagination}
        highlightOnHover
        pointerOnHover={
          props.isRowClickEditable !== undefined
            ? props.isRowClickEditable
            : false
        }
        noDataComponent={
          <NoDataContainer>
            <NoData />
          </NoDataContainer>
        }
        responsive={true}
      />
    </Container>
  );
}

export function AlertViewTable(props: Props): JSX.Element {
  const theme = useAppSelector((state) => state.theme.theme);
  return (
    <AlertContainer>
      <DataTable
        {...props}
        theme={`mydw-${theme}-modal`}
        persistTableHead
        fixedHeader={true}
        fixedHeaderScrollHeight="250px"
        sortIcon={sortIcon}
        customStyles={customModalStyles(theme)}
        expandableRows
        expandableRowsHideExpander
      />
    </AlertContainer>
  );
}

export function ReportTable(props: Props): JSX.Element {
  const theme = useAppSelector((state) => state.theme.theme);
  return (
    <ReportContainer className="table-container">
      <DataTable
        {...props}
        theme={theme}
        persistTableHead
        //pagination
        sortIcon={sortIcon}
        customStyles={customReportStyles(theme)}
        paginationComponentOptions={paginationComponentOptions}
        paginationComponent={Pagination}
        // paginationPerPage={20} // report table 은 3개가 최대
        highlightOnHover
        conditionalRowStyles={[
          {
            when: (row) =>
              (row.isValid !== undefined && row.isValid === "false") ||
              row.status === "Expired",
            style: {
              color: "#44546F",
            },
          },
        ]}
        pointerOnHover
        // fixedHeader={true}
      />
      <PaginationComponent
        rowsPerPage={props.paginationPerPage ? props.paginationPerPage : 20}
        rowCount={props.paginationTotalRows ? props.paginationTotalRows : 1}
        onChangePage={(page: number, totalRows: number) => {
          if (props.onChangePage) {
            props.onChangePage(page, totalRows);
          }
        }}
        currentPage={props.selectedPage ? props.selectedPage : 0}
        onChangeRowsPerPage={() => {
          return false;
        }}
      />
    </ReportContainer>
  );
}

export function InstallReportTable(props: Props): JSX.Element {
  const theme = useAppSelector((state) => state.theme.theme);
  return (
    <ReportContainer>
      <DataTable
        {...props}
        theme={theme}
        persistTableHead
        paginationServer
        onSort={props.handleSort}
        sortServer
        //pagination
        sortIcon={sortIcon}
        customStyles={customReportStyles(theme)}
        paginationComponentOptions={paginationComponentOptions}
        paginationComponent={Pagination}
        // paginationPerPage={20} // report table 은 3개가 최대
        highlightOnHover
        conditionalRowStyles={[
          {
            when: (row) =>
              (row.isValid !== undefined && row.isValid === "false") ||
              row.status === "Expired",
            style: {
              color: "#44546F",
            },
          },
        ]}
        pointerOnHover
        // fixedHeader={true}
      />
      <PaginationComponent
        rowsPerPage={props.paginationPerPage ? props.paginationPerPage : 20}
        rowCount={props.paginationTotalRows ? props.paginationTotalRows : 1}
        onChangePage={(page: number, totalRows: number) => {
          if (props.onChangePage) {
            props.onChangePage(page, totalRows);
          }
        }}
        currentPage={props.selectedPage ? props.selectedPage : 0}
        onChangeRowsPerPage={() => {
          return false;
        }}
      />
    </ReportContainer>
  );
}

export function DashboardTable(props: Props): JSX.Element {
  const theme = useAppSelector((state) => state.theme.theme);
  return (
    <DashboardContainer className="table-container">
      <DataTable
        {...props}
        theme={theme}
        persistTableHead
        //pagination
        sortIcon={sortIcon}
        customStyles={customDashboardStyles(theme)}
        paginationComponentOptions={paginationComponentOptions}
        paginationComponent={Pagination}
        highlightOnHover
        pointerOnHover
        fixedHeader={true}
      />
      <PaginationComponent
        rowsPerPage={props.paginationPerPage ? props.paginationPerPage : 20}
        rowCount={props.paginationTotalRows ? props.paginationTotalRows : 1}
        onChangePage={(page: number, totalRows: number) => {
          if (props.onChangePage) {
            props.onChangePage(page, totalRows);
          }
        }}
        currentPage={props.selectedPage ? props.selectedPage : 0}
        onChangeRowsPerPage={() => {
          return false;
        }}
      />
    </DashboardContainer>
  );
}
const Container = styled.div<{ noMinHeight?: boolean }>`
  ${FLEX.FlexBetweenCenter};
  flex-direction: column;
  width: 100%;
  max-width: 100%;

  ${({ noMinHeight }) =>
    noMinHeight ? `min-height: 300px;` : `min-height: calc(100% - 110px)`};

  span.__rdt_custom_sort_icon__.asc svg {
    -webkit-transform: scaleY(-1) !important;
    -ms-transform: scaleY(-1) !important;
    transform: scaleY(-1) !important;
  }

  > div {
    width: 100%;

    ${mediaQuery.isDefault} {
      overflow: unset;

      > div {
        display: unset;
      }
    }
    ${mediaQuery.isMobile} {
      flex: 1;
    }
  }

  .rdt_TableRow {
    &:hover {
      .row-click-button > button {
        filter: brightness(140%) saturate(130%) !important;
      }
    }
  }

  .custom-sort-icon {
    ${FLEX.FlexCenterCenter};

    svg {
      height: 12px !important;
      color: ${({ theme }) => theme.COLORS.WHITE};
    }
  }

  .pagination-wrapper {
    flex: 0 0 auto;
  }
`;

const AlertContainer = styled.div`
  width: 100%;
  /* min-height: 250px;
  max-height: 250px;
  width: 100%;

  > div {
    height: 100%;
    max-height: 100%;
  } */
`;

const ReportContainer = styled(Container)`
  width: 100%;
  max-width: 100%;
  /* min-height: 100%; */

  ${mediaQuery.isDefault} {
    padding-top: 15px;

    > div > div {
      display: block;
    }
  }

  ${mediaQuery.isMobile} {
    .pagination-component {
      flex: 0 0 auto;
    }
  }
`;

const NoDataContainer = styled.div`
  padding-top: 50px;
`;

const DashboardContainer = styled.div`
  ${FLEX.FlexBetweenCenter};
  flex-direction: column;
  width: 100%;
  max-width: 100%;

  span.__rdt_custom_sort_icon__.asc svg {
    -webkit-transform: scaleY(-1) !important;
    -ms-transform: scaleY(-1) !important;
    transform: scaleY(-1) !important;
  }

  .rdt_TableRow {
    &:hover {
      .row-click-button > button {
        filter: brightness(140%) saturate(130%) !important;
      }
    }
  }

  .custom-sort-icon {
    ${FLEX.FlexCenterCenter};

    svg {
      height: 12px !important;
      color: ${({ theme }) => theme.COLORS.WHITE};
    }
  }
`;
