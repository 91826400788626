import * as s from "styles/ModalCommonStyled";
import BigPopupTemplate from "components/templates/modal/big/BigPopupTemplate";
import { ModalInput } from "components/atoms/input/modal/ModalInput";
import Input from "components/atoms/input/Input";
import Selects from "components/atoms/select/Select";
import BigButton from "components/atoms/buttons/BigButton";
import Toggle from "components/atoms/toggle/Toggle";
import * as option from "utils/options";
import { CreateAccountRequest } from "api/interfaces/accountInterface.interface";
import {
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { ValidateWord } from "components/atoms/text/Text";
import { Fragment } from "react";
import Spinner from "components/atoms/loader/Spinner";
import AdditionalAccountIdModalInput from "components/atoms/input/modal/AdditionalAccountIdModalInput";

export enum UPDATE_TYPE {
  NAME,
  LEVEL,
  TIME_ZONE,
  DATE_FORMAT,
  TIME_FORMAT,
  ACCOUNT_NUMBER,
  ADDITIONAL_ACCOUNT_NUMBER,
  PRICING,
  INVITE_NAME,
  INVITE_EMAIL,
  INVITE_PHONE_NUMBER,
  TWO_FACTOR_AUTH,
}

interface Props {
  isToggled: boolean;
  onChange: () => void;
  onModal: (type?: string) => void;
  isFlagSelected: string;
  accountNumber: string;
  accountNumberPrefix: string;
  onSelectFlag: (code: string) => void;
  onClickCreate: () => void;
  onChageAccountInfo: (
    type: UPDATE_TYPE,
    value: string | number | boolean
  ) => void;
  accountValidate: Validate;
  accountValidateMsg: ValidateMsg;
  parentLevel: string;
  currentLevel: string;
  isCreationLoading: boolean;
}

// 어디까지가 프리젠터 패턴인가!
// 여기선 개별로! 위에선 합쳐서!

export function AddModalPresenter(props: Props): JSX.Element {
  const { onChageAccountInfo, onClickCreate } = props;

  return (
    <BigPopupTemplate title="Add Organization" onModal={props.onModal}>
      <s.AddWrapper>
        {props.isCreationLoading && (
          <s.LoadingWrapper>
            <Spinner />
          </s.LoadingWrapper>
        )}

        <s.Row>
          <ModalInput label="Name" essential flex={3}>
            <Input
              id="dw_account_input_account_name"
              placeholder="Enter a name for this organization"
              autoFocus
              onChange={(e) => {
                onChageAccountInfo(UPDATE_TYPE.NAME, e.target.value);
              }}
              maxLength={100}
              isValidated={props.accountValidate.isAccountName}
              autoComplete="one-time-code"
            />
            <ValidateWord>
              {props.accountValidateMsg.isAccountName}
            </ValidateWord>
          </ModalInput>
          <ModalInput label="Level" essential flex={1}>
            <Selects
              id="dw_account_input_account_level"
              defaultValue={
                props.parentLevel !== undefined &&
                props.parentLevel === "CP" &&
                props.currentLevel === "CP"
                  ? option.accountLevelOptionsSubCp[0]
                  : option.accountLevelOptions[0]
              }
              options={
                props.parentLevel !== undefined &&
                props.parentLevel === "CP" &&
                props.currentLevel === "CP"
                  ? option.accountLevelOptionsSubCp
                  : option.accountLevelOptions
              }
              onChange={(value: string) => {
                onChageAccountInfo(UPDATE_TYPE.LEVEL, value);
              }}
              isDisabled={props.currentLevel === "DW"}
            />
            <ValidateWord>
              {props.accountValidateMsg.isAccountLevel}
            </ValidateWord>
          </ModalInput>
        </s.Row>
        <ModalInput label="Timezone" flex={1}>
          <Selects
            id="dw_account_input_account_timezone"
            placeholder="Select Timezone"
            defaultValue={option.timezoneOptions.find(
              (option) =>
                option.value ===
                Intl.DateTimeFormat().resolvedOptions().timeZone
            )}
            options={option.timezoneOptions}
            onChange={(value) =>
              onChageAccountInfo(UPDATE_TYPE.TIME_ZONE, value)
            }
          />
        </ModalInput>
        <s.Row>
          <ModalInput label="Date Format" flex={1}>
            <Selects
              id="dw_account_input_account_dateformat"
              placeholder="Select Date Format"
              defaultValue={option.dateFormatOptions[2]}
              options={option.dateFormatOptions}
              onChange={(value) =>
                onChageAccountInfo(UPDATE_TYPE.DATE_FORMAT, value)
              }
            />
          </ModalInput>
          <ModalInput label="Time Format" flex={1}>
            <Selects
              id="dw_account_input_account_timeformat"
              placeholder="Select Time Format"
              defaultValue={option.timeFormatOptions[1]}
              options={option.timeFormatOptions}
              onChange={(value) =>
                onChageAccountInfo(UPDATE_TYPE.TIME_FORMAT, value)
              }
            />
          </ModalInput>
        </s.Row>
        <s.Row>
          <ModalInput label="Organization ID" flex={1}>
            {" "}
            <Input
              id="dw_account_input_account_accountNumber"
              disabled
              placeholder=""
              value={props.accountNumberPrefix + props.accountNumber}
              onChange={(e) =>
                onChageAccountInfo(UPDATE_TYPE.ACCOUNT_NUMBER, e.target.value)
              }
            />
          </ModalInput>
          <AdditionalAccountIdModalInput flex={3}>
            <Input
              id="dw_account_input_account_accountNumberAdditional"
              placeholder="Enter an additional organization number"
              maxLength={100}
              onChange={(e) =>
                onChageAccountInfo(
                  UPDATE_TYPE.ADDITIONAL_ACCOUNT_NUMBER,
                  e.target.value
                )
              }
            />
          </AdditionalAccountIdModalInput>
        </s.Row>

        {/* 
          Cp Level Only
          props 로 level 받아와 cp 인 경우 활성화
        
        */}
        {/* {level === "cp" && ( */}
        <s.Row>
          {/* <ModalInput label="Pricing (CP Level Only)" half>
            <Selects
              id="dw_account_input_account_price"
              value={option.pricingOptions[1]}
              options={option.pricingOptions}
              onChange={(value) =>
                onChageAccountInfo(UPDATE_TYPE.PRICING, value)
              }
              isDisabled
            />
          </ModalInput> */}
          <ModalInput label="Two-Factor Authentication (2FA)" half>
            <Toggle
              id="dw_account_input_2fa"
              isToggled={props.isToggled}
              onChange={(value) =>
                onChageAccountInfo(UPDATE_TYPE.TWO_FACTOR_AUTH, value)
              }
            />
          </ModalInput>
        </s.Row>
        {/* )} */}

        <s.OptionContainer>
          <s.OptionTitleContainer>
            Invite a new user to this organization (Optional)
          </s.OptionTitleContainer>
          <s.Row>
            <div style={{ display: "none" }}>
              <ModalInput label="Name" half>
                <Input
                  id="dw_account_input_invite_name"
                  placeholder="Enter a name for this user"
                  onChange={(e) =>
                    onChageAccountInfo(UPDATE_TYPE.INVITE_NAME, e.target.value)
                  }
                  isValidated={props.accountValidate.isUserName}
                  maxLength={100}
                  autoComplete="one-time-code"
                />
                <ValidateWord>
                  {props.accountValidateMsg.isUserName}
                </ValidateWord>
              </ModalInput>
            </div>
            <ModalInput label="Email" half>
              <Input
                id="dw_account_input_invite_email"
                placeholder="Enter a email for this user"
                onChange={(e) =>
                  onChageAccountInfo(UPDATE_TYPE.INVITE_EMAIL, e.target.value)
                }
                isValidated={props.accountValidate.isUserEmail}
                maxLength={100}
                autoComplete="one-time-code"
              />
              <ValidateWord>
                {props.accountValidateMsg.isUserEmail}
              </ValidateWord>
            </ModalInput>
          </s.Row>
        </s.OptionContainer>
        {/* <s.ToggleContainer>
         Two-Factor Authentication (2FA)
          <Toggle
            id="dw_account_input_2fa"
            isToggled={props.isToggled}
            onChange={props.onChange}
          />
        </s.ToggleContainer> */}
        <s.ButtonWrapper>
          <BigButton
            id="dw_account_button_create"
            label="Create"
            delay
            onClickBtn={onClickCreate}
          />
        </s.ButtonWrapper>
      </s.AddWrapper>
    </BigPopupTemplate>
  );
}
