import { AxiosResponse } from "axios";
import qs from "qs";
import { AxiosInstance } from "./axiosInstance";
import {
  CreateAccountRequest,
  GetAccountListQuery,
  GetAccountListResponse,
  UpdateAccountRequest,
  getAccoountNumberResponse,
  CUDAccountResponse,
  getAccoountNumberRequest,
} from "./interfaces/accountInterface.interface";
import { Account, getAccountListMappger } from "./mappers/accountMapper";
import { GET_ACCOUNT_LIST } from "./mocks/accountMock";
import { CustomError } from "./interfaces/commonInterface.interface";

const API_PATH = "/account";

export const getAccoountList = async ({
  payload,
  accountId,
  isSelf,
}: {
  payload: GetAccountListQuery;
  accountId: string;
  isSelf: boolean;
}): Promise<GetAccountListResponse> => {
  try {
    /**
     * @todo API 정상동작 후 제거
     */
    // if (process.env.NODE_ENV === "development") {
    //   return getAccountListMappger(GET_ACCOUNT_LIST.data as any); // @todo delete
    // }
    // return getAccountListMappger(GET_ACCOUNT_LIST.data as any); // @todo delete
    const { data }: AxiosResponse<GetAccountListResponse> =
      await AxiosInstance.get(
        `${API_PATH}/list/${accountId}/${isSelf}?${qs.stringify(payload)}`
      );
    return data;
    //return getAccountListMappger(data.result);
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const getSwitchAccountList = async ({
  payload
}: {
  payload: GetAccountListQuery;
}): Promise<GetAccountListResponse> => {
  try {
    const { data }: AxiosResponse<GetAccountListResponse> =
      await AxiosInstance.get(
        `${API_PATH}/switch/list?${qs.stringify(payload)}`
      );
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getAccoountTotalList = async ({
  accountId,
  userId,
}: {
  accountId: string;
  userId: string | undefined;
}): Promise<GetAccountListResponse> => {
  try {
    /**
     * @todo API 정상동작 후 제거
     */
    // if (process.env.NODE_ENV === "development") {
    //   return getAccountListMappger(GET_ACCOUNT_LIST.data as any); // @todo delete
    // }
    // return getAccountListMappger(GET_ACCOUNT_LIST.data as any); // @todo delete
    const { data }: AxiosResponse<GetAccountListResponse> = userId !== undefined ? 
      await AxiosInstance.get(
        `${API_PATH}/totalList/${accountId}/${userId}`
      ) : 
      await AxiosInstance.get(
        `${API_PATH}/totalList/${accountId}`
      );
    return data;
    //return getAccountListMappger(data.result);
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const putAccountUpdate = async ({
  form,
  accountId,
}: {
  form: FormData;
  accountId: string;
}): Promise<CUDAccountResponse> => {
  try {
    const { data }: AxiosResponse<CUDAccountResponse> = await AxiosInstance.put(
      `${API_PATH}/update/${accountId}`,
      form
    );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const deleteAccount = async (
  accountId: string
): Promise<CUDAccountResponse> => {
  try {
    const { data }: AxiosResponse<CUDAccountResponse> =
      await AxiosInstance.delete(`${API_PATH}/delete/${accountId}`);
    return data;
  } catch (e ) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const postAccountCreate = async ({
  payload,
  accountId,
}: {
  payload: CreateAccountRequest;
  accountId: string;
}): Promise<CUDAccountResponse> => {
  try {
    const { data }: AxiosResponse<CUDAccountResponse> =
      await AxiosInstance.post(`${API_PATH}/create/${accountId}`, payload);
    return data;
  } catch (e) {
    const customErr = e as CustomError;
    throw customErr;
  }
};

export const getAccoountNumber = async ({
  payload,
  accountId,
}: {
  payload: getAccoountNumberRequest;
  accountId: string;
}): Promise<getAccoountNumberResponse> => {
  try {
    const { data }: AxiosResponse<getAccoountNumberResponse> =
      await AxiosInstance.post(
        `${API_PATH}/accountNumber/${accountId}`,
        payload
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};

export const postUpdateSuspension = async ({
  suspensionStatus,
  accountId,
}: {
  suspensionStatus: boolean;
  accountId: string;
}): Promise<CUDAccountResponse> => {
  try {
    const { data }: AxiosResponse<CUDAccountResponse> =
      await AxiosInstance.post(
        `${API_PATH}/update/suspension/${accountId}/${suspensionStatus}`
      );
    return data;
  } catch (e) {
    console.error(e);
    throw new Error();
  }
};
