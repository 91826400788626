import {
  CreateAccountRequest,
  getAccoountNumberRequest,
  LEVEL_TYPE,
  ProfileAccountInfo,
} from "api/interfaces/accountInterface.interface";
import { getAccoountNumber } from "api/accountAPI";
import { useCallback, useEffect, useState } from "react";
import { AddModalPresenter, UPDATE_TYPE } from "./AddModalPresenter";
import { useMutation } from "react-query";
import * as option from "utils/options";
import { useAppSelector } from "redux/hooks";
import { notify } from "components/atoms/notification/Notification";
import {
  Validate,
  ValidateMsg,
} from "api/interfaces/commonInterface.interface";
import { useIntl } from "react-intl";

interface Props {
  onModal: () => void;
  onClickCreate: (accountInfo: CreateAccountRequest) => void;
  accountValidate?: Validate;
  accountValidateMsg?: ValidateMsg;
  isCreateLoading: boolean;
}

const numberPrefix = (level: LEVEL_TYPE, parentLevel: string) => {
  let createLevel = "";

  switch (level) {
    case LEVEL_TYPE.DW:
      createLevel = "C";
      break;
    case LEVEL_TYPE.CP:
      parentLevel === LEVEL_TYPE.DW ? (createLevel = "S") : (createLevel = "E");
      break;
    default:
      break;
  }

  return createLevel;
};

export function AddModalContainer(props: Props): JSX.Element {
  const intl = useIntl();
  const currentUser: ProfileAccountInfo = useAppSelector(
    (state) => state.accountSettings
  );
  // 토글 상태
  const [isToggled, setIsToggled] = useState(true);
  // 나라 선택
  const [isFlagSelected, setIsFlagSelected] = useState("US");

  const [accountNumberPrefix, setAccountNumberPrefix] = useState<string>(
    numberPrefix(currentUser.accountLevel, currentUser.parentLevel)
  );
  const [accountNumber, setAccountNumber] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // 토글 onchange 함수
  const onChange = () => {
    setIsToggled(!isToggled);
  };

  // 나라 선택 함수
  const onSelectFlag = (code: string) => {
    setIsFlagSelected(code);
  };

  const accountCondition: getAccoountNumberRequest = {
    parentId: currentUser.accountId,
    parentLevel: currentUser.accountLevel,
    parentAccountNumber: currentUser.accountNumber,
    number: "",
  };

  useEffect(() => {
    mutationCreateAccountNumber.mutate({
      payload: accountCondition, // Account -> UpdateRequest
      accountId: currentUser.accountId,
    });
  }, []);

  const [accountInfo, setAccountInfo] = useState<CreateAccountRequest>({
    //  accountId: accountId,
    accountNumber: accountNumberPrefix.concat(accountNumber),
    dateFormat: option.dateFormatOptions[2].value,
    level:
      currentUser.accountLevel === "CP" && currentUser.parentLevel === "CP"
        ? LEVEL_TYPE.EU
        : LEVEL_TYPE.CP,
    parentLevel: currentUser.accountLevel,
    name: "",
    parentId: currentUser.accountId,
    timeFormat: option.timeFormatOptions[1].value,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    accountAdditionalNumber: "",
    pricing: option.pricingOptions[1].value,
    use2faAuth: true,
    inviteEmail: "",
    inviteName: "",
    description: "",
  });

  const mutationCreateAccountNumber = useMutation(getAccoountNumber, {
    onSuccess: (res: any) => {
      setAccountNumber(res.result.number);
      setAccountInfo((info) => {
        return {
          ...info,
          accountNumber: accountNumberPrefix.concat(
            res.result.number as string
          ),
        };
      });
      setIsLoading(true);
    },
    onError: (e: any) => {
      setIsLoading(false);
      notify(
        "error",
        intl.formatMessage({
          id: "validateMsg.account.generateId.fail",
          defaultMessage: "Failed to generate organization number",
        })
      );
    },
  });

  const onChangeAccountInfo = useCallback(
    (type: UPDATE_TYPE, value: string | number | boolean | LEVEL_TYPE) => {
      if (type === UPDATE_TYPE.NAME) {
        setAccountInfo((info) => {
          return { ...info, name: value as string };
        });
      } else if (type === UPDATE_TYPE.LEVEL) {
        // level 설정 client/server 상위 선택못하게 막아야 함
        setAccountInfo((info) => {
          return { ...info, level: value as LEVEL_TYPE };
        });
        if (value === LEVEL_TYPE.EU) {
          setAccountNumberPrefix("E");
          setAccountInfo((info) => {
            return { ...info, accountNumber: "E".concat(accountNumber) };
          });
        } else {
          setAccountNumberPrefix("S");
          setAccountInfo((info) => {
            return { ...info, accountNumber: "S".concat(accountNumber) };
          });
        }
      } else if (type === UPDATE_TYPE.TIME_ZONE) {
        setAccountInfo((info) => {
          return { ...info, timezone: value as string };
        });
      } else if (type === UPDATE_TYPE.DATE_FORMAT) {
        setAccountInfo((info) => {
          return { ...info, dateFormat: value as string };
        });
      } else if (type === UPDATE_TYPE.TIME_FORMAT) {
        setAccountInfo((info) => {
          return { ...info, timeFormat: value as string };
        });
      } else if (type === UPDATE_TYPE.ACCOUNT_NUMBER) {
        setAccountInfo((info) => {
          return { ...info, accountNumber: value as string };
        });
      } else if (type === UPDATE_TYPE.INVITE_NAME) {
        setAccountInfo((info) => {
          return { ...info, inviteName: value as string };
        });
      } else if (type === UPDATE_TYPE.INVITE_EMAIL) {
        setAccountInfo((info) => {
          return { ...info, inviteEmail: (value as string).trim() };
        });
      } else if (type === UPDATE_TYPE.INVITE_PHONE_NUMBER) {
        setAccountInfo((info) => {
          return { ...info, invitePhone: value as string };
        });
      } else if (type === UPDATE_TYPE.TWO_FACTOR_AUTH) {
        setAccountInfo((info) => {
          return { ...info, use2faAuth: value as boolean };
        });
      } else if (type === UPDATE_TYPE.ADDITIONAL_ACCOUNT_NUMBER) {
        setAccountInfo((info) => {
          return { ...info, accountAdditionalNumber: value as string };
        });
      }
    },
    [accountNumber]
  );

  return (
    <>
      {isLoading && (
        <AddModalPresenter
          onClickCreate={() => props.onClickCreate(accountInfo)}
          onChageAccountInfo={onChangeAccountInfo}
          accountNumberPrefix={accountNumberPrefix}
          accountNumber={accountNumber}
          isToggled={isToggled}
          onChange={onChange}
          onModal={props.onModal}
          isFlagSelected={isFlagSelected}
          onSelectFlag={onSelectFlag}
          accountValidate={props.accountValidate as Validate}
          accountValidateMsg={props.accountValidateMsg as ValidateMsg}
          parentLevel={currentUser.parentLevel}
          currentLevel={currentUser.accountLevel}
          isCreationLoading={props.isCreateLoading}
        />
      )}
    </>
  );
}
